import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import errorFunctionComponent from "../../../common/errorFunctionComponent";

const DistributorDashboard = () => {
  const router = useHistory();
  useEffect(() => {
    router.push("/distributor/customers");
  }, []);

  return <React.Fragment>{/* <div>Manager Dashboard</div> */}</React.Fragment>;
};

export default DistributorDashboard;
