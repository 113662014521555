import React, { useState } from "react";
import {
  CardBody,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { patch } from "../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import { toastSuccess } from "../../../../common/utils/commonFunctions";

const ProfileTabOverview = ({ profileInfos, renderState, setRender }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [auth_method, set_auth_method] = useState(profileInfos.auth_method);
  const [username, set_username] = useState(profileInfos.username);
  const [phone_number, set_phone_number] = useState(profileInfos.phone_number);
  const [email, set_email] = useState(profileInfos.email);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>Please scan the QR code in Authenticator</p>,
      html: `<img src="data:image/png;base64,${msg}" width="200" height="200">`,
      showConfirmButton: true,
      confirmButtonColor: "#61FF00",
      focusConfirm: false,
    });
  };

  const updateProfile = async () => {
    paceFunction(true);
    let data = {
      email,
      phone_number,
      username,
      authentication: auth_method,
    };

    togglePopover();
    try {
      let result = await patch("/admin/profile", data);
      if (result?.success) {
        if (result?.data?.authentication?.qr_image_base64) {
          showSweetAlert(result.data.authentication.qr_image_base64);
        }
        toastSuccess(result?.message || "Account Updated");
        setRender(!renderState);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <Popover
        placement="top"
        isOpen={popoverOpen}
        target="confirmationTooltip"
        id="confirmation"
        toggle={togglePopover}
      >
        <PopoverHeader>Are you sure ?</PopoverHeader>
        <PopoverBody>
          <Button onClick={updateProfile} color="success">
            Yes
          </Button>
          <Button onClick={togglePopover} color="danger">
            No
          </Button>
        </PopoverBody>{" "}
      </Popover>
      <div className="special-card mt-5 ">
        <CardBody>
          <form id="customer_detail_form" method="post">
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Account Email :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-email-multiple-outline" />
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="email"
                      defaultValue={email}
                      onChange={(e) => set_email(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Phone Number :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-phone" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="phone_number"
                      defaultValue={phone_number}
                      onChange={(e) => set_phone_number(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Username :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-office-building-outline" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="username"
                      placeholder="Type Organization Name"
                      defaultValue={username}
                      onChange={(e) => set_username(e.target.value)}
                      required
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Authentication :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <div className="btn-group btn-group-sm   " role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faNone1"
                        defaultValue="None"
                        autoComplete="off"
                        defaultChecked={auth_method === "None" ? true : false}
                        onChange={(e) => set_auth_method(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faNone1"
                      >
                        None
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faSms1"
                        defaultValue="SMS"
                        autoComplete="off"
                        defaultChecked={auth_method === "SMS" ? true : false}
                        onChange={(e) => set_auth_method(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faSms1"
                      >
                        SMS
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faQr1"
                        defaultValue="QR"
                        autoComplete="off"
                        defaultChecked={auth_method === "QR" ? true : false}
                        onChange={(e) => set_auth_method(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faQr1"
                      >
                        QR
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-5" />
            <div className="d-flex justify-content-end ">
              <button
                onClick={togglePopover}
                type="button"
                id="confirmationTooltip"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Save
                Changes
              </button>
            </div>
          </form>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default ProfileTabOverview;
