import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const dountchart = ({ data, colors, total }) => {
  const keys = Object.keys(data);
  const values = Object.values(data);
  const [options, setOptions] = useState({
    plotOptions: {
      tooltip: {
        enabled: false, // Tooltip'i gizle
        show: false,
      },
      dataLabels: {
        enabled: false, // Etiketleri gizle
        show: false,
      },
      pie: {
        donut: {
          labels: {
            show: false,
            total: {
              show: true,
              label: "Total",
              fontSize: "15px",
              color: "rgb(188, 193, 200)",
              formatter: () => total,
            },
          },
        },
      },
    },
    labels: keys,
    colors: colors,
    legend: {
      show: false,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      // {
      //   breakpoint: 600,
      //   options: {
      //     chart: {
      //       height: 240,
      //     },
      //     legend: {
      //       show: false,
      //     },
      //   },
      // },
    ],
  });

  return (
    <>
      <div>
        <ReactApexChart
          options={options}
          series={values}
          type="donut"
          height="200"
          width="200"
        />
      </div>
    </>
  );
};

export default dountchart;
