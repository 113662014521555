import React, { useState } from "react";
import { Modal } from "reactstrap";
import { del } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const ApplianceSubnetsList = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  company_name,
  data,
  userId,
  getApplianceSubnetList,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard2, setmodal_standard2] = useState(false);
  const [subnetId, set_subnetId] = useState();

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const deleteSubnet = async () => {
    paceFunction(true);
    try {
      let result = await del(
        `/admin/customer/endpoint/subnets?id=${userId}&subnet_id=${subnetId}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Subnet Deleted");
        tog_standard2();
        getApplianceSubnetList();
      }
    } catch (error) {
      errorToastFunction(error);
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={deleteSubnet}
        text="Are you sure you want to delete subnet?"
        loadingAction={false}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {company_name} Appliance Subnet List
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          {" "}
          <table className="table table-border table-hover table-centered m-0">
            <thead className="table-light">
              <tr>
                <th>&nbsp;</th>
                <th>Address</th>
                <th>First Seen</th>
                <th>Last Seen</th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {data?.map((item, index) => {
                const firstSeen = new Date(item?.first_seen);
                const lastFirstSeen = new Intl.DateTimeFormat("en-EN", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                }).format(firstSeen);
                const lastSeen = new Date(item?.last_seen);
                const lastLastSeen = new Intl.DateTimeFormat("en-EN", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                  hour: "numeric",
                  minute: "numeric",
                  second: "numeric",
                }).format(lastSeen);
                return (
                  <tr key={index}>
                    <td>
                      {Number(index) + 1}
                      {`)`}
                    </td>
                    <td>{item?.address}</td>
                    <td>{lastFirstSeen}</td>
                    <td>{lastLastSeen}</td>
                    <td>
                      <button
                        className="btn btn-sm btn-primary"
                        type="button"
                        onClick={() => {
                          set_subnetId(item?.id);
                          tog_standard2();
                        }}
                      >
                        <span>Delete</span>
                      </button>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {data?.length === 0 && (
            <h5 className="text-center mt-4 ">There is no appliance subnet.</h5>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ApplianceSubnetsList;
