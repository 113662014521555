import React from "react";
import { Modal } from "reactstrap";

function ApplianceInfo({
  modal_standard,
  tog_standard,
  setmodal_standard,
  data,
}) {
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 fs-4 " id="myModalLabel">
            Appliance Config
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="d-flex justify-content-center ">
          <div className="row my-4 w-75 ">
            <span className="badge badge-soft-primary py-2 my-1 ">
              Uuid : {data?.uuid}
            </span>
            <span className="badge badge-soft-primary py-2 my-1 ">
              Port - Proto : {data?.vpn_service}
            </span>
            <span className="badge badge-soft-primary py-2 my-1 ">
              VPN Iface : {data?.vpn_iface}
            </span>
            <span className="badge badge-soft-primary py-2 my-1 ">
              VPN Name : {data?.vpn_server_name}
            </span>
            <span className="badge badge-soft-primary py-2 my-1 ">
              VPN Address : {data?.vpn_address}
            </span>
            <span className="badge badge-soft-primary py-2 my-1 ">
              VPN Service : {data?.vpn_service}
            </span>
            <span className="badge badge-soft-primary py-2 my-1 ">
              Sync Token : {data?.sync_token || "-"}
            </span>
            <span className="badge badge-soft-primary py-2 my-1 ">
              Connect :{" "}
              {data?.is_connect ? (
                <span>
                  <i
                    title="Connected"
                    className="mdi mdi-lan-connect me-3 text-success"
                  />
                </span>
              ) : (
                <span>
                  <i
                    title="Disconnected"
                    className="mdi mdi-lan-disconnect me-3 text-danger"
                  />
                </span>
              )}
            </span>
          </div>
        </div>
        <div className="d-flex justify-content-center my-4 ">
          <button
            type="button"
            className="btn btn-outline-primary  w-50"
            onClick={() => setmodal_standard(false)}
          >
            <i className="bx bx-check-double label-icon me-1 "></i>
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
}

export default React.memo(ApplianceInfo);
