import React, { useState } from "react";
import { Modal } from "reactstrap";
import { del } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const ManagerDeleteQuestionModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  rowId,
  getManagers,
  toggleDropdown,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [deleteLoading, set_deleteLoading] = useState(false);
  const [delete_account, set_delete_account] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const deleteFunc = async () => {
    paceFunction(true);
    set_deleteLoading(true);
    try {
      let result = await del(
        `/distributor/manager?id=${rowId}&delete_account=${delete_account}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Manager Removed");
        toggleDropdown();
        tog_standard5();
        tog_standard();
        getManagers();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_deleteLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Delete Manager */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteFunc}
        text="Are you sure you want to delete manager?"
        loadingAction={true}
        isLoading={deleteLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            PentestBX asks
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Do you want the manager's customers to be deleted?</h5>
          <div className="square-switch mt-3 text-center">
            <input
              type="checkbox"
              id="admin_manager_delete"
              name="admin_manager_delete"
              is_demo="admin_manager_delete"
              switch="primary"
              defaultChecked={delete_account}
              autoComplete="off"
              onChange={(e) => set_delete_account(e.target.checked)}
            />
            <label
              htmlFor="admin_manager_delete"
              data-on-label="Yes"
              data-off-label="No"
            />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Yes
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ManagerDeleteQuestionModal;
