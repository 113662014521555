import React, { useEffect, useState } from "react";
import { get } from "../../../helpers/api_helper";
import ManagerDetails from "../../../components/Admin/Managers/ManagerDetails";
import { useParams } from "react-router-dom";
import { Spinner } from "reactstrap";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const ManagerDetailsMain = () => {
  const { id } = useParams();

  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const getUserDetails = async () => {
    paceFunction(true);
    setIsLoading(true);
    try {
      let result = await get(`/admin/manager?id=${id}`);
      if (result.success) setData(result.data);
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    getUserDetails();
  }, []);

  return (
    <React.Fragment>
      {isLoading && (
        <div className="d-flex justify-content-center  align-items-center h-100  ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            className="ms-2 "
            color="primary"
          />
        </div>
      )}
      {!isLoading && data && <ManagerDetails data={data} />}
    </React.Fragment>
  );
};

export default ManagerDetailsMain;
