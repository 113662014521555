import axios from "axios";
// import accessToken from "./jwt-token-access/accessToken";

//pass new generated access token here
// const token = accessToken;
// //apply base url for axios
const API_URL = process.env.REACT_APP_APIURL;
const axiosApi = axios.create({
  baseURL: API_URL,
});
const storage = JSON.parse(localStorage.getItem("authUser"));
axiosApi.defaults.headers.common["Authorization"] = JSON.parse(
  localStorage.getItem("authUser")
)
  ? `Bearer ${storage?.value.data?.access}`
  : null;

axiosApi.interceptors.response.use(
  (response) => response,
  (error) => Promise.reject(error)
);

export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data);
}

export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function patch(url, data, config = {}) {
  return axiosApi
    .patch(url, { ...data }, { ...config })
    .then((response) => response.data);
}

export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data);
}
