import React, { useState, useEffect } from "react";
import { Collapse, Modal, TabContent, TabPane, Row, Col } from "reactstrap";
import CvesDescriptionV3 from "./CvesDescriptionV3";
import CvesDescriptionV2 from "./CvesDescriptionV2";
import ReactJson from "react-json-view";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import CveDetailAddTagModal from "./CveDetailAddTagModal";
import {
  getBrightness,
  removeBodyCss,
} from "../../../../../common/utils/commonFunctions";

const CvesDetailComponent = ({ data, tags, getCveDetail }) => {
  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const router = useHistory();

  const [rowId, setRowId] = useState([]);

  const [col4, setcol4] = useState(false);
  const [col5, setcol5] = useState(true);
  const [col6, setcol6] = useState(true);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);

  const [activeTab1, setactiveTab1] = useState(data?.cvss?.v2 ? "1" : "2");

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standard2() {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  }

  const lengthMetrics = Object.keys(data?.raw_nvd_data?.metrics).length;
  const sonElemanAnahtar = Object.keys(data?.raw_nvd_data?.metrics)[
    lengthMetrics - 1
  ];
  const sonElemanDeger = data?.raw_nvd_data?.metrics[sonElemanAnahtar];
  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  const tarihFormati = {
    day: "numeric",
    month: "short",
    year: "numeric",
  };
  const published = new Date(data?.created_at);
  const publishedDate = published.toLocaleDateString("en-US", tarihFormati);
  const indexed = new Date(data?.created_at);
  const indexedDate = indexed.toLocaleDateString("en-US", tarihFormati);
  const lastModified = new Date(data?.updated_at);
  const lastModifiedDate = lastModified.toLocaleDateString(
    "en-US",
    tarihFormati
  );

  const t_col5 = () => {
    setcol5(!col5);
  };

  const t_col6 = () => {
    setcol6(!col6);
  };

  const t_col4 = () => {
    setcol4(!col4);
  };

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  useEffect(() => {
    let configIds = data?.raw_nvd_data?.configurations?.map(
      (item, index) => index
    );
    setRowId(configIds);
  }, []);

  return (
    <React.Fragment>
      {modal_standard && (
        <Modal
          isOpen={modal_standard}
          toggle={() => {
            tog_standard();
          }}
          size="lg"
        >
          <div className="modal-header">
            <h5 className="modal-title mt-0" id="myModalLabel">
              Json Data
            </h5>
            <button
              type="button"
              onClick={() => {
                setmodal_standard(false);
              }}
              className="close"
              data-dismiss="modal"
              aria-label="Close"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div className="modal-body">
            <div className="bg-reactjson">
              <ReactJson
                // style={{
                //   backgroundColor: `${layoutMode === "dark" ? "white" : ""}`,
                // }}
                src={data}
              />
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard();
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Cancel
            </button>
          </div>
        </Modal>
      )}
      {tags && (
        <CveDetailAddTagModal
          modal_standard={modal_standard2}
          tog_standard={tog_standard2}
          setmodal_standard={setmodal_standard2}
          allTags={tags}
          haveTags={data?.tags}
          getCveDetail={getCveDetail}
          id={data?.id}
        />
      )}
      <div className="row">
        <div className="col-xl-8">
          <div style={{ padding: "5px 10px" }} className="special-card">
            <div className="card-header">
              <div className="d-flex flex-column w-100 ">
                <div className="cve-modal-header col-12">
                  <div className="cve-modal-header-title col-6 col-lg-4">
                    <div className="cve-modal-header-title-icon">
                      <svg
                        version="1.0"
                        xmlns="http://www.w3.org/2000/svg"
                        // width="1200.000000pt"
                        // height="1196.000000pt"
                        viewBox="0 0 1200.000000 1196.000000"
                        // preserveAspectRatio="xMidYMid meet"
                        className="spidericon"
                      >
                        <g
                          transform="translate(0.000000,1196.000000) scale(0.100000,-0.100000)"
                          fill={
                            data?.exploits?.length > 0 ? "#ff9800" : "#aeaeae"
                          }
                          stroke="none"
                        >
                          <path d="M6570 11946 c0 -3 407 -418 905 -922 816 -827 904 -918 900 -942 -3 -15 -71 -596 -151 -1292 -80 -696 -148 -1268 -152 -1271 -4 -3 -447 -192 -984 -419 l-978 -413 0 -289 0 -288 596 0 c526 0 595 -2 590 -15 -3 -8 -131 -232 -284 -498 -153 -265 -284 -492 -290 -503 -11 -21 2 -29 233 -163 135 -77 249 -141 255 -141 8 0 1558 1162 1696 1271 6 5 483 -196 1205 -508 657 -284 1195 -517 1196 -518 1 0 155 -558 343 -1240 187 -682 343 -1241 345 -1244 3 -2 4 702 3 1564 l-3 1569 -1290 817 c-928 589 -1291 824 -1293 838 -2 11 -31 700 -66 1530 -35 831 -65 1513 -67 1515 -2 2 -598 347 -1324 766 -726 419 -1335 771 -1352 782 -18 11 -33 17 -33 14z" />
                          <path d="M4080 11164 c-740 -427 -1349 -780 -1354 -784 -5 -5 -37 -696 -72 -1536 -37 -895 -68 -1530 -73 -1534 -6 -3 -588 -372 -1295 -820 l-1286 -815 1 -1570 c0 -932 4 -1560 9 -1545 5 14 160 573 344 1244 185 670 337 1220 339 1223 3 2 317 139 698 304 382 165 912 395 1179 511 267 116 493 212 502 215 11 3 304 -212 855 -627 461 -348 844 -637 850 -642 9 -7 87 33 260 133 137 78 249 143 251 145 2 1 -130 232 -292 514 -163 281 -296 513 -296 516 0 2 268 4 595 4 l595 0 -2 292 -3 293 -974 412 c-536 227 -978 416 -982 420 -4 5 -75 589 -157 1298 l-149 1290 626 634 c753 761 1184 1201 1179 1201 -2 0 -609 -349 -1348 -776z" />
                          <path d="M5050 8260 c-514 -297 -936 -544 -938 -548 -2 -7 822 -363 840 -362 5 0 673 403 989 597 l57 35 521 -317 522 -316 417 177 c229 97 420 179 424 183 8 7 -1855 1085 -1881 1088 -9 1 -437 -241 -951 -537z" />
                          <path d="M3050 4748 l0 -1094 946 -547 c521 -301 948 -546 950 -544 3 2 -106 910 -110 914 0 0 -242 133 -536 294 -294 161 -535 293 -536 293 0 1 5 240 12 531 7 292 13 566 13 610 l1 80 -326 245 c-179 135 -346 260 -370 278 l-44 34 0 -1094z" />
                          <path d="M8564 5569 c-198 -149 -362 -274 -366 -277 -3 -4 0 -281 8 -616 7 -335 13 -610 11 -611 -1 -1 -238 -132 -527 -290 -289 -158 -529 -290 -533 -294 -8 -8 -120 -898 -115 -912 2 -4 427 238 946 538 l942 545 0 1094 c0 602 -2 1094 -4 1094 -2 0 -165 -122 -362 -271z" />
                          <path d="M5143 4737 c-192 -111 -243 -146 -243 -162 0 -11 58 -488 129 -1060 71 -572 128 -1041 127 -1041 0 -1 -467 -347 -1036 -769 -569 -422 -1040 -771 -1045 -776 -6 -4 -567 137 -1255 316 -684 179 -1245 324 -1246 322 -2 -2 606 -355 1352 -785 l1355 -782 1297 679 c713 373 1325 692 1359 709 l61 32 1357 -710 1357 -710 1354 781 c745 430 1353 783 1351 785 -1 2 -562 -143 -1246 -321 l-1242 -325 -1027 762 c-565 418 -1036 769 -1047 779 l-21 19 128 1027 c70 566 127 1042 128 1059 0 30 -7 35 -246 173 -136 79 -250 139 -254 134 -4 -4 -140 -237 -301 -516 -188 -326 -295 -503 -300 -495 -4 7 -138 238 -297 512 -159 275 -293 501 -298 502 -5 1 -118 -61 -251 -139z" />
                        </g>
                      </svg>
                    </div>
                    <div className="cve-modal-header-title-text">
                      <span>{data?.id || "-"}</span>
                    </div>
                  </div>
                  {data?.cvss?.v2 && (
                    <div
                      onClick={() => {
                        toggle1("1");
                      }}
                      className="cve-modal-header-scores col-lg-4 col-3"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className={`cve-modal-header-scores-cvss col-8 ${
                          data?.cvss?.v2 <= 10 && data?.cvss?.v2 >= 7
                            ? "bg-severity-high"
                            : data?.cvss?.v2 < 7 && data?.cvss?.v2 >= 4
                            ? "bg-severity-medium"
                            : data?.cvss?.v2 < 4 && data?.cvss?.v2 >= 0
                            ? "bg-severity-low"
                            : ""
                        } ${
                          activeTab1 === "1"
                            ? "border border-2 border-primary"
                            : ""
                        }`}
                      >
                        <i className="mdi mdi-security font-size-16" />
                        <span className="ms-1 font-size-13">
                          CVSS V2 : {data?.cvss?.v2}
                        </span>
                      </div>
                    </div>
                  )}
                  {!data?.cvss?.v2 && (
                    <div className="cve-modal-header-scores col-lg-4 col-3">
                      <div
                        className={`cve-modal-header-scores-cvss col-8 bg-severity-low`}
                      >
                        <i className="mdi mdi-security font-size-16" />
                        <span className="ms-1 font-size-13">CVSS V2 : -</span>
                      </div>
                    </div>
                  )}
                  {data?.cvss?.v3 && (
                    <div
                      onClick={() => {
                        toggle1("2");
                      }}
                      className="cve-modal-header-scores col-lg-4 col-3"
                    >
                      <div
                        style={{ cursor: "pointer" }}
                        className={`cve-modal-header-scores-cvss col-8 ${
                          data?.cvss?.v3 <= 10 && data?.cvss?.v3 >= 9
                            ? "bg-severity-critical"
                            : data?.cvss?.v3 < 9 && data?.cvss?.v3 >= 7
                            ? "bg-severity-high"
                            : data?.cvss?.v3 < 7 && data?.cvss?.v3 >= 4
                            ? "bg-severity-medium"
                            : data?.cvss?.v3 < 4 && data?.cvss?.v3 >= 0
                            ? "bg-severity-low"
                            : ""
                        }${
                          activeTab1 === "2"
                            ? " border border-2 border-primary"
                            : ""
                        }`}
                      >
                        <i className="mdi mdi-security font-size-16" />
                        <span className="ms-1 font-size-13">
                          CVSS V3 : {data?.cvss?.v3}
                        </span>
                      </div>
                    </div>
                  )}
                  {!data?.cvss?.v3 && (
                    <div className="cve-modal-header-scores col-lg-4 col-3">
                      <div
                        className={`cve-modal-header-scores-cvss col-8 bg-severity-empty`}
                      >
                        <i className="mdi mdi-security font-size-16" />
                        <span className="ms-1 font-size-13">CVSS V3: -</span>
                      </div>
                    </div>
                  )}
                </div>
                <div className="d-flex align-items-center justify-content-between px-4 mt-3   ">
                  <div className="d-flex flex-column   ">
                    <span className="fw-semibold">Published Date</span>
                    <span className="font-size-13 fw-medium  ">
                      {publishedDate || "-"}
                    </span>
                  </div>
                  <div className="d-flex flex-column   ">
                    <span className="fw-semibold">Indexed Date</span>
                    <span className="font-size-13 fw-medium  ">
                      {indexedDate || "-"}
                    </span>
                  </div>
                  <div className="d-flex flex-column   ">
                    <span className="fw-semibold">Last Modified</span>
                    <span className="font-size-13 fw-medium  ">
                      {lastModifiedDate || "-"}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-body p-0">
              <div className="tab-content  text-muted">
                <div className="tab-pane active">
                  <div className="row">
                    <TabContent
                      activeTab={activeTab1}
                      className="p-3 text-muted"
                    >
                      <TabPane tabId="1">
                        <Row>
                          <Col sm="12">
                            <div className="col-sm-12">
                              <CvesDescriptionV2
                                data={data}
                                cvss={
                                  data?.raw_nvd_data?.metrics?.cvssMetricV2
                                    ? data?.raw_nvd_data?.metrics
                                        ?.cvssMetricV2[0]
                                    : undefined
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                      <TabPane tabId="2">
                        <Row>
                          <Col sm="12">
                            <div className="col-sm-12">
                              <CvesDescriptionV3
                                data={data}
                                cvss={
                                  data?.raw_nvd_data?.metrics?.cvssMetricV31
                                    ? data?.raw_nvd_data?.metrics
                                        ?.cvssMetricV31[0]
                                    : undefined
                                }
                              />
                            </div>
                          </Col>
                        </Row>
                      </TabPane>
                    </TabContent>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {data?.exploits?.length > 0 && (
            <div className="special-card card-primary mt-2 ">
              <div className="card-header d-flex justify-content-between align-items-center  ">
                <h5 className="card-title">Repositories</h5>
                <div className="card-tools pull-right">
                  <button onClick={t_col4} type="button" className="btn ">
                    <i
                      className={`mdi mdi-${
                        col4 ? "minus" : "plus"
                      } font-size-14`}
                    />
                  </button>
                </div>
              </div>
              <div className="card-body">
                <Collapse isOpen={col4}>
                  <div>
                    {data?.exploits?.length > 0 &&
                      data?.exploits?.map(
                        (repositoriesItem, repositoriesIndex) => {
                          let createdDate = new Date(
                            repositoriesItem?.created_at
                          );
                          let updatedDate = new Date(
                            repositoriesItem?.updated_at
                          );
                          let pushedDate = new Date(
                            repositoriesItem?.pushed_at
                          );
                          const formattedCreated = `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`;
                          const formattedUpdated = `${updatedDate.toLocaleDateString()} ${updatedDate.toLocaleTimeString()}`;
                          const formattedPushed = `${pushedDate.toLocaleDateString()} ${pushedDate.toLocaleTimeString()}`;
                          return (
                            <div
                              key={`${repositoriesItem?.full_name}+${repositoriesIndex}`}
                            >
                              <div className="d-flex  justify-content-between align-items-center ">
                                <div className="d-flex flex-row align-items-center">
                                  <i className="mdi mdi-github font-size-24 text-black " />
                                  <a
                                    href={repositoriesItem?.html_url}
                                    target="_blank"
                                    rel="noreferrer"
                                    className="ms-1 text-primary font-size-16 fw-bold "
                                  >
                                    {repositoriesItem?.full_name}
                                  </a>
                                </div>
                                <div className="d-flex flex-row col-5  justify-content-between align-items-center  ">
                                  <div className="d-flex flex-row align-items-center ">
                                    <i
                                      className="mdi mdi-star font-size-18"
                                      style={{ color: "#ffa700" }}
                                    />
                                    <span className=" font-size-14 fw-bold ms-1  ">
                                      {repositoriesItem?.stargazers_count}{" "}
                                    </span>
                                  </div>
                                  <div className="d-flex flex-row align-items-center  ">
                                    <i className="mdi mdi-source-fork font-size-16" />
                                    <span className=" font-size-14 fw-bold ms-1  ">
                                      {repositoriesItem?.forks_count}{" "}
                                    </span>
                                  </div>
                                  <div className="d-flex flex-row align-items-center ">
                                    <i className="mdi mdi-eye-outline font-size-16" />
                                    <span className=" font-size-14 fw-bold ms-1  ">
                                      {repositoriesItem?.watchers_count}{" "}
                                    </span>
                                  </div>
                                </div>
                              </div>
                              <div className="d-flex align-items-center justify-content-between  my-3   ">
                                <div className="d-flex flex-column   ">
                                  <span className="fw-semibold">
                                    Created Date
                                  </span>
                                  <span className="font-size-13 fw-medium  ">
                                    {formattedCreated || "-"}
                                  </span>
                                </div>
                                <div className="d-flex flex-column   ">
                                  <span className="fw-semibold">
                                    Updated Date
                                  </span>
                                  <span className="font-size-13 fw-medium  ">
                                    {formattedUpdated || "-"}
                                  </span>
                                </div>
                                <div className="d-flex flex-column   ">
                                  <span className="fw-semibold">
                                    Pushed Date
                                  </span>
                                  <span className="font-size-13 fw-medium  ">
                                    {formattedPushed || "-"}
                                  </span>
                                </div>
                              </div>
                              <div className="font-size-14 fw-bold d-flex flex-row ">
                                {repositoriesItem?.description &&
                                  repositoriesItem?.description}
                              </div>
                              {repositoriesItem?.topics?.length > 0 &&
                                repositoriesItem?.topics?.map(
                                  (topicItem, topicIndex) => (
                                    <div
                                      className="badge bg-primary font-size-12 me-1 mt-3"
                                      key={`${topicItem}+${topicIndex}`}
                                    >
                                      {topicItem}
                                    </div>
                                  )
                                )}
                              <hr />
                            </div>
                          );
                        }
                      )}
                  </div>
                </Collapse>
              </div>
            </div>
          )}
          <div className="special-card card-primary mt-2 ">
            <div className="card-header d-flex justify-content-between align-items-center  ">
              <h5 className="card-title">References</h5>
              <div className="card-tools pull-right">
                <button onClick={t_col5} type="button" className="btn ">
                  <i
                    className={`mdi mdi-${
                      col5 ? "minus" : "plus"
                    } font-size-14`}
                  />
                </button>
              </div>
            </div>
            <div className="card-body">
              <Collapse isOpen={col5}>
                <table className="table table-border table-hover table-centered col-12">
                  <thead>
                    <tr>
                      <th>Link</th>
                      <th>Resource</th>
                    </tr>
                  </thead>
                  <tbody>
                    {data?.raw_nvd_data?.references &&
                      data?.raw_nvd_data?.references.length > 0 &&
                      data?.raw_nvd_data?.references.map((ref, index) => (
                        <tr key={`ref-${index}`}>
                          <td className="col-md-8">
                            <a
                              href={ref?.url}
                              target="_blank"
                              rel="noreferrer"
                              className="hover"
                            >
                              {ref?.url.slice(0, 50)}
                              {ref?.url?.length > 50 && "..."}
                            </a>
                          </td>
                          <td className="col-md-4">
                            {ref?.tags &&
                              ref?.tags.map((tag, i) => (
                                <span key={i} className="badge bg-info me-1">
                                  {tag}
                                </span>
                              ))}
                          </td>
                        </tr>
                      ))}
                  </tbody>
                </table>
                {data?.raw_nvd_data?.references &&
                  data?.raw_nvd_data?.references.length < 1 && (
                    <div className="text-center bg-info w-100 py-2 text-white  ">
                      No reference
                    </div>
                  )}
              </Collapse>
            </div>
          </div>
          <div className="special-card card-primary my-2 ">
            <div className="card-header d-flex justify-content-between align-items-center  ">
              <h5 className="card-title">Configurations</h5>
              <div className="card-tools pull-right">
                <button onClick={t_col6} type="button" className="btn ">
                  <i
                    className={`mdi mdi-${
                      col6 ? "minus" : "plus"
                    } font-size-14`}
                  />
                </button>
              </div>
            </div>
            <div className="card-body">
              <Collapse isOpen={col6}>
                {data?.raw_nvd_data?.configurations &&
                  data?.raw_nvd_data?.configurations.map(
                    (config, configIndex) => {
                      return (
                        <React.Fragment key={`config-${configIndex}`}>
                          <div className="box-body">
                            <p>
                              <strong>Configuration {configIndex + 1}</strong> (
                              <span
                                className="pointer text-primary"
                                style={{ cursor: "pointer" }}
                                id={`config-${configIndex + 1}`}
                                onClick={() => rowIdFunc(configIndex)}
                              >
                                {rowId.some((item) => item === configIndex)
                                  ? "Hide"
                                  : "View"}
                              </span>
                              )
                            </p>
                            {rowId.some((item) => item === configIndex) && (
                              <table
                                key={`config-${configIndex + 1}-table`}
                                className="table table-configuration"
                                id={`config-${configIndex + 1}-table`}
                              >
                                <tbody>
                                  <tr>
                                    {config?.operator && (
                                      <td
                                        className="col-md-1 rowspaned-cves"
                                        rowSpan={config?.nodes?.length + 1}
                                      >
                                        {config?.operator}
                                      </td>
                                    )}
                                    <td className="col-md-11 and">
                                      {config?.nodes.map((node, nodeIndex) => (
                                        <table
                                          key={`config-${
                                            configIndex + 1
                                          }-node-${nodeIndex}`}
                                          className="table table-bordered no-margin-bottom"
                                        >
                                          <tbody>
                                            <tr>
                                              {node?.operator &&
                                                node?.cpeMatch?.length > 1 && (
                                                  <td
                                                    className="col-md-1 rowspaned-cves"
                                                    rowSpan={
                                                      node?.cpeMatch?.length + 1
                                                    }
                                                  >
                                                    {node?.operator}
                                                  </td>
                                                )}
                                            </tr>
                                            {node?.cpeMatch?.map(
                                              (cpe, cpeIndex) => (
                                                <tr
                                                  key={`cpe-${configIndex}-${nodeIndex}-${cpeIndex}`}
                                                >
                                                  <td>{cpe?.criteria}</td>
                                                </tr>
                                              )
                                            )}
                                          </tbody>
                                        </table>
                                      ))}
                                    </td>
                                  </tr>
                                </tbody>
                              </table>
                            )}
                          </div>
                          <hr />
                        </React.Fragment>
                      );
                    }
                  )}
                {!data?.raw_nvd_data?.configurations && (
                  <div className="text-center py-2 bg-info text-white">
                    No configuration
                  </div>
                )}
              </Collapse>
            </div>
          </div>
        </div>
        <div className="col-xl-4 mt-xl-0 mt-2">
          <div className="special-card card-primary mb-2 ">
            <div className="card-header with-border d-flex justify-content-between  align-items-center ">
              <h5 className="card-title">Tags</h5>
              <i
                onClick={tog_standard2}
                style={{ cursor: "pointer" }}
                className="mdi mdi-plus font-size-20 py-0"
                title="Edit Tags"
              />
            </div>
            <div className="card-body">
              {data?.tags?.map((tagItem, tagIndex) => (
                <span
                  onClick={() =>
                    router.push(
                      `/cyber-watch/threat-intelligence/cves?page=1&tag=${tagItem?.name}`
                    )
                  }
                  key={tagIndex}
                  className="badge p-2 me-1 mb-1 "
                  style={{
                    color: `${
                      getBrightness(tagItem?.color) < 128 ? "white" : "black"
                    }`,
                    backgroundColor: tagItem?.color,
                    cursor: "pointer",
                  }}
                >
                  {tagItem?.name}
                </span>
              ))}
              {data?.tags?.length < 1 && (
                <div className="text-center py-2 bg-info text-white">
                  No tags
                </div>
              )}
            </div>
          </div>
          <div className="special-card card-primary mb-2">
            <div className="card-header">
              <h5 className="card-title">Information</h5>
            </div>
            <div className="card-body">
              <div className="d-flex flex-row align-items-center mb-2 font-size-15">
                <i className="mdi mdi-arrow-top-right-bold-box-outline  text-primary   " />
                <strong className="mx-1 ">NVD link:</strong>
                <a
                  href={`https://nvd.nist.gov/vuln/detail/${data?.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="fw-bold hover"
                >
                  {data?.id}
                </a>
              </div>
              <div className="d-flex flex-row align-items-center mb-2 font-size-15">
                <i className="mdi mdi-arrow-top-right-bold-box-outline  text-primary   " />
                <strong className="mx-1   ">Mitre link:</strong>
                <a
                  href={`https://cve.mitre.org/cgi-bin/cvename.cgi?name=${data?.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="fw-bold hover"
                >
                  {data?.id}
                </a>
              </div>
              <div className="d-flex flex-row align-items-center mb-2 font-size-15">
                <i className="mdi mdi-arrow-top-right-bold-box-outline  text-primary   " />
                <strong className="mx-1 ">CVE.ORG link:</strong>
                <a
                  href={`https://www.cve.org/CVERecord?id=${data?.id}`}
                  target="_blank"
                  rel="noreferrer"
                  className="fw-bold hover"
                >
                  {data?.id}
                </a>
              </div>
              <hr />
              <p>
                <strong>
                  <i className="fa fa-code" /> Raw (JSON) :
                </strong>
                <span
                  style={{ cursor: "pointer" }}
                  className="ms-1 text-primary fw-semibold hover "
                  onClick={tog_standard}
                >
                  View
                </span>
              </p>
            </div>
          </div>
          <div className="special-card card-primary mb-2">
            <div className="card-header">
              <h5 className="card-title">Products Affected</h5>
            </div>
            <div className="card-body">
              {Object.entries(data?.vendors).map(([category, versions]) => (
                <div key={category}>
                  <p>
                    <strong>{category}</strong>
                  </p>
                  <ul>
                    {versions &&
                      versions?.map((version, index) => (
                        <li key={index}>{version}</li>
                      ))}
                  </ul>
                </div>
              ))}

              {Object.keys(data?.vendors).length < 1 && (
                <div className="text-center py-2 bg-info text-white">
                  No product affected
                </div>
              )}
            </div>
          </div>
          {data?.cwes && (
            <div className="special-card card-primary mb-2">
              <div className="card-header">
                <h5 className="card-title">CWE</h5>
              </div>
              <div className="card-body">
                {data?.cwes?.length > 0 && (
                  <ul>
                    {data?.cwes?.map((cwe, cweIndex) => (
                      <li key={cweIndex}>
                        <span
                          onClick={() =>
                            router.push(
                              `/cyber-watch/threat-intelligence/cves?page=1&cwe=${cwe}`
                            )
                          }
                          className="text-primary fw-bold hover"
                          style={{ cursor: "pointer" }}
                        >
                          {cwe}
                        </span>
                      </li>
                    ))}
                  </ul>
                )}
                {data?.cwes?.length < 1 && (
                  <div className="text-center py-2 bg-info text-white w-100 ">
                    No cwes
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default CvesDetailComponent;
