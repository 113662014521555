import React, { useRef, useState } from "react";
import {
  getScoreByTitle,
  getTotalByTitle,
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import DynamicLastScoreModule from "./DynamicLastScoreModule";
import DynamicSeverityCount from "./DynamicSeverityCount";
import DynamicGraphics from "./DynamicGraphics";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const DynamicWizard = ({ wizard, data, getWidgets }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [viewEdit, set_viewEdit] = useState(false);

  const [id, set_id] = useState();

  const dropdownRef = useRef(null);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  let score;
  let total;
  if (wizard.type === "last_module_score" && !wizard.graphic) {
    score = getScoreByTitle(data.scores, wizard.values[0]);
  }
  if (wizard.type === "total_severity_count" && !wizard.graphic) {
    total = getTotalByTitle(data.weekly_overview, wizard.values[0]);
  }

  const deleteWidget = () => {
    let storageWidgets = window.localStorage.getItem("widgets");
    if (storageWidgets) {
      let parsedStorageWidgets = JSON.parse(storageWidgets);
      let filteredStorageWidgets = parsedStorageWidgets.filter(
        (item) => item.id !== id
      );
      if (filteredStorageWidgets.length > 0) {
        window.localStorage.setItem(
          "widgets",
          JSON.stringify(filteredStorageWidgets)
        );
        getWidgets();
        tog_standard5();
        toastSuccess("Widget Deleted");
      } else {
        window.localStorage.removeItem("widgets");
        getWidgets();
        tog_standard5();
        toastSuccess("Widget Deleted");
      }
    }
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteWidget}
        text="Are you sure want to delete widget ?"
        loadingAction={false}
      />
      <div
        className="col-xxl-4  col-md-6 mb-4"
        style={{ position: "relative" }}
        onMouseEnter={() => set_viewEdit(true)}
        onMouseLeave={() => set_viewEdit(false)}
      >
        {wizard.type === "last_module_score" && !wizard.graphic && (
          <DynamicLastScoreModule title={wizard.title} score={score} />
        )}
        {wizard.type === "total_severity_count" && !wizard.graphic && (
          <DynamicSeverityCount
            title={wizard.title}
            total={total}
            color={wizard.color}
          />
        )}
        {wizard.type === "total_severity_count" && wizard.graphic && (
          <DynamicGraphics wizard={wizard} data={data} />
        )}
        {/* <div
        className={`dropdown-host ${isOpen ? "open" : ""} ${
          viewEdit ? "d-flex" : "d-none"
        }`}
        ref={dropdownRef}
        style={{ position: "absolute", top: 0, right: 0 }}
      >
        <div
          className={`selected-option-host `}
          style={{ border: "none" }}
          onClick={toggleDropdown}
        >
          <i className="mdi mdi-dots-horizontal text-primary fs-3 " />
        </div>
        <ul className="options-host">
          <li onClick={() => {}} className="fs-6">
            Edit
          </li>
          <li onClick={() => {}} className="fs-6">
            Delete
          </li>
        </ul>
      </div> */}
        <div
          className={` cursor-pointer ${viewEdit ? "d-flex" : "d-none"}`}
          style={{ position: "absolute", top: 5, right: 15 }}
          onClick={() => {
            tog_standard5();
            set_id(wizard.id);
          }}
        >
          <i className="mdi mdi-delete text-primary fs-3 " />
        </div>
      </div>
    </React.Fragment>
  );
};

export default DynamicWizard;
