import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import { post } from "../../../helpers/api_helper";
import DropdownHelp from "./DropdownHelp";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import { useHistory } from "react-router-dom";
import { toastSuccess } from "../../../../common/utils/commonFunctions";
function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function VulnerabilitiesTable({
  data,
  entryValue,
  changeValue,
  updated_at,
  hosts,
  services,
  moduleID,
  getVulnerabilities,
}) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  // Seem
  const [seeFirst, setSeeFirst] = useState(true);
  const [seeSecond, setSeeSecond] = useState(true);
  const [seeThird, setSeeThird] = useState(true);
  const [seeFourth, setSeeFourth] = useState(true);
  // Others
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [rowId, setRowId] = useState([]);
  const [vulnsDetails, setVulnsDetails] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const router = useHistory();

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "first_name",
      },
      {
        Header: "Severity",
        accessor: "severity_annotation",
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Family",
        accessor: "vuln_family",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };
  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };
  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };
  const getVulnsDetails = async (row) => {
    paceFunction(true);
    let tempVulnsDetails = vulnsDetails;
    let tempPort;
    tempPort = services?.map((item) => item?.port);
    let vulnDetsData = {
      date: updated_at,
      id: hosts?.map((item) => item?.id),
      port: tempPort,
      vuln_id: row.original.id,
    };
    let isCheck = tempVulnsDetails.some(
      (item) => item.vuln_id === row.original.id
    );
    if (!isCheck) {
      try {
        let result = await post(
          `/customer/module/host/vuln-info`,
          vulnDetsData
        );
        if (result?.success && result?.data?.length > 0) {
          tempVulnsDetails = [...tempVulnsDetails, result?.data[0]];
          setVulnsDetails(tempVulnsDetails);
          rowIdFunc(row.id);
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      rowIdFunc(row.id);
    }
    paceFunction(false);
  };

  let previous;
  const convertJSONToCSV = (data) => {
    const csvRows = data.map((item) => {
      let lastSeverity = "";
      if (item?.severity_annotation === 1) lastSeverity = "Low";
      if (item?.severity_annotation === 2) lastSeverity = "Medium";
      if (item?.severity_annotation === 3) lastSeverity = "High";
      if (item?.severity_annotation === 4) lastSeverity = "Critical";
      if (item?.severity_annotation === 0) lastSeverity = "Info";
      return `${lastSeverity};${item?.name};${item?.vuln_family}`;
    });

    const csvContent = csvRows.join("\n");
    const csvHeader = "Severity;Name;Family"; // Sütun başlıkları

    return `${csvHeader}\n${csvContent}`;
  };

  const downloadCSV = (data) => {
    // JSON verisini CSV'ye dönüştür
    const csvData = convertJSONToCSV(data);
    // CSV dosyasını indirme işlemi
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "Hosts.csv";

    if (window.navigator.msSaveBlob) {
      // IE 10+ için
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Diğer tarayıcılar için
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values?.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons  justify-content-center d-flex ">
            <button
              title="Copy Clipboard"
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                const csvHeader = "Severity,Name,Family";

                const csvRows = data.map((item) => {
                  let lastSeverity = "";
                  if (item?.severity_annotation === 1) lastSeverity = "Low";
                  if (item?.severity_annotation === 2) lastSeverity = "Medium";
                  if (item?.severity_annotation === 3) lastSeverity = "High";
                  if (item?.severity_annotation === 4)
                    lastSeverity = "Critical";
                  if (item?.severity_annotation === 0) lastSeverity = "Info";
                  return `${lastSeverity},${item?.name},${item?.vuln_family}`;
                });

                const csvContent = [csvHeader, ...csvRows].join("\n");

                var table = document.createElement("table");
                var tableBody = document.createElement("tbody");

                var rows = csvContent.split("\n");
                for (var i = 0; i < rows.length; i++) {
                  var row = document.createElement("tr");
                  var cells = rows[i].split("\t");
                  for (var j = 0; j < cells.length; j++) {
                    var cell = document.createElement(i === 0 ? "th" : "td");
                    cell.appendChild(document.createTextNode(cells[j]));
                    row.appendChild(cell);
                  }
                  tableBody.appendChild(row);
                }

                table.appendChild(tableBody);
                document.body.appendChild(table);

                var range = document.createRange();
                range.selectNode(table);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                try {
                  document.execCommand("copy");
                  toastSuccess("All Rows Copied");
                } catch (err) {
                  console.error("Panoya kopyalama hatası:", err);
                }

                table.remove();
                window.getSelection().removeAllRanges();
              }}
            >
              <span className="avatar-title bg-transparent">
                <i className="mdi mdi-content-copy  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(data);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <Dropdown
              isOpen={btnprimary1}
              toggle={() => setBtnprimary1(!btnprimary1)}
            >
              <DropdownToggle
                title="Column Visibility"
                tag="button"
                className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              >
                <i className="mdi mdi-eye" />
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                  <span className="visually-hidden">unread messages</span>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSeeFirst(!seeFirst);
                  }}
                  className={`${seeFirst ? "bg-soft-primary" : ""}`}
                >
                  Severity
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeThird(!seeThird);
                  }}
                  className={`${seeThird ? "bg-soft-primary" : ""}`}
                >
                  Name
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeFourth(!seeFourth);
                  }}
                  className={`${seeFourth ? "bg-soft-primary" : ""}`}
                >
                  Family
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <div style={{ maxHeight: "1000px", overflowY: "scroll" }}>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th className="col-1">{headerGroup.headers[0].Header}</th>
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  style={{ width: "200px" }}
                  className={`${seeFirst ? "" : "d-none "}`}
                >
                  {headerGroup.headers[1].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[2].getHeaderProps(
                    headerGroup.headers[2].getSortByToggleProps()
                  )}
                  className={`${seeSecond ? "" : "d-none "}`}
                >
                  {headerGroup.headers[2].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[2].isSorted
                      ? headerGroup.headers[2].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[3].getHeaderProps(
                    headerGroup.headers[3].getSortByToggleProps()
                  )}
                  className={`${seeThird ? "" : "d-none "}`}
                >
                  {headerGroup.headers[3].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[3].isSorted
                      ? headerGroup.headers[3].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                {/* {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {" "}
                  {column.render("Header")}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))} */}
              </tr>
            ))}
          </thead>

          {data.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                const isDifferentFamily = previous !== row.original.vuln_family;
                previous = row.original.vuln_family;
                return (
                  <React.Fragment key={index}>
                    {isDifferentFamily && (
                      <tr
                        style={{ backgroundColor: "#6c7277", color: "white" }}
                        className="text-center fw-bold "
                      >
                        <td>{row?.original?.vuln_family}</td>
                      </tr>
                    )}
                    <tr
                      className={`${
                        rowId.some((item) => item === row.id)
                          ? "bg-soft-primary"
                          : ""
                      }`}
                      style={{ width: "100%", cursor: "pointer" }}
                      {...row.getRowProps()}
                    >
                      <td
                        style={{ cursor: "pointer" }}
                        onClick={() => {
                          getVulnsDetails(row);
                        }}
                        className="col-1"
                      >
                        {vulnsDetails.some(
                          (item) => item?.vuln_id === row?.original.id
                        ) && rowId.some((item) => item === row.id) ? (
                          <i className="fa fa-chevron-down" />
                        ) : (
                          <i className="fa fa-chevron-right" />
                        )}
                      </td>
                      {seeFirst && (
                        <td style={{ width: "200px" }} className="">
                          {row.cells[1].value === 1 ? (
                            <span className="badge bg-severity-low font-size-18">
                              Low
                            </span>
                          ) : row.cells[1].value === 2 ? (
                            <span className="badge bg-severity-medium font-size-18">
                              Medium
                            </span>
                          ) : row.cells[1].value === 3 ? (
                            <span className="badge bg-severity-high font-size-18">
                              High
                            </span>
                          ) : row.cells[1].value === 4 ? (
                            <span className="badge bg-severity-critical font-size-18">
                              Critical
                            </span>
                          ) : (
                            <span className="badge bg-severity-info font-size-18">
                              Info
                            </span>
                          )}
                        </td>
                      )}
                      {seeThird && (
                        <td className="col">{row.cells[2].value}</td>
                      )}
                      {seeFourth && (
                        <td className="col">{row.cells[3].value}</td>
                      )}
                      {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                    </tr>
                    <AnimatePresence>
                      {rowId.some((item) => item === row.id) &&
                        vulnsDetails.some(
                          (item) => item?.vuln_id === row?.original?.id
                        ) && (
                          <motion.tr
                            // style={{ backgroundColor: "#f8f8f8" }}
                            initial={{ y: -100, opacity: 0 }}
                            animate={{ y: 0, opacity: 1 }}
                            exit={{ y: 0, opacity: 0 }}
                            transition={{ duration: 0.4 }}
                          >
                            {vulnsDetails?.map((vuln) => {
                              if (vuln?.vuln_id === row.original.id) {
                                return (
                                  <td key={vuln?.vuln_id}>
                                    <div
                                      className="row"
                                      style={{ padding: "40px" }}
                                    >
                                      <div className="col-lg-9">
                                        <div className="row">
                                          <h5 className="mb-3">Description</h5>
                                          <p>{vuln?.description}</p>
                                          <h5 className="mb-3">Solution</h5>
                                          <p>{vuln?.solution}</p>
                                          <hr />
                                          {vuln?.affected_hosts &&
                                            vuln?.affected_hosts?.length >
                                              0 && (
                                              <h5 className="mb-3">
                                                Affected Hosts
                                              </h5>
                                            )}
                                        </div>
                                        <div
                                          style={{
                                            maxHeight: "500px",
                                            overflowY: "overlay",
                                          }}
                                          className="row mb-10"
                                        >
                                          {vuln?.affected_hosts &&
                                            vuln?.affected_hosts?.length > 0 &&
                                            vuln?.affected_hosts?.map(
                                              (affect, index) => (
                                                <div
                                                  key={index}
                                                  className="col-lg-6 h-100 "
                                                >
                                                  <div className="special-card my-3 h-100  ">
                                                    <div className="row g-0 align-items-center">
                                                      <div className="col-md-12">
                                                        <div className="card-body">
                                                          <div className="row mb-3">
                                                            <div className="col-md-12 text-primary font-size-24">
                                                              <i className="mdi mdi-monitor me-1 " />
                                                              Host
                                                            </div>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>Name:</b>{" "}
                                                              {affect?.host__name ||
                                                                "-"}
                                                            </label>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>Address:</b>
                                                              {affect?.address ||
                                                                "-"}
                                                            </label>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>Mac:</b>{" "}
                                                              {affect?.mac ||
                                                                "-"}
                                                            </label>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>OS Name:</b>{" "}
                                                              {affect?.os_name ||
                                                                "-"}
                                                            </label>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>Type:</b>
                                                              {affect?.os_sp ||
                                                                "-"}
                                                            </label>
                                                          </div>
                                                          <div className="row mb-3">
                                                            <div className="col-md-12 text-primary font-size-24">
                                                              <i className="mdi mdi-network-outline" />
                                                              Service
                                                            </div>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>Name:</b>{" "}
                                                              {affect?.service_name ||
                                                                "-"}
                                                            </label>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>Port:</b>{" "}
                                                              {affect?.service_port ||
                                                                "-"}
                                                            </label>
                                                            <label
                                                              className="col-md-12"
                                                              style={{
                                                                fontWeight:
                                                                  "normal",
                                                              }}
                                                            >
                                                              <b>Protocol:</b>{" "}
                                                              {affect?.service_proto ||
                                                                "-"}
                                                            </label>
                                                          </div>
                                                        </div>
                                                      </div>
                                                    </div>
                                                  </div>
                                                </div>
                                              )
                                            )}
                                        </div>
                                      </div>
                                      <div className="col-lg-3">
                                        <div className="row mb-3">
                                          <div className="col-xl-8 mb-3  ">
                                            {vuln?.affected_hosts && (
                                              <DropdownHelp
                                                hosts={vuln?.affected_hosts}
                                                row={row}
                                                moduleID={moduleID}
                                                updated_at={updated_at}
                                                getVulnerabilities={
                                                  getVulnerabilities
                                                }
                                              />
                                            )}
                                          </div>
                                          <h5 className="mb-3">
                                            Vulnerability Details
                                          </h5>
                                          <div className="row">
                                            <label
                                              className="col-md-6"
                                              style={{ fontWeight: "normal" }}
                                            >
                                              Exploit Available
                                            </label>
                                            <label
                                              className="col-md-6"
                                              style={{ fontWeight: "normal" }}
                                            >
                                              {vuln?.information
                                                ?.exploit_available ? (
                                                <span className="badge bg-success fw-bold ">
                                                  Yes
                                                </span>
                                              ) : (
                                                <span className="badge bg-danger fw-bold ">
                                                  No
                                                </span>
                                              )}
                                            </label>
                                          </div>
                                        </div>
                                        <div className="row mb-3">
                                          <h5 className="mb-3">
                                            Risk Information
                                          </h5>
                                          <div className="row mb-3">
                                            {vuln?.cvss3_impactScore !==
                                              null && (
                                              <label
                                                className="col-md-12"
                                                style={{ fontWeight: "normal" }}
                                              >
                                                <b>CVSS v3.0 Impact Score:</b>
                                                <span className="badge bg-primary ms-1">
                                                  {vuln?.cvss3_impactScore}
                                                </span>
                                              </label>
                                            )}
                                            {vuln.cvss3_base_score !== null && (
                                              <label
                                                className="col-md-12"
                                                style={{ fontWeight: "normal" }}
                                              >
                                                <b>CVSS v3.0 Base Score:</b>
                                                <span className="badge bg-primary ms-1">
                                                  {vuln?.cvss3_base_score}
                                                </span>
                                              </label>
                                            )}
                                            {vuln?.cvss3_temporal_score !==
                                              null && (
                                              <label
                                                className="col-md-12"
                                                style={{ fontWeight: "normal" }}
                                              >
                                                <b>CVSS v3.0 Temporal Score:</b>
                                                <span className="badge bg-primary ms-1">
                                                  {vuln?.cvss3_temporal_score}
                                                </span>
                                              </label>
                                            )}
                                            {vuln?.cvss2_base_score !==
                                              null && (
                                              <label
                                                className="col-md-12"
                                                style={{ fontWeight: "normal" }}
                                              >
                                                <b>CVSS v2.0 Base Score:</b>
                                                <span className="badge bg-primary ms-1">
                                                  {vuln?.cvss2_base_score}
                                                </span>
                                              </label>
                                            )}
                                            {vuln?.cvss2_temporal_score !==
                                              null && (
                                              <label
                                                className="col-md-12"
                                                style={{ fontWeight: "normal" }}
                                              >
                                                <b>CVSS v2.0 Temporal Score:</b>
                                                <span className="badge bg-primary ms-1">
                                                  {vuln?.cvss2_temporal_score}
                                                </span>
                                              </label>
                                            )}
                                          </div>
                                          {vuln?.information?.cvss3_vector && (
                                            <div className="row mb-3">
                                              <h5 className="mb-3">
                                                CVSS Vector 3
                                              </h5>
                                              <div className="row mb-3 ">
                                                {vuln?.information
                                                  ?.cvss3_vector || "-"}
                                              </div>
                                            </div>
                                          )}
                                        </div>
                                        {vuln?.references &&
                                          Object.keys(vuln?.references).length >
                                            0 && (
                                            <div className="row mb-3">
                                              <h5 className="mb-3">
                                                References
                                              </h5>
                                              <div className="row mb-3">
                                                {Object.keys(
                                                  vuln?.references
                                                )?.map((key, i) => (
                                                  <div
                                                    key={i}
                                                    className="col-lg-6 "
                                                  >
                                                    <b className="text-center w-100">
                                                      {key}
                                                    </b>
                                                    <ul>
                                                      {vuln?.references[
                                                        key
                                                      ]?.map((item) => {
                                                        const parcalar =
                                                          item.split(":");
                                                        let itemValue0 =
                                                          parcalar[0];
                                                        let itemValue1 =
                                                          parcalar[1];
                                                        return (
                                                          <li
                                                            className={`${
                                                              key === "cve" ||
                                                              itemValue0 ===
                                                                "CWE"
                                                                ? "fw-bold"
                                                                : ""
                                                            }`}
                                                            style={{
                                                              cursor: `${
                                                                key === "cve" ||
                                                                itemValue0 ===
                                                                  "CWE"
                                                                  ? "pointer"
                                                                  : ""
                                                              }`,
                                                            }}
                                                            onClick={() => {
                                                              if (
                                                                itemValue0 ===
                                                                "CWE"
                                                              ) {
                                                                router.push(
                                                                  `/cyber-watch/threat-intelligence/categories-cwes?page=1&search=${itemValue0}-${itemValue1}`
                                                                );
                                                              }
                                                              if (
                                                                key === "cve"
                                                              ) {
                                                                router.push(
                                                                  `/cyber-watch/threat-intelligence/cves-detail/${item}`
                                                                );
                                                              }
                                                            }}
                                                            key={item}
                                                          >
                                                            {item}
                                                          </li>
                                                        );
                                                      })}
                                                    </ul>
                                                  </div>
                                                ))}
                                              </div>
                                            </div>
                                          )}
                                      </div>
                                    </div>
                                  </td>
                                );
                              }
                            })}
                          </motion.tr>
                        )}
                    </AnimatePresence>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= data?.length ? lastPostIndex : data?.length} of{" "}
            {data?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default VulnerabilitiesTable;
