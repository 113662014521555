import classNames from "classnames";
import React from "react";
import { Nav, NavItem, NavLink } from "reactstrap";
import { useHistory, useLocation } from "react-router-dom";
const VulnerabilitiesWatcherTabs = () => {
  const router = useHistory();
  const pathname = window.location.pathname;

  const tabList = [
    {
      name: "Vulnerabilities",
      active:
        pathname === "/cyber-watch/threat-intelligence/cves" ? true : false,
      link: "/cyber-watch/threat-intelligence/cves?page=1",
      icon: "mdi-format-list-bulleted",
    },
    {
      name: "Vendor & Product",
      active:
        pathname === "/cyber-watch/threat-intelligence/vendor-product"
          ? true
          : false,
      link: "/cyber-watch/threat-intelligence/vendor-product",
      icon: "mdi-vector-intersection",
    },
    {
      name: "Categories (CWE)",
      active:
        pathname === "/cyber-watch/threat-intelligence/categories-cwes"
          ? true
          : false,
      link: "/cyber-watch/threat-intelligence/categories-cwes?page=1",
      icon: "mdi-group",
    },
    {
      name: "Subscriptions & Tags",
      active:
        pathname === "/cyber-watch/threat-intelligence/subscriptions-tags"
          ? true
          : false,
      link: "/cyber-watch/threat-intelligence/subscriptions-tags",
      icon: "mdi-tag",
    },
  ];

  return (
    <div className="row mb-3 ">
      {tabList.map((tabItem, tabIndex) => (
        <div
          key={tabIndex}
          className="col-lg
        col-6 mb-2  "
        >
          <Nav tabs className="nav-tabs-custom2 nav-justified ">
            <NavItem className="   ">
              <NavLink
                style={{ cursor: "pointer" }}
                className={classNames({
                  active: tabItem.active,
                })}
                onClick={() => {
                  router.push(tabItem.link);
                }}
              >
                <span className="d-block ">
                  <i className={`mdi ${tabItem.icon} fs-4 `}></i>
                </span>
                <span className="d-none d-sm-block fs-4  ">{tabItem.name}</span>
              </NavLink>
            </NavItem>
          </Nav>
        </div>
      ))}
    </div>
  );
};

export default VulnerabilitiesWatcherTabs;
