import { IS_APPLIANCE_REDUX, IS_DEMO_ACCOUNT } from "./actionTypes";

export const isApplianceReduxFunction = (isApplianceRedux) => {
  return {
    type: IS_APPLIANCE_REDUX,
    payload: { isApplianceRedux },
  };
};

export const isDemoAccountFunction = (isDemo) => {
  return {
    type: IS_DEMO_ACCOUNT,
    payload: { isDemo },
  };
};
