import React from "react";
import Chart from "./Graphics/Chart";

const DynamicGraphics = ({ data, wizard }) => {
  return (
    <div className="special-score-card">
      <div className="d-flex  py-2  align-items-center justify-content-between  ">
        <div>
          <span className="fw-bold fs-4 me-1 ">{wizard.title} </span>
        </div>
      </div>
      <div>
        <div
          style={{
            height: "265px",
          }}
          className={`d-flex  align-items-center justify-content-center `}
        >
          <Chart wizard={wizard} data={data} />
        </div>
      </div>
    </div>
  );
};

export default DynamicGraphics;
