import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const dountchart = ({ data, arrayChangeValue }) => {
  const [options, setOptions] = useState({
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              label: "Total",
              fontSize: "30px",
              color: "rgb(188, 193, 200)",
              formatter: () => toplam,
            },
          },
        },
      },
    },
    labels: ["Insecure", "Secure"],
    colors: ["#f46a6a", "#34c38f"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      // {
      //   breakpoint: 1670,
      //   options: {
      //     chart: {
      //       height: 364,
      //       // width: 224,
      //     },
      //     legend: {
      //       show: false,
      //     },
      //   },
      // },
    ],
  });
  const extractValues = () => {
    const values = [];

    if (data) {
      values.push(data[arrayChangeValue]?.insecure);
      values.push(data[arrayChangeValue]?.secure);
    }

    return values;
  };

  const toplamDegeriHesapla = (dizi) => {
    const toplam = dizi.reduce((acc, currentValue) => acc + currentValue, 0);
    return toplam;
  };

  const series = extractValues();
  const toplam = toplamDegeriHesapla(series) || 0;

  return (
    <>
      <div style={{ position: "relative" }}>
        <ReactApexChart
          className="pt-5"
          options={options}
          series={series}
          type="donut"
          height="380"
          // width="180"
        />
      </div>
    </>
  );
};

export default dountchart;
