import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { get } from "../../../../../helpers/api_helper";
import { Container, Spinner } from "reactstrap";
import VendorsComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/Cves/VendorsComponent";
import ProductsComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/Cves/ProductsComponent";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import { useHistory, useLocation } from "react-router-dom";
import VulnerabilitiesWatcherTabs from "./VulnerabilitiesWatcherTabs";

const VendorProduct = () => {
  document.title = "Threat Intelligence | Vendors & Products";

  const location = useLocation();
  const router = useHistory();

  const queryParams = new URLSearchParams(location.search);
  const searchUrl = queryParams.get("search");

  const [vendorPage, set_vendorPage] = useState(1);
  const [productPage, set_productPage] = useState(1);
  const [vendorProductPage, set_vendorProductPage] = useState(1);

  const [search, set_search] = useState(searchUrl ? searchUrl : "");
  const [vendorProduct, set_vendorProduct] = useState();

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoadingVendor, setIsLoadingVendor] = useState(false);
  const [isLoadingProduct, setIsLoadingProduct] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  const [vendors, set_vendors] = useState();
  const [products, set_products] = useState();
  const [tags, set_tags] = useState();

  const getVendorList = async (data) => {
    paceFunction(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves/vendors?page=${
          data ? data : vendorPage
        }&search=${search}`
      );
      set_vendorPage(data ? data : vendorPage);
      if (result?.success) {
        set_vendors(result?.data);
        setFirstLoading(true);
        if (
          window.location.pathname ===
          "/cyber-watch/threat-intelligence/vendor-product"
        ) {
          router.replace(
            `/cyber-watch/threat-intelligence/vendor-product${
              search ? `?search=${search}` : ""
            }`
          );
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoadingVendor(false);
    paceFunction(false);
  };

  const getProductList = async (data) => {
    paceFunction(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves/products?page=${
          data ? data : productPage
        }&search=${search}`
      );
      set_productPage(data ? data : productPage);
      if (result?.success) {
        set_products(result?.data);
        setFirstLoading(true);
        if (
          window.location.pathname ===
          "/cyber-watch/threat-intelligence/vendor-product"
        ) {
          router.replace(
            `/cyber-watch/threat-intelligence/vendor-product${
              search ? `?search=${search}` : ""
            }`
          );
        }
        setFirstLoading(true);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoadingProduct(false);
    paceFunction(false);
  };

  const getVendorProductList = async (dataPage, dataVendorProduct) => {
    paceFunction(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves/vendors/products?page=${
          dataPage ? dataPage : vendorProductPage
        }&vendor=${dataVendorProduct}`
      );
      if (result?.success) {
        set_vendorProductPage(dataPage ? dataPage : vendorProductPage);
        set_products(result?.data);
        set_vendorProduct(dataVendorProduct);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const getTags = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cves/tags`);
      if (result?.success) {
        set_tags(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const vendorProductClose = () => {
    getVendorList(1);
    getProductList(1);
    set_vendorProduct();
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoadingVendor(true);
    }
    getVendorList();
  }, []);

  useEffect(() => {
    if (!firstLoading) {
      setIsLoadingProduct(true);
    }
    getProductList();
  }, []);

  useEffect(() => {
    getTags();
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    set_vendorProduct();
    getVendorList(1);
    getProductList(1);
  };

  // CLEAN ALL SEARCH AND FILTER
  const cleanAll = async () => {
    // Vendor Function
    paceFunction(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves/vendors?page=1&search=`
      );
      set_vendorPage(1);
      if (result?.success) {
        set_vendors(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }

    // Product Function
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cves/products?page=1&search=`
      );
      set_productPage(1);
      if (result?.success) {
        set_products(result?.data);
        setFirstLoading(true);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);

    set_search("");
    set_vendorProduct();
    if (
      window.location.pathname ===
      "/cyber-watch/threat-intelligence/vendor-product"
    ) {
      router.replace(`/cyber-watch/threat-intelligence/vendor-product`);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Threat Intelligence / Vendors & Products"
            breadcrumbItem="Threat Intelligence / Vendors & Products"
          />
          <VulnerabilitiesWatcherTabs />
          <div className="row justify-content-center ">
            <div className="col-md-6 ">
              <div className="special-card">
                <div className="card-header with-border p-2">
                  <h3 className="card-title my-1 ">
                    Search in vendors and products
                  </h3>
                </div>
                <div className="card-body d-flex justify-content-between align-items-center ">
                  <form
                    onSubmit={handleSubmit}
                    role="form"
                    className="form-horizontal w-100 "
                  >
                    <div className="form-group">
                      <div className="col-md-12">
                        <div className="input-group">
                          <span className="input-group-btn">
                            <button className="btn btn-primary" type="submit">
                              <i className="fa fa-search" />
                            </button>
                          </span>
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control"
                            name="search"
                            value={search}
                            onChange={(e) => set_search(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <span
                    onClick={cleanAll}
                    className="font-size-20 ms-1 fw-bolder "
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                  >
                    &times;
                  </span>
                </div>
              </div>
            </div>
          </div>
          {(isLoadingProduct || isLoadingVendor) && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 mt-4"
                color="primary"
              />
            </div>
          )}
          {!isLoadingProduct && !isLoadingVendor && (
            <div className="row mt-4">
              {vendors && (
                <div className="col-lg-5">
                  <VendorsComponent
                    vendors={vendors}
                    vendorPage={vendorPage}
                    set_vendorPage={set_vendorPage}
                    vendorProduct={vendorProduct}
                    getVendorProductList={(a, b) => getVendorProductList(a, b)}
                    vendorProductClose={vendorProductClose}
                    getVendorList={(e) => getVendorList(e)}
                    tags={tags}
                  />
                </div>
              )}
              {products && (
                <div className="col-lg-7">
                  <ProductsComponent
                    products={products}
                    productPage={productPage}
                    set_productPage={set_productPage}
                    vendorProduct={vendorProduct}
                    vendorProductPage={vendorProductPage}
                    set_vendorProductPage={set_vendorProductPage}
                    getProductList={(e) => getProductList(e)}
                    getVendorProductList={(a, b) => getVendorProductList(a, b)}
                    tags={tags}
                  />
                </div>
              )}
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default VendorProduct;
