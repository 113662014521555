import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import DropdownHelp from "./DropdownHelp";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import {
  formattedDate,
  toastSuccess,
} from "../../../common/utils/commonFunctions";
import classNames from "classnames";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <form autoComplete="off">
        <input
          id="search"
          name="search"
          type="text"
          className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
          defaultValue={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Search"
        />
      </form>
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function ManagerCustomersTable({ data, entryValue, getCustomers }) {
  const [rowId, setRowId] = useState();
  // Seem
  const [seem1, set_seem1] = useState(true);
  const [seem2, set_seem2] = useState(true);
  const [seem3, set_seem3] = useState(true);
  const [seem4, set_seem4] = useState(true);
  const [seem5, set_seem5] = useState(false);
  const [seem6, set_seem6] = useState(true);
  const [seem7, set_seem7] = useState(false);
  const [seem8, set_seem8] = useState(true);
  const [seem9, set_seem9] = useState(true);
  const [seem10, set_seem10] = useState(true);
  const [btnprimary1, setBtnprimary1] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "Company Name",
        accessor: "company_name",
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Owner",
        accessor: "owner.organization_name",
      },
      {
        Header: "Phone",
        accessor: "phone_number",
      },
      {
        Header: "Joined Date",
        accessor: "date_joined",
      },
      {
        Header: "Subscription",
        accessor: "subscription_renewal",
      },
      {
        Header: "Access Limit",
        accessor: "access_limit",
      },
      {
        Header: "Activation",
        accessor: "is_active",
      },
      {
        Header: "Demo",
        accessor: "is_demo",
      },
      {
        Header: "Actions",
        accessor: "dropdownColumn",
      },
      {
        Header: "",
        accessor: "owner.role",
      },
      {
        Header: "",
        accessor: "plan",
      },
      {
        Header: "",
        accessor: "username",
      },
    ],
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }

  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };
  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };
  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const convertJSONToCSV = (data) => {
    const csvHeader =
      "Username;Company Name;Email;Owner;Phone;Plan;Joined Date;Subscription Renewal;Linked Account Limit;Activation;Demo";

    const csvRows = data.map((item) => {
      let activation;
      let demo;
      let role;
      let lastOwner;

      activation = item?.is_active ? "YES" : "NO";
      demo = item?.is_demo ? "YES" : "NO";
      role =
        item?.owner?.role === "DISTRIBUTOR"
          ? "D"
          : item?.owner?.role === "MANAGER"
          ? "M"
          : "";
      lastOwner = role + " " + item?.owner?.organization_name;
      return `${item?.username || "-"};${item?.company_name || "-"};${
        item?.email || "-"
      };${lastOwner || "-"};${item?.phone_number || "-"};${item?.plan || "-"};${
        formattedDate(item?.date_joined) || "-"
      };${formattedDate(item?.subscription_renewal) || "-"};${
        item?.access_limit ?? "-"
      };${activation || "-"};${demo || "-"}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    return csvContent;
  };

  const downloadCSV = (data) => {
    const csvData = convertJSONToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "Customers.csv";

    if (window.navigator.msSaveBlob) {
      // IE 10+ için
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Diğer tarayıcılar için
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-column "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons flex-wrap justify-content-md-center  d-flex ">
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(data);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <Dropdown
              isOpen={btnprimary1}
              toggle={() => setBtnprimary1(!btnprimary1)}
            >
              <DropdownToggle
                title="Column Visibility"
                tag="button"
                className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              >
                <i className="mdi mdi-eye" />
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                  <span className="visually-hidden">unread messages</span>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    set_seem1(!seem1);
                  }}
                  className={`${seem1 ? "bg-soft-primary" : ""}`}
                >
                  Company Name
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem2(!seem2);
                  }}
                  className={`${seem2 ? "bg-soft-primary" : ""}`}
                >
                  Email Address
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem3(!seem3);
                  }}
                  className={`${seem3 ? "bg-soft-primary" : ""}`}
                >
                  Owner
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem4(!seem4);
                  }}
                  className={`${seem4 ? "bg-soft-primary" : ""}`}
                >
                  Phone
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem5(!seem5);
                  }}
                  className={`${seem5 ? "bg-soft-primary" : ""}`}
                >
                  Joined Date
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem6(!seem6);
                  }}
                  className={`${seem6 ? "bg-soft-primary" : ""}`}
                >
                  Subscription
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem7(!seem7);
                  }}
                  className={`${seem7 ? "bg-soft-primary" : ""}`}
                >
                  Access Limit
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem8(!seem8);
                  }}
                  className={`${seem8 ? "bg-soft-primary" : ""}`}
                >
                  Activation
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem9(!seem9);
                  }}
                  className={`${seem9 ? "bg-soft-primary" : ""}`}
                >
                  Demo
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem10(!seem10);
                  }}
                  className={`${seem10 ? "bg-soft-primary" : ""}`}
                >
                  Actions
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        <Col xxl={1} xl={2} lg={3} md={4} sm={5} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr
              className="text-nowrap"
              key={headerGroup.id}
              {...headerGroup.getHeaderGroupProps()}
            >
              {/* {headerGroup.headers.map((column,column) => (
                <th key={column.id}>{column.render("Header")}</th>
              ))} */}
              {seem1 && (
                <th
                  {...headerGroup.headers[0].getHeaderProps(
                    headerGroup.headers[0].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[0].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[0].isSorted
                      ? headerGroup.headers[0].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem2 && (
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[1].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem3 && (
                <th
                  {...headerGroup.headers[2].getHeaderProps(
                    headerGroup.headers[2].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[2].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[2].isSorted
                      ? headerGroup.headers[2].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem4 && <th className="">{headerGroup.headers[3].Header}</th>}
              {seem5 && (
                <th
                  {...headerGroup.headers[4].getHeaderProps(
                    headerGroup.headers[4].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[4].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[4].isSorted
                      ? headerGroup.headers[4].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem6 && (
                <th
                  {...headerGroup.headers[5].getHeaderProps(
                    headerGroup.headers[5].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[5].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[5].isSorted
                      ? headerGroup.headers[5].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem7 && (
                <th
                  {...headerGroup.headers[6].getHeaderProps(
                    headerGroup.headers[6].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[6].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[6].isSorted
                      ? headerGroup.headers[6].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem8 && (
                <th
                  {...headerGroup.headers[7].getHeaderProps(
                    headerGroup.headers[7].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[7].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[7].isSorted
                      ? headerGroup.headers[7].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem9 && (
                <th
                  {...headerGroup.headers[8].getHeaderProps(
                    headerGroup.headers[8].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[8].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[8].isSorted
                      ? headerGroup.headers[8].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem10 && <th className="">{headerGroup.headers[9].Header}</th>}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
            prepareRow(row);
            return (
              <tr key={index} {...row.getRowProps()}>
                {seem1 && (
                  <td className="">
                    <div
                      style={{ marginTop: "-6px" }}
                      className="d-flex  align-items-center"
                    >
                      <span
                        href="#"
                        className="badge rounded-pill badge-soft-primary cursor-pointer me-1 "
                        title={row.original.username}
                        onClick={() => {
                          var textField = document.createElement("textarea");
                          textField.innerText = row.original.username;
                          document.body.appendChild(textField);
                          textField.select();
                          document.execCommand("copy");
                          textField.remove();
                          toastSuccess(`${row.original.username} copied`);
                        }}
                      >
                        i
                      </span>
                      {row.cells[0].value}
                      {
                        <i
                          className={classNames(
                            "mdi  cursor-pointer ms-1 font-size-20 mt-1 font-bold",
                            {
                              "text-bronze mdi-medal":
                                row.cells[11].value === "PROFESSIONAL",
                              "text-silver mdi-medal":
                                row.cells[11].value === "PREMIUM",
                              "text-gold mdi-medal":
                                row.cells[11].value === "ENTERPRISE",
                              "text-primary mdi-language-c":
                                row.cells[11].value === "CUSTOM",
                            }
                          )}
                          title={row.cells[11].value}
                        />
                      }
                    </div>
                  </td>
                )}
                {seem2 && <td className="text-nowrap">{row.cells[1].value}</td>}
                {seem3 && (
                  <td className="text-nowrap">
                    <span
                      className="badge bg-primary cursor-pointer me-1 "
                      title={row.cells[10].value}
                    >
                      {row.cells[10].value === "DISTRIBUTOR"
                        ? "D"
                        : row.cells[10].value === "MANAGER"
                        ? "M"
                        : ""}
                    </span>
                    {row.cells[2].value}
                  </td>
                )}
                {seem4 && <td>{row.cells[3].value}</td>}
                {seem5 && <td>{formattedDate(row.cells[4].value)}</td>}
                {seem6 && <td>{formattedDate(row.cells[5].value)}</td>}
                {seem7 && <td>{row.cells[6].value}</td>}
                {seem8 && (
                  <td>
                    {row.cells[7].value ? (
                      <i className="mdi mdi-check-outline me-3 text-success" />
                    ) : (
                      <i className="mdi mdi-close-outline me-3 text-danger" />
                    )}
                  </td>
                )}
                {seem9 && (
                  <td>
                    {row.cells[8].value ? (
                      <i className="mdi mdi-check-outline me-3 text-success" />
                    ) : (
                      <i className="mdi mdi-close-outline me-3 text-danger" />
                    )}
                  </td>
                )}
                {seem10 && (
                  <td onClick={() => setRowId(row.original.id)}>
                    <DropdownHelp
                      rowId={rowId}
                      row={rows.slice(firstPostIndex, lastPostIndex)}
                      getCustomers={getCustomers}
                    />
                  </td>
                )}
              </tr>
            );
          })}
        </tbody>
      </table>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ManagerCustomersTable;
