import React from "react";
import { Modal } from "reactstrap";
const ConfirmModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  text,
  targetFunction,
  loadingAction,
  isLoading,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            PentestBX asks
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>{text}</h5>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Cancel
          </button>
          {loadingAction ? (
            <div>
              {isLoading ? (
                <button
                  type="button"
                  className="btn btn-primary waves-effect waves-light"
                  disabled
                >
                  <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                  Yes
                </button>
              ) : (
                <button
                  onClick={targetFunction}
                  type="button"
                  className="btn btn-primary "
                >
                  Yes
                </button>
              )}
            </div>
          ) : (
            <button
              onClick={targetFunction}
              type="button"
              className="btn btn-primary "
            >
              Yes
            </button>
          )}
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ConfirmModal;
