import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatDateTransform } from "../../../../common/utils/commonFunctions";

const DashedLine = ({ data, dateFormat }) => {
  const extractValuesByKey = (data, key) => {
    return data?.map((item) => item[key]);
  };

  const secureArray = extractValuesByKey(data, "secure");
  const inSecureArray = extractValuesByKey(data, "insecure");
  const createdAtArray = extractValuesByKey(data, "iso_format");
  const series = [
    {
      name: "Insecure",
      data: inSecureArray,
    },
    {
      name: "Secure",
      data: secureArray,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: { width: [4, 4], curve: "straight", dashArray: [8, 8] },

    colors: ["#f46a6a", "#34c38f"],
    fill: {
      type: "solid",
      colors: ["transparent"],
    },
    xaxis: {
      type: "datetime",
      labels: { format: formatDateTransform(dateFormat), datetimeUTC: false },
      categories: createdAtArray,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        // format: "dd/MM/yyyy HH:mm",
        format: `${formatDateTransform(dateFormat)} HH:mm`,
      },
      y: [
        {
          title: {
            formatter: function (e) {
              return e;
            },
          },
        },
        {
          title: {
            formatter: function (e) {
              return e;
            },
          },
        },
      ],
    },
    responsive: [
      {
        breakpoint: 1670,
        options: {
          chart: {
            height: 541,
            // width: 224,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="line"
      height="554"
    />
  );
};

export default DashedLine;
