import React, { useEffect, useRef } from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import DonutChart from "./DonutChart";
import { toPng } from "html-to-image";
import logo from "../../../../../assets/images/pdf-logo.png";
import bigLogo from "../../../../../assets/images/logo_colors_lg.png";
import watermark from "../../../../../assets/images/watermark.png";
import { styles } from "./PdfStyles";

const HostPdfReport = ({ data, imageUrl, isDemoAccount }) => {
  const profileInfo = JSON.parse(localStorage.getItem("profileInfo"));

  const parseHtmlToReact = (html) => {
    const views = [];
    console.log(`html ==>`, html);
    const doc = new DOMParser().parseFromString(html, "text/html");
    console.log(`doc ==>`, doc);
    const body = doc.body;
    console.log(`body ==>`, body);

    const parseNode = (node) => {
      switch (node.nodeName) {
        case "DIV":
          return (
            <View style={styles.div}>
              {Array.from(node.childNodes).map(parseNode)}
            </View>
          );
        case "#text":
          return <Text style={styles.p}>{node.textContent}</Text>;
        case "P":
          return <Text style={styles.p}>{node.textContent}</Text>;
        case "BR":
          return <Text style={styles.br}>&nbsp;</Text>;
        default:
          return <Text style={styles.p}>{node.textContent}</Text>;
      }
    };

    body.childNodes.forEach((node) => {
      views.push(parseNode(node));
    });

    console.log(`views ==>`, views);
    return views;
  };
  const jsonToReactPdf = (json) => {
    return json.map((item) => {
      const { type, props } = item;
      switch (type) {
        case "TEXT":
          return <Text {...props} />;
        case "VIEW":
          return <View {...props}>{props.children}</View>;
        default:
          return null;
      }
    });
  };

  // let res = parseHtmlToReact(html);
  // console.log(`res ==>`, res);
  // let res2 = jsonToReactPdf(res);
  // console.log(`res2 ==>`, res2);
  return (
    <Document>
      <Page size="A4" style={{ ...styles.onePage, position: "relative" }}>
        <Image
          src={watermark}
          style={{
            position: "absolute",
            zIndex: -1,
            top: 0,
            width: "126%",
          }}
        />
        {isDemoAccount && <Text style={styles.demoAccount}>TRIAL</Text>}
        <View style={styles.sectionOnePage}>
          <Text style={styles.onePageTitle}>{profileInfo}</Text>
          <Text style={styles.onePageTitle}>Hosts Audit Report</Text>
          <View style={styles.tableOnePage}>
            <View style={styles.row}>
              <View style={styles.labelCellOnePage}>
                <Text>Scan Date</Text>
              </View>
              <View style={styles.cellOnePage}>
                <Text>{data?.summary?.scan_date}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.labelCellOnePage}>
                <Text>Report Date</Text>
              </View>
              <View style={styles.cellOnePage}>
                <Text>{data?.summary?.report_date}</Text>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size="A4" style={{ ...styles.page, position: "relative" }}>
        {/* <Image src={bigLogo} style={styles.background} fixed /> */}
        <Image
          src={watermark}
          fixed
          style={{
            position: "absolute",
            zIndex: -1,
            top: 0,
            width: "126%",
          }}
        />
        {isDemoAccount && (
          <Text fixed style={styles.demoAccount}>
            TRIAL
          </Text>
        )}
        <View style={styles.section}>
          <Text style={styles.h1}>1. Executive Summary</Text>
          <Text style={styles.p}>
            This report represents a security audit performed by PentestBX. It
            contains confidential information about the state of your hosts
          </Text>
          <Text style={styles.p}>
            Access to this information by unauthorized personnel may allow them
            to compromise your hosts.
          </Text>
          <Text style={styles.p}>
            There were{" "}
            <Text style={styles.textBold}>{data?.summary?.vuln_count}</Text>{" "}
            vulnerabilities found during this scan.
          </Text>
          <Text style={styles.p}>
            Of these,{" "}
            <Text style={styles.textCritical}>
              {data?.summary?.severities["4"]}
            </Text>{" "}
            were critical vulnerabilities. Critical vulnerabilities require
            immediate attention. They are relatively easy for attackers to
            exploit and may provide them with full control of the affected
            systems
          </Text>
          <Text style={styles.p}>
            <Text style={styles.textHigh}>
              {data?.summary?.severities["3"]}
            </Text>{" "}
            vulnerabilities were high level. High level vulnerabilities are
            often harder to exploit and may not provide the same access to
            affected systems.
          </Text>
          <Text style={styles.p}>
            There were{" "}
            <Text style={styles.textMedium}>
              {data?.summary?.severities["2"]}
            </Text>{" "}
            medium level vulnerabilities discovered. These often provide
            information to attackers that may assist them in mounting subsequent
            attacks on your hosts. These should also be fixed in a timely
            manner, but are not as urgent as the other vulnerabilities.
          </Text>
          <Text style={styles.p}>
            There were{" "}
            <Text style={styles.textLow}>{data?.summary?.severities["1"]}</Text>{" "}
            low level vulnerabilities discovered.
          </Text>
          <Text style={styles.p}>
            Except those;{" "}
            <Text style={styles.textInfo}>
              {data?.summary?.severities["0"]}
            </Text>{" "}
            information level vulnerabilities discovered.
          </Text>
          <View style={styles.boxesContainer}>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgTotal]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Total</Text>
                <Text style={[styles.score, styles.textTotal]}>
                  {data?.summary?.vuln_count}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgCritical]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Critical</Text>
                <Text style={[styles.score, styles.textCritical]}>
                  {data?.summary?.severities["4"]}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgHigh]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>High</Text>
                <Text style={[styles.score, styles.textHigh]}>
                  {data?.summary?.severities["3"]}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgMedium]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Medium</Text>
                <Text style={[styles.score, styles.textMedium]}>
                  {data?.summary?.severities["2"]}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgLow]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Low</Text>
                <Text style={[styles.score, styles.textLow]}>
                  {data?.summary?.severities["1"]}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgInfo]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Info</Text>
                <Text style={[styles.score, styles.textInfo]}>
                  {data?.summary?.severities["0"]}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size="A4" style={{ ...styles.page, position: "relative" }}>
        {/* <Image src={bigLogo} style={styles.background} fixed /> */}
        <Image
          src={watermark}
          fixed
          style={{
            position: "absolute",
            zIndex: -1,
            top: 0,
            width: "126%",
          }}
        />
        {isDemoAccount && (
          <Text fixed style={styles.demoAccount}>
            TRIAL
          </Text>
        )}
        <View style={styles.section}>
          <Text style={styles.h1}>
            2. Discovered and Potential Vulnerabilities
          </Text>
          {data?.report?.map((reportItem, reportIndex) => {
            const referencesObj = reportItem?.references
              ? JSON.parse(reportItem?.references)
              : null;
            return (
              <View
                key={reportIndex}
                style={{ marginBottom: 10, marginTop: 25 }}
                break={reportIndex > 0 ? true : false}
              >
                <View style={styles.table}>
                  <View
                    style={{
                      ...styles.tableRow,
                      backgroundColor: "#edeef0",
                      padding: "2px 3px",
                    }}
                  >
                    <Text style={styles.h2}>
                      2.{reportIndex + 1} {reportItem?.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableRow,
                    }}
                  >
                    <View style={styles.tableTitleCellOne}>
                      {reportItem?.cvss3_base_score !== null && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Cvss3 Base Score:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#8f1860",
                              color: "white",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {reportItem?.cvss3_base_score}
                          </Text>
                        </View>
                      )}
                      {reportItem?.cvss3_impactScore !== null && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Cvss3 Impact Score:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#8f1860",
                              color: "white",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {reportItem?.cvss3_impactScore}
                          </Text>
                        </View>
                      )}
                      {reportItem?.cvss3_temporal_score !== null && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Cvss3 Temporal Score:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#8f1860",
                              color: "white",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {reportItem?.cvss3_temporal_score}
                          </Text>
                        </View>
                      )}
                      {reportItem?.cvss2_base_score !== null && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Cvss2 Base Score:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#8f1860",
                              color: "white",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {reportItem?.cvss2_base_score}
                          </Text>
                        </View>
                      )}
                      {reportItem?.cvss2_temporal_score !== null && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Cvss2 Temporal Score:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#8f1860",
                              color: "white",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {reportItem?.cvss2_temporal_score}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.tableTitleCellTwo}>
                      {reportItem?.information?.patch_publication_date && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Patch Publication Date:
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: 1,
                            }}
                          >
                            {reportItem?.information.patch_publication_date}
                          </Text>
                        </View>
                      )}
                      {reportItem?.information?.vuln_publication_date && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Vuln Publication Date:
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: 1,
                            }}
                          >
                            {reportItem?.information.vuln_publication_date}
                          </Text>
                        </View>
                      )}
                      {reportItem?.vuln_family && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Vuln Family:
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: 1,
                            }}
                          >
                            {reportItem?.vuln_family}
                          </Text>
                        </View>
                      )}
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Helvetica-Bold",
                          }}
                        >
                          Exploit Available:
                        </Text>
                        {reportItem?.information?.exploit_available ? (
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "green",
                              color: "white",
                            }}
                          >
                            Yes
                          </Text>
                        ) : (
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#dc3545",
                              color: "white",
                            }}
                          >
                            No
                          </Text>
                        )}
                      </View>
                    </View>
                    {reportItem?.severity === "4" && (
                      <View
                        style={{
                          ...styles.bgCritical,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>CRITICAL</Text>
                      </View>
                    )}
                    {reportItem?.severity === "3" && (
                      <View
                        style={{
                          ...styles.bgHigh,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>HIGH</Text>
                      </View>
                    )}
                    {reportItem?.severity === "2" && (
                      <View
                        style={{
                          ...styles.bgMedium,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>MEDIUM</Text>
                      </View>
                    )}
                    {reportItem?.severity === "1" && (
                      <View
                        style={{
                          ...styles.bgLow,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>LOW</Text>
                      </View>
                    )}
                    {reportItem?.severity === "0" && (
                      <View
                        style={{
                          ...styles.bgInfo,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>INFO</Text>
                      </View>
                    )}
                  </View>
                  {reportItem?.description && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Description</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{reportItem?.description}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.solution && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Solution</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{reportItem?.solution}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.references &&
                    Object.keys(referencesObj).length > 0 && (
                      <View style={{ ...styles.tableRow }}>
                        <View style={styles.tableCellLeft}>
                          <Text>References</Text>
                        </View>
                        <View
                          style={{
                            ...styles.tableCellRight,
                            flexDirection: "row",
                          }}
                        >
                          {Object.keys(referencesObj).map((key) => (
                            <View key={key}>
                              <Text style={{ marginBottom: 5 }}>
                                {key.toUpperCase()}:
                              </Text>
                              <View style={{ marginLeft: 10 }}>
                                {referencesObj[key].map((reference, index) => (
                                  <View
                                    key={index}
                                    style={{
                                      flexDirection: "row",
                                      alignItems: "center",
                                    }}
                                  >
                                    <Text>{"\u2022"}</Text>
                                    {key === "cve" && (
                                      <Link
                                        style={{
                                          textDecoration: "none",
                                          color: "#8f1860",
                                        }}
                                        src={`https://nvd.nist.gov/vuln/detail/${reference}`}
                                      >
                                        <Text style={{ marginLeft: 5 }}>
                                          {reference}
                                        </Text>
                                      </Link>
                                    )}
                                    {key !== "cve" && (
                                      <Text style={{ marginLeft: 5 }}>
                                        {reference}
                                      </Text>
                                    )}
                                  </View>
                                ))}
                              </View>
                            </View>
                          ))}
                        </View>
                      </View>
                    )}
                  {reportItem?.information?.cvss3_vector && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Cvss Vector 3</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{reportItem?.information?.cvss3_vector}</Text>
                      </View>
                    </View>
                  )}
                  <View style={{ ...styles.tableRow }}>
                    <View style={styles.tableCellLeft}>
                      <Text>Affected Hosts</Text>
                    </View>
                  </View>
                  <View
                    style={{
                      flexDirection: "row",
                      flexWrap: "wrap",
                      padding: 4,
                    }}
                  >
                    {reportItem?.hosts.map((hostItem, hostIndex) => {
                      return (
                        <View
                          key={`${hostItem.address}-${hostIndex}`}
                          style={{
                            flexDirection: "row",
                            alignItems: "center",
                            marginRight: 1,
                            marginBottom: 1,
                            fontSize: 8,
                          }}
                        >
                          <View
                            style={{
                              backgroundColor: "#8f1860",
                              borderRadius: 4,
                              padding: 3,
                              marginRight: 1,
                            }}
                          >
                            <Text style={{ color: "white" }}>
                              {hostItem?.address}:
                              {hostItem?.port === "0 general"
                                ? "- general"
                                : hostItem?.port}
                            </Text>
                          </View>
                        </View>
                      );
                    })}
                  </View>
                </View>
              </View>
            );
          })}
          {/* {imageUrl && (
            <View>
              <Image src={imageUrl ? imageUrl : ""} style={styles.chartImage} />
              <View
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  flexDirection: "row",
                }}
              >
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  <View
                    style={{
                      ...styles.bgCritical,
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      marginRight: 1,
                    }}
                  />
                  <Text>Critical</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  <View
                    style={{
                      ...styles.bgHigh,
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      marginRight: 1,
                    }}
                  />
                  <Text>High</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  <View
                    style={{
                      ...styles.bgMedium,
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      marginRight: 1,
                    }}
                  />
                  <Text>Medium</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  <View
                    style={{
                      ...styles.bgLow,
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      marginRight: 1,
                    }}
                  />
                  <Text>Low</Text>
                </View>
                <View
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    fontSize: 10,
                    color: "gray",
                  }}
                >
                  <View
                    style={{
                      ...styles.bgInfo,
                      width: "5px",
                      height: "5px",
                      borderRadius: "50%",
                      marginRight: 1,
                    }}
                  />
                  <Text>Info</Text>
                </View>
              </View>
            </View>
          )} */}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default HostPdfReport;
