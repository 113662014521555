import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";

const dountchart = ({ data, arrayChangeValue }) => {
  const [options, setOptions] = useState({
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              label: "Total",
              fontSize: "30px",
              color: "rgb(188, 193, 200)",
              formatter: () => toplam,
            },
          },
        },
      },
    },
    labels: ["Critical", "High", "Medium", "Low", "Info"],
    colors: ["#FD625E", "#FD8C00", "#FDC500", "#2AB57D", "#4bA6EF"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  });
  const extractValues = () => {
    const values = [];

    if (data) {
      values.push(data[arrayChangeValue]?.critical);
      values.push(data[arrayChangeValue]?.high);
      values.push(data[arrayChangeValue]?.medium);
      values.push(data[arrayChangeValue]?.low);
      values.push(data[arrayChangeValue]?.info);
    }

    return values;
  };

  const toplamDegeriHesapla = (dizi) => {
    const toplam = dizi.reduce((acc, currentValue) => acc + currentValue, 0);
    return toplam;
  };

  const series = extractValues();
  const toplam = toplamDegeriHesapla(series) || 0;

  return (
    <>
      <div style={{ position: "relative" }}>
        <ReactApexChart
          className="pt-5"
          options={options}
          series={series}
          type="donut"
          height="380"
        />
        <span
          style={{
            position: "absolute",
            right: "0",
            left: "0",
            top: "-5px",
            // bottom: "0px",
            // zIndex: "-1",
          }}
          className="d-flex flex-column  justify-content-center align-items-center fs-3 fw-bold "
        >
          Total <br /> <span className="fs-5 fw-normal ">{toplam}</span>
        </span>
      </div>
    </>
  );
};

export default dountchart;
