import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import WebApplication from "../../../components/Customer/WebApplications/WebApplication";
import { get } from "../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Container, Spinner } from "reactstrap";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../common/InfoNoDataComponent";
import { useHistory } from "react-router-dom";

const WebApplicationsMain = () => {
  document.title = "Web Application | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState();
  const [empty, setEmpty] = useState(false);
  const [moduleID, set_moduleID] = useState();
  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);
  const router = useHistory();

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getWebApplicationOverview = async (id) => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/overview?id=${id}`);
      const calculateSum = (numbers) => {
        const sum = numbers.reduce((acc, num) => acc + num, 0);
        return sum;
      };
      let scansTotal = 0;
      if (result?.data?.length > 0) {
        let total = result?.data?.map((item) => item?.scans?.length);
        let totalSum = calculateSum(total);
        scansTotal = totalSum;
      }
      if (result?.success && scansTotal > 0) {
        setAssets(result?.data);
        setIsLoading(false);
      }
      if (scansTotal < 1) set_seemInfoComponent(true);
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  const getModules = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/module");
      if (result?.success) {
        let dataArray = result.data.modules;
        const hasWebApp = dataArray.some(
          (item) => item.name === "WEB_APPLICATION"
        );
        if (hasWebApp) {
          const filteredData = dataArray.filter(
            (item) => item.name === "WEB_APPLICATION"
          );
          if ("permission" in filteredData[0]) {
            if (filteredData[0].permission) {
              for (let i = 0; i < dataArray.length; i++) {
                if (dataArray[i].name === "WEB_APPLICATION") {
                  getWebApplicationOverview(dataArray[i].id);
                  set_moduleID(dataArray[i].id);
                }
              }
            } else {
              set_seemInfoComponentGrantAccess(true);
              setIsLoading(false);
            }
          } else {
            for (let i = 0; i < dataArray.length; i++) {
              if (dataArray[i].name === "WEB_APPLICATION") {
                getWebApplicationOverview(dataArray[i].id);
                set_moduleID(dataArray[i].id);
              }
            }
          }
        } else {
          setIsLoading(false);
          setEmpty(true);
          showSweetAlert("You don't have a Web Application Module...");
          router.push("/pages-404");
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
  };

  useEffect(() => {
    setEmpty(false);
    setIsLoading(true);
    getModules();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Web Applications"
            breadcrumbItem="Web Applications"
          />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && assets && (
            <WebApplication assets={assets} moduleID={moduleID} />
          )}
          {!isLoading && seemInfoComponent && (
            <InfoNoDataComponent
              text="No web application asset were discovered"
              component="webapp"
            />
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="webapp"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default WebApplicationsMain;
