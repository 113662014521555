import React, { useEffect, useState } from "react";
import ApplianceTaskTable from "./ApplianceTaskTable";

const ApplianceTaskComponent = ({ tasks, applianceID, getApplianceTask }) => {
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [entryValue, setEntryValue] = useState(10);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className="row gap-3 ">
      <div className="col-12">
        <div
          style={{
            overflowX: windowWidth < 1000 ? "scroll" : "none",
          }}
        >
          <ApplianceTaskTable
            applianceID={applianceID}
            data={tasks}
            entryValue={entryValue}
            changeValue={(e) => changeValue(e)}
            getApplianceTask={getApplianceTask}
          />
        </div>
      </div>
    </div>
  );
};

export default ApplianceTaskComponent;
