import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import AccountLeaksComponent from "../../../components/Customer/CyberWatch/AccountLeaks/AccountLeaksComponent";
import { get } from "../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Container, Spinner } from "reactstrap";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../common/InfoNoDataComponent";

const AccountLeaks = () => {
  document.title = "Account Leaks | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [assets, setAssets] = useState();
  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getAccountLeaks = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/cyberwatch/leaks`);
      if (result?.success) {
        if (result?.data?.length > 0) {
          setAssets(result?.data);
        } else {
          set_seemInfoComponent(true);
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Account Leaks"
            );
          }
        }
      }
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getAccountLeaks();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Account Leaks" breadcrumbItem="Account Leaks" />

          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && assets && <AccountLeaksComponent assets={assets} />}
          {!isLoading && seemInfoComponent && (
            <InfoNoDataComponent
              text="No leaked accounts associated with organization employees were discovered"
              component="account-leak"
            />
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="account-leak"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AccountLeaks;
