import React, { useEffect, useState } from "react";
import {
  CardBody,
  Button,
  Popover,
  PopoverHeader,
  PopoverBody,
} from "reactstrap";
import { patch } from "../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import continents from "../../../../common/utils/continents.json";
import {
  GetCountries,
  GetState,
  GetCity,
  GetLanguages, //async functions
} from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import {
  formatDateFlatPickr,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import Flatpickr from "react-flatpickr";

const ProfileTabOverview = ({ profileInfos, getProfileInfos }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [authentication, set_authentication] = useState(
    profileInfos.authentication
  );
  const [organization_name, set_organization_name] = useState(
    profileInfos.organization_name
  );
  const [phone_number, set_phone_number] = useState(profileInfos.phone_number);
  const [email, set_email] = useState(profileInfos.email);
  const [region, set_region] = useState(profileInfos.region);
  const [country, set_country] = useState(profileInfos.country);
  const [state, set_state] = useState(profileInfos.state);
  const [city, set_city] = useState(profileInfos.city);
  const [address, set_address] = useState(profileInfos.address);
  const [subscription_renewal, set_subscription_renewal] = useState(
    profileInfos.subscription_renewal
  );
  const [date_joined, set_date_joined] = useState(profileInfos.date_joined);

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>Please scan the QR code in Authenticator</p>,
      html: `<img src="data:image/png;base64,${msg}" width="200" height="200">`,
      showConfirmButton: true,
      confirmButtonColor: "#61FF00",
      focusConfirm: false,
    });
  };

  const updateProfile = async () => {
    paceFunction(true);
    let data = {
      email,
      phone_number,
      organization_name,
      authentication: authentication === null ? "None" : authentication,
      region,
      country,
      state,
      city,
      address,
      subscription_renewal,
      date_joined,
      // manager_limit,
      // customer_limit,
    };

    togglePopover();
    try {
      let result = await patch("/distributor/settings/account/profile", data);
      if (result?.success) {
        if (result?.data?.authentication?.qr_image_base64) {
          showSweetAlert(result.data.authentication.qr_image_base64);
        }
        toastSuccess(result?.message || "Account Updated");
        getProfileInfos();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    GetCountries().then((result) => {
      let distributorCountry = result.find(
        (item) => item.name === profileInfos.country
      );
      setCountryid(distributorCountry.id);
      setCountriesList(result);
      GetState(distributorCountry.id).then((stateResult) => {
        let distributorState = stateResult.find(
          (item) => item.name === profileInfos.state
        );
        setStateid(distributorState.id);
        setStateList(stateResult);
        GetCity(distributorCountry.id, distributorState.id).then(
          (cityResult) => {
            setCityList(cityResult);
          }
        );
      });
    });
  }, []);

  return (
    <React.Fragment>
      <Popover
        placement="top"
        isOpen={popoverOpen}
        target="confirmationTooltip"
        id="confirmation"
        toggle={togglePopover}
      >
        <PopoverHeader>Are you sure ?</PopoverHeader>
        <PopoverBody>
          <Button onClick={updateProfile} color="success">
            Yes
          </Button>
          <Button onClick={togglePopover} color="danger">
            No
          </Button>
        </PopoverBody>{" "}
      </Popover>
      <div className="special-card mt-5 ">
        <CardBody>
          <form
            id="customer_detail_form"
            noValidate
            method="post"
            action="/update_profile/"
          >
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Account Email :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-email-multiple-outline" />
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="email"
                      placeholder="Account Email"
                      defaultValue={email}
                      onChange={(e) => set_email(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Phone Number :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-phone" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="phone_number"
                      placeholder="Phone Number"
                      defaultValue={phone_number}
                      onChange={(e) => set_phone_number(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Company Name :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-office-building-outline" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="organization_name"
                      defaultValue={organization_name}
                      onChange={(e) => set_organization_name(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Region :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-city-variant" />
                    </span>
                    <select
                      className="form-select"
                      style={{ width: "80%" }}
                      name="region"
                      id="region"
                      onChange={(e) => set_region(e.target.value)}
                      defaultValue={region}
                    >
                      {continents.map((continentName, continentKey) => {
                        return (
                          <option
                            value={continentName}
                            key={continentName + continentKey}
                          >
                            {continentName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Country :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-flag" />
                    </span>
                    <select
                      onChange={(e) => {
                        let countryValues = JSON.parse(e.target.value);
                        set_country(countryValues.name);
                        setCountryid(countryValues.id);
                        GetState(countryValues.id).then((result) => {
                          setStateList(result);
                          setStateid(result[0].id);
                          set_state(result[0].name);
                          GetCity(countryValues.id, result[0].id).then(
                            (resultCity) => {
                              setCityList(resultCity);
                              setCityid(resultCity[0].id);
                              set_city(resultCity[0].name);
                            }
                          );
                        });
                      }}
                      value={JSON.stringify(
                        countriesList.find((item) => item.name === country)
                      )}
                      className="form-select"
                      style={{ width: "80%" }}
                      name="country"
                      id="country"
                    >
                      {countriesList.map((item, index) => (
                        <option key={item + index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">State/Province :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-home-group" />
                    </span>
                    <select
                      onChange={(e) => {
                        let stateValues = JSON.parse(e.target.value);
                        set_state(stateValues.name);
                        setStateid(stateValues.id);
                        GetCity(countryid, stateValues.id).then((result) => {
                          setCityList(result);
                          setCityid(result[0].id);
                          set_city(result[0].name);
                        });
                      }}
                      value={JSON.stringify(
                        stateList.find((item) => item.name === state)
                      )}
                      className="form-select"
                      style={{ width: "80%" }}
                      name="state"
                      id="state"
                    >
                      {stateList.map((item, index) => (
                        <option key={item + index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">City/District :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-city" />
                    </span>
                    <select
                      onChange={(e) => {
                        let cityValues = JSON.parse(e.target.value); //here you will get full state object.
                        setCityid(cityValues.id);
                        set_city(cityValues.name);
                      }}
                      value={JSON.stringify(
                        cityList.find((item) => item.name === city)
                      )}
                      className="form-select"
                      style={{ width: "80%" }}
                      name="city"
                      id="city"
                    >
                      {cityList.map((item, index) => (
                        <option key={item + index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Address :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-map" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="address"
                      defaultValue={address}
                      onChange={(e) => set_address(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            {/* <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Joined Date :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-calendar-range" />
                    </span>
                    <Flatpickr
                      name="expiration_date"
                      className="form-control d-block"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "F j, Y",
                      }}
                      defaultValue={formattedJoinedDate}
                      // onChange={changeTime}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Subscription Renewal :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-airplane-clock" />
                    </span>
                    <Flatpickr
                      name="expiration_date"
                      className="form-control d-block "
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "F j, Y",
                      }}
                      defaultValue={formattedSubscriptionRenewal}
                      // onChange={changeTime}
                      disabled
                    />
                  </div>
                </div>
              </div>
            </div> */}
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Authentication :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <div className="btn-group btn-group-sm   " role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faNone1"
                        defaultValue="None"
                        autoComplete="off"
                        defaultChecked={
                          authentication === "None" ? true : false
                        }
                        onChange={(e) => set_authentication(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faNone1"
                      >
                        None
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faSms1"
                        defaultValue="SMS"
                        autoComplete="off"
                        defaultChecked={authentication === "SMS" ? true : false}
                        onChange={(e) => set_authentication(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faSms1"
                      >
                        SMS
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faQr1"
                        defaultValue="QR"
                        autoComplete="off"
                        defaultChecked={authentication === "QR" ? true : false}
                        onChange={(e) => set_authentication(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faQr1"
                      >
                        QR
                      </label>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-5" />
            <div className="d-flex justify-content-end ">
              <button
                onClick={togglePopover}
                type="button"
                id="confirmationTooltip"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Save
                Changes
              </button>
            </div>
          </form>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default ProfileTabOverview;
