import React from "react";
import { useHistory } from "react-router-dom";

const ExploitDetailsRepositories = ({ repositories, cvesID }) => {
  const router = useHistory();
  return (
    <div>
      {repositories?.length > 0 &&
        repositories.map((repositoriesItem, repositoriesIndex) => {
          return (
            <div key={`${repositoriesItem?.full_name}+${repositoriesIndex}`}>
              <div className="d-flex  justify-content-between align-items-center ">
                <div className="d-flex flex-row align-items-center">
                  <i className="mdi mdi-github font-size-24 text-black " />
                  <a
                    href={repositoriesItem?.html_url}
                    target="_blank"
                    rel="noreferrer"
                    className="ms-1 text-primary font-size-16 fw-bold "
                  >
                    {repositoriesItem?.full_name}
                  </a>
                </div>
                <div className="d-flex flex-row col-5  justify-content-between align-items-center  ">
                  <div className="d-flex flex-row align-items-center ">
                    <i
                      className="mdi mdi-star font-size-18"
                      style={{ color: "#ffa700" }}
                    />
                    <span className=" font-size-14 fw-bold ms-1  ">
                      {repositoriesItem?.stargazers_count}{" "}
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-center  ">
                    <i className="mdi mdi-source-fork font-size-16" />
                    <span className=" font-size-14 fw-bold ms-1  ">
                      {repositoriesItem?.forks_count}{" "}
                    </span>
                  </div>
                  <div className="d-flex flex-row align-items-center ">
                    <i className="mdi mdi-eye-outline font-size-16" />
                    <span className=" font-size-14 fw-bold ms-1  ">
                      {repositoriesItem?.watchers_count}{" "}
                    </span>
                  </div>
                </div>
              </div>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  router.push(
                    `/cyber-watch/threat-intelligence/cves-detail/${cvesID}`
                  );
                }}
                className="mt-1"
              >
                <div className="font-size-14 fw-bold d-flex flex-row ">
                  {repositoriesItem?.description &&
                    repositoriesItem?.description.substring(0, 50)}
                  {repositoriesItem?.description &&
                  repositoriesItem?.description.length > 50
                    ? "..."
                    : null}
                </div>
              </div>
              <hr />
            </div>
          );
        })}
    </div>
  );
};

export default ExploitDetailsRepositories;
