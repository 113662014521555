import React, { useEffect, useRef, useState } from "react";
import { del, post } from "../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const DropdownHelp = ({ rowId }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [isOpen, setIsOpen] = useState(false);

  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);

  const [removeLoading, setRemoveLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const dropdownRef = useRef(null);

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const tog_standard4 = () => {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  };

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  const deleteFunc = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del(`/admin/demo-account?id=${rowId}`);
      if (result?.success) {
        toastSuccess(result?.message || "Request Removed");
        tog_standard4();
        toggleDropdown();
        dispatch({ type: "RENDER_COMPONENT_REQ" });
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  const approveFunc = async () => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await post(`/admin/demo-account`, { id: rowId });
      if (result?.success) {
        toastSuccess(result?.message || "Request Approved");
        tog_standard3();
        toggleDropdown();
        dispatch({ type: "RENDER_COMPONENT_REQ" });
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      {/* Approve Request */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={approveFunc}
        text="Are you sure you want to approve request?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Delete Request */}
      <ConfirmModal
        modal_standard={modal_standard4}
        tog_standard={tog_standard4}
        setmodal_standard={setmodal_standard4}
        targetFunction={deleteFunc}
        text="Are you sure you want to delete request?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      <div className={`dropdownn ${isOpen ? "open" : ""}`} ref={dropdownRef}>
        <div className="selected-optionn" onClick={toggleDropdown}>
          <i className="bx bx-dots-horizontal-rounded text-primary fs-5 " />
          <i className="mdi mdi-chevron-down text-primary fs-6 " />
        </div>
        <ul className="optionss">
          <li className="fs-6" onClick={tog_standard3}>
            <i className="mdi mdi-check-circle label-icon text-success me-1 " />
            Approve
          </li>
          <li className="fs-6" onClick={tog_standard4}>
            <i className="mdi mdi-account-remove-outline label-icon text-danger me-1 " />
            Delete
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default DropdownHelp;
