import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  formatDateTransform,
  removeBodyCss,
} from "../../../../common/utils/commonFunctions";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const ScoreTrends = ({ data, default_widgets, defaultWidgetDelete }) => {
  const [viewEdit, set_viewEdit] = useState(false);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };
  let scoreArray = [];
  let lastScoreArray = [];
  let scoreDates = [];
  let filteredWeeklyOverview = data?.weekly_overview?.filter(
    (item) => item.score !== null
  );
  if (filteredWeeklyOverview?.length > 0) {
    scoreArray = filteredWeeklyOverview?.map((item) => item?.score);
    scoreDates = filteredWeeklyOverview?.map((item) => item?.week);
  }
  if (scoreArray.length > 0) {
    lastScoreArray = scoreArray.map((item) => {
      if (item >= 0 && item <= 0.8) {
        return 0;
      } else if (item > 0.8 && item <= 1.5) {
        return 1;
      } else if (item > 1.5 && item <= 2.4) {
        return 2;
      } else if (item > 2.4 && item <= 3.5) {
        return 3;
      } else {
        return 4;
      }
    });
  }

  const options = {
    dataLabels: {
      enabled: false,
    },
    series: [
      {
        name: "Score",
        data: lastScoreArray,
      },
    ],
    colors: ["red"],
    fill: {
      type: "solid",
      colors: ["transparent"],
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: formatDateTransform(data?.date_format),
        datetimeUTC: false,
      },
      categories: scoreDates,
    },
    yaxis: {
      // reversed: true,
      labels: {
        formatter: function (value) {
          const yLabels = ["A", "B", "C", "D", "E"];
          if (value >= 0 && value <= 0.8) {
            return yLabels[0];
          } else if (value > 0.8 && value <= 1.5) {
            return yLabels[1];
          } else if (value > 1.5 && value <= 2.4) {
            return yLabels[2];
          } else if (value > 2.4 && value <= 3.5) {
            return yLabels[3];
          } else {
            return yLabels[4];
          }
        },
      },
      min: 0,
      max: 4,
      tickAmount: 4,
    },

    stroke: {
      curve: "smooth",
    },
    tooltip: {
      x: {
        format: `${formatDateTransform(data?.date_format)} HH:mm`,
      },
    },
  };

  const series = options.series;

  const targetFunc = () => {
    defaultWidgetDelete("score_trends");
  };

  return (
    <div className="col-12">
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={targetFunc}
        text="Are you sure want to delete widget ?"
        loadingAction={false}
      />
      {filteredWeeklyOverview.length > 0 && default_widgets.score_trends && (
        <div
          onMouseEnter={() => set_viewEdit(true)}
          onMouseLeave={() => set_viewEdit(false)}
          className="special-card"
        >
          <div className="d-flex position-relative  py-2  align-items-center justify-content-between  ">
            <div>
              <span className="fw-bold fs-4 me-1 ">Score Trends </span>
            </div>{" "}
            <div
              className={` cursor-pointer ${viewEdit ? "d-flex" : "d-none"}`}
              style={{ position: "absolute", top: 5, right: 0 }}
              onClick={tog_standard5}
            >
              <i className="mdi mdi-delete text-primary fs-3 " />
            </div>
          </div>
          <div>
            <ReactApexChart
              options={options}
              series={series}
              type="area"
              height={230}
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default ScoreTrends;
