import React, { useState } from "react";
import {
  calculatePercentage,
  getModuleName,
  randomHexCode,
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import { post } from "../../../helpers/api_helper";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const Quotas = ({ tickets }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [id, set_id] = useState();

  const [increaseLoading, setIncreaseLoading] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const colors = [];

  for (let i = 0; i < 200; i++) {
    const hexKodu = randomHexCode();
    colors.push(hexKodu);
  }

  const increaseQuota = async () => {
    paceFunction(true);
    setIncreaseLoading(true);
    let data = {
      id,
    };
    try {
      let result = await post("/customer/settings/ticket", data);
      if (result?.success) {
        toastSuccess(result?.message || "Request was sent");
        tog_standard();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
    setIncreaseLoading(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        targetFunction={increaseQuota}
        text="Are you sure you want to submit a quota increase request?"
        loadingAction={true}
        isLoading={increaseLoading}
      />
      <div className="special-card mt-5">
        <div
          style={{
            maxHeight: "308px",
            overflowY: "overlay",
            overflowX: "hidden",
            marginRight: "0px",
          }}
          className="row pt-2 "
        >
          <div className="row ps-4">
            <div className="col-3  d-flex">
              <span className="font-size-14 fw-semibold my-2    ">Name</span>
            </div>
            <div className="col-2 justify-content-center d-flex">
              <span className="font-size-14 fw-semibold my-2 me-3 text-center  ">
                Current usage
              </span>
            </div>
            <div className="col-4 justify-content-center d-flex">
              <span className="font-size-14 fw-semibold my-2  text-center  ">
                Current usage pertencage
              </span>
            </div>
            <div className="col-xxl-2 col-2 justify-content-center d-flex">
              <span className="font-size-14 fw-semibold my-2  ">Quota</span>
            </div>
            <div className="col-1 justify-content-center d-flex"></div>
          </div>
          <div className="row ps-4">
            <div className="col-12">
              <div className="d-flex flex-column ">
                {tickets?.map((ticket, index) => {
                  const widthPercentage = (ticket.handled / ticket.stock) * 100;
                  const percentage = calculatePercentage(
                    ticket.handled,
                    ticket.stock
                  );
                  return (
                    <div
                      key={ticket.module + index}
                      className="row align-items-center mb-1 "
                    >
                      <div className="col-3 ">
                        <div className="d-flex flex-row align-items-center ">
                          <div
                            style={{
                              width: "5px",
                              height: "5px",
                            }}
                            className="rounded bg-primary "
                          />
                          <span className="font-size-13 fw-medium ms-2  ">
                            {getModuleName(ticket.module)}
                          </span>
                        </div>
                      </div>
                      <div className="col-2 justify-content-center d-flex">
                        {" "}
                        <span
                          key={name + "a"}
                          className="font-size-13 fw-medium   "
                        >
                          {ticket.handled}
                        </span>
                      </div>
                      <div className="col-4 ">
                        <div className="d-flex align-items-center justify-content-center ">
                          <div className="my-progress-bar col-11">
                            <div
                              className="my-progress-bar-inner bg-primary"
                              style={{
                                width: `${widthPercentage}%`,
                              }}
                            ></div>
                          </div>
                          <span className="font-size-13 fw-medium ">
                            {percentage}%
                          </span>
                        </div>
                      </div>
                      <div className="col-xxl-2 col-2 justify-content-center d-flex">
                        {" "}
                        <span
                          key={ticket.module + "a"}
                          className="font-size-13 fw-medium    "
                        >
                          {ticket.stock}
                        </span>
                      </div>
                      <div className="col-xxl-1 col-1 justify-content-center d-flex">
                        <button
                          onClick={() => {
                            tog_standard();
                            set_id(ticket.id);
                          }}
                          type="button"
                          className="btn btn-primary btn-sm  text-center "
                        >
                          <i
                            title="Increase Quota"
                            className="mdi mdi-elevation-rise font-size-14  label-icon  d-block"
                          />
                        </button>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Quotas;
