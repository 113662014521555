import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import IssuesTable from "../../../components/Admin/Issues/IssuesTable";
import { Container, Spinner } from "reactstrap";

import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { get } from "../../../helpers/api_helper";

const Issues = () => {
  document.title = "Issues | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState();
  const [issues, setIssues] = useState();
  const [entryValue, setEntryValue] = useState(10);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getIssues = async () => {
    paceFunction(true);
    try {
      let result = await get(`/account/issue`);
      if (result?.success) {
        setIssues(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getIssues();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Issues" breadcrumbItem="Issues" />

          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && issues && (
            <div
              className="special-card"
              style={{
                overflowX: windowWidth < 800 ? "overlay" : "none",
              }}
            >
              <IssuesTable
                data={issues}
                getIssues={getIssues}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
              />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Issues;
