import React, { useEffect, useState } from "react";
import { post } from "../../../../helpers/api_helper";
import { CardHeader, CardBody, Spinner } from "reactstrap";
import VulnerabilitiesTable from "./VulnerabilitiesTable";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";

const Vulnerabilies = ({
  services,
  updated_at,
  hosts,
  moduleID,
  setSelectDisabled,
}) => {
  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [entryValue, setEntryValue] = useState(10);

  const [isLoading, setIsLoading] = useState(false);

  const [data, setData] = useState([]);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  const getVulnerabilities = async () => {
    paceFunction(true);
    setSelectDisabled(true);
    let tempPort;
    tempPort = services?.map((item) => item?.port);
    let vulnData = {
      date: updated_at,
      id: hosts?.map((item) => item?.id),
      port: tempPort,
    };
    try {
      let result = await post(`/customer/module/host/vulns`, vulnData);
      if (result?.success) {
        const sortedArray = sortByFamilyAscending(result.data);
        setData(sortedArray);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setSelectDisabled(false);
    paceFunction(false);
  };

  useEffect(() => {
    if (services?.length > 0) {
      setIsLoading(true);
      getVulnerabilities();
    } else {
      setData([]);
    }
  }, [services]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const sortByFamilyAscending = (data) => {
    return data.sort((a, b) => {
      const familyA = a.vuln_family.toUpperCase();
      const familyB = b.vuln_family.toUpperCase();

      if (familyA < familyB) {
        return -1;
      }
      if (familyA > familyB) {
        return 1;
      }
      return 0;
    });
  };

  return (
    <React.Fragment>
      <div className="special-card2 mt-5 ">
        <CardHeader>
          <h4 className="card-title text-primary ">Vulnerabilities</h4>
        </CardHeader>
        {isLoading && (
          <div className="d-flex justify-content-center  align-items-center h-100 my-5  ">
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              className="ms-2 "
              color="primary"
            />
          </div>
        )}{" "}
        {!isLoading && data && (
          <CardBody>
            <div
              style={{
                overflowX: windowWidth < 800 ? "overlay" : "none",
              }}
            >
              <VulnerabilitiesTable
                updated_at={updated_at}
                services={services}
                hosts={hosts}
                data={data}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
                moduleID={moduleID}
                getVulnerabilities={getVulnerabilities}
              />
            </div>
          </CardBody>
        )}
      </div>
    </React.Fragment>
  );
};

export default Vulnerabilies;
