import React, { useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import PortScan from "../../../components/Admin/Utils/PortScan";
import WafControl from "../../../components/Admin/Utils/WafControl";
import Subdomains from "../../../components/Admin/Utils/Subdomains";
import { Container } from "reactstrap";
import classnames from "classnames";
import {
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Col,
} from "reactstrap";

const Utils = () => {
  document.title = "Utils | PentestBX";

  const [activeTab, setactiveTab] = useState("1");

  const toggle = (tab) => {
    if (activeTab !== tab) {
      setactiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Utils" breadcrumbItem="Utils" />

          <div className="special-card">
            <Nav tabs>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "1",
                  })}
                  onClick={() => {
                    toggle("1");
                  }}
                >
                  Port Scan
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "2",
                  })}
                  onClick={() => {
                    toggle("2");
                  }}
                >
                  Waf Control
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: activeTab === "3",
                  })}
                  onClick={() => {
                    toggle("3");
                  }}
                >
                  Subdomains
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="p-3 text-muted">
              <TabPane tabId="1">
                <Row>
                  <Col sm="12">
                    <div className="mb-0">
                      <PortScan />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="2">
                <Row>
                  <Col sm="12">
                    <div className="mb-0">
                      <WafControl />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="3">
                <Row>
                  <Col sm="12">
                    <div className="mb-0">
                      <Subdomains />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Utils;
