import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { get } from "../../../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Container, Spinner } from "reactstrap";
import CwesComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/Cves/CwesComponent";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../../../common/InfoNoDataComponent";
import { useHistory, useLocation } from "react-router-dom";
import VulnerabilitiesWatcherTabs from "./VulnerabilitiesWatcherTabs";

const Cwes = () => {
  document.title = "Threat Intelligence | Categories (CWE)";

  const router = useHistory();
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const searchUrl = queryParams.get("search");
  const page = queryParams.get("page");

  const [cwePage, set_cwePage] = useState(page);
  const [cwes, set_cwes] = useState();
  const [search, set_search] = useState(searchUrl ? searchUrl : "");

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);

  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getThreatIntelligenceCwes = async (data) => {
    paceFunction(true);
    try {
      let result = await get(
        `/customer/module/cyberwatch/ti/cwes?page=${
          data ? data : cwePage
        }&search=${search}`
      );
      if (result?.success) {
        if (result?.data?.length > 0) {
          if (!firstLoading) {
            setFirstLoading(true);
          }
          set_cwePage(data ? data : cwePage);
          set_cwes(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname ===
            "/cyber-watch/threat-intelligence/categories-cwes"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/categories-cwes?page=${
                data ? data : cwePage
              }${search ? `&search=${search}` : ""}`
            );
          }
        } else {
          set_seemInfoComponent(true);
          set_cwes();
          if (
            window.location.pathname ===
            "/cyber-watch/threat-intelligence/categories-cwes"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/categories-cwes?page=${
                data ? data : cwePage
              }${search ? `&search=${search}` : ""}`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname ===
            "/cyber-watch/threat-intelligence/categories-cwes"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/categories-cwes?page=${
                data ? data : cwePage
              }${search ? `&search=${search}` : ""}`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cwes"
            );
          }
        }
      }
      set_cwes();
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoading(true);
    }
    getThreatIntelligenceCwes();
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    getThreatIntelligenceCwes(1);
  };

  // CLEAN SEARCH
  const cleanAll = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cwes?page=1`);
      if (result?.success) {
        if (result?.data?.length > 0) {
          set_cwePage(1);
          set_cwes(result?.data);
          set_seemInfoComponent(false);
          set_seemInfoComponentGrantAccess(false);
          if (
            window.location.pathname ===
            "/cyber-watch/threat-intelligence/categories-cwes"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/categories-cwes?page=1`
            );
          } else {
          }
          set_seemInfoComponent(true);
          set_cwes();
          if (
            window.location.pathname ===
            "/cyber-watch/threat-intelligence/categories-cwes"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/categories-cwes?page=1`
            );
          }
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
          if (
            window.location.pathname ===
            "/cyber-watch/threat-intelligence/categories-cwes"
          ) {
            router.replace(
              `/cyber-watch/threat-intelligence/categories-cwes?page=1`
            );
          }
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Cwes"
            );
          }
        }
      }
      set_cwes();
    }
    paceFunction(false);
    set_search("");
  };

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Threat Intelligence / Categories (CWE)"
            breadcrumbItem="Threat Intelligence / Categories (CWE)"
          />
          <VulnerabilitiesWatcherTabs />
          <div className="row   flex-lg-row flex-column-reverse ">
            <div className="col-lg-8">
              {" "}
              {isLoading && (
                <div className="d-flex justify-content-center  align-items-center h-100  ">
                  <Spinner
                    style={{ width: "3rem", height: "3rem" }}
                    className="ms-2 "
                    color="primary"
                  />
                </div>
              )}
              {!isLoading && (
                <div style={{ overflowX: "overlay" }}>
                  {cwes && (
                    <CwesComponent
                      cwes={cwes}
                      getThreatIntelligenceCwes={getThreatIntelligenceCwes}
                      cwePage={cwePage}
                    />
                  )}
                </div>
              )}
              {!isLoading && seemInfoComponent && (
                <InfoNoDataComponent
                  text="No cwes data were found"
                  component="cves"
                />
              )}
              {!isLoading && seemInfoComponentGrantAccess && (
                <InfoNoDataComponent
                  text="Module permission denied. Contact with customer account."
                  component="cves"
                />
              )}
            </div>
            <div className="col-lg-4 mb-lg-0 mb-2">
              <div className="special-card">
                <div className="card-header with-border p-2">
                  <h3 className="card-title my-1 ">Search</h3>
                </div>
                <div className="card-body d-flex justify-content-between align-items-center ">
                  <form
                    onSubmit={handleSubmit}
                    role="form"
                    className="form-horizontal w-100 "
                  >
                    <div className="form-group">
                      <div className="col-md-12">
                        <div className="input-group">
                          <span className="input-group-btn">
                            <button className="btn btn-primary" type="submit">
                              <i className="fa fa-search" />
                            </button>
                          </span>
                          <input
                            type="text"
                            placeholder="Search"
                            className="form-control"
                            name="search"
                            value={search}
                            onChange={(e) => set_search(e.target.value)}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                  <span
                    onClick={cleanAll}
                    className="font-size-20 ms-1 fw-bolder "
                    style={{ cursor: "pointer" }}
                    aria-hidden="true"
                  >
                    &times;
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Cwes;
