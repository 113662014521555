import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import TasksComponent from "../../../components/Admin/TaskScanners/TasksComponent";
import { Container, Spinner } from "reactstrap";
import { get } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const TaskScanners = () => {
  document.title = "Task-Scanners | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [tasks, setTasks] = useState();
  const [isLoading, setIsLoading] = useState();

  const { id, customer } = useParams();

  const getTasks = async () => {
    paceFunction(true);
    try {
      let result = await get(`/admin/customer/task?id=${id}`);
      if (result.success) {
        setTasks(result.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getTasks();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title={`Task & Scanners for ${customer}`}
            breadcrumbItem={`Task & Scanners for  ${customer}`}
          />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && tasks && (
            <TasksComponent tasks={tasks} userID={id} getTasks={getTasks} />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TaskScanners;
