import React, { useState } from "react";
import { Container } from "reactstrap";
// import html2pdf from "html3pdf";
import MyComponent from "./MyComponent";
import logo from "../../assets/images/pdf-logo.png";

const ExportToPdfButton = ({ contentId, fileName }) => {
  const handleExportToPdf = () => {
    const content = document.getElementById(contentId);

    const config = {
      filename: "test.pdf",
      image: { type: "png", quality: 0.5 },
      html2canvas: { dpi: 75, scale: 2, letterRendering: true },
      pagebreak: { mode: ["avoid-all", "css", "legacy"] },
      jsPDF: {
        orientation: "portrait",
        unit: "in",
        format: "a4",
        compressPDF: true,
      },
    };

    html2pdf()
      .from(content)
      .set(config)
      .toPdf()
      .get("pdf")
      .then((pdf) => {
        var totalPages = pdf.internal.getNumberOfPages();
        for (let i = 1; i <= totalPages; i++) {
          pdf.setPage(i);
          pdf.saveGraphicsState();
          pdf.setGState(new pdf.GState({ opacity: 1 }));
          pdf.addImage(logo, "png", 0.2, 0.6, 0, 0, undefined, "FAST", 45);
          // const imageHeight = 25; // Örneğin, resmin yüksekliği 50
          // pdf.addImage(logo, "png", 0, pageHeight - imageHeight, 0, 0);

          pdf.restoreGraphicsState();
        }
      })
      .save();
  };

  return <button onClick={handleExportToPdf}>Export to PDF</button>;
};

const Example = () => {
  document.title = "Example Page";
  const [value, setValue] = React.useState("**Hello world!!!**");
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <div>
            <div className="d-flex justify-content-center      ">
              <MyComponent />
            </div>
            <ExportToPdfButton
              contentId="myContent"
              fileName="exported-document.pdf"
            />
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Example;
