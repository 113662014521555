import React, { useEffect, useState } from "react";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";
import AddWidgetModal from "./Dynamics/AddWidgetModal";
import ScoreTrends from "./ScoreSection/ScoreTrends";
import Score from "./ScoreSection/Score";
import DynamicWizard from "./Dynamics/DynamicWizard";
import DistributionSeverity from "./SeveritySection/DistributionSeverity";
import SeverityChanges from "./SeveritySection/SeverityChanges";

const DashboardComponent = ({ data }) => {
  const [modal_standard, setmodal_standard] = useState(false);
  const [widgets, set_widgets] = useState();
  const [default_widgets, set_default_widgets] = useState();

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const getWidgets = () => {
    let storageWidgets = window.localStorage.getItem("widgets");
    if (storageWidgets) {
      set_widgets(JSON.parse(storageWidgets));
    } else {
      set_widgets();
    }
  };

  const getDefaultWidgets = () => {
    let storageWidgets = window.localStorage.getItem("defaultWidgets");
    if (storageWidgets) {
      set_default_widgets(JSON.parse(storageWidgets));
    } else {
      set_default_widgets();
    }
  };

  useEffect(() => {
    getWidgets();
    getDefaultWidgets();
  }, []);

  const defaultWidgetDelete = (widget) => {
    let storageWidgets = window.localStorage.getItem("defaultWidgets");
    let parsedStorageWidgets = JSON.parse(storageWidgets);
    if (parsedStorageWidgets && parsedStorageWidgets.hasOwnProperty(widget)) {
      parsedStorageWidgets[widget] = false;
    }
    window.localStorage.setItem(
      "defaultWidgets",
      JSON.stringify(parsedStorageWidgets)
    );
    getDefaultWidgets();
    toastSuccess("Widget Deleted");
  };

  return (
    <div>
      <AddWidgetModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        getWidgets={getWidgets}
        getDefaultWidgets={getDefaultWidgets}
        default_widgets={default_widgets}
      />
      <div className="row">
        {data && default_widgets?.score_trends && (
          <div className="col-xxl-4  col-lg-6 mb-4">
            <ScoreTrends
              data={data}
              default_widgets={default_widgets}
              defaultWidgetDelete={(e) => defaultWidgetDelete(e)}
            />
          </div>
        )}{" "}
        {data && default_widgets?.distribution_severity && (
          <div className="col-xxl-4 col-lg-6 mb-4">
            <DistributionSeverity
              data={data}
              default_widgets={default_widgets}
              defaultWidgetDelete={(e) => defaultWidgetDelete(e)}
            />
          </div>
        )}
        {data && default_widgets?.score && (
          <div className="col-xxl-4 col-md-6 mb-4">
            <Score
              data={data}
              default_widgets={default_widgets}
              defaultWidgetDelete={(e) => defaultWidgetDelete(e)}
            />
          </div>
        )}
        {data && default_widgets?.severity_changes && (
          <div className=" col-xxl-4 col-md-6 mb-4">
            <SeverityChanges
              data={data}
              default_widgets={default_widgets}
              defaultWidgetDelete={(e) => defaultWidgetDelete(e)}
            />
          </div>
        )}
        {data &&
          widgets &&
          widgets.map((wizard, index) => (
            <DynamicWizard
              key={index}
              wizard={wizard}
              data={data}
              getWidgets={getWidgets}
            />
          ))}
      </div>
      <div onClick={tog_standard} className="col-12 mb-1 cursor-pointer ">
        <div className="file-upload justify-content-center d-flex ">
          <span></span>
          <label htmlFor="file-input" className="file-label">
            <div className="d-flex flex-row font-size-16 mt-1 ">
              <i className="mdi mdi-plus me-1" />{" "}
              <div className="d-flex flex-row cursor-pointer">
                <span>
                  Click to add widget or change default widget situation
                </span>
              </div>
              <i
                style={{ marginTop: "1px" }}
                title="Widgets will be saved in the browser"
                className="mdi mdi-information ms-1 text-primary"
              />
            </div>
          </label>
        </div>
      </div>
    </div>
  );
};

export default DashboardComponent;
