import React, { useEffect, useState } from "react";
import { Modal, CardHeader, Card, CardBody } from "reactstrap";
import CyberIntelligenceModuleTable from "./CyberWatchTable";
import { del, get, put, patch } from "../../../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import CyberIntelligenceModuleTickets from "./CyberWatchTickets";
import { motion, AnimatePresence } from "framer-motion";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";
import CyberWatchAddTask from "./CyberWatchAddTask";

const CyberWatch = ({ datas }) => {
  const dispatch = useDispatch();

  const { errorFunction, errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  // Loading
  const [updateLoading, setUpdateLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  // Others
  const [entryValue, setEntryValue] = useState(10);
  const [data, setData] = useState();
  const [viewTickets, setViewTickets] = useState(false);
  const [tickets, setTickets] = useState();
  // Modals
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  // Assets
  const [asset, set_asset] = useState("");
  const [type, set_type] = useState("email");
  // Update
  const [is_active, set_is_active] = useState(datas.is_enable);
  const [asset_count, set_asset_count] = useState(datas.asset_count);
  const [attack_surface, set_attack_surface] = useState(
    datas.data.sub_modules?.attack_surface?.enable || false
  );
  const [brand_security, set_brand_security] = useState(
    datas.data.sub_modules?.brand_security?.enable || false
  );
  const [threat_intelligence, set_threat_intelligence] = useState(
    datas.data.sub_modules?.threat_intelligence?.enable || false
  );

  const changeValue = (value) => {
    setEntryValue(value);
  };

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const tog_standard4 = () => {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  };
  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const getAssets = async () => {
    paceFunction(true);
    try {
      let result = await get(
        `/admin/customer/module/asset?id=${datas?.user_id}&module_id=${datas?.id}`
      );
      if (result?.success) {
        const updatedData = result?.data[0]?.assets?.map((asset) => {
          if (asset?.discovered) {
            return {
              ...asset,
              discovered_type: "true",
            };
          } else {
            return {
              ...asset,
              discovered_type: "false",
            };
          }
        });
        setData(updatedData);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const getTickets = async () => {
    paceFunction(true);
    try {
      let result = await get(`/admin/customer/module/tickets?id=${datas?.id}`);
      if (result?.success) {
        setTickets(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getAssets();
    getTickets();
  }, []);

  const addRecord = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      address: asset,
      type,
      id: datas.id,
    };
    try {
      let result = await put("/admin/customer/module/asset", data);
      if (result?.success) {
        toastSuccess(result?.message || "Asset Added");
        getAssets();
        tog_standard();
        tog_standard5();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  const updateCyberSight = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let data = {
      asset_count,
      is_enable: is_active,
      user_id: datas.user_id,
      id: datas.id,
      attack_surface,
      brand_monitoring: brand_security,
      threat_intelligence,
    };
    try {
      let response = await patch("/admin/customer/module", data);
      if (response?.success) {
        toastSuccess(response?.message || "Module Updated");
        tog_standard3();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  const removeModule = async () => {
    setRemoveLoading(true);
    try {
      let result = await del(
        `/admin/customer/module?id=${datas.user_id}&module_id=${datas.id}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Module Removed");
        tog_standard4();
        dispatch({ type: "RENDER_COMPONENT_REQ" });
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  return (
    <div className="col-xxl-12  ">
      {/* Add Record */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addRecord}
        text="Are you sure you want to add record?"
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Update Module  */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={updateCyberSight}
        text="Are you sure you want to update module?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      {/* Remove Module  */}
      <ConfirmModal
        modal_standard={modal_standard4}
        tog_standard={tog_standard4}
        setmodal_standard={setmodal_standard4}
        targetFunction={removeModule}
        text="Are you sure you want to remove module?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      {/* Add Task Modal */}
      {data && (
        <CyberWatchAddTask
          modal_standard={modal_standard2}
          tog_standard={tog_standard2}
          setmodal_standard={setmodal_standard2}
          userID={datas.user_id}
          moduleID={datas.id}
          allAssets={data}
        />
      )}
      {/* Module Add Modal */}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Record
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="type">Type:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-control"
                name="type"
                style={{ border: "1px solid #3fa0da" }}
                id="type"
                defaultValue="email"
                onChange={(e) => set_type(e.target.value)}
              >
                <option value="email">EMAIL</option>
                <option value="domain">DOMAIN</option>
                <option value="ip">IP ADDRESS</option>
              </select>
              <label id="type-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="asset">Asset:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                name="asset"
                id="asset"
                placeholder={`${
                  type === "domain" ? "http-https://example.com" : ""
                }`}
                style={{ overflow: "hidden" }}
                onChange={(e) => set_asset(e.target.value)}
              />
              <label id="asset-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Add Record
          </button>
        </div>
      </Modal>
      <Card className="border-primary special-card ">
        <CardHeader className="d-flex justify-content-between   ">
          <h5 className="my-0 text-primary">
            <i className="mdi mdi-bullseye-arrow me-3"></i>
            {datas.text}
          </h5>
          <div className="d-flex  ">
            <label className="form-label me-1 ">Active</label>
            <div className="square-switch">
              <input
                type="checkbox"
                id="is_active_cyber"
                name="is_active_cyber"
                switch="primary"
                defaultChecked={is_active}
                autoComplete="off"
                onChange={(e) => set_is_active(e.target.checked)}
              />
              <label
                htmlFor="is_active_cyber"
                data-on-label="Yes"
                data-off-label="No"
              />
            </div>
          </div>
        </CardHeader>
        <CardBody>
          <div className="dt-buttons btn-group flex-wrap">
            <button
              className="btn btn-primary"
              aria-controls="webapps_table"
              type="button"
              onClick={() => {
                tog_standard();
              }}
            >
              <span>Add</span>
            </button>
          </div>
          {data && (
            <div
              style={{
                overflowX: "overlay",
              }}
            >
              <CyberIntelligenceModuleTable
                data={data}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
                userID={datas.user_id}
                moduleID={datas.id}
                getAssets={getAssets}
              />
            </div>
          )}
          <div className="row mt-2 ">
            <div className="col-xl-4 col-md-4">
              <div className="d-flex flex-column   ">
                <label className="form-label me-1 ">
                  Attack Surface Management
                </label>
                <div className="square-switch">
                  <input
                    type="checkbox"
                    id="is_active_attackSurface"
                    name="is_active_attackSurface"
                    switch="primary"
                    defaultChecked={attack_surface}
                    autoComplete="off"
                    onChange={(e) => set_attack_surface(e.target.checked)}
                  />
                  <label
                    htmlFor="is_active_attackSurface"
                    data-on-label="Yes"
                    data-off-label="No"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="d-flex flex-column  ">
                <label className="form-label me-1 ">Brand Monitor</label>
                <div className="square-switch">
                  <input
                    type="checkbox"
                    id="is_active_brandSec"
                    name="is_active_brandSec"
                    switch="primary"
                    defaultChecked={brand_security}
                    autoComplete="off"
                    onChange={(e) => set_brand_security(e.target.checked)}
                  />
                  <label
                    htmlFor="is_active_brandSec"
                    data-on-label="Yes"
                    data-off-label="No"
                  />
                </div>
              </div>
            </div>
            <div className="col-xl-4 col-md-4">
              <div className="d-flex flex-column   ">
                <label className="form-label me-1 ">Threat Intelligence</label>
                <div className="square-switch">
                  <input
                    type="checkbox"
                    id="is_active_threatInt"
                    name="is_active_threatInt"
                    switch="primary"
                    defaultChecked={threat_intelligence}
                    autoComplete="off"
                    onChange={(e) => set_threat_intelligence(e.target.checked)}
                  />
                  <label
                    htmlFor="is_active_threatInt"
                    data-on-label="Yes"
                    data-off-label="No"
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="row mt-2 justify-content-center ">
            <div className="col-xl-4 col-md-6">
              <div className="form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  name="scanperiod"
                  min={1}
                  step={1}
                  defaultValue={asset_count}
                  autoComplete="off"
                  onChange={(e) => set_asset_count(e.target.value)}
                />
                <label>Number of Asset</label>
              </div>
            </div>
          </div>
          <div className="col border-end d-flex justify-content-between align-items-end">
            <button
              onClick={() => {
                setViewTickets(!viewTickets);
              }}
              className="btn btn-outline-info "
            >
              <i className="mdi mdi-view-grid label-icon  me-1 " />
              {tickets && viewTickets ? "Close Tickets" : "View Tickets"}
            </button>
            <div className="d-flex flex-wrap justify-content-center  gap-2">
              <button
                className="btn btn-outline-primary"
                id="updateWebAppBtn"
                type="button"
                onClick={tog_standard3}
              >
                <i className="bx bx-check-double label-icon" /> Update
              </button>
              <button
                className="btn btn-outline-danger removeModule"
                data-mod="WEB_APPLICATION"
                type="button"
                onClick={() => {
                  tog_standard4();
                }}
              >
                <i className="bx bx-block label-icon" /> Remove
              </button>
            </div>
            <button
              onClick={() => {
                tog_standard2();
              }}
              className="btn btn-outline-success "
            >
              <i className="mdi mdi-view-grid label-icon  me-1 " />
              Add Task
            </button>
          </div>
          <AnimatePresence>
            {viewTickets && tickets && (
              <motion.div
                initial={{ y: -50, opacity: 0 }}
                animate={{ y: 0, opacity: 1 }}
                exit={{ y: 0, opacity: 0 }}
                transition={{ duration: 0.4 }}
                className="mt-4"
              >
                <CyberIntelligenceModuleTickets tickets={tickets} />
              </motion.div>
            )}
          </AnimatePresence>
        </CardBody>
      </Card>
    </div>
  );
};

export default CyberWatch;
