import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { del } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function IgnoredVulnsTable({ data, entryValue, getIgnoredVulns }) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  // Seem
  const [seeFirst, setSeeFirst] = useState(true);
  const [seeSecond, setSeeSecond] = useState(true);
  const [seeThird, setSeeThird] = useState(true);
  const [seeFourth, setSeeFourth] = useState(true);
  // Others
  const [rowId, setRowId] = useState();
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, set_addLoading] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "Severity",
        accessor: "vuln_severity",
      },
      {
        Header: "Asset",
        accessor: "asset",
      },
      {
        Header: "Name",
        accessor: "vuln_name",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "",
        accessor: "vuln_familys",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "asset", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const removeIgnoredVulns = async () => {
    paceFunction(true);
    set_addLoading(true);
    try {
      let result = await del(`/customer/module/ignored-vulns?id=${rowId}`);
      if (result?.success) {
        toastSuccess(result?.message || "Ignore Deleted", 2000);
        tog_standard5();
        getIgnoredVulns();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={removeIgnoredVulns}
        text="Are you sure you want to remove?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <div style={{ maxHeight: "1000px", overflowY: "scroll" }}>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th
                  {...headerGroup.headers[0].getHeaderProps(
                    headerGroup.headers[0].getSortByToggleProps()
                  )}
                  style={{ width: "100px" }}
                >
                  {headerGroup.headers[0].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[0].isSorted
                      ? headerGroup.headers[0].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  className={`${seeFirst ? "col-3" : "d-none "}`}
                >
                  {headerGroup.headers[1].Header}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[2].getHeaderProps(
                    headerGroup.headers[2].getSortByToggleProps()
                  )}
                  style={{ width: "45%" }}
                >
                  {headerGroup.headers[2].Header}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[2].isSorted
                      ? headerGroup.headers[2].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[3].getHeaderProps(
                    headerGroup.headers[3].getSortByToggleProps()
                  )}
                  className={`${seeThird ? "col-2" : "d-none "}`}
                >
                  {headerGroup.headers[3].Header}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[3].isSorted
                      ? headerGroup.headers[3].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th className={`${seeFourth ? "col-1" : "d-none "}`}>
                  {headerGroup.headers[4].Header}
                </th>
                {/* {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {" "}
                  {column.render("Header")}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))} */}
              </tr>
            ))}
          </thead>

          {data.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                return (
                  <React.Fragment key={index}>
                    <tr
                      style={{ width: "100%", cursor: "pointer" }}
                      {...row.getRowProps()}
                    >
                      {seeFirst && (
                        <td style={{ width: "100px" }} className="">
                          {row.cells[0].value === "1.0" ? (
                            <span className="badge bg-severity-low font-size-18">
                              Low
                            </span>
                          ) : row.cells[0].value === "0.0" ? (
                            <span className="badge bg-severity-info font-size-18">
                              Info
                            </span>
                          ) : row.cells[0].value === "2.0" ? (
                            <span className="badge bg-severity-medium font-size-18">
                              Medium
                            </span>
                          ) : row.cells[0].value === "3.0" ? (
                            <span className="badge bg-severity-high font-size-18">
                              High
                            </span>
                          ) : (
                            <span className="badge bg-severity-critical font-size-18">
                              Critical
                            </span>
                          )}
                        </td>
                      )}
                      {seeSecond && (
                        <td className="col">{row.cells[1].value}</td>
                      )}
                      {seeThird && (
                        <td style={{ width: "45%" }}>{row.cells[2].value}</td>
                      )}
                      {seeFourth && (
                        <td className="col">
                          {row.cells[3].value === "Ignored" ? (
                            <span className="badge bg-danger font-size-14 ">
                              {row.cells[3].value}
                            </span>
                          ) : row.cells[3].value === "Fixed" ? (
                            <span className="badge bg-success font-size-14 ">
                              {row.cells[3].value}
                            </span>
                          ) : (
                            <span className="badge bg-info font-size-14 ">
                              {row.cells[3].value}
                            </span>
                          )}
                        </td>
                      )}
                      <td className="col-1">
                        <button
                          onClick={() => {
                            setRowId(row?.original?.id);
                            tog_standard5();
                          }}
                          className="btn btn-sm btn-outline-danger "
                        >
                          Delete
                        </button>
                      </td>
                      {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default IgnoredVulnsTable;
