import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import DropdownHelp from "./DropdownHelp";
import { formattedDate } from "../../../common/utils/commonFunctions";
function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <form autoComplete="off">
        <input
          autoComplete="new-password"
          id="search"
          name="search"
          type="text"
          className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
          defaultValue={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Search"
        />
      </form>
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function DistributorsTable({ data, entryValue, changeValue, getDistributors }) {
  const [rowId, setRowId] = useState();
  // Seem
  const [seem1, set_seem1] = useState(true);
  const [seem2, set_seem2] = useState(true);
  const [seem3, set_seem3] = useState(true);
  const [seem4, set_seem4] = useState(true);
  const [seem5, set_seem5] = useState(true);
  const [seem6, set_seem6] = useState(true);
  const [seem7, set_seem7] = useState(true);
  const [btnprimary1, setBtnprimary1] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);
  const columns = React.useMemo(
    () => [
      {
        Header: "Organization",
        accessor: "organization_name",
      },
      {
        Header: "Email Address",
        accessor: "email",
      },
      {
        Header: "Owner",
        accessor: "owner.organization_name",
      },
      {
        Header: "Phone",
        accessor: "phone_number",
      },
      {
        Header: "Joined Date",
        accessor: "date_joined",
      },
      {
        Header: "Subscription",
        accessor: "subscription_renewal",
      },
      {
        Header: "Actions",
        accessor: "company_name",
      },
      {
        Header: "",
        accessor: "owner.role",
      },
    ],
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([
    { id: "organization_name", desc: true },
  ]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
      // manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const convertJSONToCSV = (data) => {
    const csvHeader =
      "Organization;Email;Owner;Phone;Joined Date;Subscription Renewal";

    const csvRows = data.map((item) => {
      return `${item?.organization_name || "-"};${item?.email || "-"};${
        item?.owner.organization_name || "-"
      };${item?.phone_number || "-"};${
        formattedDate(item?.date_joined) || "-"
      };${formattedDate(item?.subscription_renewal) || "-"}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    return csvContent;
  };

  const downloadCSV = (data) => {
    const csvData = convertJSONToCSV(data);
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "Distributors.csv";

    if (window.navigator.msSaveBlob) {
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons flex-wrap justify-content-md-center  d-flex ">
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(data);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <Dropdown
              isOpen={btnprimary1}
              toggle={() => setBtnprimary1(!btnprimary1)}
            >
              <DropdownToggle
                title="Column Visibility"
                tag="button"
                className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              >
                <i className="mdi mdi-eye" />
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                  <span className="visually-hidden">unread messages</span>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    set_seem1(!seem1);
                  }}
                  className={`${seem1 ? "bg-soft-primary" : ""}`}
                >
                  Organization
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem2(!seem2);
                  }}
                  className={`${seem2 ? "bg-soft-primary" : ""}`}
                >
                  Email Address
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem3(!seem3);
                  }}
                  className={`${seem3 ? "bg-soft-primary" : ""}`}
                >
                  Owner
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem4(!seem4);
                  }}
                  className={`${seem4 ? "bg-soft-primary" : ""}`}
                >
                  Phone
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem5(!seem5);
                  }}
                  className={`${seem5 ? "bg-soft-primary" : ""}`}
                >
                  Joined Date
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem6(!seem6);
                  }}
                  className={`${seem6 ? "bg-soft-primary" : ""}`}
                >
                  Subscription Renewal
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    set_seem7(!seem7);
                  }}
                  className={`${seem7 ? "bg-soft-primary" : ""}`}
                >
                  Actions
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {/* {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render("Header")}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))} */}
              {seem1 && (
                <th
                  {...headerGroup.headers[0].getHeaderProps(
                    headerGroup.headers[0].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[0].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[0].isSorted
                      ? headerGroup.headers[0].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem2 && (
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[1].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem3 && (
                <th
                  {...headerGroup.headers[2].getHeaderProps(
                    headerGroup.headers[2].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[2].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[2].isSorted
                      ? headerGroup.headers[2].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem4 && <th className="">{headerGroup.headers[3].Header}</th>}
              {seem5 && (
                <th
                  {...headerGroup.headers[4].getHeaderProps(
                    headerGroup.headers[4].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[4].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[4].isSorted
                      ? headerGroup.headers[4].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem6 && (
                <th
                  {...headerGroup.headers[5].getHeaderProps(
                    headerGroup.headers[5].getSortByToggleProps()
                  )}
                  className=""
                >
                  {headerGroup.headers[5].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[5].isSorted
                      ? headerGroup.headers[5].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              )}
              {seem7 && <th className="">{headerGroup.headers[6].Header}</th>}
            </tr>
          ))}
        </thead>

        {data.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
              prepareRow(row);
              return (
                <tr key={index} {...row.getRowProps()}>
                  {seem1 && <td>{row.cells[0].value}</td>}
                  {seem2 && <td>{row.cells[1].value}</td>}
                  {seem3 && (
                    <td className="text-nowrap">
                      <span
                        style={{ marginTop: "4px" }}
                        className="badge bg-primary cursor-pointer me-1 "
                        title={row.cells[7].value}
                      >
                        {row.cells[7].value === "DISTRIBUTOR"
                          ? "D"
                          : row.cells[7].value === "MANAGER"
                          ? "M"
                          : ""}
                      </span>
                      {row.cells[2].value}
                    </td>
                  )}
                  {seem4 && <td>{row.cells[3].value}</td>}
                  {seem5 && <td>{formattedDate(row.cells[4].value)}</td>}
                  {seem6 && <td>{formattedDate(row.cells[5].value)}</td>}
                  {seem7 && (
                    <td
                      onClick={() => {
                        setRowId(row.original.id);
                      }}
                    >
                      <DropdownHelp
                        rowId={rowId}
                        getDistributors={getDistributors}
                      />
                    </td>
                  )}
                  {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {data.length < 1 && (
        <span
          style={{ width: "100%" }}
          className="d-flex justify-content-center "
        >
          No data available in table
        </span>
      )}
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default DistributorsTable;
