import React, { useRef, useState } from "react";
import PropTypes from "prop-types";
import { Card, CardBody, Col, Row } from "reactstrap";
import { del } from "../../../helpers/api_helper";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin, { Draggable } from "@fullcalendar/interaction";
import BootstrapTheme from "@fullcalendar/bootstrap";
import Calnderimg from "../../../../assets/images/undraw-calendar.svg";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
//css
import "@fullcalendar/bootstrap/main.css";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import ScheduleNewTaskModal from "./ScheduleNewTaskModal";

const CalendarComponent = (props) => {
  document.title = "Calendar | PentestBX";

  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const calendarRef = useRef(null);

  const clearCalendar = () => {
    if (calendarRef.current) {
      calendarRef.current.getApi().removeAllEvents();
    }
  };

  const customButtons = {
    clearButton: {
      text: "Clear",
      click: clearCalendar,
    },
  };

  const formattedEvents = props.data.tasks.map((event) => ({
    title: event.module,
    date: event.date,
    id: event?.id || "",
  }));

  const [expire_date, set_expire_date] = useState();

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);

  const [selectedDay, setSelectedDay] = useState(0);

  const [deleteLoading, set_deleteLoading] = useState(false);

  const [eventID, set_eventID] = useState();

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const handleDateClick = (arg) => {
    const date = arg["date"];
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();

    const currectDate = new Date();
    const currentHour = currectDate.getHours();
    const currentMin = currectDate.getMinutes();
    const currentSec = currectDate.getSeconds();
    const modifiedDate = new Date(
      year,
      month,
      day,
      currentHour,
      currentMin,
      currentSec
    );
    const modifiedData = { ...arg, date: modifiedDate };
    setSelectedDay(modifiedData);
    const dateObject = new Date(modifiedData.date);
    const isoString = dateObject.toISOString();
    set_expire_date(isoString);
    tog_standard3();
  };

  const handleEventClick = (arg) => {
    const event = arg.event;
    set_eventID(event._def.publicId);
    tog_standard2();
  };

  const deleteTask = async () => {
    paceFunction(true);
    set_deleteLoading(true);
    try {
      let result = await del(
        `/customer/settings/module/task_calendar?id=${eventID}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Task Deleted", 2000);
        tog_standard2();
        props.getTaskCalendar();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_deleteLoading(false);
    paceFunction(false);
  };

  const handleChangeModule = (e) => {
    let parseData = JSON.parse(e.target.value);
    props.set_moduleID(parseData.id);
    props.set_moduleName(parseData.name);
  };

  return (
    <React.Fragment>
      {/* Delete Task */}
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={deleteTask}
        text="Are you sure you want to delete task?"
        loadingAction={true}
        isLoading={deleteLoading}
      />
      {/* ScheduleNewTask Modal */}
      <ScheduleNewTaskModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        selectedDay={selectedDay}
        expire_date={expire_date}
        set_expire_date={(e) => set_expire_date(e)}
        allAssets={props.assets}
        className={props.className}
        moduleID={props.moduleID}
        moduleName={props.moduleName}
        getTaskCalendar={props.getTaskCalendar}
      />
      <Row>
        <Col className="col-12">
          <div className="row">
            <div className="col-lg-3">
              <Card>
                <CardBody>
                  <div className="mb-3 ">
                    <select
                      onChange={handleChangeModule}
                      className="form-control form-select"
                      id="taskModule"
                      defaultValue={props.moduleID}
                    >
                      {props.modules?.length > 0 &&
                        props?.modules?.map((mod) => (
                          <option key={mod.id} value={JSON.stringify(mod)}>
                            {mod.text}
                          </option>
                        ))}
                    </select>
                  </div>
                  {/* <div className=" d-grid gap-3">
                    <ul
                      className=" special-card    border list-unstyled mb-0 fw-semibold "
                    >
                      <li>
                        <ul>
                          <li>
                            Periodically :
                            {props.data?.periodically ? (
                              <i className="mdi mdi-check-outline me-3 text-success" />
                            ) : (
                              <i className="mdi mdi-close-outline me-3 text-danger" />
                            )}
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div> */}

                  <div id="external-events" className="mt-2">
                    <br />
                    <p className="text-muted fw-semibold ">
                      Caution: Scans are taken automatically. The calendar shows
                      30 days of planning.
                    </p>
                  </div>
                  <Row className="justify-content-center mt-5">
                    <Col lg={12} sm={6}>
                      <img
                        src={Calnderimg}
                        alt=""
                        className="img-fluid d-block"
                      />
                    </Col>
                  </Row>
                </CardBody>
              </Card>
            </div>
            <div className="col-lg-9">
              <Card>
                <CardBody>
                  <FullCalendar
                    ref={calendarRef}
                    plugins={[BootstrapTheme, dayGridPlugin, interactionPlugin]}
                    slotDuration={"00:15:00"}
                    handleWindowResize={true}
                    themeSystem="bootstrap"
                    headerToolbar={{
                      left: "prev,next today",
                      center: "title",
                      right: "clearButton dayGridMonth,dayGridWeek,dayGridDay",
                    }}
                    events={formattedEvents}
                    editable={false}
                    droppable={false}
                    selectable={false}
                    dateClick={handleDateClick}
                    eventClick={handleEventClick}
                    customButtons={customButtons}
                  />
                </CardBody>
              </Card>
            </div>
          </div>
        </Col>
      </Row>
    </React.Fragment>
  );
};

CalendarComponent.propTypes = {
  events: PropTypes.array,
  categories: PropTypes.array,
  className: PropTypes.string,
  onGetEvents: PropTypes.func,
  onAddNewEvent: PropTypes.func,
  onUpdateEvent: PropTypes.func,
  onDeleteEvent: PropTypes.func,
  onGetCategories: PropTypes.func,
};

export default CalendarComponent;
