import React, { useEffect, useState } from "react";
import Hosts from "./Hosts/Hosts";
import WebApplication from "./WebApplication/WebApplication";
import MailboxThreats from "./MailboxThreats/MailboxThreats";
import CyberWatch from "./CyberWatch/CyberWatch";
import { CardHeader, CardBody } from "reactstrap";

const Modules = ({ modules }) => {
  const [isHost, setIsHost] = useState(false);
  const [hosts, setHosts] = useState([]);
  const [isWeb, setIsWeb] = useState(false);
  const [webApps, setWebApps] = useState([]);
  const [isCyber, setIsCyber] = useState(false);
  const [cybers, setCybers] = useState([]);
  const [isMailbox, setIsMailbox] = useState(false);
  const [mailboxs, setMailboxs] = useState([]);

  useEffect(() => {
    for (const item of modules) {
      if (item.name === "HOST") {
        setHosts({ ...item });
        setIsHost(true);
      }
      if (item.name === "WEB_APPLICATION") {
        setWebApps({ ...item });
        setIsWeb(true);
      }
      if (item.name === "MXTHREAT") {
        setMailboxs({ ...item });
        setIsMailbox(true);
      }
      if (item.name === "CYBER_WATCH") {
        setCybers({ ...item });
        setIsCyber(true);
      }
    }
  }, []);

  return (
    <div className="special-card">
      <CardHeader>
        <h4 className="card-title">Modules</h4>
      </CardHeader>
      <CardBody>
        <div className="row">
          {isWeb && <WebApplication datas={webApps} />}
          {isMailbox && <MailboxThreats datas={mailboxs} />}
          {isCyber && <CyberWatch datas={cybers} />}
          {isHost && <Hosts datas={hosts} />}
        </div>
      </CardBody>
    </div>
  );
};

export default Modules;
