import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import emptyImage from "../../../../assets/images/empty.png";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import { removeBodyCss } from "../../../../common/utils/commonFunctions";

const SeverityChanges = ({ data, default_widgets, defaultWidgetDelete }) => {
  const [viewEdit, set_viewEdit] = useState(false);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const donutSeries = [
    data.weekly_chance["critical"],
    data.weekly_chance["high"],
    data.weekly_chance["medium"],
    data.weekly_chance["low"],
    data.weekly_chance["info"],
  ];

  const donutOptions = {
    plotOptions: {
      pie: {
        donut: {
          labels: {
            show: true,
            total: {
              show: false,
              label: "Total",
              fontSize: "30px",
              color: "rgb(188, 193, 200)",
              formatter: () => toplam,
            },
          },
        },
      },
    },
    labels: ["Critical", "High", "Medium", "Low", "Info"],
    colors: ["#FD625E", "#FD8C00", "#FDC500", "#2AB57D", "#4bA6EF"],
    legend: {
      show: true,
      position: "bottom",
      horizontalAlign: "center",
      verticalAlign: "middle",
      floating: false,
      fontSize: "14px",
      offsetX: 0,
      offsetY: -10,
    },
    responsive: [
      {
        breakpoint: 600,
        options: {
          chart: {
            height: 240,
          },
          legend: {
            show: false,
          },
        },
      },
    ],
  };

  const CalculateTotal = (dizi) => {
    var toplam = 0;
    for (var i = 0; i < dizi.length; i++) {
      toplam += dizi[i];
    }

    return toplam;
  };

  const totalDonut = CalculateTotal(donutSeries);

  const targetFunc = () => {
    defaultWidgetDelete("severity_changes");
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={targetFunc}
        text="Are you sure want to delete widget ?"
        loadingAction={false}
      />
      <div className="col-12 ">
        {default_widgets.severity_changes && (
          <div
            onMouseEnter={() => set_viewEdit(true)}
            onMouseLeave={() => set_viewEdit(false)}
            className="special-score-card "
          >
            <div className="d-flex position-relative  py-2  align-items-center justify-content-between  ">
              <div>
                <span className="fw-bold fs-4 me-1 ">Severity Changes </span>
              </div>
              <div
                className={` cursor-pointer ${viewEdit ? "d-flex" : "d-none"}`}
                style={{ position: "absolute", top: 5, right: 0 }}
                onClick={tog_standard5}
              >
                <i className="mdi mdi-delete text-primary fs-3 " />
              </div>
            </div>
            {totalDonut > 0 ? (
              <div
                style={{
                  height: "265px",
                }}
                className={`d-flex  align-items-center justify-content-center `}
              >
                <ReactApexChart
                  options={donutOptions}
                  series={donutSeries}
                  type="donut"
                  height="320"
                />
              </div>
            ) : (
              <div className="d-flex flex-column  align-items-center justify-content-center ">
                <img style={{ height: "232px" }} src={emptyImage} alt="empty" />
                <h4
                  // style={{ height: "336px" }}
                  className="text-center "
                >
                  No change in severity values
                </h4>
              </div>
            )}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default SeverityChanges;
