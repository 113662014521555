import React, { useState } from "react";
import { Modal } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const AddTaskModal = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  applianceID,
  getApplianceTask,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [period, set_period] = useState();
  const [auto_task, set_auto_task] = useState(false);
  const [proc_name, set_proc_name] = useState("WEAK_CREDS");

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const addTask = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      id: applianceID,
      auto_task,
      period,
      proc_name,
    };
    try {
      let result = await put(`/admin/customer/endpoint/tasks`, data);
      if (result?.success) {
        tog_standard5();
        tog_standard();
        toastSuccess(result?.message || "Task Added");
        getApplianceTask();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      {/* Add Task */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addTask}
        text="Are you sure you want to add task?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Task
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="period">Period:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                pattern=".*"
                name="period"
                id="period"
                placeholder="Period"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_period(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="is_credentials">Name:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-control"
                name="is_credentials"
                style={{ border: "1px solid #3fa0da" }}
                id="is_credentials"
                placeholder="Authorized Scan "
                onChange={(e) => set_proc_name(e.target.value)}
              >
                <option value="WEAK_CREDS">WEAK_CREDS</option>
                <option value="NET_MAP">NET_MAP</option>
                <option value="NET_SHARE">NET_SHARE</option>
                <option value="FW_TEST">FW_TEST</option>
              </select>
              <label id="is_credentials-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="auto_task_appliance">Auto Task:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="auto_task_appliance"
                  name="auto_task_appliance"
                  switch="primary"
                  defaultChecked={auto_task}
                  autoComplete="off"
                  onChange={(e) => set_auto_task(e.target.checked)}
                />
                <label
                  htmlFor="auto_task_appliance"
                  data-on-label="Yes"
                  data-off-label="No"
                />
              </div>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Add Task
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddTaskModal;
