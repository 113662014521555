import React from "react";
import { Modal } from "reactstrap";

const LinkedAccounts = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  linkedAccounts,
}) => {
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Linked Account List
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <table className="table table-border table-hover table-centered m-0">
            <thead className="table-light">
              <tr>
                <th>&nbsp;</th>
                <th>Email</th>
                <th>Phone</th>
              </tr>
            </thead>
            <tbody>
              {linkedAccounts.map((item, index) => (
                <tr key={index}>
                  <td>
                    {Number(index) + 1}
                    {`)`}
                  </td>
                  <td>{item.email}</td>
                  <td>{item.phone_number}</td>
                </tr>
              ))}
            </tbody>
          </table>
          {linkedAccounts.length === 0 && (
            <h5 className="text-center mt-4 ">There is no linked account...</h5>
          )}
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default LinkedAccounts;
