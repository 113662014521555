import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { put } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastError,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";
import continents from "../../../../../common/utils/continents.json";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";

const AddGrantAccess = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  getGrantAccess,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [email, set_email] = useState();
  const [phone_number, set_phone_number] = useState();
  const [region, set_region] = useState(continents[0]);
  const [country, set_country] = useState();
  const [state, set_state] = useState();
  const [city, set_city] = useState();
  const [address, set_address] = useState();

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [addLoading, setAddLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  useEffect(() => {
    GetCountries().then((result) => {
      set_country(result[0].name);
      setCountryid(result[0].id);
      setCountriesList(result);

      GetState(result[0].id).then((stateResult) => {
        setStateid(stateResult[0].id);
        set_state(stateResult[0].name);
        setStateList(stateResult);
        GetCity(result[0].id, stateResult[0].id).then((cityResult) => {
          set_city(cityResult[0].name);
          setCityList(cityResult);
        });
      });
    });
  }, []);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const addGrantAccess = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      email,
      phone_number,
      region,
      country,
      state,
      city,
      address,
    };
    if (
      email &&
      phone_number &&
      region &&
      country &&
      state &&
      city &&
      address
    ) {
      if (phone_number.length < 7) {
        toastError("Phone Number must be at least 7 characters");
      } else {
        try {
          let result = await put("/distributor/settings/linked-account", data);
          if (result?.success) {
            tog_standard();
            tog_standard5();
            toastSuccess(result?.message || "Grant Access Added");
            getGrantAccess();
          }
        } catch (error) {
          console.log(`error ==>`, error);
          errorToastFunction(error);
        }
      }
    } else {
      toastError("You must fill in all fields");
    }

    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addGrantAccess}
        text="Are you sure you want to add new grant access?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        size="lg"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        {" "}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add New Grant Access
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            id="grant_access"
            noValidate
            method="post"
            action="/update_profile/"
          >
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Email :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-email" />
                    </span>
                    <input
                      type="email"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="email"
                      onChange={(e) => set_email(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Phone Number :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-phone" />
                    </span>
                    <input
                      type="number"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="phone_number"
                      onChange={(e) => set_phone_number(e.target.value)}
                      required
                    />
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Region :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-city-variant" />
                    </span>
                    <select
                      className="form-select"
                      style={{ width: "80%" }}
                      name="region"
                      id="region"
                      onChange={(e) => set_region(e.target.value)}
                      defaultValue={region}
                    >
                      {continents.map((continentName, continentKey) => {
                        return (
                          <option
                            value={continentName}
                            key={continentName + continentKey}
                          >
                            {continentName}
                          </option>
                        );
                      })}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Country :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-flag" />
                    </span>
                    <select
                      onChange={(e) => {
                        let countryValues = JSON.parse(e.target.value);
                        set_country(countryValues.name);
                        setCountryid(countryValues.id);
                        GetState(countryValues.id).then((result) => {
                          setStateList(result);
                          setStateid(result[0].id);
                          set_state(result[0].name);
                          GetCity(countryValues.id, result[0].id).then(
                            (resultCity) => {
                              setCityList(resultCity);
                              setCityid(resultCity[0].id);
                              set_city(resultCity[0].name);
                            }
                          );
                        });
                      }}
                      value={JSON.stringify(
                        countriesList.find((item) => item.name === country)
                      )}
                      className="form-select"
                      style={{ width: "80%" }}
                      name="country"
                      id="country"
                    >
                      {countriesList.map((item, index) => (
                        <option key={item + index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">State/Province :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-home-group" />
                    </span>
                    <select
                      onChange={(e) => {
                        let stateValues = JSON.parse(e.target.value);
                        set_state(stateValues.name);
                        setStateid(stateValues.id);
                        GetCity(countryid, stateValues.id).then((result) => {
                          setCityList(result);
                          setCityid(result[0].id);
                          set_city(result[0].name);
                        });
                      }}
                      value={JSON.stringify(
                        stateList.find((item) => item.name === state)
                      )}
                      className="form-select"
                      style={{ width: "80%" }}
                      name="state"
                      id="state"
                    >
                      {stateList.map((item, index) => (
                        <option key={item + index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">City/District :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-city" />
                    </span>
                    <select
                      onChange={(e) => {
                        let cityValues = JSON.parse(e.target.value); //here you will get full state object.
                        setCityid(cityValues.id);
                        set_city(cityValues.name);
                      }}
                      value={JSON.stringify(
                        cityList.find((item) => item.name === city)
                      )}
                      className="form-select"
                      style={{ width: "80%" }}
                      name="city"
                      id="city"
                    >
                      {cityList.map((item, index) => (
                        <option key={item + index} value={JSON.stringify(item)}>
                          {item.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className="pb-3">
              <div className="row align-items-center">
                <div className="col-xl-3">
                  <div>
                    <h5 className="font-size-15">Address :</h5>
                  </div>
                </div>
                <div className="col-xl">
                  <div className="input-group">
                    <span className="input-group-text">
                      <i className="mdi mdi-map" />
                    </span>
                    <input
                      type="text"
                      className="form-control"
                      style={{ width: "80%" }}
                      name="address"
                      defaultValue={address}
                      onChange={(e) => set_address(e.target.value)}
                    />
                  </div>
                </div>
              </div>
            </div>
            <hr className="my-1 mb-3" />
            <div className="d-flex justify-content-end ">
              <button
                onClick={tog_standard5}
                type="button"
                id="confirmationTooltip1"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Add
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default AddGrantAccess;
