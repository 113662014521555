import { toast } from "react-toastify";

export const formatDateTransform = (formatDate) => {
  if (formatDate === "%Y-%m-%d") return "yyyy-MM-dd";
  if (formatDate === "%d-%m-%Y") return "dd-MM-yyyy";
  if (formatDate === "%m-%d-%Y") return "MM-dd-yyyy";
  if (formatDate === "%d/%m/%Y") return "dd/MM/yyyy";
  if (formatDate === "%m/%d/%Y") return "MM/dd/yyyy";
  if (formatDate === "%d %b %Y") return "dd MMM yyyy";
  if (formatDate === "%d-%b-%Y") return "dd-MMM-yyyy";
  if (formatDate === "%b %d %Y") return "MMM dd yyyy";
  if (formatDate === "%b-%d-%Y") return "MMM-dd-yyyy";
};

export const formattedDate = (value) => {
  const date = new Date(value);
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Aylar 0'dan başlar, bu yüzden +1 ekliyoruz
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const formatDateFlatPickr = (date) => {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const day = String(date.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const formattedDateWithHours = (value) => {
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
  };
  const isoDate = new Date(value);
  const formattedDate = new Intl.DateTimeFormat("en-US", options).format(
    isoDate
  );
  return formattedDate;
};

export const formattedDateWithoutHours = (value) => {
  let dateObj = new Date(value);
  let day = dateObj.getDate(); // Gün
  let month = dateObj.toLocaleString("EN", {
    month: "long",
  }); // Ay
  let year = dateObj.getFullYear(); // Yıl
  let formattedDate = `${day} ${month} ${year}`;
  return formattedDate;
};

export const toastSuccess = (msg, time = 3000) =>
  toast.success(msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: time,
  });

export const toastError = (msg, time = 3000) =>
  toast.error(msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: time,
  });

export const toastWarning = (msg, time = 3000) =>
  toast.warning(msg, {
    position: toast.POSITION.TOP_CENTER,
    autoClose: time,
  });

export const getModuleName = (module) => {
  if (module === "NET_MON") return "Network Topology";
  if (module === "HOST") return "Hosts";
  if (module === "MXTHREAT") return "Mailbox Threats";
  if (module === "CYBER_WATCH") return "Cyber Watch";
  if (module === "WEB_APPLICATION") return "Web Applications";
};

export const removeBodyCss = () => {
  document.body.classList.add("no_padding");
};

export const getScoreByTitle = (array, title) => {
  const foundItem = array.find((item) => item.title === title);
  return foundItem ? foundItem.score : null;
};

export const getTotalByTitle = (data, title) => {
  return data.reduce((total, item) => total + (item[title] || 0), 0);
};

export const getFieldTotal = (data, fields) => {
  let result = {
    labels: [],
    series: [],
  };

  // Initialize result object with capitalized field names and values
  fields.forEach((field) => {
    const capitalizedField = field.charAt(0).toUpperCase() + field.slice(1);
    result.labels.push(capitalizedField); // Add label to labels array
    result.series.push(0); // Initialize series value for this field
  });

  // Loop through the data array and sum the values for each field
  data.forEach((item) => {
    fields.forEach((field, index) => {
      if (item[field] !== undefined) {
        result.series[index] += item[field]; // Add to the series array
      }
    });
  });

  return result;
};

export const getBrightness = (color) => {
  var match = color.match(/^rgb\((\d+),\s*(\d+),\s*(\d+)\)$/);
  if (!match) {
    var hex = color.replace("#", "");
    var r = parseInt(hex.substring(0, 2), 16);
    var g = parseInt(hex.substring(2, 4), 16);
    var b = parseInt(hex.substring(4, 6), 16);
    return (r * 299 + g * 587 + b * 114) / 1000;
  }
  return (
    (parseInt(match[1]) * 299 +
      parseInt(match[2]) * 587 +
      parseInt(match[3]) * 114) /
    1000
  );
};

export const randomHexCode = () => {
  const randomColor = Math.floor(Math.random() * 16777215)
    .toString(16)
    .toUpperCase();
  return `#${randomColor.padStart(6, "0")}`;
};

export const calculatePercentage = (num1, num2) => {
  const percentage = (num1 / num2) * 100;
  return percentage.toFixed(1);
};

export const remainingDaysCalculation = (inputDate, dateFormat = null) => {
  let day;
  let month;
  let year;

  if (dateFormat === "%d-%m-%Y") {
    [day, month, year] = inputDate.split("-");
  } else if (dateFormat === "%m/%d/%Y") {
    [month, day, year] = inputDate.split("/");
  } else {
    [day, month, year] = inputDate.split("/");
  }
  const formattedDate = `${year}-${month}-${day}`;
  const currentDate = new Date();
  const targetDate = new Date(formattedDate);
  const timeDifference = targetDate - currentDate;
  const dayDifference = Math.ceil(timeDifference / (1000 * 60 * 60 * 24));
  return dayDifference;
};

export const goToTop = () => {
  window.scrollTo({
    top: 0,
    behavior: "smooth",
  });
};
