import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  Input,
  Label,
  Form,
  FormFeedback,
  Spinner,
} from "reactstrap";
import DateIconComponent from "../../common/DateIconComponent";
// Formik Validation
import * as Yup from "yup";
import { useFormik } from "formik";
import axios from "axios";
// action
import { registerUser, apiError } from "../../store/actions";

//redux
import { useSelector, useDispatch } from "react-redux";

import { Link } from "react-router-dom";

// import images
import logo from "../../assets/images/logo_colors_lg.svg";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import pdf from "../../assets/terms.pdf";

// import countries
import country from "all-country-data";

//Toast
import errorFunctionComponent from "../../common/errorFunctionComponent";
import { toastSuccess } from "../../common/utils/commonFunctions";

const Register = (props) => {
  //meta title
  document.title = "Demo Request | PentestBX";
  const { errorToastFunction } = errorFunctionComponent();
  const [isCheck, setIsCheck] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const allCountries = country.all();
  const dispatch = useDispatch();

  const { user, registrationError } = useSelector((state) => ({
    user: state.Account.user,
    registrationError: state.Account.registrationError,
    loading: state.Account.loading,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      first_name: "",
      last_name: "",
      job_title: "",
      email: "",
      phone_number: "",
      country: "",
      company_name: "",
    },
    validationSchema: Yup.object({
      first_name: Yup.string().required("Please Enter Your First Name"),
      last_name: Yup.string().required("Please Enter Your Last Name"),
      job_title: Yup.string().required("Please Enter Your Job Title"),
      email: Yup.string().required("Please Enter Your Email"),
      phone_number: Yup.string().required("Please Enter Your Phone"),
      country: Yup.string().required("Please Enter Your Country"),
      company_name: Yup.string().required("Please Enter Your Company Name"),
    }),
    onSubmit: (values) => {
      // dispatch(registerUser(values));
      requestFunc(values);
    },
  });

  const requestFunc = async (values) => {
    setIsLoading(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_APIURL}/account/request-demo`,
        values
      );
      if (response?.status === 200) {
        toastSuccess(response?.message || "Demo Request was sent...");
        setIsLoading(false);
      }
      setIsLoading(false);
    } catch (error) {
      console.log("Error ==>", error);
      setIsLoading(false);
      errorToastFunction(error);
    }
  };

  useEffect(() => {
    dispatch(apiError(""));
  }, [dispatch]);

  const jobTitles = [
    { id: 1, title: "Analyst/Press" },
    { id: 2, title: "Business Executive" },
    { id: 3, title: "Database Administrator/Architect (DBA)" },
    { id: 4, title: "Developer/Engineer" },
    { id: 5, title: "IT or Technical Manager" },
    { id: 6, title: "Marketing/Sales" },
    { id: 7, title: "Othet IT Professional" },
    { id: 8, title: "Researcher/Academic/Student" },
    { id: 9, title: "System Administrator/Architect" },
  ];
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              {isLoading ? (
                <div className="auth-full-page-content d-flex justify-content-center  align-items-center h-100  ">
                  <Spinner
                    style={{ width: "3rem", height: "3rem" }}
                    className="ms-2 "
                    color="primary"
                  />
                </div>
              ) : (
                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5 text-center">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img src={logo} alt="" height="40" />{" "}
                          {/* <span className="logo-txt">Minia</span> */}
                        </Link>
                      </div>
                      <div className="auth-content my-auto">
                        <div className="text-center">
                          <h5 className="mb-0 fw-semibold">Welcome</h5>
                          <p className="text-muted mt-2">
                            Fill in the form and we will contact you.
                          </p>
                        </div>

                        <Form
                          className="needs-validation custom-form mt-4 pt-2"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          {/* {user && user ? (
                          <Alert color="success">
                            Register User Successfully
                          </Alert>
                        ) : null}

                        {registrationError && registrationError ? (
                          <Alert color="danger">{registrationError}</Alert>
                        ) : null} */}

                          <div className="mb-3">
                            <Label className="form-label">First Name</Label>
                            <Input
                              id="first_name"
                              name="first_name"
                              className="form-control"
                              placeholder="Enter Your First Name"
                              type="text"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.first_name || ""}
                              invalid={
                                validation.touched.first_name &&
                                validation.errors.first_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.first_name &&
                            validation.errors.first_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.first_name}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-3">
                            <Label className="form-label">Last Name</Label>
                            <Input
                              name="last_name"
                              type="text"
                              placeholder="Enter Your Last Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.last_name || ""}
                              invalid={
                                validation.touched.last_name &&
                                validation.errors.last_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.last_name &&
                            validation.errors.last_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.last_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Work Email</Label>
                            <Input
                              name="email"
                              type="email"
                              placeholder="Enter Your Email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Phone</Label>
                            <Input
                              name="phone_number"
                              type="phone"
                              placeholder="Enter Your Phone"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.phone_number || ""}
                              invalid={
                                validation.touched.phone_number &&
                                validation.errors.phone_number
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.phone_number &&
                            validation.errors.phone_number ? (
                              <FormFeedback type="invalid">
                                {validation.errors.phone_number}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Company Name</Label>
                            <Input
                              name="company_name"
                              type="text"
                              placeholder="Enter Your Company Name"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.company_name || ""}
                              invalid={
                                validation.touched.company_name &&
                                validation.errors.company_name
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.company_name &&
                            validation.errors.company_name ? (
                              <FormFeedback type="invalid">
                                {validation.errors.company_name}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Job Title</Label>
                            <Input
                              name="job_title"
                              type={"select"}
                              placeholder="Enter Phone"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.job_title || ""}
                              invalid={
                                validation.touched.job_title &&
                                validation.errors.job_title
                                  ? true
                                  : false
                              }
                            >
                              <option value="" hidden></option>
                              {jobTitles.map((title) => (
                                <option key={title.id} value={title.title}>
                                  {title.title}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.job_title &&
                            validation.errors.job_title ? (
                              <FormFeedback type="invalid">
                                {validation.errors.job_title}
                              </FormFeedback>
                            ) : null}
                          </div>
                          <div className="mb-3">
                            <Label className="form-label">Country/Region</Label>
                            <Input
                              name="country"
                              type={"select"}
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.country || ""}
                              invalid={
                                validation.touched.country &&
                                validation.errors.country
                                  ? true
                                  : false
                              }
                            >
                              <option value="" hidden></option>
                              {allCountries.map((country, index) => (
                                <option key={index} value={country.country}>
                                  {country.country}
                                </option>
                              ))}
                            </Input>
                            {validation.touched.country &&
                            validation.errors.country ? (
                              <FormFeedback type="invalid">
                                {validation.errors.country}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <div className="mb-4">
                            <Label check>
                              <Input
                                checked={isCheck}
                                onChange={(e) => setIsCheck(e.target.checked)}
                                type="checkbox"
                              />
                              <a
                                href={pdf}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="ms-1"
                              >
                                Terms & Conditions
                              </a>
                            </Label>
                          </div>
                          <div className="mb-3">
                            <button
                              disabled={!isCheck}
                              className="btn btn-primary w-100 waves-effect waves-light fw-semibold"
                              type="submit"
                            >
                              Request
                            </button>
                          </div>
                        </Form>

                        <div className="mt-5 text-center">
                          <p className="text-muted mb-0">
                            Already have an account ?
                            <Link
                              to="/login"
                              className="text-primary fw-semibold ms-1 "
                            >
                              Login
                            </Link>
                          </p>
                        </div>
                      </div>
                      <DateIconComponent />
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Register;
