import React from "react";
import { VectorMap } from "react-jvectormap";
import "./jquery-jvectormap.scss";

const map = React.createRef(null);
const Vectormap = ({ data }) => {
  const isos = {};
  const isoCounts = {};

  data?.forEach((item) => {
    const iso = item?.data?.iso;
    isos[iso] = 100;
  });

  data?.forEach((item) => {
    const iso = item?.data?.iso;

    // Eğer "iso" değeri objede zaten varsa, sayacı artır, yoksa başlangıç değeri olarak 1 ayarla
    isoCounts[iso] = (isoCounts[iso] || 0) + 1;
  });
  return (
    <div style={{ height: 500 }}>
      <VectorMap
        map={"world_mill"}
        backgroundColor="transparent"
        ref={map}
        containerStyle={{
          width: "100%",
          height: "80%",
        }}
        markerStyle={{
          initial: {
            fill: "#5E32CA",
            stroke: "#383f47",
          },
        }}
        regionStyle={{
          initial: {
            fill: "#adb5bd",
            stroke: "none",
            "stroke-width": 0,
            "stroke-opacity": 0,
          },

          hover: {
            "fill-opacity": 0.8,
            cursor: "pointer",
          },
          selectedHover: {},
        }}
        containerClassName="map"
        series={{
          regions: [
            {
              scale: ["#f86262", "#f86262"],
              attribute: "fill",
              values: isos,
              normalizeFunction: "polynomial",
              min: 0,
              max: 100,
            },
          ],
        }}
        onRegionTipShow={function (event, label, code, ...props) {
          //   console.log("-----> ", label.html(), event, label, code, props);
          // console.log("-----> ", code);
          label.html(
            '<div style="background-color: black; border: 1px solid black; outline: 10px solid white; border-radius: 5px; min-height: 10px; width: 200px; color: white"; padding-left: 10px>' +
              "<p>" +
              "<b>" +
              label.html() +
              "  (Total Assets: " +
              `${isoCounts[code] || 0})` +
              "</b>" +
              "</p>" +
              "</div>"
          );
        }}
      />
    </div>
  );
};

export default Vectormap;
