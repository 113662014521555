import React, { useState } from "react";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import { removeBodyCss } from "../../../../common/utils/commonFunctions";

const Score = ({ data, default_widgets, defaultWidgetDelete }) => {
  const [viewDetails, set_viewDetails] = useState(false);
  const [viewEdit, set_viewEdit] = useState(false);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const findMaxScore = (dataArray) => {
    if (dataArray?.length === 0) {
      return null;
    }
    let maxScoreItem = dataArray[0];
    for (let i = 1; i < dataArray?.length; i++) {
      if (dataArray[i].score > maxScoreItem?.score) {
        maxScoreItem = dataArray[i];
      }
    }
    return maxScoreItem.score;
  };

  let filteredScores = data?.scores.filter((item) => item.score !== null);
  const maxScore = findMaxScore(filteredScores);

  const targetFunc = () => {
    defaultWidgetDelete("score");
  };

  return (
    <div className="col-12">
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={targetFunc}
        text="Are you sure want to delete widget ?"
        loadingAction={false}
      />
      {data?.scores?.length > 0 && default_widgets.score && (
        <div
          onMouseEnter={() => set_viewEdit(true)}
          onMouseLeave={() => set_viewEdit(false)}
          className="special-score-card"
        >
          <div className="d-flex  py-2 position-relative  align-items-center justify-content-between  ">
            <div>
              <span className="fw-bold fs-4 me-1 ">Score </span>
            </div>
            <div
              className={` cursor-pointer ${viewEdit ? "d-flex" : "d-none"}`}
              style={{ position: "absolute", top: 5, right: 0 }}
              onClick={tog_standard5}
            >
              <i className="mdi mdi-delete text-primary fs-3 " />
            </div>
          </div>
          <div
            onMouseEnter={() => set_viewDetails(true)}
            onMouseLeave={() => set_viewDetails(false)}
          >
            {viewDetails ? (
              <div className=" row-bs row row-bs">
                {data?.scores?.map((item, index) => (
                  <div
                    key={index}
                    style={{
                      height: "265px",
                    }}
                    className={`col row-bs bg-hexagon-${
                      item?.score == null
                        ? "g"
                        : item?.score <= 0.8
                        ? "a"
                        : item?.score <= 1.5
                        ? "b"
                        : item?.score <= 2.4
                        ? "c"
                        : item?.score <= 3.5
                        ? "d"
                        : "e"
                    } d-flex flex-column   align-items-center justify-content-around relative `}
                  >
                    <span
                      style={{ height: "42px" }}
                      className="font-size-14 fw-semibold  text-white  text-center absolute top-0  "
                    >
                      {item?.title}
                    </span>
                    <div>
                      <div className="d-flex align-items-center  justify-content-center  ">
                        <div className="hexagon-small   ">
                          <span className="text-uppercase ">
                            {item?.score == null
                              ? ""
                              : item?.score <= 0.8
                              ? "a"
                              : item?.score <= 1.5
                              ? "b"
                              : item?.score <= 2.4
                              ? "c"
                              : item?.score <= 3.5
                              ? "d"
                              : "e"}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <div
                style={{
                  height: "265px",
                }}
                className={`row-bs row row-bs bg-hexagon-${
                  maxScore == null
                    ? "g"
                    : maxScore <= 0.8
                    ? "a"
                    : maxScore <= 1.5
                    ? "b"
                    : maxScore <= 2.4
                    ? "c"
                    : maxScore <= 3.5
                    ? "d"
                    : "e"
                } pb-5  pt-2 align-items-center justify-content-center `}
              >
                <div className="hexagon   mt-5 ">
                  <span className="">
                    {maxScore == null
                      ? ""
                      : maxScore <= 0.8
                      ? "A"
                      : maxScore <= 1.5
                      ? "B"
                      : maxScore <= 2.4
                      ? "C"
                      : maxScore <= 3.5
                      ? "D"
                      : "E"}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export default Score;
