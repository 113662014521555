import React, { useState } from "react";
import { Modal } from "reactstrap";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const ApplianceSubnetAdd = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  company_name,
  userId,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard2, setmodal_standard2] = useState(false);
  const [subnet, set_subnet] = useState();

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const addSubnet = async () => {
    paceFunction(true);
    let data = {
      id: userId,
      subnet,
    };
    try {
      let result = await post(`/admin/customer/endpoint/subnets`, data);
      if (result?.success) {
        toastSuccess(result?.message || "Subnet Added");
        tog_standard2();
      }
    } catch (error) {
      errorToastFunction(error);
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={addSubnet}
        text="Are you sure you want to add subnet?"
        loadingAction={false}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {company_name} Appliance Subnet Add
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <input
            className="customer-input input-dark py-2 "
            name="appliance-subnet-add-input"
            type="text"
            placeholder="Example 192.168.110.0/24"
            onChange={(e) => set_subnet(e.target.value)}
          />
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            type="button"
            className="btn btn-primary "
            data-dismiss="modal"
            onClick={tog_standard2}
          >
            Add
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ApplianceSubnetAdd;
