import React from "react";
import DonutChart from "./DonutChart";

const MyComponent = () => {
  return (
    <div
      className=""
      style={{ width: "715px", padding: "100px" }}
      id="myContent"
    >
      <h1 className="text-primary  ">Hello, this is my content!</h1>
      <p>You can export this to PDF.</p>
      <p className="nav-underline text-danger">Y.</p>
      <DonutChart />
      <div className="new-page"></div>
      <h2 className="text-info">New PDF</h2>
      <h1 className="text-primary  ">Hello, this is my content!</h1>
    </div>
  );
};

export default MyComponent;
