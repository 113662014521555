import React, { useState } from "react";

const AccountLeaksComponent = ({ assets }) => {
  const [goster, setGoster] = useState(false);
  const [gosterIndex, setGosterIndex] = useState();

  const toggleGoster = () => {
    setGoster(!goster);
  };

  const convertJSONToCSV = (data) => {
    const csvHeader =
      "Database Name;Address;Email;Hashed Password;ID;Ip Address;Name;Password;Phone;Username;Vin";

    const csvRows = data.map((item) => {
      return `${item?.database_name || "-"};${item?.address || "-"};${
        item?.email || "-"
      };${item?.hashed_password || "-"};${item?.id || "-"};${
        item?.ip_address || "-"
      };${item?.name || "-"};${item?.password || "-"};${item?.phone || "-"};${
        item?.username || "-"
      };${item?.vin || "-"}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    return csvContent;
  };

  const downloadCSV = () => {
    // JSON verisini CSV'ye dönüştür
    const csvData = convertJSONToCSV(assets);
    // CSV dosyasını indirme işlemi
    const blob = new Blob([csvData], { type: "text/csv;charset=utf-8" });
    const fileName = "Account_Leaks.csv";

    if (window.navigator.msSaveBlob) {
      // IE 10+ için
      window.navigator.msSaveBlob(blob, fileName);
    } else {
      // Diğer tarayıcılar için
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
    }
  };

  return (
    <div className="row">
      <div className="mb-2   d-flex align-items-start ">
        <button
          type="button"
          className="btn btn-soft-primary special-card-button w-100 "
          onClick={downloadCSV}
        >
          <i className="mdi mdi-download label-icon  me-1 " />
          Download CSV
        </button>
      </div>
      {assets?.map((item, index) => {
        const kırpılmışMetin = item?.hashed_password?.slice(0, 15);
        return (
          <div key={index} className="col-lg-6 mb-4 ">
            <div className="special-card h-100 mb-4 ">
              <h3 className="text-center text-primary ">
                {item?.database_name || "-"}
              </h3>
              <div className="row">
                <div className="col-md-6">
                  <strong className="me-2 text-primary">Address:</strong>
                  {""}
                  {item?.address || "-"}
                </div>
                <div className="col-md-6 ">
                  <strong className="me-2 text-primary">Email:</strong>
                  {""}
                  {item?.email || "-"}
                </div>
                <div className="col-md-6">
                  <strong className="me-2 text-primary">ID:</strong>
                  {""}
                  {item?.id || "-"}
                </div>
                <div className="col-md-6">
                  <strong className="me-2 text-primary">IP Address:</strong>
                  {""}
                  {item?.ip_address || "-"}
                </div>
                <div className="col-md-6">
                  <strong className="me-2 text-primary">Name:</strong>
                  {""}
                  {item?.name || "-"}
                </div>
                <div className="col-md-6">
                  <strong className="me-2 text-primary">Password:</strong>
                  {""}
                  {item?.password || "-"}
                </div>
                <div className="col-md-6">
                  <strong className="me-2 text-primary">Phone:</strong>
                  {""}
                  {item?.phone || "-"}
                </div>
                <div className="col-md-6">
                  <strong className="me-2 text-primary">Username:</strong>
                  {""}
                  {item?.username || "-"}
                </div>
                <div className="col-md-6  ">
                  <strong className="me-2 text-primary ">Vin:</strong>
                  {""}
                  {item?.Vin || "-"}
                </div>{" "}
                <div
                  className={`${
                    gosterIndex === index
                      ? "col-md-6 d-flex flex-row overflow-scroll align-items-center "
                      : "col-md-6 d-flex flex-row align-items-center"
                  }`}
                >
                  <strong className="me-2 text-primary">
                    Hashed Password:
                  </strong>
                  {""}
                  <div className="d-flex flex-row ">
                    {item?.hashed_password.length > 15 && (
                      <div
                        style={{ cursor: "pointer" }}
                        className="fw-semibold "
                        title="More"
                        onClick={() => {
                          toggleGoster();
                          if (gosterIndex === index) {
                            setGosterIndex("");
                          } else {
                            setGosterIndex(Number(index));
                          }
                        }}
                      >
                        {gosterIndex === Number(index)
                          ? "Little..."
                          : "More..."}
                      </div>
                    )}
                    {gosterIndex === Number(index)
                      ? item?.hashed_password
                      : item?.hashed_password.length > 0
                      ? kırpılmışMetin
                      : "-"}
                  </div>
                </div>
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default AccountLeaksComponent;
