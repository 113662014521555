import React, { useState } from "react";
import { Form, FormFeedback, Input, Label, Modal } from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import { useFormik } from "formik";
import * as Yup from "yup";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import { planLists } from "../../../common/utils/commonValues";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { useDispatch } from "react-redux";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import { toastSuccess } from "../../../common/utils/commonFunctions";

const AddNewCustomerModal = ({
  modal_standard,
  modal_standard3,
  setmodal_standard,
  setmodal_standard3,
  tog_standard,
  tog_standard3,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const dispatch = useDispatch();

  const [isAppliance, setIsAppliance] = useState(false);
  const [remote_db, set_remote_db] = useState(false);
  const [isDemo, setIsDemo] = useState(false);
  const [isActive, setIsActive] = useState(false);
  const [access_limit, set_access_limit] = useState(0);
  const [plan, set_plan] = useState("PROFESSIONAL");

  const [addLoading, setAddLoading] = useState(false);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      phone_number: "",
      company_name: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      phone_number: Yup.string().required("Please Enter Your Phone Number"),
      company_name: Yup.string().required(
        "Please Enter Your Organization Name"
      ),
    }),
    onSubmit: (values) => {
      let data = {
        ...values,
        is_active: isActive,
        is_endpoint: isAppliance,
        is_demo: isDemo,
        remote_db: isAppliance ? remote_db : false,
        access_limit,
        plan,
      };
      addNewCustomer(data);
    },
  });

  const addNewCustomer = async (data) => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await post("/admin/customer", data);
      if (result?.success) {
        toastSuccess(result?.message || "Customer Added...");
        dispatch({ type: "RENDER_COMPONENT_REQ" });
        tog_standard();
        tog_standard3();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };
  return (
    <div>
      {/* Add New Customer */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={validation.handleSubmit}
        text="Are you sure you want to add new customer?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            New Customer
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <Form
          onSubmit={(e) => {
            e.preventDefault();
            validation.handleSubmit();
            return false;
          }}
        >
          <div className="modal-body">
            <div className="row">
              <div className="col-xl-6 ">
                <div className="form-floating mb-2">
                  <Input
                    type="text"
                    name="company_name"
                    className="form-control"
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.company_name || ""}
                    invalid={
                      validation.touched.company_name &&
                      validation.errors.company_name
                        ? true
                        : false
                    }
                  />
                  <label>Company Name</label>
                  {validation.touched.company_name &&
                  validation.errors.company_name ? (
                    <FormFeedback type="invalid">
                      {validation.errors.company_name}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="col-xl-6 ">
                <div className="form-floating mb-2">
                  <Input
                    type="email"
                    name="email"
                    className="form-control"
                    // required
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.email || ""}
                    invalid={
                      validation.touched.email && validation.errors.email
                        ? true
                        : false
                    }
                  />
                  <label>E-mail Address</label>
                  {validation.touched.email && validation.errors.email ? (
                    <FormFeedback type="invalid">
                      {validation.errors.email}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="col-xl-4 ">
                <div className="form-floating mb-2">
                  <Input
                    type="text"
                    name="phone_number"
                    className="form-control"
                    // required
                    autoComplete="off"
                    onChange={validation.handleChange}
                    onBlur={validation.handleBlur}
                    value={validation.values.phone_number || ""}
                    invalid={
                      validation.touched.phone_number &&
                      validation.errors.phone_number
                        ? true
                        : false
                    }
                  />
                  <label>Phone Number</label>
                  {validation.touched.phone_number &&
                  validation.errors.phone_number ? (
                    <FormFeedback type="invalid">
                      {validation.errors.phone_number}
                    </FormFeedback>
                  ) : null}
                </div>
              </div>
              <div className="col-xl-4  form-floating mb-3">
                <select
                  className="form-select"
                  name="type"
                  id="type"
                  defaultValue={plan}
                  onChange={(e) => set_plan(e.target.value)}
                >
                  {planLists.map((planItem, index) => {
                    return (
                      <option key={planItem + index} value={planItem}>
                        {planItem}
                      </option>
                    );
                  })}
                </select>
                <label style={{ marginLeft: "6px" }}>Plan</label>
              </div>
              <div className="col-xl-4 form-floating mb-3">
                <input
                  className="form-control"
                  type="number"
                  name="scanperiod"
                  min={1}
                  step={1}
                  defaultValue={access_limit}
                  autoComplete="off"
                  onChange={(e) => set_access_limit(e.target.value)}
                />
                <label style={{ marginLeft: "6px" }}>
                  Linked Account Limit
                </label>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-md-4 d-flex align-items-center">
                    <label className="form-label me-2">Active</label>
                    <Input
                      defaultValue={isActive}
                      type="checkbox"
                      id="switch1"
                      switch="primary"
                      defaultChecked={isActive}
                      onChange={(e) => setIsActive(e.target.checked)}
                    />
                    <Label
                      htmlFor="switch1"
                      data-on-label="Yes"
                      data-off-label="No"
                    ></Label>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <label className="form-label me-2">Appliance</label>
                    <Input
                      defaultValue={isAppliance}
                      type="checkbox"
                      id="switch2"
                      switch="primary"
                      defaultChecked={isAppliance}
                      onChange={(e) => setIsAppliance(e.target.checked)}
                    />
                    <Label
                      htmlFor="switch2"
                      data-on-label="Yes"
                      data-off-label="No"
                    ></Label>
                  </div>
                  <div className="col-md-4 d-flex align-items-center">
                    <label className="form-label me-2">Demo</label>
                    <Input
                      defaultValue={isDemo}
                      type="checkbox"
                      id="switch6"
                      switch="primary"
                      defaultChecked={isDemo}
                      onChange={(e) => setIsDemo(e.target.checked)}
                    />
                    <Label
                      htmlFor="switch6"
                      data-on-label="Yes"
                      data-off-label="No"
                    ></Label>
                  </div>
                </div>
              </div>
              <div className="col-12">
                <div className="row">
                  <div className="col-4" />
                  <AnimatePresence>
                    {isAppliance && (
                      <motion.div
                        className="col-4"
                        id="dbLocationContainer"
                        initial={{ y: -50, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 0, opacity: 0 }}
                        transition={{ duration: 0.4 }}
                      >
                        <label className="form-label">Database Location</label>
                        <div className="form-floating mb-2">
                          <div className="btn-group" role="group">
                            <input
                              type="radio"
                              className="btn-check"
                              name="db_location"
                              defaultValue="CLOUD"
                              id="db_cloudchk"
                              autoComplete="off"
                              defaultChecked={!remote_db}
                              onChange={(e) => set_remote_db(false)}
                            />
                            <label
                              className="btn btn-outline-secondary"
                              htmlFor="db_cloudchk"
                            >
                              Cloud
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="db_location"
                              defaultValue="REMOTE"
                              id="db_epchk"
                              autoComplete="off"
                              defaultChecked={remote_db}
                              onChange={(e) => set_remote_db(true)}
                            />
                            <label
                              className="btn btn-outline-secondary"
                              htmlFor="db_epchk"
                            >
                              Appliance
                            </label>
                          </div>
                        </div>
                      </motion.div>
                    )}
                  </AnimatePresence>
                </div>
              </div>
            </div>
          </div>

          <div className="modal-footer">
            <button
              type="button"
              onClick={() => {
                tog_standard();
              }}
              className="btn btn-secondary "
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              onClick={tog_standard3}
              type="button"
              className="btn btn-primary "
            >
              Add Customer
            </button>
          </div>
        </Form>
      </Modal>
    </div>
  );
};

export default AddNewCustomerModal;
