import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import {
  formatDateTransform,
  removeBodyCss,
} from "../../../../common/utils/commonFunctions";
import ConfirmModal from "../../../../common/utils/ConfirmModal";

const DistributionSeverity = ({
  data,
  default_widgets,
  defaultWidgetDelete,
}) => {
  const [viewEdit, set_viewEdit] = useState(false);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };
  let criticalValues = [];
  let highValues = [];
  let mediumValues = [];
  let lowValues = [];
  let infoValues = [];
  let weekValues = [];

  if (data?.weekly_overview?.length > 0) {
    criticalValues = data?.weekly_overview?.map((item) => item.critical);
    highValues = data?.weekly_overview?.map((item) => item.high);
    mediumValues = data?.weekly_overview?.map((item) => item.medium);
    lowValues = data?.weekly_overview?.map((item) => item.low);
    infoValues = data?.weekly_overview?.map((item) => item.info);
    weekValues = data?.weekly_overview?.map((item) => item.week);
  }

  const series = [
    {
      name: "Critical",
      data: criticalValues,
    },
    {
      name: "High",
      data: highValues,
    },
    {
      name: "Medium",
      data: mediumValues,
    },
    {
      name: "Low",
      data: lowValues,
    },
    {
      name: "Info",
      data: infoValues,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#FD625E", "#FD8C00", "#FDC500", "#2AB57D", "#4bA6EF"],
    fill: {
      type: "solid",
      colors: ["transparent"],
    },
    xaxis: {
      type: "datetime",
      labels: {
        format: formatDateTransform(data?.date_format),
        datetimeUTC: false,
      },
      categories: weekValues,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: `${formatDateTransform(data?.date_format)} HH:mm`,
      },
    },
  };

  const targetFunc = () => {
    defaultWidgetDelete("distribution_severity");
  };

  return (
    <div className="col-12 ">
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={targetFunc}
        text="Are you sure want to delete widget ?"
        loadingAction={false}
      />
      {default_widgets.distribution_severity && (
        <div
          onMouseEnter={() => set_viewEdit(true)}
          onMouseLeave={() => set_viewEdit(false)}
          className="special-card "
        >
          <div className="d-flex position-relative  py-2  align-items-center justify-content-between  ">
            <div>
              <span className="fw-bold fs-4 me-1 ">
                Distribution of Severity{" "}
              </span>
            </div>{" "}
            <div
              className={` cursor-pointer ${viewEdit ? "d-flex" : "d-none"}`}
              style={{ position: "absolute", top: 5, right: 0 }}
              onClick={tog_standard5}
            >
              <i className="mdi mdi-delete text-primary fs-3 " />
            </div>
          </div>
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            height="230"
          />
        </div>
      )}
    </div>
  );
};

export default DistributionSeverity;
