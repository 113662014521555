import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get } from "../../../helpers/api_helper";
import { Container, Spinner } from "reactstrap";
import AuditsComponent from "../../../components/Customer/Hosts/Audits/AuditsComponent";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../common/InfoNoDataComponent";
import { useHistory } from "react-router-dom";

const Audits = () => {
  document.title = "Audits | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [isLoading2, setIsLoading2] = useState(false);

  const [data, setData] = useState();
  const [assets, setAssets] = useState();
  const [groupList, setGroupList] = useState();
  const [empty, setEmpty] = useState(false);
  const [moduleID, set_moduleID] = useState();

  const [seemInfoComponentScan, set_seemInfoComponentScan] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);
  const [textScanInfo, set_textScanInfo] = useState();

  const router = useHistory();

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getGroupList = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/host/group`);
      if (result?.success) {
        setGroupList(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoading2(false);
    paceFunction(false);
  };

  const getAuditsOverview = async (id) => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/overview?id=${id}`);
      if (result?.success) {
        if (result?.data?.scan_history) {
          setData(result.data);
        } else {
          set_textScanInfo(result?.message || "No host audits scan discovered");
          set_seemInfoComponentScan(true);
          setIsLoading2(false);
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
  };

  const getAssets = async (id) => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/asset?id=${id}`);
      if (result?.success && result?.data?.assets?.length > 0) {
        setAssets(result.data);
        getGroupList();
      }
      if (result?.data?.assets?.length < 1) {
        setIsLoading2(false);
        set_seemInfoComponentScan(true);
        set_textScanInfo(result?.message || "No host audits scan discovered");
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
  };

  const getModules = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/module");
      if (result?.success) {
        let dataArray = result?.data?.modules;
        const hasHost = dataArray.some((item) => item.name === "HOST");
        if (hasHost) {
          const filteredData = dataArray.filter((item) => item.name === "HOST");
          if ("permission" in filteredData[0]) {
            if (filteredData[0].permission) {
              for (let i = 0; i < dataArray.length; i++) {
                if (dataArray[i].name === "HOST") {
                  getAuditsOverview(dataArray[i].id);
                  getAssets(dataArray[i].id);
                  set_moduleID(dataArray[i].id);
                }
              }
            } else {
              set_seemInfoComponentGrantAccess(true);
              setIsLoading(false);
              setIsLoading2(false);
            }
          } else {
            for (let i = 0; i < dataArray.length; i++) {
              if (dataArray[i].name === "HOST") {
                getAuditsOverview(dataArray[i].id);
                getAssets(dataArray[i].id);
                set_moduleID(dataArray[i].id);
              }
            }
          }
        } else {
          setIsLoading(false);
          setIsLoading2(false);
          setEmpty(true);
          showSweetAlert("You don't have a Host Audits Module...");
          router.push("/pages-404");
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
  };

  useEffect(() => {
    setEmpty(false);
    setIsLoading(true);
    setIsLoading2(true);
    getModules();
  }, []);

  useEffect(() => {
    if (isLoading || isLoading2) paceFunction(true);
    else paceFunction(false);
  }, [isLoading, isLoading2]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Audits" breadcrumbItem="Audits" />
          {(isLoading || isLoading2) && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && !isLoading2 && data && assets && groupList && (
            <AuditsComponent
              data={data}
              assets={assets}
              groupList={groupList}
              moduleID={moduleID}
            />
          )}
          {!isLoading && !isLoading2 && seemInfoComponentScan && (
            <InfoNoDataComponent
              text={textScanInfo || "No host audits scan were discovered"}
              component="audits"
            />
          )}
          {/* {!isLoading && !isLoading2 && seemInfoComponentAsset && (
            <InfoNoDataComponent
              text="No host audits asset were discovered"
              component="audits"
            />
          )} */}
          {!isLoading && !isLoading2 && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="audits"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Audits;
