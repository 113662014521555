import React, { useState } from "react";
import { CardBody, CardTitle } from "reactstrap";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import { unsubscribeReasons } from "../../../../common/utils/commonValues";
import { post } from "../../../helpers/api_helper";

const AutoRenewal = ({ renewal, getProfileInfos }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [subscriptionLoading, set_subscriptionLoading] = useState(false);

  const [reason, set_reason] = useState();
  const [subject, set_subject] = useState(unsubscribeReasons[0]);
  const [auto_renewal, set_auto_renewal] = useState(renewal);

  const [modal_standard, setmodal_standard] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const subscribeSituation = async () => {
    paceFunction(true);
    set_subscriptionLoading(true);
    let data = {
      auto_renewal,
      subject,
      reason,
    };
    try {
      let result = await post("/customer/settings/account/auto_renewal", data);
      if (result?.success) {
        toastSuccess(result?.message || "AutoRenewal situation changed");
        tog_standard();
        getProfileInfos();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
    set_subscriptionLoading(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        targetFunction={subscribeSituation}
        text="Are you sure you want to change auto renewal situation?"
        loadingAction={true}
        isLoading={subscriptionLoading}
      />
      <div className="special-card mt-4">
        <CardBody>
          <CardTitle className="mb-3 fw-bold">
            <div className="d-flex justify-content-between align-items-center">
              <span>Auto Renewal</span>{" "}
              <div className="form-check form-switch">
                <input
                  onChange={(e) => set_auto_renewal(e.target.checked)}
                  className="form-check-input"
                  type="checkbox"
                  role="switch"
                  id="flexSwitchCheckDefault"
                  checked={auto_renewal}
                />
              </div>
            </div>
          </CardTitle>
          {!auto_renewal && renewal && (
            <React.Fragment>
              <div className="mt-2">
                <label className="form-label me-2">Subject</label>
                <select
                  className="form-select border-primary "
                  onChange={(e) => set_subject(e.target.value)}
                  defaultValue={subject}
                >
                  {unsubscribeReasons.map((item, index) => (
                    <option key={item + index} value={item}>
                      {item}
                    </option>
                  ))}
                </select>
              </div>
              <div className="mt-2">
                <label className="form-label me-2">Reason</label>
                <textarea
                  name="unsubscription"
                  id="unsubscription"
                  cols={3}
                  rows={2}
                  maxLength={150}
                  placeholder="Please enter your reason"
                  className="reason-black"
                  onChange={(e) => set_reason(e.target.value)}
                />
              </div>
            </React.Fragment>
          )}

          <div className="mt-2">
            <button
              onClick={tog_standard}
              type="button"
              className="btn btn-primary waves-effect btn-label waves-light w-100 "
            >
              <i className="mdi mdi-update me-3 label-icon"></i>Update
            </button>
          </div>
        </CardBody>
      </div>
    </React.Fragment>
  );
};

export default AutoRenewal;
