import React, { useState } from "react";
import { del, patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";

const GroupInfo = ({ item, getGroupList, getHostsAsset }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [name, set_name] = useState(item?.name);
  const [severity, set_severity] = useState(item?.severity);

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);
  const [updateLoading, setUpdateLoading] = useState(false);
  const [removeLoading, setRemoveLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  const updateGroup = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let data = {
      id: item.id,
      name,
      severity,
    };
    try {
      let result = await patch("/customer/module/host/group", data);
      if (result?.success) {
        toastSuccess(result?.message || "Group Updated");
        tog_standard5();
        getGroupList();
        getHostsAsset();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  const removeGroup = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del(`/customer/module/host/group?id=${item?.id}`);
      if (result?.success) {
        toastSuccess(result?.message || "Group Deleted");
        tog_standard6();
        getGroupList();
        getHostsAsset();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Update Group */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={updateGroup}
        text={`Are you sure you want to update ${item?.name}?`}
        loadingAction={true}
        isLoading={updateLoading}
      />
      {/* Delete Group */}
      <ConfirmModal
        modal_standard={modal_standard6}
        tog_standard={tog_standard6}
        setmodal_standard={setmodal_standard6}
        targetFunction={removeGroup}
        text={`Are you sure you want to delete ${item?.name}?`}
        loadingAction={true}
        isLoading={removeLoading}
      />
      <div className="col-xl-4 col-sm-6">
        <div className="card text-center">
          <div className="card-body">
            <div className="mx-auto mb-4">
              <i className="avatar-xl mdi mdi-layers-triple-outline text-primary  font-size-24" />
            </div>
            <div className="row mb-3 ">
              <div className="col-xl-3 align-items-center justify-content-center d-flex  ">
                <div className="d-flex align-items-center justify-content-center flex-row   ">
                  <h5 className="font-size-13">Name: </h5>
                </div>
              </div>
              <div className="col-xl">
                <div className="input-group">
                  <input
                    type="text"
                    className="form-control"
                    style={{ width: "80%" }}
                    name="name"
                    defaultValue={item?.name}
                    onChange={(e) => set_name(e.target.value)}
                  />
                </div>
              </div>
            </div>
            <select
              name="new_ip_target_group"
              data-group-severity="deneme"
              className="form-select"
              defaultValue={item?.severity}
              onChange={(e) => set_severity(e.target.value)}
            >
              <option className="text-danger" value="CRITICAL">
                Critical
              </option>
              <option className="text-warning" value="HIGH">
                High
              </option>
              <option className="text-info" value="MEDIUM">
                Medium
              </option>
              <option className="text-success" value="LOW">
                Low
              </option>
            </select>
          </div>
          <div className="btn-group" role="group">
            <button
              onClick={tog_standard5}
              type="button"
              data-delete-groupbtn="deneme"
              className="btn btn-outline-success text-truncate"
            >
              <i className="mdi mdi-update me-1" /> Update
            </button>
            <button
              onClick={tog_standard6}
              type="button"
              data-delete-groupbtn="deneme"
              className="btn btn-outline-danger text-truncate"
            >
              <i className="mdi mdi-delete me-1" /> Delete
            </button>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default React.memo(GroupInfo);
