import React, { useState } from "react";
import { CardHeader, CardBody, Container, Modal } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import { useHistory } from "react-router-dom";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const AddManager = () => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const router = useHistory();

  const [organization_name, set_organization_name] = useState("");
  const [phone_number, set_phone_number] = useState("");
  const [email, set_email] = useState("");

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, set_addLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const handleSubmit = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      email,
      organization_name,
      phone_number,
    };
    try {
      let result = await put("/admin/manager", data);
      if (result?.success) {
        toastSuccess(result?.message || "Manager Added", 2000);
        tog_standard5();
        setTimeout(() => {
          router.push("/admin/managers");
        }, 3000);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard5}
        toggle={() => {
          tog_standard5();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            PentestBX asks
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard5(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5>Are you sure you want to add manager?</h5>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard5();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Cancel
          </button>
          {addLoading ? (
            <button
              type="button"
              className="btn btn-primary waves-effect waves-light"
              disabled
            >
              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
              Yes
            </button>
          ) : (
            <button
              onClick={() => {
                handleSubmit();
              }}
              type="button"
              className="btn btn-primary "
            >
              Yes
            </button>
          )}
        </div>
      </Modal>
      <div className="page-content">
        <Container fluid>
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title text-primary ">Add Manager</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className=" col-md-4">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="organization_name"
                        className="form-control"
                        onChange={(e) => set_organization_name(e.target.value)}
                        required
                      />
                      <label>Name</label>
                    </div>
                  </div>
                  <div className=" col-md-4">
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={(e) => set_email(e.target.value)}
                        required
                      />
                      <label>Email</label>
                    </div>
                  </div>
                  <div className=" col-md-4">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        onChange={(e) => set_phone_number(e.target.value)}
                        required
                      />
                      <label>Phone Number</label>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    onClick={tog_standard5}
                    type="button"
                    id="save_updatebtn"
                    className="btn btn-lg btn-primary"
                  >
                    <i className="mdi mdi-book-edit-outline me-3" />
                    Add Manager
                  </button>
                </div>
              </form>
            </CardBody>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddManager;
