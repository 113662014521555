import React, { useState } from "react";
import { Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useHistory } from "react-router-dom";
import SubscribeVendorProductModal from "./SubscribeVendorProductModal";
import { del } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const ProductsComponent = ({
  products,
  productPage,
  vendorProduct,
  vendorProductPage,
  getProductList,
  getVendorProductList,
  tags,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const router = useHistory();

  const [name, set_name] = useState();

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);

  const [vendorName, set_vendorName] = useState();

  const [deleteLoading, set_deleteLoading] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
    if (modal_standard) {
      set_name();
    }
  }

  function tog_standard2() {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  }

  const pages = Math.ceil(products?.total / 20);
  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }

  const rightPagination = () => {
    if (vendorProduct) {
      if (vendorProductPage < pages) {
        getVendorProductList(Number(vendorProductPage) + 1, vendorProduct);
      } else getVendorProductList(Number(vendorProductPage), vendorProduct);
    } else {
      if (productPage < pages) {
        getProductList(Number(productPage) + 1);
      } else getProductList(productPage);
    }
  };

  const leftPagination = () => {
    if (vendorProduct) {
      if (vendorProductPage === 1) {
        getVendorProductList(vendorProductPage, vendorProduct);
      } else if (vendorProductPage <= pages) {
        getProductList(Number(vendorProductPage) - 1, vendorProduct);
      }
    } else {
      if (productPage === 1) {
        getProductList(productPage);
      } else if (productPage <= pages) {
        getProductList(Number(productPage) - 1);
      }
    }
  };

  const unsubscribeProduct = async () => {
    paceFunction(true);
    set_deleteLoading(true);
    try {
      let result = await del(
        `/customer/module/cyberwatch/ti/cves/subscriptions?name=${name}&type=product&vendor_name=${vendorName}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Unsubscribed");
        tog_standard2();
        getProductList();
        set_name();
        set_vendorName();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_deleteLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={unsubscribeProduct}
        text={`Are you sure you want to unsubscribe?`}
        loadingAction={true}
        isLoading={deleteLoading}
      />
      {name && tags && vendorName && (
        <SubscribeVendorProductModal
          tog_standard={tog_standard}
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          allTags={tags}
          type="product"
          name={name}
          vendor_name={vendorName}
          getList={() =>
            vendorProduct
              ? getVendorProductList(vendorProductPage, vendorProduct)
              : getProductList()
          }
        />
      )}
      <div className="special-card">
        <div className="card-header with-border">
          <h3 className="card-title">Products ({products?.total})</h3>
        </div>
        <div className="card-body table-responsive no-padding">
          <table className="table table-hover table-striped">
            <tbody>
              <tr>
                <th>Vendor</th>
                <th>Product</th>
                <th className="text-center">Actions</th>
              </tr>
              {products?.items?.map((product, productIndex) => (
                <tr key={productIndex}>
                  <td>
                    {vendorProduct
                      ? vendorProduct
                      : product?.vendor_human_name
                      ? product?.vendor_human_name
                      : "-"}
                  </td>
                  <td>{product?.human_name || "-"}</td>
                  <td className="text-center">
                    <div className="btn-group">
                      {product?.is_subscribe ? (
                        <button
                          onClick={() => {
                            set_name(product?.name);
                            set_vendorName(
                              vendorProduct
                                ? vendorProduct
                                : product?.vendor_name
                            );
                            tog_standard2();
                          }}
                          className="btn btn-danger btn-sm subscribe"
                          type="button"
                        >
                          Unsubscribe
                        </button>
                      ) : (
                        <button
                          onClick={() => {
                            set_name(product?.name);
                            set_vendorName(
                              vendorProduct
                                ? vendorProduct
                                : product?.vendor_name
                            );
                            tog_standard();
                          }}
                          className="btn btn-default-cves btn-sm subscribe"
                          type="button"
                        >
                          <i className="mdi mdi-bell-outline" /> Subscribe
                        </button>
                      )}

                      <button
                        onClick={() =>
                          router.push(
                            `/cyber-watch/threat-intelligence/cves?page=1&vendor=${
                              vendorProduct
                                ? vendorProduct
                                : product?.vendor_name
                            }&product=${product?.name}`
                          )
                        }
                        className="btn btn-default-cves btn-sm"
                      >
                        <i className="mdi mdi-shield-half-full"></i> CVE
                      </button>
                    </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {products?.items?.length < 1 && (
            <div className="w-100 bg-info py-2 text-center text-white">
              No product
            </div>
          )}
          {vendorProduct ? (
            // Vendor Product Pagination
            <Col className="d-flex align-items-center justify-content-center  mt-2 ">
              <Pagination
                className="d-flex align-items-center "
                aria-label="Page navigation "
              >
                <PaginationItem>
                  <PaginationLink
                    onClick={() => leftPagination()}
                    tabIndex={-1}
                  >
                    Previous
                  </PaginationLink>
                </PaginationItem>
                {pageArrays.length > 5 && (
                  <>
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => getVendorProductList(1, vendorProduct)}
                      >
                        First
                      </PaginationLink>
                    </PaginationItem>
                    {vendorProductPage > 2 && (
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            getVendorProductList(
                              Number(vendorProductPage) - 2,
                              vendorProduct
                            )
                          }
                        >
                          {Number(vendorProductPage) - 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem active>
                      <PaginationLink
                        onClick={() =>
                          getVendorProductList(
                            Number(vendorProductPage),
                            vendorProduct
                          )
                        }
                      >
                        {Number(vendorProductPage)}
                      </PaginationLink>
                    </PaginationItem>
                    {vendorProductPage + 2 <= pageArrays.length && (
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            getVendorProductList(
                              Number(vendorProductPage) + 2,
                              vendorProduct
                            )
                          }
                        >
                          {Number(vendorProductPage) + 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem>
                      <PaginationLink
                        onClick={() =>
                          getVendorProductList(pageArrays.length, vendorProduct)
                        }
                      >
                        Last
                      </PaginationLink>
                    </PaginationItem>
                  </>
                )}
                {pageArrays.length <= 5 &&
                  pageArrays.map((page) => (
                    <PaginationItem
                      key={page}
                      active={page === productPage ? true : false}
                    >
                      <PaginationLink
                        onClick={() =>
                          getVendorProductList(page, vendorProduct)
                        }
                      >
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                <PaginationItem>
                  <PaginationLink onClick={() => rightPagination()}>
                    Next
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          ) : (
            // Product Pagination
            <Col className="d-flex align-items-center justify-content-center  mt-2 ">
              <Pagination
                className="d-flex align-items-center "
                aria-label="Page navigation "
              >
                <PaginationItem>
                  <PaginationLink
                    onClick={() => leftPagination()}
                    tabIndex={-1}
                  >
                    Previous
                  </PaginationLink>
                </PaginationItem>
                {pageArrays.length > 5 && (
                  <>
                    <PaginationItem>
                      <PaginationLink onClick={() => getProductList(1)}>
                        First
                      </PaginationLink>
                    </PaginationItem>
                    {productPage > 2 && (
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            getProductList(Number(productPage) - 2)
                          }
                        >
                          {Number(productPage) - 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem active>
                      <PaginationLink
                        onClick={() => getProductList(Number(productPage))}
                      >
                        {Number(productPage)}
                      </PaginationLink>
                    </PaginationItem>
                    {productPage + 2 <= pageArrays.length && (
                      <PaginationItem>
                        <PaginationLink
                          onClick={() =>
                            getProductList(Number(productPage) + 2)
                          }
                        >
                          {Number(productPage) + 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => getProductList(pageArrays.length)}
                      >
                        Last
                      </PaginationLink>
                    </PaginationItem>
                  </>
                )}
                {pageArrays.length <= 5 &&
                  pageArrays.map((page) => (
                    <PaginationItem
                      key={page}
                      active={page === productPage ? true : false}
                    >
                      <PaginationLink onClick={() => getProductList(page)}>
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                <PaginationItem>
                  <PaginationLink onClick={() => rightPagination()}>
                    Next
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          )}
        </div>
      </div>
    </React.Fragment>
  );
};

export default ProductsComponent;
