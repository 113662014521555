// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { IS_APPLIANCE_REDUX } from "./actionTypes";

import { isApplianceReduxFunction } from "./actions";

function* isApplianceFunction({ payload }) {
  yield put({ type: "IS_APPLIANCE_REDUX" });
}

export function* watchRender() {
  yield takeEvery("IS_APPLIANCE_REDUX_REQ", isApplianceFunction);
}

function* CustomSaga() {
  yield all([fork(watchRender)]);
}

export default CustomSaga;
