import React from "react";
import ReactApexChart from "react-apexcharts";
import { formatDateTransform } from "../../../../../common/utils/commonFunctions";

const Splinearea = ({ data, dateFormat }) => {
  const extractValuesByKey = (data, key) => {
    return data?.map((item) => item[key]);
  };

  const criticalArray = extractValuesByKey(data, "critical");
  const highArray = extractValuesByKey(data, "high");
  const mediumArray = extractValuesByKey(data, "medium");
  const lowArray = extractValuesByKey(data, "low");
  const infoArray = extractValuesByKey(data, "info");
  const createdAtArray = extractValuesByKey(data, "iso_format");

  const series = [
    {
      name: "Critical",
      data: criticalArray,
    },
    {
      name: "High",
      data: highArray,
    },
    {
      name: "Medium",
      data: mediumArray,
    },
    {
      name: "Low",
      data: lowArray,
    },
    {
      name: "Info",
      data: infoArray,
    },
  ];

  const options = {
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "smooth",
      width: 3,
    },

    colors: ["#FD625E", "#FD8C00", "#FDC500", "#2AB57D", "#4bA6EF"],
    fill: {
      type: "solid",
      colors: ["transparent"],
    },
    xaxis: {
      type: "datetime",
      labels: { format: formatDateTransform(dateFormat), datetimeUTC: false },
      categories: createdAtArray,
    },
    grid: {
      borderColor: "#f1f1f1",
    },
    tooltip: {
      x: {
        format: `${formatDateTransform(dateFormat)} HH:mm`,
      },
    },
    // responsive: [
    //   {
    //     breakpoint: 1700,
    //     options: {
    //       chart: {
    //         height: 429,
    //       },
    //     },
    //   },
    //   {
    //     breakpoint: 1668,
    //     options: {
    //       chart: {
    //         height: 421,
    //       },
    //     },
    //   },
    // ],
  };

  return (
    <ReactApexChart
      options={options}
      series={series}
      type="area"
      height="405"
    />
  );
};

export default Splinearea;
