import React from "react";

const SocialAccounts = ({ data }) => {
  return (
    <div className="row">
      {data?.map((event) => (
        <div
          key={event?.address}
          className=" col-xxl-3 col-lg-4 col-md-6  mb-2 "
        >
          <a href={`${event?.address}`} target="_blank" rel="noreferrer">
            <div className="special-card2 d-flex align-items-center ">
              <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-4 " />
              <span className="text-primary">
                {event?.address.slice(0, 20)}
                {event?.address?.length > 20 ? "..." : ""}
              </span>
            </div>
          </a>
        </div>
      ))}
    </div>
  );
};

export default SocialAccounts;
