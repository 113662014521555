import React, { useEffect, useState } from "react";
import { post } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { CardHeader, CardBody, Spinner } from "reactstrap";
import VulnerabilitiesTable from "./VulnerabilitiesTable";

const Vulnerabilies = ({ updated_at, assetID, setSelectDisabled }) => {
  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [entryValue, setEntryValue] = useState(10);
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  const getVulnerabilities = async () => {
    paceFunction(true);
    setSelectDisabled(true);
    let vulnData = {
      date: updated_at,
      id: assetID,
    };
    try {
      let result = await post(`/customer/module/mxthreats/vulns`, vulnData);
      if (result?.success) {
        const updatedData = result?.data?.map((vulnerability) => {
          if (vulnerability?.secure) {
            return {
              ...vulnerability,
              secure_type: "Secure",
            };
          } else {
            return {
              ...vulnerability,
              secure_type: "Insecure",
            };
          }
        });
        setData(updatedData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setSelectDisabled(false);
    paceFunction(false);
  };

  useEffect(() => {
    if (updated_at) {
      setIsLoading(true);
      getVulnerabilities();
    } else {
      setData();
    }
  }, [updated_at, assetID]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <React.Fragment>
      <div className="special-card">
        <CardHeader>
          <h4 className="card-title text-primary ">Vulnerabilities</h4>
        </CardHeader>
        {isLoading && (
          <div className="d-flex justify-content-center  align-items-center h-100  ">
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              className="ms-2 "
              color="primary"
            />
          </div>
        )}
        {!isLoading && data && (
          <CardBody>
            <div
              style={{
                overflowX: windowWidth < 800 ? "scroll" : "none",
              }}
            >
              <VulnerabilitiesTable
                data={data}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
              />
            </div>
          </CardBody>
        )}
      </div>
    </React.Fragment>
  );
};

export default Vulnerabilies;
