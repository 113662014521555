import React, { useState } from "react";
import ReactApexChart from "react-apexcharts";
import { getFieldTotal } from "../../../../../common/utils/commonFunctions";
import { useSelector } from "react-redux";

const Chart = ({ data, wizard }) => {
  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  let options;
  let series;
  let result = getFieldTotal(data.weekly_overview, wizard.values);

  if (wizard.graphic_type === "donut" || wizard.graphic_type === "pie") {
    options = {
      plotOptions: {
        pie: {
          donut: {
            labels: {
              show: true,
              total: {
                show: false,
                label: "Total",
                fontSize: "30px",
                color: "rgb(188, 193, 200)",
                formatter: () => toplam,
              },
            },
          },
        },
      },
      labels: result.labels,
      colors: wizard.label_colors,
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
      responsive: [
        {
          breakpoint: 600,
          options: {
            chart: {
              height: 260,
            },
            legend: {
              show: false,
            },
          },
        },
      ],
    };
    series = result.series;
  }

  if (wizard.graphic_type === "radar") {
    options = {
      yaxis: {
        stepSize: 20,
      },
      xaxis: {
        categories: result.labels,
      },
    };
    series = [
      {
        name: "Count",
        data: result.series,
      },
    ];
  }

  if (wizard.graphic_type === "bar") {
    options = {
      chart: {
        toolbar: {
          show: false,
        },
      },
      plotOptions: {
        bar: {
          distributed: true,
          borderRadius: 10,
          columnWidth: "100%",
          dataLabels: {
            position: "top", // top, center, bottom
          },
        },
      },
      dataLabels: {
        enabled: true,
        formatter: function (val) {
          // return val + "%";
          return val + "";
        },
        offsetY: -20,
        style: {
          fontSize: "12px",
          colors: layoutMode === "dark" ? ["#c5c5c5"] : ["#000000"],
        },
      },
      colors: wizard.label_colors,
      grid: { show: false, borderColor: "#FFF" },
      xaxis: {
        categories: result.labels,
        labels: {
          show: true,
          rotate: -45,
          rotateAlways: true,
          fontSize: "100px",
          minHeight: 100,
          maxHeight: 180,
          style: {
            fontWeight: "bold",
            fontSize: "12px",
            color: "#304758",
          },
        },
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        crosshairs: {
          fill: {
            type: "gradient",
            gradient: {
              colorFrom: "#D8E3F0",
              colorTo: "#BED1E6",
              stops: [0, 100],
              opacityFrom: 0.4,
              opacityTo: 0.5,
            },
          },
        },
        tooltip: {
          enabled: false,
          offsetY: -35,
        },
      },
      fill: {
        gradient: {
          shade: "light",
          type: "horizontal",
          shadeIntensity: 0.25,
          gradientToColors: undefined,
          inverseColors: true,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [50, 0, 100, 100],
        },
      },
      yaxis: {
        axisBorder: {
          show: false,
        },
        axisTicks: {
          show: false,
        },
        labels: {
          show: true,
          formatter: function (val) {
            //   return val + "%";
            return val + "";
          },
        },
      },
      title: {
        //   text: "Monthly Inflation in Argentina, 2002",
        floating: true,
        offsetY: 330,
        align: "center",
        style: {
          color: "#444",
        },
      },
    };
    series = [
      {
        name: "Count",
        data: result.series,
      },
    ];
  }

  if (wizard.graphic_type === "polarArea") {
    options = {
      stroke: {
        colors: ["white"],
      },
      labels: result.labels,
      fill: {
        opacity: 0.8,
      },
      colors: wizard.label_colors,
      responsive: [
        {
          breakpoint: 480,
          options: {
            chart: {
              width: 200,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],
      legend: {
        show: true,
        position: "bottom",
        horizontalAlign: "center",
        verticalAlign: "middle",
        floating: false,
        fontSize: "14px",
        offsetX: 0,
        offsetY: -10,
      },
    };
    series = result.series;
  }

  return (
    <>
      <div>
        <ReactApexChart
          options={options}
          series={series}
          type={wizard.graphic_type}
          height={
            wizard.graphic_type === "bar"
              ? "280"
              : wizard.graphic_type === "radar"
              ? "295"
              : wizard.graphic_type === "polarArea"
              ? "300"
              : "320"
          }
        />
      </div>
    </>
  );
};

export default Chart;
