// @flow
import { RENDER_COMPONENT, RENDER_COMPONENT2 } from "./actionTypes";

const INIT_STATE = {
  render: false,
  render2: false,
};

const Render = (state = INIT_STATE, action) => {
  switch (action.type) {
    case RENDER_COMPONENT:
      return {
        ...state,
        render: !state.render,
      };
    case RENDER_COMPONENT2:
      return {
        ...state,
        render2: !state.render2,
      };

    default:
      return state;
  }
};

export default Render;
