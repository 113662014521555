import React from "react";

//import Breadcrumbs
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import { Card, CardBody, Col, Container, Row } from "reactstrap";

const Pricing = () => {
  //meta title
  document.title = "Dashboard | PentestBX";
  const { errorFunction } = errorFunctionComponent();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Pricing" breadcrumbItem="Pricing" />

          <div>Pricing</div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Pricing;
