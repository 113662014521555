import React, { useEffect, useState } from "react";
import {
  CardBody,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import classnames from "classnames";
import ProfileTabOverview from "./ProfileTabOverview";
import Notification from "./Notification";
import UpdatePassword from "./UpdatePassword";

import { get } from "../../../../helpers/api_helper";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import Tickets from "./Quotas";
import GrantAccess from "./GrantAccess/GrantAccess";
import Issues from "./Issues/Issues";
import Appliance from "./Appliance/Appliance";
import { removeBodyCss } from "../../../../common/utils/commonFunctions";

const CustomerInfo = ({ profileInfos, getProfileInfos }) => {
  const { paceFunction } = paceFunctionComponent();

  const [activeTab, toggleTab] = useState("1");

  const [modal_standard, setmodal_standard] = useState(false);

  const [notifications, set_notifications] = useState();
  const [grantAccess, set_grantAccess] = useState();
  const [appliance, set_appliance] = useState();
  const [issues, set_issues] = useState();
  const [tickets, set_tickets] = useState();

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const getNotifications = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/account/notification-config");
      if (result?.success) set_notifications(result?.data);
    } catch (error) {
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  const getGrantAccess = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/linked-account");
      if (result?.success) set_grantAccess(result?.data);
    } catch (error) {
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  const getAppliance = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/endpoint");
      if (result?.success) {
        set_appliance(result);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      set_appliance(error.response.data);
    }
    paceFunction(false);
  };

  const getIssues = async () => {
    paceFunction(true);
    try {
      let result = await get("/account/issue");
      if (result?.success) {
        set_issues(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  const getTickets = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/ticket");
      if (result?.success) {
        let filteredResult = result.data.filter(
          (item) => item.module !== "CYBER_WATCH"
        );
        set_tickets(filteredResult);
      }
    } catch (error) {
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getNotifications();
    getIssues();
    getTickets();
    getAppliance();
    getGrantAccess();
  }, []);

  return (
    <>
      <UpdatePassword
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
      />
      <div className="special-card">
        <CardBody>
          <Row>
            <div className="col-sm order-2 order-sm-1">
              <div className="d-flex align-items-start mt-3 mt-sm-0">
                <div className="flex-grow-1">
                  <div>
                    <h5 className="font-size-16 mb-1">
                      {profileInfos?.company_name}
                    </h5>

                    <div className="d-flex flex-wrap align-items-start gap-2 gap-lg-3 text-muted font-size-13">
                      <div>
                        <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                        {profileInfos?.phone_number}
                      </div>
                      <div>
                        <i className="mdi mdi-circle-medium me-1 text-primary align-middle"></i>
                        {profileInfos?.email}
                      </div>
                    </div>
                    <div className="d-flex flex-wrap gap-1 mt-2">
                      <div className="d-flex  align-items-center">
                        <span className="font-size-14 fw-bold">Plan:</span>
                        <span className="font-size-12 ms-1 badge bg-primary ">
                          {profileInfos?.plan}
                        </span>
                      </div>
                      {!profileInfos.is_linked && (
                        <div className="d-flex  align-items-center">
                          <span className="font-size-14 fw-bold">
                            Grant Access Limit:
                          </span>
                          <span className="font-size-12 ms-1 badge bg-primary ">
                            {profileInfos?.access_limit}
                          </span>
                        </div>
                      )}
                      <div className="d-flex  align-items-center">
                        <span className="font-size-14 fw-bold">
                          Joined Date:
                        </span>
                        <span className="font-size-12 ms-1 badge bg-primary ">
                          {profileInfos?.date_joined}
                        </span>
                      </div>
                      <div className="d-flex  align-items-center">
                        <span className="font-size-14 fw-bold">
                          Subscription Renewal:
                        </span>
                        <span className="font-size-12 ms-1 badge bg-primary ">
                          {profileInfos?.subscription_renewal}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-auto order-1 order-sm-2">
              <div className="d-flex align-items-start justify-content-center justify-content-sm-end  gap-2">
                <div>
                  <button
                    type="button"
                    className="btn btn-primary"
                    onClick={tog_standard}
                  >
                    <i className="mdi mdi-account-key-outline me-1"></i> Update
                    Password
                  </button>
                </div>
              </div>
            </div>
          </Row>

          <Nav className="nav-tabs-custom card-header-tabs border-top mt-4">
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "1",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("1");
                }}
              >
                Overview
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "2",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("2");
                }}
              >
                Notification Config
              </NavLink>
            </NavItem>
            {!profileInfos?.is_linked && (
              <>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "3",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("3");
                    }}
                  >
                    Grant Access
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    href="#"
                    className={classnames(
                      {
                        active: activeTab === "4",
                      },
                      "px-3"
                    )}
                    onClick={() => {
                      toggleTab("4");
                    }}
                  >
                    Appliance
                  </NavLink>
                </NavItem>
              </>
            )}
            <NavItem>
              <NavLink
                href="#"
                className={classnames(
                  {
                    active: activeTab === "5",
                  },
                  "px-3"
                )}
                onClick={() => {
                  toggleTab("5");
                }}
              >
                Issues
              </NavLink>
            </NavItem>
            {!profileInfos?.is_linked && (
              <NavItem>
                <NavLink
                  href="#"
                  className={classnames(
                    {
                      active: activeTab === "6",
                    },
                    "px-3"
                  )}
                  onClick={() => {
                    toggleTab("6");
                  }}
                >
                  Quotas
                </NavLink>
              </NavItem>
            )}
          </Nav>
        </CardBody>
      </div>
      <TabContent activeTab={activeTab}>
        <TabPane tabId="1">
          <ProfileTabOverview
            getProfileInfos={getProfileInfos}
            profileInfos={profileInfos}
          />
        </TabPane>
        <TabPane tabId="2">
          {notifications && <Notification notifications={notifications} />}
        </TabPane>
        {!profileInfos?.is_linked && (
          <>
            <TabPane tabId="3">
              {grantAccess && (
                <GrantAccess
                  grantAccess={grantAccess}
                  getGrantAccess={getGrantAccess}
                />
              )}
            </TabPane>
            <TabPane tabId="4">
              {appliance && (
                <Appliance data={appliance} getAppliance={getAppliance} />
              )}
            </TabPane>
          </>
        )}
        <TabPane tabId="5">
          {issues && <Issues issues={issues} getIssues={getIssues} />}
        </TabPane>
        {!profileInfos?.is_linked && (
          <TabPane tabId="6">
            {tickets && <Tickets tickets={tickets} />}
          </TabPane>
        )}
      </TabContent>
    </>
  );
};

export default CustomerInfo;
