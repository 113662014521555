import React, { useState } from "react";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import { del } from "../../../../helpers/api_helper";
import IssuesDetail from "./IssuesDetail";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
  toastWarning,
} from "../../../../../common/utils/commonFunctions";
import IssueImg from "../../../../../assets/images/no-issue.webp";

const Issues = ({ issues, getIssues }) => {
  const sortedData = issues.sort((a, b) => {
    return new Date(b.updated) - new Date(a.updated);
  });
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [issueDetail, set_issueDetail] = useState();
  const [issueID, set_issueID] = useState();

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const deleteIssue = async () => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await del(`/account/issue?id=${issueID}`);
      if (result?.success) {
        toastSuccess(result?.message || "Issue Deleted");
        tog_standard5();
        getIssues();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {issueDetail && (
        <IssuesDetail
          issueDetail={issueDetail}
          issueID={issueID}
          getIssues={getIssues}
          modal_standard={modal_standard2}
          tog_standard={tog_standard2}
          setmodal_standard={setmodal_standard2}
        />
      )}
      {/* Delete Issue */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteIssue}
        text="Are you sure you want to delete issue?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <div
        style={{ maxHeight: "500px", overflowY: "overlay" }}
        className="special-card mt-5"
      >
        {sortedData.length > 0 && (
          <table className="table table-borderless table-hover table-centered m-0">
            <thead className="">
              <tr>
                <th>Subject</th>
                <th>Type</th>
                <th>Status</th>
                <th>Created Date</th>
                <th>Updated Date</th>
                <th></th>
                <th></th>
              </tr>
            </thead>
            <tbody>
              {sortedData?.map((item, index) => (
                <tr key={index}>
                  <td>{item?.subject}</td>
                  <td>{item?.type}</td>
                  <td>
                    {item?.status === "OPENED" ? (
                      <span className="badge bg-success">{item?.status}</span>
                    ) : item?.status === "CLOSED" ? (
                      <span className="badge bg-danger">{item?.status}</span>
                    ) : (
                      <span className="badge bg-info">WORK IN PROGRESS</span>
                    )}
                  </td>
                  <td>{item?.created}</td>
                  <td>{item?.updated}</td>
                  <td>
                    <button
                      onClick={() => {
                        set_issueDetail(item);
                        tog_standard2();
                        set_issueID(item?.id);
                      }}
                      className="btn btn-success btn-sm "
                    >
                      Detail
                    </button>
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        if (item?.status === "WIP") {
                          toastWarning(
                            "You can't delete the issue because its status is work in progress."
                          );
                        } else {
                          set_issueID(item?.id);
                          tog_standard5();
                        }
                      }}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        )}
        {sortedData?.length < 1 && (
          <React.Fragment>
            <div className="d-flex justify-content-center flex-column align-items-center">
              <img src={IssueImg} alt="No Issue" width={300} />
              <h4 className="text-center mt-5 ">There is no issue</h4>
            </div>
          </React.Fragment>
        )}
      </div>
    </React.Fragment>
  );
};

export default Issues;
