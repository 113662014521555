import React, { useEffect, useRef } from "react";
import {
  PDFViewer,
  Document,
  Page,
  View,
  Text,
  StyleSheet,
  Image,
  Link,
} from "@react-pdf/renderer";
import DonutChart from "./DonutChart";
import { toPng } from "html-to-image";
import bigLogo from "../../../../assets/images/logo_colors_lg.png";
import watermark from "../../../../assets/images/watermark.png";
import { styles } from "./PdfStyles";

const WebApplicationPdfReport = ({ data, imageUrl, isDemoAccount }) => {
  const profileInfo = JSON.parse(localStorage.getItem("profileInfo"));
  const total =
    data?.critical + data?.high + data?.medium + data?.low + data?.info;

  const parseHtmlToReact = (html) => {
    const doc = new DOMParser().parseFromString(html, "text/html");
    const body = doc.body;
    const views = [];

    const parseNode = (node, index) => {
      const uniqueKey = node.nodeName + index + "_customKey";
      switch (node.nodeName) {
        case "UL":
          return (
            <View key={uniqueKey} style={styles.ul}>
              {Array.from(node.childNodes).map((childNode, index) => (
                <React.Fragment key={index + "node"}>
                  {parseNode(childNode)}
                </React.Fragment>
              ))}
            </View>
          );
        case "LI":
          return (
            <View key={uniqueKey} style={styles.strong}>
              <Text>{node.textContent}</Text>
            </View>
          );
        case "BR":
          return <Text key={uniqueKey}>{"\n"}</Text>;
        case "br":
          return <Text key={uniqueKey}>{"\n"}</Text>;
        case "PRE":
          return (
            <Text key={uniqueKey} style={styles.pre}>
              {node.textContent}
            </Text>
          );
        case "STRONG":
          return (
            <Text key={uniqueKey} style={styles.strong}>
              {node.textContent}
            </Text>
          );
        case "SPAN":
          return (
            <Text key={uniqueKey} style={styles.span}>
              {node.textContent}
            </Text>
          );
        case "P":
          return (
            <View>
              <Text key={uniqueKey} style={styles.p}>
                {node.textContent}
              </Text>
            </View>
          );
        case "#text":
          return (
            <Text key={uniqueKey} style={styles.p}>
              {node.textContent}
            </Text>
          );
        default:
          return (
            <View>
              <Text key={uniqueKey} style={styles.p}>
                {node.textContent}
              </Text>
            </View>
          );
      }
    };

    body.childNodes.forEach((node, index) => {
      views.push(parseNode(node, index));
    });

    return views;
  };

  return (
    <Document>
      <Page size="A4" style={{ ...styles.onePage, position: "relative" }}>
        <Image
          src={watermark}
          style={{
            position: "absolute",
            zIndex: -1,
            top: 0,
            width: "126%",
          }}
        />
        {isDemoAccount && <Text style={styles.demoAccount}>TRIAL</Text>}
        <View style={styles.sectionOnePage}>
          <Text style={styles.onePageTitle}>{profileInfo}</Text>
          <Text style={styles.onePageTitle}>Web Application Report</Text>
          <View style={styles.tableOnePage}>
            <View style={styles.row}>
              <View style={styles.labelCellOnePage}>
                <Text>Target URL</Text>
              </View>
              <View style={styles.cellOnePage}>
                <Text>{data?.address}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.labelCellOnePage}>
                <Text>Scan Date</Text>
              </View>
              <View style={styles.cellOnePage}>
                <Text>{data?.date}</Text>
              </View>
            </View>
            <View style={styles.row}>
              <View style={styles.labelCellOnePage}>
                <Text>Report Date</Text>
              </View>
              <View style={styles.cellOnePage}>
                <Text>{data?.report_date}</Text>
              </View>
            </View>
          </View>
          {/* <Image src={bigLogo} /> */}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size="A4" style={{ ...styles.page, position: "relative" }}>
        {/* <Image src={bigLogo} style={styles.background} fixed /> */}
        <Image
          src={watermark}
          fixed
          style={{
            position: "absolute",
            zIndex: -1,
            top: 0,
            width: "126%",
          }}
        />
        {isDemoAccount && (
          <Text fixed style={styles.demoAccount}>
            TRIAL
          </Text>
        )}
        <View style={styles.section}>
          <Text style={styles.h1}>1. Executive Summary</Text>
          <View style={styles.boxesContainer}>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgTotal]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Total</Text>
                <Text style={[styles.score, styles.textTotal]}>{total}</Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgCritical]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Critical</Text>
                <Text style={[styles.score, styles.textCritical]}>
                  {data?.critical}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgHigh]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>High</Text>
                <Text style={[styles.score, styles.textHigh]}>
                  {data?.high}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgMedium]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Medium</Text>
                <Text style={[styles.score, styles.textMedium]}>
                  {data?.medium}
                </Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgLow]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Low</Text>
                <Text style={[styles.score, styles.textLow]}>{data?.low}</Text>
              </View>
            </View>
            <View style={styles.boxes}>
              <View style={[styles.backBox, styles.bgInfo]}></View>
              <View style={styles.frontBox}>
                <Text style={styles.title}>Info</Text>
                <Text style={[styles.score, styles.textInfo]}>
                  {data?.info}
                </Text>
              </View>
            </View>
          </View>
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
      <Page size="A4" style={{ ...styles.page, position: "relative" }}>
        {/* <Image src={bigLogo} style={styles.background} fixed /> */}
        <Image
          src={watermark}
          fixed
          style={{
            position: "absolute",
            zIndex: -1,
            top: 0,
            width: "126%",
          }}
        />
        {isDemoAccount && (
          <Text fixed style={styles.demoAccount}>
            TRIAL
          </Text>
        )}
        <View style={styles.section}>
          <Text style={styles.h1}>
            2. Discovered and Potential Vulnerabilities
          </Text>
          {data?.report?.map((reportItem, reportIndex) => {
            let parseDescription = parseHtmlToReact(reportItem?.description);
            let parseImpact = parseHtmlToReact(reportItem?.impact);
            let parseRecommendation = parseHtmlToReact(
              reportItem?.recommendation
            );
            let parseDetails = parseHtmlToReact(reportItem?.details);

            return (
              <View
                key={reportIndex}
                style={{ marginBottom: 10, marginTop: 25 }}
                break={reportIndex > 0 ? true : false}
              >
                <View style={styles.table}>
                  <View
                    style={{
                      ...styles.tableRow,
                      backgroundColor: "#edeef0",
                      padding: "2px 3px",
                    }}
                  >
                    <Text style={styles.h2}>
                      2.{reportIndex + 1} {reportItem?.name}
                    </Text>
                  </View>
                  <View
                    style={{
                      ...styles.tableRow,
                    }}
                  >
                    <View style={styles.tableTitleCellOne}>
                      {reportItem?.vuln_count !== null && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Vuln Count:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#FF4500",
                              color: "white",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {reportItem?.vuln_count}
                          </Text>
                        </View>
                      )}
                      {reportItem?.cvss_score !== null && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Cvss Score:
                          </Text>
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "#FF4500",
                              color: "white",
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            {reportItem?.cvss_score}
                          </Text>
                        </View>
                      )}
                    </View>
                    <View style={styles.tableTitleCellTwo}>
                      {reportItem?.type !== "" && (
                        <View
                          style={{
                            display: "flex",
                            flexDirection: "row",
                            alignItems: "center",
                            marginBottom: 1,
                          }}
                        >
                          <Text
                            style={{
                              fontSize: 10,
                              fontFamily: "Helvetica-Bold",
                            }}
                          >
                            Type:
                          </Text>
                          <Text
                            style={{
                              fontSize: 10,
                              marginLeft: 1,
                            }}
                          >
                            {reportItem?.type}
                          </Text>
                        </View>
                      )}
                      <View
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          marginBottom: 1,
                        }}
                      >
                        <Text
                          style={{
                            fontSize: 10,
                            fontFamily: "Helvetica-Bold",
                          }}
                        >
                          SSL:
                        </Text>
                        {reportItem?.use_ssl ? (
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "green",
                              color: "white",
                            }}
                          >
                            Yes
                          </Text>
                        ) : (
                          <Text
                            style={{
                              fontSize: 8,
                              marginLeft: 1,
                              padding: 2,
                              borderRadius: 3,
                              backgroundColor: "orange",
                              color: "white",
                            }}
                          >
                            No
                          </Text>
                        )}
                      </View>
                    </View>
                    {reportItem?.severity === 4 && (
                      <View
                        style={{
                          ...styles.bgCritical,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>CRITICAL</Text>
                      </View>
                    )}
                    {reportItem?.severity === 3 && (
                      <View
                        style={{
                          ...styles.bgHigh,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>HIGH</Text>
                      </View>
                    )}
                    {reportItem?.severity === 2 && (
                      <View
                        style={{
                          ...styles.bgMedium,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>MEDIUM</Text>
                      </View>
                    )}
                    {reportItem?.severity === 1 && (
                      <View
                        style={{
                          ...styles.bgLow,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>LOW</Text>
                      </View>
                    )}
                    {reportItem?.severity === 0 && (
                      <View
                        style={{
                          ...styles.bgInfo,
                          ...styles.tableTitleCellThree,
                        }}
                      >
                        <Text>INFO</Text>
                      </View>
                    )}
                  </View>
                  {reportItem?.description && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Description</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{parseDescription}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.impact !== "" && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Impact</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{parseImpact}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.recommendation !== "" && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Recommendation</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{parseRecommendation}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.url !== "" && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>URL</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{reportItem?.url}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.cvss2 !== "" && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Cvss Vector 2</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{reportItem?.cvss2}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.cvss3 !== "" && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Cvss Vector 3</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{reportItem?.cvss3}</Text>
                      </View>
                    </View>
                  )}
                  {reportItem?.details !== "" && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Attack Details</Text>
                      </View>
                      <View style={styles.tableCellRight}>
                        <Text>{parseDetails}</Text>
                      </View>
                    </View>
                  )}
                  {/* {reportItem?.tags?.length > 0 && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>Tags</Text>
                      </View>
                      <View
                        style={{
                          ...styles.tableCellRight,
                          flexDirection: "row",
                        }}
                      >
                        {reportItem?.tags.map((tagItem, tagIndex) => (
                          <View
                            key={tagIndex + "tags"}
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginRight: 1,
                              marginBottom: 1,
                              fontSize: 8,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                backgroundColor: "#8f1860",
                                borderRadius: 4,
                                padding: 3,
                                marginRight: 1,
                              }}
                            >
                              <Text style={{ color: "white" }}>{tagItem}</Text>
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  )} */}
                  {reportItem?.refs?.length > 0 && (
                    <View style={{ ...styles.tableRow }}>
                      <View style={styles.tableCellLeft}>
                        <Text>References</Text>
                      </View>
                      <View
                        style={{
                          ...styles.tableCellRight,
                          flexDirection: "row",
                        }}
                      >
                        {reportItem?.refs.map((refItem, refIndex) => (
                          <View
                            key={refIndex + "refs"}
                            style={{
                              flexDirection: "row",
                              alignItems: "center",
                              marginRight: 1,
                              marginBottom: 1,
                              fontSize: 8,
                            }}
                          >
                            <View
                              style={{
                                flexDirection: "row",
                                alignItems: "center",
                                backgroundColor: "#8f1860",
                                borderRadius: 4,
                                padding: 3,
                                marginRight: 1,
                              }}
                            >
                              <Link
                                style={{ textDecoration: "none" }}
                                src={refItem?.url}
                              >
                                <Text style={{ color: "white" }}>
                                  {refItem?.name}
                                </Text>
                              </Link>
                            </View>
                          </View>
                        ))}
                      </View>
                    </View>
                  )}
                </View>
              </View>
            );
          })}
        </View>
        <Text
          style={styles.pageNumber}
          render={({ pageNumber, totalPages }) =>
            `${pageNumber} of ${totalPages}`
          }
          fixed
        />
      </Page>
    </Document>
  );
};

export default WebApplicationPdfReport;
