import React from "react";
import logoSm from "../assets/images/logo_colors_sm.svg";
const DateIconComponent = () => {
  return (
    <div>
      <div className="mt-4 mt-md-5 text-center">
        <p className="mb-0">
          © {new Date().getFullYear()}
          <img
            style={{ width: "15px", height: "15px" }}
            className="ms-1 mb-1 "
            src={logoSm}
            alt="logo"
          />{" "}
          PentestBX
        </p>
      </div>
    </div>
  );
};

export default DateIconComponent;
