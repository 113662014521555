import React from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useHistory } from "react-router-dom";

const CwesComponent = ({ cwes, getThreatIntelligenceCwes, cwePage }) => {
  const router = useHistory();

  const getNumberAfterCWE = (ID) => {
    if (typeof ID === "string" && ID.includes("CWE")) {
      const numberAfterCWE = parseInt(ID.substring(ID.indexOf("CWE") + 4));
      if (!isNaN(numberAfterCWE)) {
        return numberAfterCWE;
      }
    }
    return undefined;
  };

  return (
    <React.Fragment>
      <div className="special-card ">
        <div className="card-header with-border">
          <h3 className="card-title">Categories (CWE)</h3>
        </div>
        <div className="card-body table-responsive no-padding">
          <table className="table table-hover table-striped">
            <tbody>
              {cwes?.map((cwe, cweIndex) => {
                let afterCweNumber = getNumberAfterCWE(cwe?.id);
                return (
                  <tr key={cweIndex}>
                    <td>{cwe?.id || "-"}</td>
                    <td className="">{cwe?.name || "No name"}</td>
                    <td className="text-center">
                      <div className="btn-group">
                        {afterCweNumber && (
                          <a
                            className="btn btn-default-cves btn-sm subscribe"
                            href={`https://cwe.mitre.org/data/definitions/${afterCweNumber}.html`}
                            target="_blank"
                            rel="noreferrer"
                          >
                            <i className="mdi mdi-arrow-top-right-bold-box-outline" />{" "}
                            Mitre
                          </a>
                        )}
                        <button
                          onClick={() =>
                            router.push(
                              `/cyber-watch/threat-intelligence/cves?page=1&cwe=${cwe?.id}`
                            )
                          }
                          type="button"
                          className="btn btn-default-cves btn-sm"
                        >
                          <i className="mdi mdi-shield-half-full" /> View CVE
                        </button>
                      </div>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </table>
          {cwes?.length < 1 && (
            <div className="w-100 bg-info py-2 text-center text-white ">
              No cwes
            </div>
          )}
          <Pagination
            className="d-flex align-items-center justify-content-center "
            aria-label="Page navigation "
          >
            {cwePage > 1 && (
              <PaginationItem>
                <PaginationLink
                  onClick={() => getThreatIntelligenceCwes(Number(cwePage) - 1)}
                  tabIndex={-1}
                >
                  Previous
                </PaginationLink>
              </PaginationItem>
            )}
            <PaginationItem active>
              <PaginationLink>{Number(cwePage)}</PaginationLink>
            </PaginationItem>
            <PaginationItem>
              <PaginationLink
                onClick={() => getThreatIntelligenceCwes(Number(cwePage) + 1)}
              >
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </div>
      </div>
    </React.Fragment>
  );
};

export default CwesComponent;
