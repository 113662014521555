import classNames from "classnames";
import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import ExploitDetailsRepositories from "./ExploitDetailsRepositories";

const CvesComponentExploitDetails = ({ exploits, cvesID }) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <React.Fragment>
      <Nav tabs className="nav-tabs-custom nav-justified mt-1 ">
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={classNames({
              active: customActiveTab === "1",
            })}
            onClick={() => {
              toggleCustom("1");
            }}
          >
            <span className="d-block ">
              <i style={{ fontSize: "22px" }} className="mdi mdi-github"></i>
            </span>
            <span className="d-none d-sm-block">Repositories</span>
          </NavLink>
        </NavItem>
      </Nav>
      <div style={{ maxHeight: "300px", overflowY: "overlay" }}>
        <TabContent activeTab={customActiveTab} className="p-3 ">
          <TabPane tabId="1">
            <Row>
              <Col sm="12">
                <ExploitDetailsRepositories
                  repositories={exploits}
                  cvesID={cvesID}
                />
              </Col>
            </Row>
          </TabPane>
        </TabContent>
      </div>
    </React.Fragment>
  );
};

export default CvesComponentExploitDetails;
