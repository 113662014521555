import React, { useRef, useState } from "react";
import { Modal, Badge } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import { motion, AnimatePresence } from "framer-motion";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";

const AddPrivateHost = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  groupList,
  moduleID,
  limit,
  getHostsAsset,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const fileInputRef = useRef(null);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const [addLoading, set_addLoading] = useState(false);
  const [addPrivateLoading, setAddPrivateLoading] = useState(false);

  const [hosts, set_hosts] = useState([]);
  const [hostInput, setHostInput] = useState("");
  const [groupID, set_groupID] = useState(groupList[0]?.id);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const handleRemoveAddress = async (tag, index) => {
    const updatedTags = [...hosts];
    updatedTags.splice(index, 1);
    set_hosts(updatedTags);
  };

  const sendHostsAsset = async (array) => {
    let tempArray = hosts;
    tempArray = hosts.concat(array);
    set_hosts(tempArray);
  };

  const addHostsAsset = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      id: moduleID,
      group_id: Number(groupID),
      assets: hosts,
    };
    try {
      let result = await put("/customer/module/asset", data);
      if (result?.success) {
        toastSuccess(result?.message || "Hosts Added");
        tog_standard5();
        tog_standard();
        getHostsAsset();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  const parseCIDR = (CIDR) => {
    var beg = CIDR.substr(CIDR, CIDR.indexOf("/"));
    var end = beg;
    var off = (1 << (32 - parseInt(CIDR.substr(CIDR.indexOf("/") + 1)))) - 1;
    var sub = beg.split(".").map(function (a) {
      return parseInt(a);
    });
    var buf = new ArrayBuffer(4);
    var i32 = new Uint32Array(buf);
    i32[0] = (sub[0] << 24) + (sub[1] << 16) + (sub[2] << 8) + sub[3] + off;
    var end = Array.apply([], new Uint8Array(buf)).reverse().join(".");
    //Wildcard ve broadcast i cikar
    var ipParts = beg.split(".");
    beg =
      ipParts[0] +
      "." +
      ipParts[1] +
      "." +
      ipParts[2] +
      "." +
      (parseInt(ipParts[3], 10) + 1);
    var ipParts = end.split(".");
    end =
      ipParts[0] +
      "." +
      ipParts[1] +
      "." +
      ipParts[2] +
      "." +
      (parseInt(ipParts[3], 10) - 1);
    return [beg, end];
  };

  const ipToHex = (ipAddr) => {
    var match = /(\d+\.\d+\.\d+\.\d+)/.exec(ipAddr);
    var output = "";
    var matchText = match[1];
    var ipParts = matchText.split(".");
    var p3 = parseInt(ipParts[3], 10);
    var p3x = p3.toString(16);
    var p2 = parseInt(ipParts[2], 10);
    var p2x = p2.toString(16);
    var p1 = parseInt(ipParts[1], 10);
    var p1x = p1.toString(16);
    var p0 = parseInt(ipParts[0], 10);
    var p0x = p0.toString(16);
    var dec = p3 + p2 * 256 + p1 * 256 * 256 + p0 * 256 * 256 * 256;
    var hex = dec.toString(16);
    function pad2(hex) {
      while (hex.length < 2) {
        hex = "0" + hex;
      }
      return hex;
    }
    function pad8(hex) {
      while (hex.length < 8) {
        hex = "0" + hex;
      }
      return hex;
    }
    hex = "0x" + pad8(hex);
    output +=
      pad2(p0x) +
      "." +
      pad2(p1x) +
      "." +
      pad2(p2x) +
      "." +
      pad2(p3x) +
      " (" +
      hex +
      ")";
    return parseInt(hex);
  };

  const ipRangeToList = (ipv4_1, ipv4_2) => {
    var startIp = ipToHex(ipv4_1),
      endIp = ipToHex(ipv4_2);
    var temp,
      list = [],
      str;
    for (var i = startIp; i <= endIp; i++) {
      temp = i.toString(16);
      str = "";
      if (temp.length == 7) {
        temp = "0" + temp;
      }
      for (var k = temp.length - 1; k >= 0; k -= 2) {
        str = parseInt(temp[k - 1] + "" + temp[k], 16) + "." + str;
      }
      list.push(str.substring(0, str.length - 1));
    }
    return list;
  };

  const filterIPsWithEndingZero = (ipList) => {
    const filteredList = ipList.filter((ip) => !ip.endsWith(".0"));
    return filteredList;
  };

  const handleGenerateIPsPrivate = () => {
    setAddPrivateLoading(true);
    let result = addIPtoPrivate(hostInput);
    sendHostsAsset(result);
  };
  //IP to cidr notation, new_u, useradd_iptag->inventory
  //________________________________________________________
  const addIPtoPrivate = (listIP) => {
    const ipParts = listIP.split(",");
    let addIPList = [];

    for (const ip of ipParts) {
      if (ip.includes("-")) {
        const range = ip.split("-");
        const rangeList = ipRangeToList(range[0], range[1]);
        addIPList = addIPList.concat(rangeList);
      } else if (ip.includes("/")) {
        const rangeIP = parseCIDR(ip);
        const ipList = ipRangeToList(rangeIP[0], rangeIP[1]);
        const filteredIPs = filterIPsWithEndingZero(ipList);
        addIPList = addIPList.concat(filteredIPs);
      } else {
        addIPList.push(ip);
      }
    }

    const uniqueAddIPList = [...new Set(addIPList)];

    return uniqueAddIPList.map((ip) => ({ address: ip, is_private: true }));
  };

  const handleTagInputChange = (e) => {
    setHostInput(e.target.value);
  };

  const convertToCustomFormat = (data) => {
    const lines = data.split("\n"); // Her bir satırı ayırma
    let linesSplice = lines.splice(1, lines.length - 1);
    let filteredLines = linesSplice.filter(
      (item) => item.trim() !== "" && item.trim() !== "\r"
    );
    const convertedList = filteredLines.map((line) => {
      const [address, isPrivate] = line.split(";"); // Satırdaki öğeleri ayırma

      return {
        address: address.trim(), // IP adresi
        is_private: isPrivate === "internal\r" ? true : false, // Boolean değer kontrolü
      };
    });
    return convertedList;
  };

  const handleFileRead = (e) => {
    const content = e.target.result;
    const result = convertToCustomFormat(content);
    sendHostsAsset(result);
  };

  const handleFileReadDrop = (file) => {
    const reader = new FileReader();

    reader.onload = (event) => {
      const content = event.target.result;
      const result = convertToCustomFormat(content);
      sendHostsAsset(result);
    };

    reader.readAsText(file);
  };

  const handleFileChosen = (file) => {
    const reader = new FileReader();
    reader.onload = handleFileRead;
    reader.readAsText(file);
  };

  const handleChange = (e) => {
    e.preventDefault();
    const selectedFile = e.target.files[0];
    if (selectedFile) {
      handleFileChosen(selectedFile);
      fileInputRef.current.value = "";
    }
  };

  const handleDrop = (e) => {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    handleFileReadDrop(file);
  };

  const handleDragOver = (e) => {
    e.preventDefault();
  };

  const downloadExampleCSV = () => {
    // Örnek CSV dosyasının URL'sini oluştur
    const downloadUrl = "/target_ip_address_sample_file.csv";

    // Dosyayı indirme işlemi için bir link oluştur
    const link = document.createElement("a");
    link.href = downloadUrl;
    link.download = "target_ip_address_sample_file.csv"; // İndirilen dosyanın adı

    // Oluşturulan linki tıkla ve dosyayı indir
    link.click();
  };

  return (
    <React.Fragment>
      {/* Add Hosts */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addHostsAsset}
        text="Are you sure you want to add hosts?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        size="lg"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Private Hosts
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="col-xl-6 col-md-6">
            <div className="form-group mb-3">
              <label>Target Group</label>
              <div className="input-group mb-3">
                <select
                  name="new_ip_target_group"
                  className="form-select"
                  onChange={(e) => set_groupID(e.target.value)}
                >
                  {groupList?.map((item, index) => (
                    <option
                      key={item.id}
                      className={
                        item?.severity === "CRITICAL"
                          ? "text-danger"
                          : item?.severity === "HIGH"
                          ? "text-warning"
                          : item?.severity === "MEDIUM"
                          ? "text-info"
                          : "text-success"
                      }
                      value={item?.id}
                    >
                      {item?.name} [{item?.severity}]
                    </option>
                  ))}
                </select>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="col-12 mb-3 ">
              <div
                onDrop={handleDrop}
                onDragOver={handleDragOver}
                className="file-upload"
              >
                <span></span>
                <label htmlFor="file-input" className="file-label">
                  <input
                    ref={fileInputRef}
                    id="file-input"
                    type="file"
                    className="file-input"
                    accept=".csv"
                    onChange={handleChange}
                  />
                  <div className="d-flex flex-row font-size-16 mt-1 ">
                    <i className="mdi mdi-file-upload" />{" "}
                    <div className="d-flex flex-row ">
                      <span>Drag your designs here or</span>
                      <span className="file-button ms-1 ">click to upload</span>
                    </div>
                  </div>
                </label>
                <button
                  onClick={downloadExampleCSV}
                  className="btn btn-success btn-sm me-1  "
                >
                  Example CSV Download
                </button>
              </div>
            </div>
            <div className="form-group mb-3">
              <label>New IP or Subnet (private ip range)</label>
              <div className="input-group mb-3">
                <input
                  value={hostInput}
                  onChange={handleTagInputChange}
                  type="text"
                  className="form-control"
                  id="systemscan_newsubnetinput"
                  placeholder="Ex: 192.168.1.0/24 or 192.168.3.16-192.168.3.18 or 10.0.0.1 or 10.0.0.2 ..."
                />
                <div className="input-group-append">
                  <button
                    className="btn btn-success"
                    id="systemscan_newsubnetbtn"
                    type="button"
                    onClick={handleGenerateIPsPrivate}
                  >
                    ADD
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="col-xl-12 col-md-12">
            <div className="form-group mb-3">
              <div className="d-flex justify-content-between align-items-center ">
                <label>
                  Ip List (limit={Number(limit) - Number(hosts.length)})
                </label>
                <button
                  onClick={() => {
                    set_hosts([]);
                  }}
                  className="btn btn-outline-danger btn-sm  "
                >
                  Remove All
                </button>
              </div>
              <div
                className="border border-1 border-primary d-flex flex-wrap gap-1   p-1 rounded-3   "
                style={{
                  maxHeight: "280px",
                  overflowY: "scroll",
                  minHeight: "100px",
                }}
              >
                <AnimatePresence>
                  {hosts?.map((tag, index) => (
                    <motion.div
                      initial={{ height: 0, opacity: 0 }}
                      animate={{ height: "auto", opacity: 1 }}
                      exit={{ height: 0, opacity: 0 }}
                      transition={{ duration: 0.3 }}
                      key={tag.address}
                      className=" "
                      // className="w-25 mb-1 "
                      // style={{ width: "20%" }}
                    >
                      <Badge
                        style={{ maxHeight: "25px" }}
                        className={`fs-6 w-100   tag  bg-${
                          tag.is_private === false ? "danger" : "primary"
                        } mr-1`}
                        title={` ${
                          tag.is_private === true ? "Private" : "Public"
                        } ${tag.address}`}
                      >
                        {tag.is_private === true ? (
                          <i className="float-start mdi mdi-ip-network-outline address-white me-1" />
                        ) : (
                          <i className="float-start mdi mdi-weather-cloudy-alert address-white me-1" />
                        )}

                        <span className="mx-1">{tag?.address}</span>
                        <span
                          className={`float-end remove-tag mdi mdi-delete  badge-danger me-1   ${
                            tag.is_private === false
                              ? "public-tag"
                              : "private-tag"
                          }`}
                          onClick={() => handleRemoveAddress(tag, index)}
                        />
                      </Badge>
                    </motion.div>
                  ))}
                </AnimatePresence>
              </div>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            onClick={tog_standard5}
            type="button"
            data-delete-groupbtn="deneme"
            className="btn btn-primary text-truncate"
          >
            <i className="mdi mdi-plus me-1" /> Add
          </button>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddPrivateHost;
