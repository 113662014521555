import React from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row } from "reactstrap";

//import images
import logo from "../../assets/images/logo_colors_sm.svg";

const Maintenance = () => {
  const sendMail = (mailSubject, mailMessage) => {
    return (window.location.href =
      "mailto:" +
      "info@pentestbx.com" +
      "?subject=" +
      encodeURIComponent(mailSubject) +
      "&body=" +
      encodeURIComponent(mailMessage));
  };
  return (
    <React.Fragment>
      <div className="bg-soft-light min-vh-100 py-5">
        <div className="py-4">
          <Container>
            <Row>
              <Col lg={12}>
                <div className="text-center">
                  <div className="mb-5">
                    <Link to="/dashboard">
                      <img src={logo} alt="" height="30" className="me-1" />
                      <span className="logo-txt text-dark font-size-22">
                        PentestBX
                      </span>
                    </Link>
                  </div>

                  <div className="maintenance-cog-icon text-primary pt-4">
                    <i className="mdi mdi-cog spin-right display-3"></i>
                    <i className="mdi mdi-cog spin-left display-4 cog-icon"></i>
                  </div>
                  <h3 className="mt-4">Site is Under Maintenance</h3>

                  <div className="mt-4">
                    <h1>We'll be back soon!</h1>
                    <p className="mt-5 fs-3 fw-normal  ">
                      Sorry for the inconvenience but we're performing some
                      maintenance at the moment.If you need to you can always{" "}
                      <span
                        style={{ cursor: "pointer" }}
                        onClick={() =>
                          sendMail(
                            "Contact",
                            "Hello PentestBX  \nPlease contact me. \n \nRegards, \n"
                          )
                        }
                        className="text-danger fw-medium  "
                      >
                        contact us
                      </span>
                      ,otherwise we'll be back online shortly!
                    </p>
                    <div className="d-flex flex-row align-items-center fs-3 fw-normal ms-4 ">
                      <span className="custom-line"></span>
                      <p className="d-inline ms-2 ">PentestBX Team</p>
                    </div>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </React.Fragment>
  );
};

export default Maintenance;
