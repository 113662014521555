import React, { useState } from "react";
import { patch } from "../../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const WebApplicationTickets = ({ tickets }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  const [updateLoading, setUpdateLoading] = useState(false);

  const [modal_standard3, setmodal_standard3] = useState(false);
  // Tickets
  const [stock, set_stock] = useState(tickets?.stock);
  const [handled, set_handled] = useState(tickets?.handled);

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const updateTicket = async () => {
    paceFunction(true);
    setUpdateLoading(true);
    let ticketData = {
      id: tickets?.id,
      stock,
      handled,
    };
    try {
      let result = await patch("/admin/customer/module/tickets", ticketData);
      if (result?.success) {
        toastSuccess(result?.message || "Ticket Updated");
        tog_standard3();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Update Ticket */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={updateTicket}
        text="Are you sure you want to update ticket?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      <div className="row mt-2 ">
        <div className="col-xl-6 col-md-6">
          <div className="form-floating mb-3">
            <input
              className="form-control"
              type="number"
              name="scanperiod"
              min={1}
              step={1}
              defaultValue={stock}
              autoComplete="off"
              onChange={(e) => set_stock(e.target.value)}
            />
            <label>Quota</label>
          </div>
        </div>
        <div className="col-xl-6 col-md-6">
          <div className="form-floating mb-3">
            <input
              className="form-control"
              name="numberof_asset"
              type="number"
              min={1}
              step={1}
              defaultValue={handled}
              autoComplete="off"
              onChange={(e) => set_handled(e.target.value)}
              disabled
            />
            <label>Handled</label>
          </div>
        </div>
      </div>
      <div className="col border-end d-flex justify-content-center  align-items-end">
        <div className="d-flex flex-wrap  gap-2">
          <button
            className="btn btn-outline-primary"
            id="updateWebAppBtn"
            type="button"
            onClick={tog_standard3}
          >
            <i className="bx bx-check-double label-icon" /> Update
          </button>
        </div>
      </div>
    </React.Fragment>
  );
};

export default WebApplicationTickets;
