import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { del, get, post, put, patch } from "../helpers/api_helper";
import FeatherIcon from "feather-icons-react";
import errorFunctionComponent from "../../common/errorFunctionComponent";
// Import menuDropdown
import LanguageDropdown from "../CommonForBoth/TopbarDropdown/LanguageDropdown";
import NotificationDropdown from "../CommonForBoth/TopbarDropdown/NotificationDropdown";
import ProfileMenu from "../CommonForBoth/TopbarDropdown/ProfileMenu";
import LightDark from "../CommonForBoth/Menus/LightDark";

// import images
import logoSvg from "../../assets/images/logo-sm.svg";
import logoSvgSm from "../../assets/images/logo_colors_sm.svg";
import logoSvgLg from "../../assets/images/logo_colors_lg.svg";
import logoWhiteSvgSm from "../../assets/images/logo_white_sm.svg";
import logoWhiteSvgLg from "../../assets/images/logo_white_lg.svg";
import github from "../../assets/images/brands/github.png";
import bitbucket from "../../assets/images/brands/bitbucket.png";
import dribbble from "../../assets/images/brands/dribbble.png";
import dropbox from "../../assets/images/brands/dropbox.png";
import mail_chimp from "../../assets/images/brands/mail_chimp.png";
import slack from "../../assets/images/brands/slack.png";
import { renderComponent, renderComponent2 } from "../../store/actions";
//i18n
import { withTranslation } from "react-i18next";
//redux
import { useSelector, useDispatch } from "react-redux";

// Redux Store
import {
  showRightSidebarAction,
  toggleLeftmenu,
  changeSidebarType,
  changelayoutMode,
  isApplianceReduxFunction,
  isDemoAccountFunction,
} from "../../store/actions";
import Screenshot from "../../pages/Customer/ReportIssuePage/Screenshot";
import { remainingDaysCalculation } from "../../common/utils/commonFunctions";
import classNames from "classnames";

const Header = (props) => {
  const { errorFunctionNotSwal } = errorFunctionComponent();
  const [notifications, set_notifications] = useState();
  const { render, render2 } = useSelector((state) => ({
    render: state.render.render,
    render2: state.render.render2,
  }));
  const storage = JSON.parse(localStorage.getItem("authUser"));
  const profileInfo = JSON.parse(localStorage.getItem("profileInfo"));
  const dispatch = useDispatch();

  const { showRightSidebar } = useSelector((state) => ({
    showRightSidebar: state.Layout.ShowRightSidebar,
  }));
  const [isApplianceConnect, setIsApplianceConnect] = useState(false);
  const [isAppliance, setIsAppliance] = useState(false);
  const [isClick, setClick] = useState(true);
  const [remainingDay, set_remainingDay] = useState();
  const [profilData, set_profilData] = useState();

  const { onChangeLayoutMode } = props;

  /*** Sidebar menu icon and default menu set */
  function tToggle() {
    var body = document.body;
    setClick(!isClick);
    if (isClick === true) {
      body.classList.add("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "sm");
    } else {
      body.classList.remove("sidebar-enable");
      document.body.setAttribute("data-sidebar-size", "lg");
    }
  }
  function toggleFullScreen() {
    var doc = window.document;
    var docEl = doc.documentElement;

    var requestFullScreen =
      docEl.requestFullscreen ||
      docEl.mozRequestFullScreen ||
      docEl.webkitRequestFullScreen ||
      docEl.msRequestFullscreen;
    var cancelFullScreen =
      doc.exitFullscreen ||
      doc.mozCancelFullScreen ||
      doc.webkitExitFullscreen ||
      doc.msExitFullscreen;

    if (
      !doc.fullscreenElement &&
      !doc.mozFullScreenElement &&
      !doc.webkitFullscreenElement &&
      !doc.msFullscreenElement
    ) {
      requestFullScreen.call(docEl);
    } else {
      cancelFullScreen.call(doc);
    }
  }

  const getAppliance = async () => {
    try {
      let result = await get("/customer/settings/endpoint");
      if (result?.success) {
        dispatch(isApplianceReduxFunction(true));
        setIsAppliance(true);
        setIsApplianceConnect(result?.data?.is_connect);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      // errorFunctionNotSwal(error);
      if (!error?.response?.data?.success) {
        dispatch(isApplianceReduxFunction(false));
        setIsAppliance(false);
      }
    }
  };

  useEffect(() => {
    if (storage.value.role === "CUSTOMER") {
      getAppliance();
    }
  }, [render2]);

  const getNotifications = async () => {
    try {
      let result = await get("/customer/settings/notifications");
      if (result?.success) {
        set_notifications(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunctionNotSwal(error);
    }
  };
  const getProfileInfos = async () => {
    try {
      let result = await get("/customer/settings/account/profile");
      if (result?.success) {
        localStorage.setItem(
          "profileInfo",
          JSON.stringify(result?.data?.company_name)
        );
        set_remainingDay(
          remainingDaysCalculation(
            result?.data?.subscription_renewal,
            result?.data?.dateFormat
          )
        );
        set_profilData(result.data);
        dispatch(isDemoAccountFunction(result?.data?.is_demo));
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunctionNotSwal(error);
    }
  };

  useEffect(() => {
    if (storage.value.role === "CUSTOMER") {
      getNotifications();
    }
  }, [render]);

  useEffect(() => {
    if (
      storage.value.role === "CUSTOMER"
      // && !profileInfo
    ) {
      getProfileInfos();
    }
  }, []);

  return (
    <React.Fragment>
      <header id="page-topbar">
        <div
          //  style={{ backgroundColor: "#fbfaff" }}
          className="navbar-header"
        >
          <div className="d-flex">
            <div className="navbar-brand-box">
              <Link to="/dashboard" className="logo logo-light">
                <span className="logo-sm">
                  <img src={logoWhiteSvgSm} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoWhiteSvgLg} alt="" height="32" />{" "}
                  {/* <span className="logo-txt">Minia</span> */}
                </span>
              </Link>

              <Link to="/dashboard" className="logo logo-dark">
                <span className="logo-sm">
                  <img src={logoSvgSm} alt="" height="24" />
                </span>
                <span className="logo-lg">
                  <img src={logoSvgLg} alt="" height="32" />{" "}
                  {/* <span className="logo-txt">Minia</span> */}
                </span>
              </Link>
            </div>

            <button
              onClick={() => {
                tToggle();
              }}
              type="button"
              className="btn btn-sm px-3 font-size-16 header-item"
              id="vertical-menu-btn"
            >
              <i className="fa fa-fw fa-bars"></i>
            </button>

            {/* <form className="app-search d-none d-lg-block">
              <div className="position-relative">
                <input type="text" className="form-control" placeholder="Search..." />
                <button className="btn btn-primary" type="button"><i className="bx bx-search-alt align-middle"></i></button>
              </div>
            </form> */}
            {storage.value.role === "CUSTOMER" && profileInfo && (
              <div className="d-flex  align-items-center ">
                <h3
                  style={{ fontSize: "17px" }}
                  className="badge bg-primary mt-2 "
                >
                  {profileInfo}
                </h3>
              </div>
            )}
          </div>

          {/* <div className="d-flex">
            <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                type="button"
                className="btn header-item"
                id="page-header-search-dropdown"
                data-bs-toggle="dropdown"
                aria-haspopup="true"
                aria-expanded="false"
              >
                <FeatherIcon icon="search" className="icon-lg" />
              </button>
              <div
                className="dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Search Result"
                      />

                      <button className="btn btn-primary" type="submit">
                        <i className="mdi mdi-magnify"></i>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div> */}
          <div className="d-flex">
            {/* <div className="dropdown d-inline-block d-lg-none ms-2">
              <button
                onClick={() => {
                  setsearch(!search);
                }}
                type="button"
                className="btn header-item noti-icon "
                id="page-header-search-dropdown"
              >
                <i className="mdi mdi-magnify" />
              </button>
              <div
                className={
                  search
                    ? "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0 show"
                    : "dropdown-menu dropdown-menu-lg dropdown-menu-end p-0"
                }
                aria-labelledby="page-header-search-dropdown"
              >
                <form className="p-3">
                  <div className="form-group m-0">
                    <div className="input-group">
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Search ..."
                        aria-label="Recipient's username"
                      />
                      <div className="input-group-append">
                        <button className="btn btn-primary" type="submit">
                          <i className="mdi mdi-magnify" />
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div> */}
            {remainingDay !== undefined && remainingDay < 0 && (
              <div className="d-flex justify-content-center align-items-center me-2">
                <div
                  title="Number of days until your membership is deleted"
                  className={classNames("h-auto badge", {
                    "bg-critical-warning":
                      profilData.account_deletion_remaining <= 7,
                    "bg-high-warning":
                      profilData.account_deletion_remaining > 7 &&
                      profilData.account_deletion_remaining <= 14,
                    "bg-warning":
                      profilData.account_deletion_remaining > 14 &&
                      profilData.account_deletion_remaining <= 30,
                  })}
                >
                  <div className="d-flex justify-content-center">
                    <span
                      style={{ fontSize: "17px" }}
                      className="text-white text-center fw-bold"
                    >
                      {profilData.account_deletion_remaining} d
                    </span>
                  </div>
                </div>
              </div>
            )}
            {remainingDay !== undefined &&
              remainingDay >= 0 &&
              remainingDay <= 30 && (
                <div className="d-flex justify-content-center align-items-center me-2">
                  <div
                    title="Number of days until your subscription expires"
                    className={classNames("h-auto badge", {
                      "bg-critical-warning": remainingDay <= 7,
                      "bg-high-warning": remainingDay > 7 && remainingDay <= 14,
                      "bg-warning": remainingDay > 14 && remainingDay <= 30,
                    })}
                  >
                    <div className="d-flex justify-content-center">
                      <span
                        style={{ fontSize: "17px" }}
                        className="text-white text-center fw-bold"
                      >
                        {remainingDay} d
                      </span>
                    </div>
                  </div>
                </div>
              )}
            {storage.value.role === "CUSTOMER" && isAppliance && (
              <div className="d-flex justify-content-center  align-items-center ">
                {isApplianceConnect ? (
                  <i
                    title="Appliance Status"
                    className="mdi mdi-lan-connect me-3 text-success fs-4 "
                  />
                ) : (
                  <i
                    title="Appliance Status"
                    className="mdi mdi-lan-disconnect me-3 text-danger fs-4 "
                  />
                )}
              </div>
            )}
            <Screenshot />
            {/* <LanguageDropdown /> */}

            {/* light / dark mode */}
            <LightDark
              layoutMode={props["layoutMode"]}
              onChangeLayoutMode={onChangeLayoutMode}
            />

            {/* <Dropdown
              className="d-none d-lg-inline-block ms-1"
              isOpen={socialDrp}
              toggle={() => {
                setsocialDrp(!socialDrp);
              }}
            >
              <DropdownToggle
                className="btn header-item noti-icon "
                tag="button"
              >
                <FeatherIcon icon="grid" className="icon-lg" />
              </DropdownToggle>
              <DropdownMenu className="dropdown-menu-lg dropdown-menu-end">
                <div className="p-2">
                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={github} alt="Github" />
                        <span>GitHub</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={bitbucket} alt="bitbucket" />
                        <span>Bitbucket</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dribbble} alt="dribbble" />
                        <span>Dribbble</span>
                      </Link>
                    </Col>
                  </Row>

                  <Row className="g-0">
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={dropbox} alt="dropbox" />
                        <span>Dropbox</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={mail_chimp} alt="mail_chimp" />
                        <span>Mail Chimp</span>
                      </Link>
                    </Col>
                    <Col>
                      <Link className="dropdown-icon-item" to="#">
                        <img src={slack} alt="slack" />
                        <span>Slack</span>
                      </Link>
                    </Col>
                  </Row>
                </div>
              </DropdownMenu>
            </Dropdown> */}

            <button
              onClick={() => {
                toggleFullScreen();
              }}
              type="button"
              className="btn header-item noti-icon "
              id="page-header-search-dropdown"
            >
              {/* <i className="mdi mdi-fullscreen" /> */}
              <FeatherIcon icon="maximize" className="icon-lg" />
            </button>
            {notifications && (
              <NotificationDropdown notifications={notifications} />
            )}
            {/* <div
              onClick={() => {
                dispatch(showRightSidebarAction(!showRightSidebar));
              }}
              className="dropdown d-inline-block"
            >
              <button
                type="button"
                className="btn header-item noti-icon right-bar-toggle "
              >
                <FeatherIcon icon="settings" className="icon-lg" />
              </button>
            </div> */}
            <ProfileMenu />
          </div>
        </div>
      </header>
    </React.Fragment>
  );
};

Header.propTypes = {
  changeSidebarType: PropTypes.func,
  leftMenu: PropTypes.any,
  showRightSidebar: PropTypes.any,
  showRightSidebarAction: PropTypes.func,
  t: PropTypes.any,
  toggleLeftmenu: PropTypes.func,
  changelayoutMode: PropTypes.func,
  layoutMode: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { layoutType, showRightSidebar, leftMenu, layoutMode } = state.Layout;
  return { layoutType, showRightSidebar, leftMenu, layoutMode };
};

export default connect(mapStatetoProps, {
  showRightSidebarAction,
  changelayoutMode,
  toggleLeftmenu,
  changeSidebarType,
})(withTranslation()(Header));
