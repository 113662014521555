import React from "react";
import { Col, Modal, Row } from "reactstrap";
import { formattedDateWithHours } from "../../../../common/utils/commonFunctions";
import classNames from "classnames";

const ScanInfoModal = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  data,
}) => {
  const correctedJsonString = data?.data
    .replace(/'/g, '"')
    .replace(/False/g, "false")
    .replace(/True/g, "true")
    .replace(/None/g, "null")
    .replace(/Null/g, "null");
  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Scan Info
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row font-size-16">
            <div className="d-flex align-items-center gap-2 my-2 col-6">
              <span className="badge bg-secondary">Date :</span>
              <span className="font-size-13 fw-bold">
                {formattedDateWithHours(data?.date)}
              </span>
            </div>
            <div className="d-flex align-items-center gap-2 my-2 col-6">
              <span className="badge bg-secondary">Event :</span>
              <span className="font-size-14 fw-bold">{data?.event}</span>
            </div>
            <div className="d-flex align-items-center gap-2 my-2 col-6">
              <span className="badge bg-secondary">Info :</span>
              <span className="font-size-14 fw-bold">{data?.info}</span>
            </div>
            <div className="d-flex align-items-center gap-2 my-2 col-6">
              <span className="badge bg-secondary">State :</span>
              <span className="font-size-14 fw-bold">{data?.state}</span>
            </div>
            <div className="mb-2">
              <span className="badge bg-secondary mb-1">
                Assets : ({JSON.parse(correctedJsonString)?.assets?.length})
              </span>
              <div
                style={{ maxHeight: "300px", overflowY: "overlay" }}
                className="d-flex flex-wrap gap-1 border border-primary rounded-2 p-2"
              >
                {JSON.parse(correctedJsonString)
                  ?.assets?.sort((a, b) => {
                    const ipA = a.address.split(".").map(Number);
                    const ipB = b.address.split(".").map(Number);

                    for (let i = 0; i < 4; i++) {
                      if (ipA[i] !== ipB[i]) {
                        return ipA[i] - ipB[i];
                      }
                    }
                    return 0;
                  })
                  ?.map((item) => (
                    <span
                      key={item.address}
                      className={classNames("badge bg-danger", {
                        "bg-primary": item.is_private,
                      })}
                    >
                      {item.address}
                    </span>
                  ))}
              </div>
            </div>
          </div>
          <Row>
            <Col>
              <div className="text-end">
                <button
                  type="button"
                  className="btn btn-light me-2"
                  onClick={tog_standard}
                >
                  Close
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default ScanInfoModal;
