import React from "react";
import DemoRequest from "../../../components/Admin/Dashboard/DemoRequest";
import VpnConnections from "../../../components/Admin/Dashboard/VpnConnections";
const AdminDashboard = () => {
  return (
    <React.Fragment>
      <DemoRequest />
      <VpnConnections />
    </React.Fragment>
  );
};

export default AdminDashboard;
