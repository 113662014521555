import React from "react";

//import Breadcrumbs
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import { Container } from "reactstrap";

const Invoices = () => {
  document.title = "Dashboard | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Invoices" breadcrumbItem="Invoices" />

          <div>Invoices</div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Invoices;
