//redux
import { useSelector, useDispatch } from "react-redux";
// Redux Store
import { changePreloader } from "../store/actions";
const paceFunctionComponent = () => {
  const dispatch = useDispatch();
  const paceFunction = (data) => {
    // dispatch(changePreloader(data));

    let preloader = document.getElementById("preloader");
    if (preloader) {
      if (data) {
        !preloader.classList.contains("pace-active") &&
          preloader.classList.add("pace-active");
        preloader.classList.contains("pace-inactive") &&
          preloader.classList.remove("pace-inactive");
      } else {
        preloader.classList.contains("pace-active") &&
          preloader.classList.remove("pace-active");
        !preloader.classList.contains("pace-inactive") &&
          preloader.classList.add("pace-inactive");
      }
    }
  };
  return { paceFunction };
};

export default paceFunctionComponent;
