import React from "react";

const splitDataIntoTwo = (data) => {
  const keys = Object.keys(data);
  const middleIndex = Math.ceil(keys.length / 2);
  const firstHalfKeys = keys.slice(0, middleIndex);
  const secondHalfKeys = keys.slice(middleIndex);
  const firstHalf = {};
  const secondHalf = {};

  firstHalfKeys.forEach((key) => {
    firstHalf[key] = data[key];
  });

  secondHalfKeys.forEach((key) => {
    secondHalf[key] = data[key];
  });

  return [firstHalf, secondHalf];
};

const Whois = ({ data }) => {
  const [firstHalf, secondHalf] = splitDataIntoTwo(data);
  const objectsOnly = [];

  for (const key in data) {
    if (typeof data[key] === "object" && !Array.isArray(data[key])) {
      objectsOnly.push({ title: key, data: data[key] });
    }
  }

  return (
    <div className="row  ">
      {objectsOnly?.map((item, index) => (
        <div key={index} className="col-xxl-6  mb-3">
          <div className="special-card2 h-100 ">
            <h2 className="text-center">{item?.title}</h2>
            <ul>
              {Object.entries(item?.data)?.map(([key, value]) => (
                <li key={key}>
                  <strong>{key}:</strong> {value ? value : "-"}
                </li>
              ))}
            </ul>
          </div>
        </div>
      ))}

      <div className="col-xxl-6  mb-3">
        <div className="special-card2">
          <h2 className="text-center">nameservers</h2>
          <ul>
            {data?.nameservers?.map((item, index) => (
              <li key={index}>{item}</li>
            ))}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default Whois;
