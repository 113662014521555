import React, { useEffect, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { del, get } from "../../../helpers/api_helper";
import GroupsModal from "./GroupsModal";
import AddGroupModal from "./AddGroupModal";
import AddPrivateHost from "./AddPrivateHost";
import MoveGroupAsset from "./MoveGroupAsset";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
  toastWarning,
} from "../../../../common/utils/commonFunctions";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
  setRowId,
  setIsChecked,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);

  return (
    <span className="d-flex justify-content-center  align-items-center ">
      <span className="d-flex justify-content-end fw-semibold me-1 ">
        Search:
      </span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
          setRowId([]);
          setIsChecked(false);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function HostsAssetTable({
  data,
  entryValue,
  changeValue,
  moduleID,
  maxAssetCount,
  getHostsAsset,
}) {
  const { errorToastFunction, errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  // Seem
  const [seeFirst, setSeeFirst] = useState(true);
  const [seeSecond, setSeeSecond] = useState(true);
  const [seeThird, setSeeThird] = useState(true);
  const [seeFourth, setSeeFourth] = useState(true);
  // Others
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [rowId, setRowId] = useState([]);
  const [groupList, set_groupList] = useState();
  const [isChecked, setIsChecked] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const [deleteLoading, set_deleteLoading] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standard2() {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  }

  function tog_standard3() {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  }

  function tog_standard4() {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  }

  function tog_standard5() {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "first_name",
      },
      {
        Header: "Address",
        accessor: "address",
      },
      {
        Header: "Location",
        accessor: "is_private",
      },
      {
        Header: "Group Name",
        accessor: "group_name",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "address", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      initialState: {
        sortBy,
      },
      // manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
      setRowId([]);
      setIsChecked(false);
    } else {
      setCurrentPage(currentPage);
      setRowId([]);
      setIsChecked(false);
    }
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
      setRowId([]);
      setIsChecked(false);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
      setRowId([]);
      setIsChecked(false);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
    setRowId([]);
    setIsChecked(false);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  const selectAllRow = () => {
    if (!isChecked) {
      let ids = rows
        .slice(firstPostIndex, lastPostIndex)
        .map((item) => item.original.id);
      setRowId(ids);
    } else {
      setRowId([]);
    }
    setIsChecked(!isChecked);
  };

  const getGroupList = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/module/host/group");
      if (result?.success) set_groupList(result.data);
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunctionNotSwal(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    getGroupList();
  }, []);

  const deleteAssets = async () => {
    paceFunction(true);
    if (rowId.length == 0) {
      toastWarning("You must select a asset...");
    } else if (rowId.length == 1) {
      try {
        let result = await del(`/customer/module/asset`, {
          params: {
            id: moduleID,
            asset_id: rowId[0],
          },
        });
        if (result?.success) {
          toastSuccess(result?.message || "Assets Deleted", 2000);
          tog_standard5();
          getHostsAsset();
          setRowId([]);
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      toastWarning("You must select only one asset");
    }
    paceFunction(false);
  };

  const MySwal = withReactContent(Swal);
  const showSweetAlert = (curl, wget) => {
    MySwal.fire({
      title: (
        <p>
          You have to verify that the IP address belongs to you. Request
          verification on the device hosting the ip address by selecting the
          appropriate command below.
        </p>
      ),
      html: (
        <div>
          <div className="mb-3 ">
            <h5>cURL</h5>
            <span className="text-primary">{curl}</span>
          </div>
          <div className="">
            <h5>wget</h5>
            <span className="text-primary">{wget}</span>
          </div>
        </div>
      ),
      icon: "info",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      width: "800px",
      showConfirmButton: false,
    });
  };

  const addPublicHost = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/public-host");
      if (result?.success) {
        const sanitizedCurlCommand = result.data.curl.replace(/\\/g, "");
        const sanitizedWgetCommand = result.data.wget.replace(/\\/g, "");
        showSweetAlert(sanitizedCurlCommand, sanitizedWgetCommand);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {groupList && (
        <GroupsModal
          getGroupList={getGroupList}
          getHostsAsset={getHostsAsset}
          groupList={groupList}
          tog_standard={tog_standard}
          modal_standard={modal_standard}
          setmodal_standard={(e) => setmodal_standard(e)}
        />
      )}
      {groupList && (
        <AddPrivateHost
          getHostsAsset={getHostsAsset}
          limit={Number(maxAssetCount) - Number(data.length)}
          moduleID={moduleID}
          groupList={groupList}
          tog_standard={tog_standard3}
          modal_standard={modal_standard3}
          setmodal_standard={(e) => setmodal_standard3(e)}
        />
      )}
      <AddGroupModal
        getGroupList={getGroupList}
        getHostsAsset={getHostsAsset}
        tog_standard={tog_standard2}
        modal_standard={modal_standard2}
        setmodal_standard={(e) => setmodal_standard2(e)}
      />
      {groupList && (
        <MoveGroupAsset
          getHostsAsset={getHostsAsset}
          rowId={rowId}
          groupList={groupList}
          tog_standard={tog_standard4}
          modal_standard={modal_standard4}
          setmodal_standard={(e) => setmodal_standard4(e)}
        />
      )}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteAssets}
        text="Are you sure you want to delete host?"
        loadingAction={true}
        isLoading={deleteLoading}
      />
      <Row className="d-flex  mt-1 align-items-center ">
        <Col sm={4} className="d-flex flex-row ">
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col
          sm={4}
          className="d-flex justify-content-lg-end justify-content-xl-center  "
        >
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
            setRowId={(e) => setRowId(e)}
            setIsChecked={(e) => setIsChecked(e)}
          />
        </Col>
        <Col sm={4} className="d-flex justify-content-sm-end  ">
          <Dropdown
            isOpen={btnprimary1}
            toggle={() => setBtnprimary1(!btnprimary1)}
          >
            <DropdownToggle
              tag="button"
              className="btn btn-primary d-flex justify-content-center flex-row  "
            >
              Actions <i className="mdi mdi-chevron-down" />
            </DropdownToggle>
            <DropdownMenu>
              <DropdownItem onClick={tog_standard}>
                <i className="mdi mdi-select-group label-icon me-1 " />
                Groups
              </DropdownItem>
              <DropdownItem onClick={tog_standard2}>
                <i className="mdi mdi-layers-outline label-icon me-1 " />
                Add new hosts group
              </DropdownItem>
              <DropdownItem onClick={tog_standard3}>
                <i className="mdi mdi-ip-network label-icon me-1 text-primary" />
                Add new private host
              </DropdownItem>
              <DropdownItem onClick={addPublicHost}>
                <i className="mdi mdi-weather-cloudy-alert label-icon me-1 text-warning" />
                Add new public host
              </DropdownItem>
              <DropdownItem onClick={tog_standard4}>
                <i className="mdi mdi-cursor-move label-icon me-1 text-info" />
                Move selected hosts
              </DropdownItem>
              <DropdownItem onClick={tog_standard5}>
                <i className="mdi mdi-trash-can-outline label-icon me-1 text-danger " />
                Delete selected host
              </DropdownItem>
            </DropdownMenu>
          </Dropdown>{" "}
        </Col>
      </Row>

      <div style={{ maxHeight: "500px", overflowY: "scroll" }}>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th className={`${seeFirst ? "col-1" : "d-none "}`}>
                  <input
                    checked={isChecked}
                    onChange={() => {}}
                    onClick={selectAllRow}
                    type="checkbox"
                  />
                </th>
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  className={`${seeSecond ? "col-7" : "d-none "}`}
                >
                  {headerGroup.headers[1].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[2].getHeaderProps(
                    headerGroup.headers[2].getSortByToggleProps()
                  )}
                  className={`${seeThird ? "col-2" : "d-none "}`}
                >
                  {headerGroup.headers[2].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[2].isSorted
                      ? headerGroup.headers[2].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  {...headerGroup.headers[3].getHeaderProps(
                    headerGroup.headers[3].getSortByToggleProps()
                  )}
                  className={`${seeFourth ? "col-2" : "d-none "}`}
                >
                  {headerGroup.headers[3].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[3].isSorted
                      ? headerGroup.headers[3].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                {/* {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {" "}
                  {column.render("Header")}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))} */}
              </tr>
            ))}
          </thead>

          {data?.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                return (
                  <React.Fragment key={index}>
                    <tr
                      style={{ width: "100%", cursor: "pointer" }}
                      {...row.getRowProps()}
                    >
                      {seeFirst && (
                        <td className="col-1">
                          <input
                            onChange={() => {}}
                            onClick={() => {
                              rowIdFunc(row.original.id);
                            }}
                            checked={
                              rowId.some((item) => item === row.original.id)
                                ? true
                                : false
                            }
                            type="checkbox"
                          />
                        </td>
                      )}
                      {seeSecond && (
                        <td className="col">{row.cells[1].value}</td>
                      )}
                      {seeThird && (
                        <td className="col">
                          {row.cells[2].value ? (
                            <span>
                              <i className="mdi mdi-ip-network-outline text-primary me-1" />
                              Private
                            </span>
                          ) : (
                            <span>
                              <i className="mdi mdi-weather-cloudy-alert text-warning me-1" />
                              Public
                            </span>
                          )}
                        </td>
                      )}
                      {seeFourth && (
                        <td className="col ">
                          <span className="badge badge-soft-danger fs-5 ">
                            {row.cells[3].value}
                          </span>
                        </td>
                      )}
                    </tr>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data?.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink
                    onClick={() => {
                      setCurrentPage(1);
                      setRowId([]);
                      setIsChecked(false);
                    }}
                  >
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => {
                        setCurrentPage(Number(currentPage) - 2);
                        setRowId([]);
                        setIsChecked(false);
                      }}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => {
                      setCurrentPage(Number(currentPage));
                      setRowId([]);
                      setIsChecked(false);
                    }}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => {
                        setCurrentPage(Number(currentPage) + 2);
                        setRowId([]);
                        setIsChecked(false);
                      }}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => {
                      setCurrentPage(pageArrays.length);
                      setRowId([]);
                      setIsChecked(false);
                    }}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink
                    onClick={() => {
                      setCurrentPage(page);
                      setRowId([]);
                      setIsChecked(false);
                    }}
                  >
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default HostsAssetTable;
