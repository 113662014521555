import React from "react";

const PossiblePhising = ({ data }) => {
  return (
    <div className="row">
      {data?.map((event, index) => (
        <div
          key={event?.domain_name}
          className=" col-xxl-3 col-lg-4 col-md-6  mb-2 "
        >
          <div className="special-card2 d-flex align-items-center ">
            <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-4 " />
            <span>{event?.domain_name}</span>
          </div>
        </div>
      ))}
    </div>
  );
};

export default PossiblePhising;
