import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";

//i18n
import { withTranslation } from "react-i18next";
// Redux
import { connect, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { del, get, post, put, patch } from "../../helpers/api_helper";
// users
import user1 from "../../../assets/images/user.png";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
const ProfileMenu = (props) => {
  const { errorFunctionNotSwal } = errorFunctionComponent();

  const [menu, setMenu] = useState(false);
  const [username, setusername] = useState("Admin");

  const storage = JSON.parse(localStorage.getItem("authUser"));

  const { isDemoAccount } = useSelector((state) => ({
    isDemoAccount: state.custom.isDemoAccount,
  }));

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      if (process.env.REACT_APP_DEFAULTAUTH === "firebase") {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.displayName);
      } else if (
        process.env.REACT_APP_DEFAULTAUTH === "fake" ||
        process.env.REACT_APP_DEFAULTAUTH === "jwt"
      ) {
        const obj = JSON.parse(localStorage.getItem("authUser"));
        setusername(obj.username);
      }
    }
  }, [props.success]);

  const logoutFunc = async () => {
    try {
      let result = await post(`/account/logout`, {
        refresh: storage?.value?.data?.refresh,
      });
      if (result?.success) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("profileInfo");
        window.location.reload();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunctionNotSwal(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("profileInfo");
        window.location.reload();
      }
    }
  };
  return (
    <React.Fragment>
      <Dropdown
        isOpen={menu}
        toggle={() => setMenu(!menu)}
        className="d-inline-block"
      >
        <DropdownToggle
          className="btn header-item bg-soft-light border-start border-end"
          id="page-header-user-dropdown"
          tag="button"
        >
          <div className="d-flex flex-row align-items-center  ">
            <div className="d-flex flex-column justify-content-center align-items-center  ">
              <img
                className="rounded-circle header-profile-user"
                src={user1}
                alt="Header Avatar"
              />
              {isDemoAccount && <span className="demo-account">TRIAL</span>}
              <span className="d-none d-xl-inline-block ms-2 me-1">
                {username}
              </span>
            </div>
            <i className="mdi mdi-chevron-down d-none d-xl-inline-block" />
          </div>
        </DropdownToggle>
        <DropdownMenu className="dropdown-menu-end">
          {/* <DropdownItem tag="a" href="/profile">
            {" "}
            <i className="bx bx-user font-size-16 align-middle me-1" />
            {props.t("Profile")}{" "}
          </DropdownItem>
          <DropdownItem tag="a" href="/profile">
            <span className="badge bg-success float-end">11</span>
            <i className="bx bx-wrench font-size-16 align-middle me-1" />
            {props.t("Settings")}
          </DropdownItem>
          <DropdownItem tag="a" href="/page-lock-screen">
            <i className="bx bx-lock-open font-size-16 align-middle me-1" />
            {props.t("Lock screen")}
          </DropdownItem> */}
          {/* <div className="dropdown-divider" /> */}
          <div
            style={{ cursor: "pointer" }}
            onClick={logoutFunc}
            className="dropdown-item"
          >
            <i className="bx bx-power-off font-size-16 align-middle me-1 text-danger" />
            <span>{props.t("Logout")}</span>
          </div>
        </DropdownMenu>
      </Dropdown>
    </React.Fragment>
  );
};

ProfileMenu.propTypes = {
  success: PropTypes.any,
  t: PropTypes.any,
};

const mapStatetoProps = (state) => {
  const { error, success } = state.Profile;
  return { error, success };
};

export default connect(mapStatetoProps, {})(withTranslation()(ProfileMenu));
