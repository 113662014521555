import React, { useEffect, useRef, useState } from "react";
import { patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { toastSuccess } from "../../../common/utils/commonFunctions";

const DropdownHelp = ({ issue, getIssues }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isOpen, setIsOpen] = useState(false);

  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && buttonRef.current) {
        buttonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const updateIssueStatus = async (statu) => {
    paceFunction(true);
    let data = {
      id: issue?.id,
      type: issue?.type,
      subject: issue?.subject,
      description: issue?.description,
      status: statu,
      screenshot: issue?.screenshot,
    };
    try {
      let result = await patch(`/account/issue`, data);
      if (result?.success) {
        toastSuccess(result?.message || "Issue Updated");
        toggleDropdown();
        getIssues();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setIsOpen(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <div className={`dropdownn ${isOpen ? "open" : ""}`} ref={dropdownRef}>
        <div className="selected-optionn py-0 " onClick={toggleDropdown}>
          {issue?.status === "OPENED" ? (
            <span className="badge bg-success">{issue?.status}</span>
          ) : issue?.status === "CLOSED" ? (
            <span className="badge bg-danger">{issue?.status}</span>
          ) : (
            <span className="badge bg-info">WORK IN PROGRESS</span>
          )}
          <i className="mdi mdi-chevron-down text-primary fs-6 " />
        </div>
        <ul className="optionss">
          <li
            className="fs-6 text-success"
            onClick={() => {
              updateIssueStatus("OPENED");
            }}
          >
            OPENED
          </li>
          <li
            onClick={() => {
              updateIssueStatus("CLOSED");
            }}
            className="fs-6 text-danger "
          >
            CLOSED
          </li>
          <li
            className="fs-6 text-info "
            onClick={() => updateIssueStatus("WIP")}
          >
            WIP
          </li>
        </ul>
      </div>
    </React.Fragment>
  );
};

export default DropdownHelp;
