import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import ReCAPTCHA from "react-google-recaptcha";
import {
  Row,
  Col,
  Container,
  Form,
  Input,
  FormFeedback,
  Label,
  Spinner,
} from "reactstrap";
//redux
import { useSelector, useDispatch } from "react-redux";
import TwoStepVerification from "../AuthenticationInner/TwoStepVerfication";
import { withRouter, Link, useHistory } from "react-router-dom";
import errorFunctionComponent from "../../common/errorFunctionComponent";
import paceFunctionComponent from "../../common/paceFunctionComponent";
import DateIconComponent from "../../common/DateIconComponent";
// Formik validation
import * as Yup from "yup";
import { useFormik } from "formik";

//Social Media Imports
import { GoogleLogin } from "react-google-login";
// import TwitterLogin from "react-twitter-auth"
import FacebookLogin from "react-facebook-login/dist/facebook-login-render-props";

// actions
import { loginUser, socialLogin } from "../../store/actions";

// import images
import logo from "../../assets/images/logo_colors_lg.svg";

//Import config
import { facebook, google } from "../../config";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import axios from "axios";
const Login = (props) => {
  document.title = "Login | PentestBX";

  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();
  const dispatch = useDispatch();
  const router = useHistory();
  const { error, smsResponse, loading } = useSelector((state) => ({
    error: state.Login.error,
    smsResponse: state.Login.smsResponse,
    loading: state.Login.loading,
  }));
  const [checkCaptcha, set_checkCaptcha] = useState(true);

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      password: Yup.string().required("Please Enter Your Password"),
    }),
    onSubmit: (values) => {
      dispatch(loginUser(values, props.history));
    },
  });

  const signIn = (res, type) => {
    if (type === "google" && res) {
      const postData = {
        name: res.profileObj.name,
        email: res.profileObj.email,
        token: res.tokenObj.access_token,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    } else if (type === "facebook" && res) {
      const postData = {
        name: res.name,
        email: res.email,
        token: res.accessToken,
        idToken: res.tokenId,
      };
      dispatch(socialLogin(postData, props.history, type));
    }
  };

  //handleGoogleLoginResponse
  const googleResponse = (response) => {
    signIn(response, "google");
  };

  //handleFacebookLoginResponse
  const facebookResponse = (response) => {
    signIn(response, "facebook");
  };

  const handleReCaptcha = (e) => {
    verifyReCaptcha(e);
  };

  const verifyReCaptcha = async (recaptchaToken) => {
    paceFunction(true);
    let data = {
      token: recaptchaToken,
    };
    try {
      const result = await axios.post(
        `${process.env.REACT_APP_APIURL}/account/recaptcha`,
        data
      );
      if (result?.data?.success) {
        set_checkCaptcha(false);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    if (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
      // toastError(error?.response?.data?.message || "Something went wrong");
      // errorFunctionNotSwal(error);
    }
  }, [error]);

  useEffect(() => {
    if (localStorage.getItem("authUser")) {
      router.push("/dashboard");
    }
  }, []);
  return (
    <React.Fragment>
      {smsResponse ? (
        <TwoStepVerification />
      ) : (
        <div style={{ overflow: "hidden" }} className="auth-page">
          <Container fluid className="p-0">
            <Row className="g-0">
              <Col lg={4} md={5} className="col-xxl-3">
                {loading ? (
                  <div className="auth-full-page-content d-flex justify-content-center  align-items-center h-100  ">
                    <Spinner
                      style={{ width: "3rem", height: "3rem" }}
                      className="ms-2 "
                      color="primary"
                    />
                  </div>
                ) : (
                  <div className="auth-full-page-content d-flex p-sm-5 p-4">
                    <div className="w-100">
                      <div className="d-flex flex-column h-100">
                        <div className="mb-4 mb-md-5 text-center">
                          <Link to="/dashboard" className="d-block auth-logo">
                            <img src={logo} alt="" height="40" />{" "}
                            {/* <span className="logo-txt">PentestBX</span> */}
                          </Link>
                        </div>
                        <div className="auth-content my-auto">
                          <div className="text-center">
                            <h5 className="mb-0 fw-semibold">Welcome !</h5>
                            <p className="text-muted mt-2">
                              Sign in to continue to PentestBX.
                            </p>
                          </div>
                          <Form
                            className="custom-form mt-4 pt-2"
                            onSubmit={(e) => {
                              e.preventDefault();
                              validation.handleSubmit();
                              return false;
                            }}
                          >
                            {/* {error ? <Alert color="danger">error</Alert> : null} */}
                            {/* {!error?.response.data.success && toastError("dasdsa")} */}
                            <div className="mb-3">
                              <Label className="form-label">Email</Label>
                              <Input
                                name="email"
                                className="form-control"
                                placeholder="Enter email"
                                type="email"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                value={validation.values.email || ""}
                                invalid={
                                  validation.touched.email &&
                                  validation.errors.email
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.email &&
                              validation.errors.email ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.email}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="mb-3">
                              <div className="d-flex justify-content-between ">
                                <Label className="form-label">Password</Label>
                                <Label
                                  onClick={() =>
                                    router.push("/forgot-password")
                                  }
                                  style={{ cursor: "pointer" }}
                                  className="form-label fw-normal  "
                                >
                                  Forgot password?
                                </Label>
                              </div>
                              <Input
                                name="password"
                                value={validation.values.password || ""}
                                type="password"
                                placeholder="Enter Password"
                                onChange={validation.handleChange}
                                onBlur={validation.handleBlur}
                                invalid={
                                  validation.touched.password &&
                                  validation.errors.password
                                    ? true
                                    : false
                                }
                              />
                              {validation.touched.password &&
                              validation.errors.password ? (
                                <FormFeedback type="invalid">
                                  {validation.errors.password}
                                </FormFeedback>
                              ) : null}
                            </div>

                            <div className="row mb-4">
                              <div className="col">
                                {/* <div className="form-check">
                              <input
                                className="form-check-input"
                                type="checkbox"
                                id="remember-check"
                              />
                              <label
                                className="form-check-label"
                                htmlFor="remember-check"
                              >
                                Remember me
                              </label>
                            </div> */}
                                <div>
                                  <ReCAPTCHA
                                    sitekey={process.env.REACT_APP_SITEKEY}
                                    onChange={handleReCaptcha}
                                  />
                                </div>

                                <div className="mt-3 d-grid">
                                  <button
                                    disabled={
                                      process.env.REACT_APP_CAPTCHA === "false"
                                        ? false
                                        : checkCaptcha
                                    }
                                    className="btn btn-primary btn-block fw-semibold"
                                    type="submit"
                                  >
                                    Log In
                                  </button>
                                </div>
                              </div>
                            </div>
                          </Form>

                          {/* <div className="mt-4 text-center">
                        <h5 className="font-size-14 mb-3">Sign in with</h5>

                        <ul className="list-inline">
                          <li className="list-inline-item">
                            <FacebookLogin
                              appId={facebook.APP_ID}
                              autoLoad={false}
                              callback={facebookResponse}
                              render={(renderProps) => (
                                <Link
                                  to="#"
                                  className="social-list-item bg-primary text-white border-primary"
                                  onClick={renderProps.onClick}
                                >
                                  <i className="mdi mdi-facebook" />
                                </Link>
                              )}
                            />
                          </li>
                          {google.CLIENT_ID && (
                            <li className="list-inline-item">
                              <GoogleLogin
                                clientId={google.CLIENT_ID}
                                render={(renderProps) => (
                                  <Link
                                    to="#"
                                    className="social-list-item bg-danger text-white border-danger"
                                    onClick={renderProps.onClick}
                                  >
                                    <i className="mdi mdi-google" />
                                  </Link>
                                )}
                                onSuccess={googleResponse}
                                onFailure={() => {}}
                              />
                            </li>
                          )}
                        </ul>
                      </div> */}

                          <div className="mt-5 text-center">
                            <p className="text-muted mb-0">
                              Don't have an account ?
                              <Link
                                to="/register"
                                className="text-primary fw-bold ms-1 "
                              >
                                Demo Request
                              </Link>
                            </p>
                          </div>
                        </div>
                        <DateIconComponent />
                      </div>
                    </div>
                  </div>
                )}
              </Col>
              <CarouselPage />
            </Row>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default withRouter(Login);

Login.propTypes = {
  history: PropTypes.object,
};
