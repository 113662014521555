// @flow
import { IS_APPLIANCE_REDUX, IS_DEMO_ACCOUNT } from "./actionTypes";

const INIT_STATE = {
  isApplianceRedux: false,
  isDemoAccount: false,
};

const Custom = (state = INIT_STATE, action) => {
  switch (action.type) {
    case IS_APPLIANCE_REDUX:
      return {
        ...state,
        isApplianceRedux: action.payload.isApplianceRedux,
      };
    case IS_DEMO_ACCOUNT:
      return {
        ...state,
        isDemoAccount: action.payload.isDemo,
      };

    default:
      return state;
  }
};

export default Custom;
