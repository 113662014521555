import React, { useEffect, useState } from "react";
import { Col, Row, Container, Spinner } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get } from "../../../../helpers/api_helper";
import ProfileCards from "../../../../components/Customer/Account/Profile/ProfileCards";
import CustomerInfo from "../../../../components/Customer/Account/Profile/CustomerInfo";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import AutoRenewal from "../../../../components/Customer/Account/Profile/AutoRenewal";
import RemainingDay from "../../../../components/Customer/Account/Profile/RemainingDay";

const Profile = () => {
  document.title = "Profile | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [profileInfos, setProfileInfos] = useState();
  const [modules, setModules] = useState();

  const getModules = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/module");
      if (result.success) {
        setModules(result.data.modules);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const getProfileInfos = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/account/profile");
      if (result.success) {
        setIsLoading(false);
        setProfileInfos(result.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getProfileInfos();
  }, []);

  useEffect(() => {
    setIsLoading(true);
    getModules();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profile" breadcrumbItem="Profile" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && (
            <Row>
              <Col xl={9} lg={8}>
                {profileInfos && (
                  <CustomerInfo
                    getProfileInfos={getProfileInfos}
                    profileInfos={profileInfos}
                  />
                )}
              </Col>
              <Col xl={3} lg={4}>
                {modules && <ProfileCards modules={modules} />}
                {profileInfos && (
                  <AutoRenewal
                    renewal={profileInfos.auto_renewal}
                    getProfileInfos={getProfileInfos}
                  />
                )}
                {profileInfos && (
                  <RemainingDay
                    day={profileInfos.subscription_renewal}
                    dateFormat={profileInfos.date_format}
                    profileInfos={profileInfos}
                  />
                )}
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Profile;
