import React, { useEffect, useState } from "react";
import { get } from "../../../helpers/api_helper";
import "bootstrap/dist/css/bootstrap.min.css";
import ManagerCustomersTable from "../../../components/Manager/Customers/ManagerCustomersTable";
import { Col, Container, Row, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { removeBodyCss } from "../../../common/utils/commonFunctions";
import AddNewCustomerModal from "../../../components/Manager/Customers/AddNewCustomerModal";

const Customers = () => {
  document.title = "Customers | PentestBX";

  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [entryValue, setEntryValue] = React.useState(10);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);

  const [data, setData] = useState();

  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { render } = useSelector((state) => ({
    render: state.render.render,
  }));

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCustomers = async () => {
    paceFunction(true);
    try {
      let result = await get("/manager/customer");
      if (result?.success) {
        setData(result?.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunctionNotSwal(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCustomers();
  }, [render]);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  function tog_standard3() {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  }

  return (
    <React.Fragment>
      <AddNewCustomerModal
        modal_standard={modal_standard}
        modal_standard3={modal_standard3}
        setmodal_standard={(e) => setmodal_standard(e)}
        setmodal_standard3={(e) => setmodal_standard3(e)}
        tog_standard={tog_standard}
        tog_standard3={tog_standard3}
        getCustomers={getCustomers}
      />
      <div className="page-content">
        <Container fluid>
          <div className="special-card">
            <Row>
              <Col
                className="d-flex justify-content-start align-items-center "
                xs={10}
              >
                <h4 className="text-primary">Customers</h4>
              </Col>
              <Col xs={2} className="d-flex justify-content-end">
                <div>
                  <button
                    data-toggle="modal"
                    data-target="#myModal"
                    onClick={() => {
                      tog_standard();
                    }}
                    type="button"
                    className="btn btn-soft-primary"
                  >
                    <i className="mdi mdi-account-plus-outline me-1"></i> Add
                    New
                  </button>
                </div>
              </Col>
            </Row>
            {isLoading && (
              <div className="d-flex justify-content-center  align-items-center h-100  ">
                <Spinner
                  style={{ width: "3rem", height: "3rem" }}
                  className="ms-2 "
                  color="primary"
                />
              </div>
            )}
            {!isLoading && data && (
              <div
                style={{
                  overflowX: windowWidth < 800 ? "scroll" : "none",
                }}
              >
                <ManagerCustomersTable
                  data={data}
                  entryValue={entryValue}
                  changeValue={(e) => changeValue(e)}
                  getCustomers={getCustomers}
                />
              </div>
            )}
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Customers;
