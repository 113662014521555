import React from "react";
import { Progress } from "reactstrap";
import DOMPurify from "dompurify";

const CvesDescriptionV2 = ({ data, cvss }) => {
  let baseScore = cvss?.cvssData?.baseScore;
  return (
    <div className="cve-modal-body-description">
      <div className="cve-modal-body-description-text">
        <div
          className="font-size-15  "
          dangerouslySetInnerHTML={{
            __html: DOMPurify.sanitize(
              data?.raw_nvd_data?.descriptions[0]?.value
            ),
          }}
        ></div>
        {/* <p>{data?.descriptions[0]?.value}</p> */}
      </div>
      {cvss && (
        <div className="cve-modal-body-description-cvssv3 row col-12 ">
          <div className="col-lg-6">
            <div className="cve-modal-body-description-cvssv3-info">
              <div className="cve-modal-body-description-cvssv3-info-header col-lg-12">
                <div className="col-lg-8 remove-padding">
                  <span>Vector</span>
                </div>
                <div className="col-lg-4 remove-padding">
                  <span>Priority</span>
                </div>
              </div>
              <div className="cve-modal-body-description-cvssv3-info-content col-lg-12">
                {cvss?.cvssData?.accessVector && (
                  <div className="cve-modal-body-description-cvssv3-info-content-items">
                    <div className="col-8">
                      <span>Access Vector (AV):</span>
                    </div>
                    <div
                      className={`col-4 ${
                        cvss?.cvssData?.accessVector === "NETWORK"
                          ? "bg-severity-critical"
                          : "bg-severity-info"
                      }`}
                    >
                      <span>{cvss?.cvssData?.accessVector || "-"}</span>
                    </div>
                  </div>
                )}
                {cvss?.cvssData?.authentication && (
                  <div className="cve-modal-body-description-cvssv3-info-content-items">
                    <div className="col-8">
                      <span>Authentication (A):</span>
                    </div>
                    <div
                      className={`col-4 ${
                        cvss?.cvssData?.authentication === "NONE"
                          ? "bg-secondary"
                          : "bg-success"
                      }`}
                    >
                      <span>{cvss?.cvssData?.authentication || "-"}</span>
                    </div>
                  </div>
                )}
                {cvss?.cvssData?.availabilityImpact && (
                  <div className="cve-modal-body-description-cvssv3-info-content-items">
                    <div className="col-8">
                      <span>Availability Impact (A):</span>
                    </div>
                    <div
                      className={`col-4 ${
                        cvss?.cvssData?.availabilityImpact === "LOW"
                          ? "bg-severity-low"
                          : cvss?.cvssData?.availabilityImpact === "MEDIUM"
                          ? "bg-severity-medium"
                          : cvss?.cvssData?.availabilityImpact === "HIGH"
                          ? "bg-severity-high"
                          : "bg-severity-critical"
                      }`}
                    >
                      <span>{cvss?.cvssData?.availabilityImpact || "-"}</span>
                    </div>
                  </div>
                )}
                {cvss?.cvssData?.confidentialityImpact && (
                  <div className="cve-modal-body-description-cvssv3-info-content-items">
                    <div className="col-8">
                      <span>Confidentiality Impact (C):</span>
                    </div>
                    <div
                      className={`col-4 ${
                        cvss?.cvssData?.confidentialityImpact === "LOW"
                          ? "bg-severity-low"
                          : cvss?.cvssData?.confidentialityImpact === "MEDIUM"
                          ? "bg-severity-medium"
                          : cvss?.cvssData?.confidentialityImpact === "HIGH"
                          ? "bg-severity-high"
                          : "bg-severity-critical"
                      }`}
                    >
                      <span>{cvss.cvssData.confidentialityImpact || "-"}</span>
                    </div>
                  </div>
                )}
                {cvss?.cvssData?.accessComplexity && (
                  <div className="cve-modal-body-description-cvssv3-info-content-items">
                    <div className="col-8">
                      <span>Access Complexity (AC):</span>
                    </div>
                    <div
                      className={`col-4 ${
                        cvss?.cvssData?.accessComplexity === "LOW"
                          ? "bg-severity-low"
                          : cvss?.cvssData?.accessComplexity === "MEDIUM"
                          ? "bg-severity-medium"
                          : cvss?.cvssData?.accessComplexity === "HIGH"
                          ? "bg-severity-high"
                          : "bg-severity-critical"
                      }`}
                    >
                      <span>{cvss?.cvssData?.accessComplexity || "-"}</span>
                    </div>
                  </div>
                )}
                {cvss?.cvssData?.integrityImpact && (
                  <div className="cve-modal-body-description-cvssv3-info-content-items">
                    <div className="col-8">
                      <span>Integrity Impact (I):</span>
                    </div>
                    <div
                      className={`col-4 ${
                        cvss?.cvssData?.integrityImpact === "LOW"
                          ? "bg-severity-low"
                          : cvss?.cvssData?.integrityImpact === "MEDIUM"
                          ? "bg-severity-medium"
                          : cvss?.cvssData?.integrityImpact === "HIGH"
                          ? "bg-severity-high"
                          : "bg-severity-critical"
                      }`}
                    >
                      <span>{cvss?.cvssData?.integrityImpact || "-"}</span>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="col-lg-6 cve-modal-body-description-cvssv3-scores">
            <div className="cve-modal-body-description-cvssv3-scores-title">
              <span>Ranks</span>
            </div>
            <div className="cve-modal-body-description-cvssv3-scores-ranks">
              <div className="cve-modal-body-description-cvssv3-scores-ranks-cvss col-4">
                <div
                  className="cve-modal-body-description-cvssv3-scores-ranks-cvss-inner"
                  style={{
                    width: `${Number(cvss?.exploitabilityScore * 10)}%`,
                  }}
                >
                  <span>Exploitability</span>
                </div>
              </div>
              <div className="cve-modal-body-description-cvssv3-scores-ranks-cvss col-4">
                <div
                  className="cve-modal-body-description-cvssv3-scores-ranks-cvss-inner"
                  style={{
                    width: `${Number(cvss?.cvssData?.baseScore * 10)}%`,
                  }}
                >
                  <span>CVSS</span>
                </div>
              </div>
              <div className="cve-modal-body-description-cvssv3-scores-ranks-cvss col-4">
                <div
                  className="cve-modal-body-description-cvssv3-scores-ranks-cvss-inner"
                  style={{ width: `${Number(cvss?.impactScore * 10)}%` }}
                >
                  <span>Impact</span>
                </div>
              </div>
            </div>
            <div className="cve-modal-body-description-cvssv3-scores-severity">
              <div className="cve-modal-body-description-cvssv3-scores-severity-title col-12">
                <div
                  className="col-9"
                  style={{
                    paddingLeft: "0px!important",
                    paddingRight: "0px!important",
                  }}
                >
                  <span>Severity</span>
                </div>
                <div
                  className="col-3"
                  style={{
                    paddingRight: "0px!important",
                    paddingLeft: "0px!important",
                  }}
                >
                  <span data-cy="cve-modal-severity-score">
                    (
                    {baseScore <= 10 && baseScore >= 7
                      ? "HIGH"
                      : baseScore < 7 && baseScore >= 4
                      ? "MEDIUM"
                      : baseScore < 4 && baseScore >= 0
                      ? "LOW"
                      : ""}
                    )
                  </span>
                </div>
              </div>
              <div style={{ marginTop: "10px" }}>
                <Progress
                  color={
                    baseScore <= 10 && baseScore >= 7
                      ? "severity-high"
                      : baseScore < 7 && baseScore >= 4
                      ? "severity-medium"
                      : baseScore < 4 && baseScore >= 0
                      ? "severity-low"
                      : ""
                  }
                  max={10}
                  value={cvss?.cvssData?.baseScore}
                ></Progress>
              </div>
            </div>
            <div>
              <div className="cve-modal-body-description-cvssv3-scores-vector">
                <div className="cve-modal-body-description-cvssv3-scores-vector-title">
                  <span>Vector String</span>
                </div>
                <div className="cve-modal-body-description-cvssv3-scores-vector-content row col-lg-12">
                  <div className="cve-modal-body-description-cvssv3-scores-vector-content-items col-lg-12">
                    <span>{cvss?.cvssData?.vectorString || "-"}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default CvesDescriptionV2;
