import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
} from "reactstrap";
import ReactJson from "react-json-view";
import { del } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  formattedDateWithHours,
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import classNames from "classnames";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function ScheduledTable({ data, entryValue, userID, getTasks }) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [taskId, setTaskId] = useState();
  const [rowId, setRowId] = useState();
  const [stopTaskLoading, setStopTaskLoading] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }
  function tog_standard2() {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  }

  const columns = React.useMemo(
    () => [
      {
        Header: "Module",
        accessor: "module.text",
      },
      {
        Header: " Trigger",
        accessor: "trigger",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Scanner",
        accessor: "scanner",
      },
      {
        Header: "Info",
        accessor: "info",
      },
      {
        Header: "Event",
        accessor: "event",
      },
      {
        Header: "State",
        accessor: "state",
      },
      {
        Header: "Internal",
        accessor: "internal",
      },
      {
        Header: "Data",
        accessor: "data",
      },
      {
        Header: "",
        accessor: "email",
      },
    ],
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "first_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const stopTask = async () => {
    paceFunction(true);
    setStopTaskLoading(true);
    try {
      let result = await del(`/admin/customer/task?id=${taskId}`);
      if (result?.success) {
        toastSuccess(result?.message || "Task Stopped", 2000);
        tog_standard2();
        getTasks();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setStopTaskLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Stop Task */}
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={stopTask}
        text="Are you sure you want to stop task?"
        loadingAction={true}
        isLoading={stopTaskLoading}
      />
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  // {...column.getHeaderProps()}
                  // onClick={() => handleMultiSortBy(column, setSortBy, sortBy)}
                  // onClick={() => {
                  //   column.getSortByToggleProps();
                  // }}
                  key={column.id}
                >
                  {column.render("Header")}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {data.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
              prepareRow(row);
              const jsonString = row.original.data;
              const correctedJsonString = jsonString
                .replace(/'/g, '"')
                .replace(/False/g, "false")
                .replace(/True/g, "true")
                .replace(/None/g, "null")
                .replace(/Null/g, "null");
              return (
                <tr key={index} {...row.getRowProps()}>
                  <td>{row.cells[0].value || "-"}</td>
                  <td>
                    {row.cells[1]?.value
                      ? formattedDateWithHours(row.cells[1].value)
                      : "-"}
                  </td>
                  <td>
                    {row.cells[2]?.value
                      ? formattedDateWithHours(row.cells[2].value)
                      : "-"}
                  </td>
                  <td>{row.cells[3].value || "-"}</td>
                  <td>{row.cells[4].value || "-"}</td>
                  <td>{row.cells[5].value || "-"}</td>
                  <td>
                    <span
                      className={classNames("badge", {
                        "bg-success": row.cells[6].value === "IN PROGRESS",
                        "bg-warning": row.cells[6].value === "PREPARING",
                        "bg-danger": row.cells[6].value === "QUEUE",
                        "bg-info": row.cells[6].value === "ON HOLD",
                      })}
                    >
                      {row.cells[6].value}
                    </span>
                  </td>
                  <td>
                    {row.cells[7].value ? (
                      <i className="mdi mdi-check-outline me-3 text-success" />
                    ) : (
                      <i className="mdi mdi-close-outline me-3 text-danger" />
                    )}
                  </td>
                  <td>
                    <button
                      onClick={() => {
                        tog_standard();
                        setRowId(row.original.id);
                      }}
                      type="button"
                      className="btn btn-sm btn-primary btn-label taskinfo_btn"
                      title="Task Details"
                    >
                      <i className="mdi mdi-information-outline me-3 label-icon"></i>
                      Data
                    </button>
                  </td>
                  <td>
                    {row.cells[0].value === "Hosts" ||
                    row.cells[0].value === "Web Applications" ? (
                      <button
                        onClick={() => {
                          tog_standard2();
                          setTaskId(row.original.id);
                        }}
                        type="button"
                        className="btn btn-sm btn-danger btn-label taskStopBtn"
                        title="Emergency Stop"
                      >
                        <i className="mdi mdi-stop-circle-outline me-3 label-icon"></i>
                        {row.cells[6].value === "IN PROGRESS"
                          ? "Stop"
                          : "Delete"}
                      </button>
                    ) : (
                      "-"
                    )}
                  </td>
                  {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                  {rowId === row.original.id && (
                    <Modal
                      isOpen={modal_standard}
                      toggle={() => {
                        tog_standard();
                      }}
                    >
                      <div className="modal-header">
                        <h5 className="modal-title mt-0" id="myModalLabel">
                          Task Info
                        </h5>
                        <button
                          type="button"
                          onClick={() => {
                            setmodal_standard(false);
                          }}
                          className="close"
                          data-dismiss="modal"
                          aria-label="Close"
                        >
                          <span aria-hidden="true">&times;</span>
                        </button>
                      </div>
                      <div className="modal-body">
                        <>
                          <ReactJson src={JSON.parse(correctedJsonString)} />
                        </>
                      </div>
                      <div className="modal-footer">
                        <button
                          type="button"
                          onClick={() => {
                            tog_standard();
                          }}
                          className="btn btn-secondary "
                          data-dismiss="modal"
                        >
                          Cancel
                        </button>
                      </div>
                    </Modal>
                  )}
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {data.length < 1 && (
        <span
          style={{ width: "100%" }}
          className="d-flex justify-content-center "
        >
          No data available in table
        </span>
      )}
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ScheduledTable;
