import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Spinner } from "reactstrap";
import { get } from "../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../common/InfoNoDataComponent";
import NetworkSharesTable from "../../../components/Customer/Hosts/NetworkShares/NetworkSharesTable";
import { AiFillFolder, AiOutlineFolder, AiFillFile } from "react-icons/ai";
import FolderTree from "./FolderTree";
import { tempData } from "./TempData";

const NetworkShares = () => {
  //meta title
  document.title = "Network Shares | PentestBX";

  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [firstLoading, setFirstLoading] = useState(false);
  const [data, setData] = useState();
  const [renderGetOverview, set_renderGetOverview] = useState(false);
  const [entryValue, setEntryValue] = useState(10);
  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);
  const [seemInfoComponentAppliance, set_seemInfoComponentAppliance] =
    useState(false);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const changeValue = (value) => {
    setEntryValue(value);
  };

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getSharesOverview = async (id) => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/netshares`);
      if (result?.success) {
        if (result?.data?.length > 0) {
          setData(result.data);
          setFirstLoading(true);
        } else {
          set_seemInfoComponent(true);
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunctionNotSwal(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
        } else if (
          error?.response?.data?.message === "Endpoint is not connected."
        ) {
          set_seemInfoComponentAppliance(true);
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error?.response?.data?.message || "You don't have Network Shares"
            );
          }
        }
      }
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoading(true);
    }
    getSharesOverview();
  }, [renderGetOverview]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Network Shares" breadcrumbItem="Network Shares" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && data && (
            <React.Fragment>
              <div
                className="special-card"
                style={{
                  overflowX: windowWidth < 800 ? "overlay" : "none",
                }}
              >
                <NetworkSharesTable
                  data={data}
                  entryValue={entryValue}
                  changeValue={(e) => changeValue(e)}
                />
              </div>
            </React.Fragment>
          )}
          {!isLoading && seemInfoComponent && (
            <InfoNoDataComponent
              text="No network shares info were discovered"
              component="network-shares"
            />
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="network-shares"
            />
          )}
          {!isLoading && seemInfoComponentAppliance && (
            <InfoNoDataComponent
              text="Appliance is not connected."
              component="network-shares"
            />
          )}
          {/* <FolderTree data={tempData} /> */}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default NetworkShares;
