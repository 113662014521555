import React from "react";
import JVectorMap from "./Graphics/JVectorMap";
import { CardHeader } from "reactstrap";
import "/node_modules/flag-icons/css/flag-icons.min.css";

const TopSection = ({ assets }) => {
  const countryCounts = {};

  assets?.location_of_assets?.forEach((item) => {
    const { iso, name } = item?.data;

    if (!countryCounts[iso]) {
      countryCounts[iso] = {
        iso,
        name,
        count: 1,
      };
    } else {
      countryCounts[iso].count++;
    }
  });

  const countryArray = Object.values(countryCounts);

  return (
    <div className="row mb-5 ">
      <div className="row ">
        <div className="col-12 special-card">
          <CardHeader>
            <h3>Geographical Distribution of Assets</h3>
          </CardHeader>
          <div className="row mt-5 ">
            <div className="col-lg-8">
              <JVectorMap data={assets?.location_of_assets} />
            </div>
            <div className="col-lg-4">
              <table className="table table-borderless table-hover table-centered m-0">
                <thead className="table-light">
                  <tr>
                    <th>&nbsp;</th>
                    <th>Country</th>
                    <th>Assets</th>
                  </tr>
                </thead>
                <tbody>
                  {countryArray?.map((item, index) => (
                    <tr key={index}>
                      <td>
                        <span
                          className={`fi fi-${item?.iso?.toLowerCase()}`}
                        ></span>
                      </td>
                      <td>{item?.name}</td>
                      <td>{item?.count}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(TopSection);
