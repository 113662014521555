import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import ManagerDeleteQuestionModal from "./ManagerDeleteQuestionModal";
import { removeBodyCss } from "../../../common/utils/commonFunctions";

const DropdownHelp = ({ rowId, getManagers }) => {
  const dropdownRef = useRef(null);
  const buttonRef = useRef(null);
  const router = useHistory();

  const [modal_standard5, setmodal_standard5] = useState(false);

  const [isOpen, setIsOpen] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter" && buttonRef.current) {
        buttonRef.current.click();
      }
    };

    document.addEventListener("keydown", handleKeyPress);

    return () => {
      document.removeEventListener("keydown", handleKeyPress);
    };
  }, []);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <React.Fragment>
      {/* ManagerDeleteQuestionModal */}
      <ManagerDeleteQuestionModal
        modal_standard={modal_standard5}
        setmodal_standard={setmodal_standard5}
        tog_standard={tog_standard5}
        rowId={rowId}
        getManagers={getManagers}
        toggleDropdown={toggleDropdown}
      />
      <div className={`dropdownn ${isOpen ? "open" : ""}`} ref={dropdownRef}>
        <div className="selected-optionn py-0 " onClick={toggleDropdown}>
          <i className="bx bx-dots-horizontal-rounded text-primary fs-5 " />
          <i className="mdi mdi-chevron-down text-primary fs-6 " />
        </div>
        <ul className="optionss">
          <li
            className="fs-6"
            onClick={() => {
              router.push(`/distributor/manager-details/${rowId}`);
            }}
          >
            <i className="mdi mdi-account-details-outline label-icon text-info me-1 " />
            Details
          </li>
          <li className="fs-6" onClick={tog_standard5}>
            <i className="mdi mdi-account-remove-outline label-icon text-danger me-1 " />
            Remove
          </li>
          {/* ))} */}
        </ul>
      </div>
    </React.Fragment>
  );
};

export default DropdownHelp;
