import React from "react";

const Zonetransfer = ({ data }) => {
  return (
    <div className="row">
      {Object.keys(data)?.length > 0 &&
        data?.data?.map((zone, index) => (
          <div key={index} className="col-xxl-3 col-lg-4 col-md-6 mb-2 ">
            <div className="special-card2 d-flex align-items-center h-100  ">
              <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-4 " />
              <span>{zone}</span>
            </div>
          </div>
        ))}
      {Object.keys(data)?.length < 1 && (
        <div className="d-flex align-items-center justify-content-center  ">
          <i className="mdi mdi-check-decagram text-primary fs-3 me-2 " />
          <h4 className="mt-2 ">Zone Transfer not detected</h4>
        </div>
      )}
    </div>
  );
};

export default Zonetransfer;
