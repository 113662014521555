import React, { useEffect, useRef, useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Spinner,
} from "reactstrap";
function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function LogsTable({ data, entryValue }) {
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(5);
  const [isLoading, setIsLoading] = useState(false);
  const columns = React.useMemo(
    () => [
      {
        Header: "Log Type",
        accessor: "log_type",
      },
      {
        Header: "Type",
        accessor: "type",
      },
      {
        Header: "Date",
        accessor: "date",
      },
      {
        Header: "Detail",
        accessor: "message",
      },
      {
        Header: "",
        accessor: "newDate",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "date", desc: true }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
      // manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);
  const [pageNumber, setPageNumber] = useState(1);
  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !isLoading) {
      if (postsPerPage * pageNumber < rows?.length) {
        setIsLoading(true);
        setTimeout(() => {
          setPageNumber((prevPageNumber) => prevPageNumber + 1);
          setIsLoading(false);
        }, 1000);
      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  return (
    <React.Fragment>
      {data.length > 0 && (
        <div
        // style={{
        //   maxHeight: "600px",
        //   overflow: "overlay",
        //   //   overflowX: "none",
        // }}
        >
          <Row className="d-flex justify-content-between mt-1 align-items-center ">
            <Col></Col>
            {/* <Col
              xxl={1}
              xl={1}
              lg={2}
              md={2}
              sm={3}
              xs={3}
              className="d-flex flex-row "
            >
              <span className="fw-semibold">Show</span>
              <select
                className="rounded-1 border-primary fw-semibold"
                onChange={(e) => {
                  setpostsPerPage(e.target.value);
                  setCurrentPage(1);
                }}
                name=""
                placeholder="Enter Phone"
              >
                <option value={entryValue}>{entryValue}</option>
                {values.map((item) => (
                  <option key={item} value={item}>
                    {item}
                  </option>
                ))}
              </select>{" "}
              <span className="fw-semibold">entries</span>
            </Col> */}
            <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
              <GlobalFilter
                preGlobalFilteredRows={preGlobalFilteredRows}
                globalFilter={state.globalFilter}
                setGlobalFilter={setGlobalFilter}
                setCurrentPage={(e) => setCurrentPage(e)}
              />
            </Col>
          </Row>

          <table className="table" {...getTableProps()}>
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column, columnID) => {
                    if (columnID < 4) {
                      return (
                        <th
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                          // {...column.getHeaderProps()}
                          // onClick={() => handleMultiSortBy(column, setSortBy, sortBy)}
                          // onClick={() => {
                          //   column.getSortByToggleProps();
                          // }}
                          key={column.id}
                        >
                          {column.render("Header")}{" "}
                          <span style={{ cursor: "pointer" }} title="Sorting">
                            {column.isSorted
                              ? column.isSortedDesc
                                ? " 🔽"
                                : // : " 🔼"
                                  " 🔽"
                              : "🔘"}
                          </span>
                        </th>
                      );
                    }
                  })}
                </tr>
              ))}
            </thead>

            <tbody {...getTableBodyProps()}>
              {/* {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => { */}
              {rows.slice(0, postsPerPage * pageNumber).map((row, index) => {
                prepareRow(row);
                const date = new Date(row.cells[2]?.value);

                const formattedDateTime = `${date.toLocaleDateString()} ${date.toLocaleTimeString()}`;
                return (
                  <tr key={index} {...row.getRowProps()}>
                    <td>
                      <span className="badge bg-primary">
                        {row.cells[0]?.value}
                      </span>
                    </td>
                    <td
                      className={`${
                        row.cells[1]?.value === "INFO"
                          ? "text-info fw-bold"
                          : row.cells[1]?.value === "ERROR"
                          ? "text-danger fw-bold"
                          : "text-warning fw-bold"
                      }`}
                    >
                      {row.cells[1]?.value}
                    </td>
                    <td>{formattedDateTime}</td>
                    <td>{row.cells[3]?.value}</td>
                    {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      )}
      {data.length < 1 && (
        <span
          style={{ width: "100%" }}
          className="d-flex justify-content-center fs-3 fw-bold mt-5 "
        >
          There is no log
        </span>
      )}
      {/* {data.length > 0 && (
        <Row>
          <Col xs={5} className="d-flex align-items-center ">
            <span>
              {firstPostIndex + 1}-
              {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
              {rows?.length}
            </span>
          </Col>
          <Col className="d-flex align-items-center justify-content-end  mt-2 ">
            <Pagination
              className="d-flex align-items-center "
              aria-label="Page navigation "
            >
              <PaginationItem>
                <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                  Previous
                </PaginationLink>
              </PaginationItem>
              {pageArrays.length > 10 && (
                <>
                  <PaginationItem>
                    <PaginationLink onClick={() => setCurrentPage(1)}>
                      First
                    </PaginationLink>
                  </PaginationItem>
                  {currentPage > 2 && (
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => setCurrentPage(Number(currentPage) - 2)}
                      >
                        {Number(currentPage) - 2}
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  <PaginationItem active>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage))}
                    >
                      {Number(currentPage)}
                    </PaginationLink>
                  </PaginationItem>
                  {currentPage + 2 <= pageArrays.length && (
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => setCurrentPage(Number(currentPage) + 2)}
                      >
                        {Number(currentPage) + 2}
                      </PaginationLink>
                    </PaginationItem>
                  )}
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(pageArrays.length)}
                    >
                      Last
                    </PaginationLink>
                  </PaginationItem>
                </>
              )}
              {pageArrays.length <= 10 &&
                pageArrays.map((page) => (
                  <PaginationItem
                    key={page}
                    active={page === currentPage ? true : false}
                  >
                    <PaginationLink onClick={() => setCurrentPage(page)}>
                      {page}
                    </PaginationLink>
                  </PaginationItem>
                ))}

              <PaginationItem>
                <PaginationLink onClick={() => rightPagination()}>
                  Next
                </PaginationLink>
              </PaginationItem>
            </Pagination>
          </Col>
        </Row>
      )} */}
      {data.length > 0 && (
        <Row>
          <Col
            xs={12}
            className="d-flex align-items-center justify-content-center  "
          >
            <span className="fw-bold">
              {postsPerPage * pageNumber <= rows?.length
                ? postsPerPage * pageNumber
                : rows?.length}{" "}
              of {rows?.length}
            </span>
          </Col>
        </Row>
      )}
      {isLoading && (
        <div className="d-flex justify-content-center  align-items-center h-100  ">
          <Spinner
            style={{ width: "3rem", height: "3rem" }}
            className="ms-2 "
            color="primary"
          />
        </div>
      )}
    </React.Fragment>
  );
}

export default LogsTable;
