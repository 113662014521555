import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Modal,
  Spinner,
} from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import { post } from "../../../helpers/api_helper";
import ReactJson from "react-json-view";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { removeBodyCss } from "../../../common/utils/commonFunctions";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function ApplianceTable({ data, entryValue }) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [rowId, setRowId] = useState([]);
  const [applianceID, setApplianceID] = useState();
  const [down, setDown] = useState(false);
  const [manageLoading, setManageLoading] = useState(false);
  const [manageOutput, setManageOutput] = useState();
  const [modal_standard, setmodal_standard] = useState(false);
  const [pingTestData, set_pingTestData] = useState();
  const [address, set_address] = useState();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "",
        accessor: "first_name",
      },
      {
        Header: "Customer",
        accessor: "company_name",
      },
      {
        Header: "Sync Token",
        accessor: "sync_token",
      },
      {
        Header: "Vpn",
        accessor: "is_connect",
      },
    ],
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );
  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
      // manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }

  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  const manageAppliance = async (row, manage) => {
    paceFunction(true);
    setManageLoading(true);
    setApplianceID(row.id);
    let manageData = {};
    if (manage === "SET_IFACE") {
      manageData = {
        id: row.user,
        endpoint_id: row.id,
        method: manage,
        dhcp: false,
        address: row.wan_ip,
        netmask: row.lan_netmask,
        gateway: row.lan_gw,
        nameserver: row.lan_ip,
      };
    } else if (manage === "PING_TEST") {
      manageData = {
        id: row.user,
        endpoint_id: row.id,
        method: manage,
        address,
      };
    } else {
      manageData = {
        id: row.user,
        endpoint_id: row.id,
        method: manage,
      };
    }

    try {
      let result = await post("/admin/customer/endpoint", manageData);
      if (result.success) {
        setManageOutput(result);
        if (manage === "PING_TEST") {
          tog_standard();
        }
      }
      if (!result.success) {
        setManageOutput(result);
        if (manage === "PING_TEST") {
          tog_standard();
        }
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
      setManageOutput();
    }
    paceFunction(false);
    setManageLoading(false);
  };

  return (
    <React.Fragment>
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0 fs-4 " id="myModalLabel">
            Ping Test
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="d-flex justify-content-center flex-column  align-items-center ">
          <div className=" my-2 w-100 px-3   ">
            <div className="form-floating m-0 ">
              <input
                type="text"
                name="lan_ip"
                className="form-control"
                defaultValue={address}
                onChange={(e) => set_address(e.target.value)}
              />
              <label> IP Address</label>
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={() => {
              manageAppliance(pingTestData, "PING_TEST");
            }}
            type="button"
            className="btn btn-primary "
          >
            Ping
          </button>
        </div>
      </Modal>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render("Header")}{" "}
                  {index > 0 && (
                    <span style={{ cursor: "pointer" }} title="Sorting">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : // : " 🔼"
                            " 🔽"
                        : "🔘"}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {data.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
              prepareRow(row);
              return (
                <React.Fragment key={index}>
                  <tr
                    onClick={() => {
                      setDown(!down);
                      rowIdFunc(row.id);
                    }}
                    style={{ width: "100%", cursor: "pointer" }}
                    {...row.getRowProps()}
                  >
                    <td style={{ cursor: "pointer" }} className="col-3">
                      {rowId.some((item) => item === row.id) ? (
                        <i className="fa fa-chevron-down" />
                      ) : (
                        <i className="fa fa-chevron-right" />
                      )}
                    </td>
                    <td className="col-3">{row.cells[1].value}</td>
                    <td className="col-3">{row.cells[2].value || "-"}</td>
                    <td className="col-3">
                      {row.cells[3].value ? (
                        <span>
                          <i className="mdi mdi-lan-connect me-3 text-success" />
                          Connected
                        </span>
                      ) : (
                        <span>
                          <i className="mdi mdi-lan-disconnect me-3 text-danger" />
                          Disconnected
                        </span>
                      )}
                    </td>
                    {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                  </tr>
                  <AnimatePresence>
                    {rowId.some((item) => item === row.id) && (
                      <motion.tr
                        initial={{ y: -100, opacity: 0 }}
                        animate={{ y: 0, opacity: 1 }}
                        exit={{ y: 0, opacity: 0 }}
                        transition={{ duration: 0.4 }}
                      >
                        <td>
                          <div className="row m-3">
                            <div className="col-md-10">
                              <div className="row">
                                <div className="col-md-6">
                                  <h5 className="font-size-15">Appliance ID</h5>
                                  <p>{row.original.uuid}</p>
                                </div>
                                <div className="col-md-6">
                                  <h5 className="font-size-15">Vpn Address</h5>
                                  <p>{row.original.vpn_address}</p>
                                </div>
                                <div className="col-md-3">
                                  <h5 className="font-size-15">Vpn Service</h5>
                                  <p>{row.original.vpn_service}</p>
                                </div>
                                <div className="col-md-3">
                                  <h5 className="font-size-15">
                                    VPN Interface
                                  </h5>
                                  <p>{row.original.vpn_iface}</p>
                                </div>
                                <div className="col-md-3">
                                  <h5 className="font-size-15">
                                    VPN Server Name
                                  </h5>
                                  <p>{row.original.vpn_server_name}</p>
                                </div>
                                <div className="col-md-3">
                                  <h5 className="font-size-15">Port </h5>
                                  <p>{row.original.vpn_service}</p>
                                </div>
                              </div>
                              <div className="row">
                                <div className="col-md-12">
                                  <div className="card">
                                    <div className="card-header">
                                      <h4 className="card-title text-primary ">
                                        Output
                                      </h4>{" "}
                                    </div>
                                    <div
                                      className="card-body"
                                      id="epCommandOutput-7"
                                    >
                                      {manageLoading &&
                                        applianceID === row.original.id && (
                                          <div className="d-flex justify-content-center  align-items-center h-100  ">
                                            <Spinner
                                              style={{
                                                width: "3rem",
                                                height: "3rem",
                                              }}
                                              className="ms-2 "
                                              color="primary"
                                            />
                                          </div>
                                        )}
                                      {!manageLoading &&
                                        manageOutput &&
                                        applianceID === row.original.id && (
                                          <div
                                            className="bg-reactjson"
                                            style={{
                                              maxHeight: "400px",
                                              overflowY: "overlay",
                                            }}
                                          >
                                            <ReactJson src={manageOutput} />
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-2">
                              <div className="row">
                                <h5 className="font-size-15">Manage</h5>
                              </div>
                              <div className="row mt-3">
                                <div
                                  className="btn-group-vertical"
                                  role="group"
                                  aria-label="Vertical button group"
                                >
                                  <button
                                    onClick={() => {
                                      manageAppliance(
                                        row.original,
                                        "DEVICE_INFO"
                                      );
                                    }}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    Device Info
                                  </button>
                                  <button
                                    onClick={() => {
                                      set_pingTestData(row.original);
                                      tog_standard();
                                    }}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    Ping Test
                                  </button>
                                  <button
                                    onClick={() => {
                                      manageAppliance(
                                        row.original,
                                        "GET_IFACE"
                                      );
                                    }}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    Network Interfaces
                                  </button>
                                  <button
                                    onClick={() => {
                                      manageAppliance(row.original, "GET_CONF");
                                    }}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    User Configuration
                                  </button>
                                  {/* <button
                                    onClick={() => {
                                      manageAppliance(
                                        row.original,
                                        "SET_IFACE"
                                      );
                                    }}
                                    type="button"
                                    className="btn btn-secondary setIfaceBtn"
                                  >
                                    Set Network Interface
                                  </button> */}
                                  <button
                                    onClick={() => {
                                      manageAppliance(row.original, "LOGS");
                                    }}
                                    type="button"
                                    className="btn btn-secondary"
                                  >
                                    Logs
                                  </button>
                                  <button
                                    onClick={() => {
                                      manageAppliance(row.original, "REBOOT");
                                    }}
                                    type="button"
                                    className="btn btn-danger"
                                  >
                                    Reboot Device
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </td>
                      </motion.tr>
                    )}
                  </AnimatePresence>
                </React.Fragment>
              );
            })}
          </tbody>
        )}
      </table>
      {data.length < 1 && (
        <span
          style={{ width: "100%" }}
          className="d-flex justify-content-center "
        >
          No data available in table
        </span>
      )}
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.map((page) => (
              <PaginationItem
                key={page}
                active={page === currentPage ? true : false}
              >
                <PaginationLink onClick={() => setCurrentPage(page)}>
                  {page}
                </PaginationLink>
              </PaginationItem>
            ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default ApplianceTable;
