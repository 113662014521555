import React from "react";
import { Modal } from "reactstrap";
import GroupInfo from "./GroupInfo";

const GroupsModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  groupList,
  getGroupList,
  getHostsAsset,
}) => {
  return (
    <div>
      <Modal
        size="lg"
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Host Groups
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="row">
            {groupList?.map((item, index) => (
              <GroupInfo
                getGroupList={getGroupList}
                getHostsAsset={getHostsAsset}
                key={index}
                item={item}
              />
            ))}
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default GroupsModal;
