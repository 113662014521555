import React, { useEffect, useState } from "react";
import { CardHeader, CardBody, Container } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import { useHistory } from "react-router-dom";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import continents from "../../../common/utils/continents.json";
import { GetCountries, GetState, GetCity } from "react-country-state-city";
import "react-country-state-city/dist/react-country-state-city.css";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const AddDistributor = () => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const router = useHistory();
  const [authentication, set_authentication] = useState();
  const [organization_name, set_organization_name] = useState();
  const [phone_number, set_phone_number] = useState();
  const [email, set_email] = useState();
  const [region, set_region] = useState(continents[0]);
  const [country, set_country] = useState();
  const [state, set_state] = useState();
  const [city, set_city] = useState();
  const [address, set_address] = useState();

  const [countryid, setCountryid] = useState(0);
  const [stateid, setStateid] = useState(0);
  const [cityid, setCityid] = useState(0);

  const [countriesList, setCountriesList] = useState([]);
  const [stateList, setStateList] = useState([]);
  const [cityList, setCityList] = useState([]);

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, set_addLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  useEffect(() => {
    GetCountries().then((result) => {
      set_country(result[0].name);
      setCountryid(result[0].id);
      setCountriesList(result);

      GetState(result[0].id).then((stateResult) => {
        setStateid(stateResult[0].id);
        set_state(stateResult[0].name);
        setStateList(stateResult);
        GetCity(result[0].id, stateResult[0].id).then((cityResult) => {
          set_city(cityResult[0].name);
          setCityList(cityResult);
        });
      });
    });
  }, []);

  const handleSubmit = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      organization_name,
      phone_number,
      email,
      region,
      country,
      state,
      city,
      address,
    };
    try {
      let result = await put("/admin/distributor", data);
      if (result?.success) {
        toastSuccess(result?.message || "Distributor Added", 2000);
        tog_standard5();
        setTimeout(() => {
          router.push("/admin/distributors");
        }, 3000);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={handleSubmit}
        text="Are you sure you want to add distributors?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <div className="page-content">
        <Container fluid>
          <div className="special-card">
            <CardHeader>
              <h4 className="card-title text-primary ">Add Distributor</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Account Email :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-email-multiple-outline" />
                        </span>
                        <input
                          type="email"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="email"
                          defaultValue={email}
                          onChange={(e) => set_email(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Phone Number :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-phone" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="phone_number"
                          defaultValue={phone_number}
                          onChange={(e) => set_phone_number(e.target.value)}
                          required
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Company Name :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-office-building-outline" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="organization_name"
                          defaultValue={organization_name}
                          onChange={(e) =>
                            set_organization_name(e.target.value)
                          }
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Region :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-city-variant" />
                        </span>
                        <select
                          className="form-select"
                          style={{ width: "80%" }}
                          name="region"
                          id="region"
                          onChange={(e) => set_region(e.target.value)}
                          defaultValue={region}
                        >
                          {continents.map((continentName, continentKey) => {
                            return (
                              <option
                                value={continentName}
                                key={continentName + continentKey}
                              >
                                {continentName}
                              </option>
                            );
                          })}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Country :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-flag" />
                        </span>
                        <select
                          onChange={(e) => {
                            let countryValues = JSON.parse(e.target.value);
                            set_country(countryValues.name);
                            setCountryid(countryValues.id);
                            GetState(countryValues.id).then((result) => {
                              setStateList(result);
                              setStateid(result[0].id);
                              set_state(result[0].name);
                              GetCity(countryValues.id, result[0].id).then(
                                (resultCity) => {
                                  setCityList(resultCity);
                                  setCityid(resultCity[0].id);
                                  set_city(resultCity[0].name);
                                }
                              );
                            });
                          }}
                          value={JSON.stringify(
                            countriesList.find((item) => item.name === country)
                          )}
                          className="form-select"
                          style={{ width: "80%" }}
                          name="country"
                          id="country"
                        >
                          {countriesList.map((item, index) => (
                            <option
                              key={item + index}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">State/Province :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-home-group" />
                        </span>
                        <select
                          onChange={(e) => {
                            let stateValues = JSON.parse(e.target.value);
                            set_state(stateValues.name);
                            setStateid(stateValues.id);
                            GetCity(countryid, stateValues.id).then(
                              (result) => {
                                setCityList(result);
                                setCityid(result[0].id);
                                set_city(result[0].name);
                              }
                            );
                          }}
                          value={JSON.stringify(
                            stateList.find((item) => item.name === state)
                          )}
                          className="form-select"
                          style={{ width: "80%" }}
                          name="state"
                          id="state"
                        >
                          {stateList.map((item, index) => (
                            <option
                              key={item + index}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">City/District :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-city" />
                        </span>
                        <select
                          onChange={(e) => {
                            let cityValues = JSON.parse(e.target.value); //here you will get full state object.
                            setCityid(cityValues.id);
                            set_city(cityValues.name);
                          }}
                          value={JSON.stringify(
                            cityList.find((item) => item.name === city)
                          )}
                          className="form-select"
                          style={{ width: "80%" }}
                          name="city"
                          id="city"
                        >
                          {cityList.map((item, index) => (
                            <option
                              key={item + index}
                              value={JSON.stringify(item)}
                            >
                              {item.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="pb-3">
                  <div className="row align-items-center">
                    <div className="col-xl-3">
                      <div>
                        <h5 className="font-size-15">Address :</h5>
                      </div>
                    </div>
                    <div className="col-xl">
                      <div className="input-group">
                        <span className="input-group-text">
                          <i className="mdi mdi-map" />
                        </span>
                        <input
                          type="text"
                          className="form-control"
                          style={{ width: "80%" }}
                          name="address"
                          defaultValue={address}
                          onChange={(e) => set_address(e.target.value)}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <div className="form-group">
                  <button
                    onClick={tog_standard5}
                    type="button"
                    id="save_updatebtn"
                    className="btn btn-lg btn-primary"
                  >
                    <i className="mdi mdi-book-edit-outline me-3" />
                    Add Distributor
                  </button>
                </div>
              </form>
            </CardBody>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddDistributor;
