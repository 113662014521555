import React from "react";
import Completed from "./Completed/Completed";
import Tasks from "./Tasks/Tasks";
import Progressing from "./Progressing/Progressing";
import Scheduled from "./Scheduled/Scheduled";
import OnTime from "./OnTime/OnTime";

const TasksComponent = ({ tasks, userID, getTasks }) => {
  return (
    <div className="row gap-3 ">
      {/* {tasks?.tasks?.length > 0 && (
        <div className="col-12">
          <Tasks datas={tasks?.tasks} />
        </div>
      )} */}
      {tasks?.progressing?.length > 0 && (
        <div className="col-12">
          <Progressing
            datas={tasks?.progressing}
            getTasks={getTasks}
            userID={userID}
          />
        </div>
      )}
      {tasks?.scheduled?.length > 0 && (
        <div className="col-12">
          <Scheduled
            datas={tasks?.scheduled}
            getTasks={getTasks}
            userID={userID}
          />
        </div>
      )}
      {tasks?.ontime?.length > 0 && (
        <div className="col-12">
          <OnTime datas={tasks?.ontime} getTasks={getTasks} userID={userID} />
        </div>
      )}
      {tasks?.completed?.length > 0 && (
        <div className="col-12">
          <Completed datas={tasks?.completed} />
        </div>
      )}
      {tasks?.completed?.length < 1 &&
        tasks?.queue?.length < 1 &&
        tasks?.tasks?.length < 1 && (
          <div className="d-flex justify-content-center ">
            There is no task.
          </div>
        )}
    </div>
  );
};

export default TasksComponent;
