import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { patch } from "../../../../helpers/api_helper";

import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import {
  removeBodyCss,
  toastSuccess,
  toastWarning,
} from "../../../../../common/utils/commonFunctions";

const IssuesDetail = ({
  issueDetail,
  issueID,
  modal_standard,
  tog_standard,
  setmodal_standard,
  getIssues,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isFits, setisFits] = useState(false);
  const [addLoading, setAddLoading] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const [type, setType] = useState(issueDetail?.type);
  const [subject, setSubject] = useState(issueDetail?.subject);
  const [description, setDescription] = useState(issueDetail?.description);
  const [screenshot, setScreenShot] = useState(issueDetail?.screenshot);
  const [status, setStatus] = useState(issueDetail?.status);

  useEffect(() => {
    setType(issueDetail?.type);
    setSubject(issueDetail?.subject);
    setDescription(issueDetail?.description);
    setScreenShot(issueDetail?.screenshot);
    setStatus(issueDetail?.status);
  }, [issueDetail]);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const updateIssue = async () => {
    paceFunction(true);
    setAddLoading(true);
    let data = {
      id: issueID,
      type,
      subject,
      description,
      status,
      screenshot,
    };
    if (issueDetail?.status === "WIP") {
      toastWarning(
        "You can't update the issue because its status is work in progress."
      );
    } else {
      try {
        let result = await patch("/account/issue", data);
        if (result?.success) {
          tog_standard();
          tog_standard5();
          toastSuccess(result?.message || "Issue Updated");
          getIssues();
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    }
    paceFunction(false);
    setAddLoading(false);
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={updateIssue}
        text={`Are you sure you want to update ${issueDetail?.subject} issue?`}
        loadingAction={true}
        isLoading={addLoading}
      />
      {isFits ? (
        <Lightbox
          mainSrc={`data:image/png;base64,${issueDetail?.screenshot}`}
          enableZoom={true}
          onCloseRequest={() => {
            setisFits(!isFits);
          }}
        />
      ) : null}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        {" "}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Update <span className="text-primary">{issueDetail?.subject}</span>{" "}
            Issue
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            id="issues_detail"
            noValidate
            method="post"
            action="/update_profile/"
          >
            <div className="row justify-content-center">
              <div className="col-12">
                <div
                  // style={{ width: width, height: height }}
                  className="screenshot-image"
                >
                  <img
                    src={`data:image/png;base64,${issueDetail?.screenshot}`}
                    alt={"Screenshot"}
                    onClick={() => setisFits(true)}
                    className="img-fluid"
                    // width={width}
                    // height={height}
                  />
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="ss-subject">Subject</label>
                    <input
                      name="ss-subject"
                      type="text"
                      placeholder="Subject"
                      className="input-subject mb-2"
                      onChange={(e) => setSubject(e.target.value)}
                      defaultValue={issueDetail?.subject}
                    />
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="ss-select-status">Status</label>
                    <select
                      name="ss-select-status"
                      id="ss-select-status"
                      className="ss-select"
                      style={{ padding: "11px" }}
                      onChange={(e) => setStatus(e.target.value)}
                      defaultValue={issueDetail?.status}
                      disabled={issueDetail?.status === "WIP" ? true : false}
                    >
                      <option value="OPENED">OPENED</option>
                      <option value="CLOSED">CLOSED</option>
                      <option disabled value="WIP">
                        WORK IN PROGRESS
                      </option>
                    </select>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="ss-select-type">Type</label>
                    <select
                      name="ss-select-type"
                      id="ss-select-type"
                      className="ss-select"
                      style={{ padding: "11px" }}
                      onChange={(e) => setType(e.target.value)}
                      defaultValue={issueDetail?.type}
                    >
                      <option value="QUESTION">QUESTION</option>
                      <option value="ENHANCEMENT">ENHANCEMENT</option>
                      <option value="INVALID">INVALID</option>
                    </select>
                  </div>
                </div>
                <label htmlFor="ss-description">Description</label>
                <textarea
                  name="ss-description"
                  className="rounded"
                  placeholder="Description"
                  onChange={(e) => setDescription(e.target.value)}
                  defaultValue={issueDetail?.description}
                ></textarea>
              </div>
            </div>
            <hr className="" />
            <div className="d-flex justify-content-end ">
              <button
                onClick={tog_standard5}
                type="button"
                id="confirmationTooltip1"
                className="btn btn-primary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-check-all me-3 label-icon"></i>Save
                Changes
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default IssuesDetail;
