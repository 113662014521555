import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../../../components/Common/Breadcrumb";
import { get } from "../../../../../helpers/api_helper";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { Container, Spinner } from "reactstrap";
import CyberNewsComponent from "../../../../../components/Customer/CyberWatch/ThreatIntelligence/CyberNews/CyberNewsComponent";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import InfoNoDataComponent from "../../../../../common/InfoNoDataComponent";

const CyberNews = () => {
  document.title = "Threat Intelligence | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);

  const [cyberNews, set_cyberNews] = useState();

  const [seemInfoComponent, set_seemInfoComponent] = useState(false);
  const [seemInfoComponentGrantAccess, set_seemInfoComponentGrantAccess] =
    useState(false);

  const MySwal = withReactContent(Swal);
  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "error",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const getThreatIntelligenceCyberNews = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/cyberwatch/ti/cyber-news`);
      if (result?.success) {
        if (result?.data?.length > 0) {
          set_cyberNews(
            result.data.sort((a, b) => {
              const dateA = new Date(a.date);
              const dateB = new Date(b.date);
              return dateB - dateA;
            })
          );
        } else {
          set_seemInfoComponent(true);
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
      if (!error?.response?.data?.success) {
        if (
          error?.response?.data?.message ===
          "Module permission denied. Contact with customer account."
        ) {
          set_seemInfoComponentGrantAccess(true);
        } else {
          if (error?.response?.status === 404) {
            showSweetAlert(
              error.response.data.message || "You don't have Cyber News"
            );
          }
        }
      }
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getThreatIntelligenceCyberNews();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Threat Intelligence / Cyber News"
            breadcrumbItem="Threat Intelligence / Cyber News"
          />

          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && (
            <div>
              {cyberNews && <CyberNewsComponent cyberNews={cyberNews} />}
            </div>
          )}
          {!isLoading && seemInfoComponent && (
            <InfoNoDataComponent
              text="No cyber news  were discovered"
              component="cyber-news"
            />
          )}
          {!isLoading && seemInfoComponentGrantAccess && (
            <InfoNoDataComponent
              text="Module permission denied. Contact with customer account."
              component="cyber-news"
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default CyberNews;
