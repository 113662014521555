import React, { useState } from "react";
import { Col, Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useHistory } from "react-router-dom";
import SubscribeVendorProductModal from "./SubscribeVendorProductModal";
import { del } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const VendorsProducts = ({
  vendors,
  vendorPage,
  getVendorList,
  vendorProduct,
  getVendorProductList,
  vendorProductClose,
  tags,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const router = useHistory();

  const [name, set_name] = useState();

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);

  const [delName, set_delName] = useState();

  const [deleteLoading, set_deleteLoading] = useState(false);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
    if (modal_standard) {
      set_name();
    }
  }

  function tog_standard2() {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  }

  const pages = Math.ceil(vendors?.total / 20);
  let pageArrays = [];

  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }

  const rightPagination = () => {
    if (vendorPage < pages) {
      getVendorList(Number(vendorPage) + 1);
    } else getVendorList(vendorPage);
  };

  const leftPagination = () => {
    if (vendorPage === 1) {
      getVendorList(vendorPage);
    } else if (vendorPage <= pages) {
      getVendorList(Number(vendorPage) - 1);
    }
  };

  if (vendorPage < 1) {
    getVendorList(1);
  }

  const unsubscribeVendor = async () => {
    paceFunction(true);
    set_deleteLoading(true);
    try {
      let result = await del(
        `/customer/module/cyberwatch/ti/cves/subscriptions?name=${delName}&type=vendor`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Unsubscribed");
        tog_standard2();
        getVendorList();
        set_delName();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_deleteLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={unsubscribeVendor}
        text={`Are you sure you want to unsubscribe?`}
        loadingAction={true}
        isLoading={deleteLoading}
      />
      {name && tags && (
        <SubscribeVendorProductModal
          tog_standard={tog_standard}
          modal_standard={modal_standard}
          setmodal_standard={setmodal_standard}
          allTags={tags}
          type="vendor"
          name={name}
          getList={getVendorList}
        />
      )}
      {vendorProduct ? (
        <div className="special-card card-info d-flex justify-content-between align-items-center mb-lg-0 mb-2 ">
          <div className="font-size-16">
            <i className="fa fa-filter" /> Vendor{" "}
            <strong>{vendorProduct}</strong> selected
          </div>
          <div className="">
            <span
              onClick={vendorProductClose}
              className="font-size-20"
              style={{ cursor: "pointer" }}
              aria-hidden="true"
            >
              &times;
            </span>
          </div>
        </div>
      ) : (
        <div className="special-card mb-lg-0 mb-2">
          <div className="card-header with-border">
            <h3 className="card-title">Vendors ({vendors?.total})</h3>
          </div>
          <div className="card-body table-responsive no-padding">
            <table className="table table-hover table-striped">
              <tbody>
                <tr>
                  <th>Vendor</th>
                  <th className="text-center">Products</th>
                  <th className="text-center">Actions</th>
                </tr>
                {vendors?.items?.map((vendor, vendorIndex) => (
                  <tr key={vendorIndex}>
                    <td>{vendor?.human_name || "-"}</td>
                    <td className="text-center">
                      <span
                        onClick={() => getVendorProductList(1, vendor?.name)}
                        style={{ cursor: "pointer" }}
                        className="text-primary"
                      >
                        {vendor?.product_count}
                      </span>
                    </td>
                    <td className="text-center">
                      <div className="btn-group">
                        {vendor?.is_subscribe ? (
                          <button
                            onClick={() => {
                              set_delName(vendor?.name);
                              tog_standard2();
                            }}
                            className="btn btn-danger btn-sm subscribe"
                            type="button"
                          >
                            Unsubscribe
                          </button>
                        ) : (
                          <button
                            onClick={() => {
                              set_name(vendor?.name);
                              tog_standard();
                            }}
                            className="btn btn-default-cves btn-sm subscribe"
                            type="button"
                          >
                            <i className="mdi mdi-bell-outline" /> Subscribe
                          </button>
                        )}
                        <button
                          onClick={() =>
                            router.push(
                              `/cyber-watch/threat-intelligence/cves?page=1&vendor=${vendor?.name}`
                            )
                          }
                          className="btn btn-default-cves btn-sm"
                        >
                          <i className="mdi mdi-shield-half-full" /> CVE
                        </button>
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {vendors?.items?.length < 1 && (
              <div className="w-100 bg-info py-2 text-center text-white ">
                No vendor
              </div>
            )}
            <Col className="d-flex align-items-center justify-content-center  mt-2 ">
              <Pagination
                className="d-flex align-items-center "
                aria-label="Page navigation "
              >
                <PaginationItem>
                  <PaginationLink
                    onClick={() => leftPagination()}
                    tabIndex={-1}
                  >
                    Previous
                  </PaginationLink>
                </PaginationItem>
                {pageArrays.length > 5 && (
                  <>
                    <PaginationItem>
                      <PaginationLink onClick={() => getVendorList(1)}>
                        First
                      </PaginationLink>
                    </PaginationItem>
                    {vendorPage > 2 && (
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => getVendorList(Number(vendorPage) - 2)}
                        >
                          {Number(vendorPage) - 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem active>
                      <PaginationLink
                        onClick={() => getVendorList(Number(vendorPage))}
                      >
                        {Number(vendorPage)}
                      </PaginationLink>
                    </PaginationItem>
                    {vendorPage + 2 <= pageArrays.length && (
                      <PaginationItem>
                        <PaginationLink
                          onClick={() => getVendorList(Number(vendorPage) + 2)}
                        >
                          {Number(vendorPage) + 2}
                        </PaginationLink>
                      </PaginationItem>
                    )}
                    <PaginationItem>
                      <PaginationLink
                        onClick={() => getVendorList(pageArrays.length)}
                      >
                        Last
                      </PaginationLink>
                    </PaginationItem>
                  </>
                )}
                {pageArrays.length <= 5 &&
                  pageArrays.map((page) => (
                    <PaginationItem
                      key={page}
                      active={page === vendorPage ? true : false}
                    >
                      <PaginationLink onClick={() => getVendorList(page)}>
                        {page}
                      </PaginationLink>
                    </PaginationItem>
                  ))}

                <PaginationItem>
                  <PaginationLink onClick={() => rightPagination()}>
                    Next
                  </PaginationLink>
                </PaginationItem>
              </Pagination>
            </Col>
          </div>
        </div>
      )}
    </React.Fragment>
  );
};

export default VendorsProducts;
