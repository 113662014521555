import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { logoutUser } from "../../store/actions";
import { useHistory } from "react-router-dom";
//redux
import { useDispatch } from "react-redux";

const Logout = (props) => {
  const router = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(logoutUser(props.history));
  }, [dispatch, props.history]);

  return <></>;
};

Logout.propTypes = {
  history: PropTypes.object,
};

export default Logout;
