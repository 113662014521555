import React, { useEffect, useState } from "react";
import { Col, Row, Container, Spinner } from "reactstrap";
import Breadcrumbs from "../../../../components/Common/Breadcrumb";
import { get } from "../../../../helpers/api_helper";
import ProfileInfo from "../../../../components/Distributor/Account/Profile/ProfileInfo";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";

const AdminProfile = () => {
  document.title = "Profile | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState(false);
  const [profileInfos, setProfileInfos] = useState();
  const [firstLoading, set_firstLoading] = useState(false);

  const getProfileInfos = async () => {
    paceFunction(true);
    try {
      let result = await get("/distributor/settings/account/profile");
      if (result.success) {
        setIsLoading(false);
        setProfileInfos(result.data);
        set_firstLoading(true);
      }
    } catch (error) {
      console.log(`xxxError ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoading(true);
    }
    getProfileInfos();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Profile" breadcrumbItem="Profile" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && (
            <Row>
              <Col>
                {profileInfos && (
                  <ProfileInfo
                    profileInfos={profileInfos}
                    getProfileInfos={getProfileInfos}
                  />
                )}
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AdminProfile;
