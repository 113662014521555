import React from "react";
import { getBrightness } from "../../../../common/utils/commonFunctions";

const DynamicSeverityCount = ({ title, total, color }) => {
  return (
    <div className="special-score-card">
      <div className="d-flex  py-2  align-items-center justify-content-between  ">
        <div>
          <span className="fw-bold fs-4 me-1 ">{title} </span>
        </div>
      </div>
      <div
        style={{
          backgroundColor: color,
        }}
        className="row row-bs "
      >
        <div
          style={{
            height: "265px",
          }}
          className={`d-flex  align-items-center justify-content-center `}
        >
          <div style={{ fontSize: "76px" }} className=" fw-bold   ">
            <span
              style={{
                color: `${getBrightness(color) < 128 ? "white" : "black"}`,
              }}
            >
              {total}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicSeverityCount;
