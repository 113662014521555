import React from "react";
import ReactApexChart from "react-apexcharts";
const LineGraphic = ({ data }) => {
  const extractValuesByKey = (data, key) => {
    return data?.map((item) => item[key]);
  };
  const scoreArray = extractValuesByKey(data, "score");
  const createdAtArray = extractValuesByKey(data, "created_at");

  let lastScoreArray = [];
  if (scoreArray.length > 0) {
    lastScoreArray = scoreArray.map((item) => {
      if (item >= 0 && item <= 0.8) {
        return 0;
      } else if (item > 0.8 && item <= 1.5) {
        return 1;
      } else if (item > 1.5 && item <= 2.4) {
        return 2;
      } else if (item > 2.4 && item <= 3.5) {
        return 3;
      } else {
        return 4;
      }
    });
  }
  const datas = lastScoreArray;
  const options = {
    chart: {
      height: "50px",
      type: "line",
      toolbar: { show: false },
    },
    colors: ["#FF1654", "#247BA0"],
    stroke: {
      curve: "smooth",
      width: 2,
    },
    xaxis: {
      labels: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      axisBorder: {
        show: false,
      },
      gridLines: {
        display: false,
      },
      categories: createdAtArray,
    },
    yaxis: {
      // reversed: true,
      labels: {
        show: false,
        formatter: function (value, index) {
          const yLabels = ["A", "B", "C", "D", "E"];

          if (value >= 0 && value <= 0.8) {
            return yLabels[0];
          } else if (value > 0.8 && value <= 1.5) {
            return yLabels[1];
          } else if (value > 1.5 && value <= 2.4) {
            return yLabels[2];
          } else if (value > 2.4 && value <= 3.5) {
            return yLabels[3];
          } else {
            return yLabels[4];
          }
        },
      },
      gridLines: {
        display: false,
      },
    },
    tooltip: {
      enabled: true,
      fixed: {
        enabled: true,
      },
      x: {
        show: false,
        enabled: false,
      },
      y: {
        enabled: false,
        title: {
          formatter: function (seriesName) {
            return "";
          },
        },
      },
      marker: {
        enabled: false,
        show: false,
      },
    },
    grid: {
      show: false,
      row: {
        colors: ["transparent", "transparent"], // takes an array which will be repeated on columns
        opacity: 1,
        show: false,
      },
    },
  };

  return (
    <div className="d-flex justify-content-center ">
      <div className="col-8    ">
        <ReactApexChart
          options={options}
          series={[{ data: datas }]}
          type="line"
          className="apex-charts"
          dir="ltr"
          height="115"
        />
      </div>
    </div>
  );
};

export default LineGraphic;
