import React from "react";
import ReactApexChart from "react-apexcharts";
import { useSelector } from "react-redux";
import { CardBody, CardHeader } from "reactstrap";
import { randomHexCode } from "../../../../../common/utils/commonFunctions";

const ColumnWithDataLabels = ({ data }) => {
  const osNameArray = data?.map((item) => item?.os_name);
  const countArray = data?.map((item) => item?.count);

  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const colors = [];

  for (let i = 0; i < 10; i++) {
    const hexKodu = randomHexCode();
    colors.push(hexKodu);
  }
  const series = [
    {
      name: [""],
      data: countArray,
    },
  ];
  const options = {
    chart: {
      toolbar: {
        show: false,
      },
    },
    plotOptions: {
      bar: {
        distributed: true,
        borderRadius: 10,
        columnWidth: "50%",
        dataLabels: {
          position: "top", // top, center, bottom
        },
      },
    },
    dataLabels: {
      enabled: true,
      formatter: function (val) {
        // return val + "%";
        return val + "";
      },
      offsetY: -20,
      style: {
        fontSize: "12px",
        colors: layoutMode === "dark" ? ["#c5c5c5"] : ["#000000"],
      },
    },
    colors,
    grid: { show: true, borderColor: "#FFF" },
    xaxis: {
      categories: osNameArray,
      labels: {
        show: true,
        rotate: -45,
        rotateAlways: true,
        fontSize: "100px",
        minHeight: 100,
        maxHeight: 180,
        style: {
          fontWeight: "bold",
          fontSize: "12px",
          color: "#304758",
        },
      },
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      crosshairs: {
        fill: {
          type: "gradient",
          gradient: {
            colorFrom: "#D8E3F0",
            colorTo: "#BED1E6",
            stops: [0, 100],
            opacityFrom: 0.4,
            opacityTo: 0.5,
          },
        },
      },
      tooltip: {
        enabled: false,
        offsetY: -35,
      },
    },
    fill: {
      gradient: {
        shade: "light",
        type: "horizontal",
        shadeIntensity: 0.25,
        gradientToColors: undefined,
        inverseColors: true,
        opacityFrom: 1,
        opacityTo: 1,
        stops: [50, 0, 100, 100],
      },
    },
    yaxis: {
      axisBorder: {
        show: false,
      },
      axisTicks: {
        show: false,
      },
      labels: {
        show: true,
        formatter: function (val) {
          //   return val + "%";
          return val + "";
        },
      },
    },
    title: {
      //   text: "Monthly Inflation in Argentina, 2002",
      floating: true,
      offsetY: 330,
      align: "center",
      style: {
        color: "#444",
      },
    },
  };
  return (
    <div className="special-card">
      <CardHeader>
        <h4 className="card-title mb-0">Most Common Operating Systems </h4>
      </CardHeader>
      <CardBody>
        <ReactApexChart
          options={options}
          series={series}
          type="bar"
          height={350}
        />
      </CardBody>
    </div>
  );
};
export default React.memo(ColumnWithDataLabels);
