import React, { useEffect, useState } from "react";
import { Collapse, Modal } from "reactstrap";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  getBrightness,
  goToTop,
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";
import {
  dynamicWizardInfoType,
  moduleScoreValues,
  severityValues,
} from "../../../../common/utils/commonValues";
import Select, { components } from "react-select";
import makeAnimated from "react-select/animated";
import { useSelector } from "react-redux";
import ColorPicker from "@vtaits/react-color-picker";
import { v4 as uuidv4 } from "uuid";
import classNames from "classnames";

const animatedComponents = makeAnimated();

const ValueContainer = ({ children, ...props }) => {
  let [values, input] = children;

  if (Array.isArray(values)) {
    const plural = values.length === 1 ? "" : "s";
    values = `${values.length} (item${plural}) selected`;
  }

  return (
    <components.ValueContainer {...props}>
      {values}
      {input}
    </components.ValueContainer>
  );
};

const AddWidgetModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  getWidgets,
  getDefaultWidgets,
  default_widgets,
}) => {
  const { layoutMode } = useSelector((state) => ({
    layoutMode: state.Layout.layoutMode,
  }));

  const [step, setStep] = useState(1);
  const buttons = Array(2).fill(0);

  const [graphic, set_graphic] = useState(false);
  const [graphic_type, set_graphic_type] = useState("donut");
  const [type, set_type] = useState("last_module_score");
  const [values, set_values] = useState([]);
  const [color, set_color] = useState("#ffffff");
  const [title, set_title] = useState("");

  const [colorFirst, set_colorFirst] = useState("#ffffff");
  const [colorSecond, set_colorSecond] = useState("#ffffff");
  const [colorThird, set_colorThird] = useState("#ffffff");
  const [colorFourth, set_colorFourth] = useState("#ffffff");
  const [colorFifth, set_colorFifth] = useState("#ffffff");

  const [simple_color1, setsimple_color1] = useState(false);

  const [simple_colorFirst, setsimple_colorFirst] = useState(false);
  const [simple_colorSecond, setsimple_colorSecond] = useState(false);
  const [simple_colorThird, setsimple_colorThird] = useState(false);
  const [simple_colorFourth, setsimple_colorFourth] = useState(false);
  const [simple_colorFifth, setsimple_colorFifth] = useState(false);

  const [score_trends, set_score_trends] = useState(
    default_widgets?.score_trends
  );
  const [score, set_score] = useState(default_widgets?.score);
  const [distribution_severity, set_distribution_severity] = useState(
    default_widgets?.distribution_severity
  );
  const [severity_changes, set_severity_changes] = useState(
    default_widgets?.severity_changes
  );

  const [options, set_options] = useState([]);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const onDragRgb = (c1) => {
    set_color(c1);
  };

  const customStyles = {
    control: (provided) => ({
      ...provided,
      overFlowY: "overlay",
      backgroundColor: layoutMode === "dark" ? "#313533" : "white",
      color: layoutMode === "dark" ? "white" : "black",
    }),
    menu: (provided) => ({
      ...provided,
      backgroundColor: layoutMode === "dark" ? "#313533" : "white",
      color: layoutMode === "dark" ? "white" : "black",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: layoutMode === "dark" ? "white" : "",
    }),
    input: (provided) => ({
      ...provided,
      color: layoutMode === "dark" ? "white" : "",
    }),
  };

  useEffect(() => {
    if (!modal_standard) {
      set_graphic(false);
      set_graphic_type("donut");
      set_type("last_module_score");
      set_values([]);
      set_color("#ffffff");
      set_title("");

      set_colorFirst("#ffffff");
      set_colorSecond("#ffffff");
      set_colorThird("#ffffff");
      set_colorFourth("#ffffff");
      set_colorFifth("#ffffff");

      setsimple_color1(false);

      setsimple_colorFirst(false);
      setsimple_colorSecond(false);
      setsimple_colorThird(false);
      setsimple_colorFourth(false);
      setsimple_colorFifth(false);

      set_options([]);

      setStep(1);
    }
  }, [modal_standard]);

  useEffect(() => {
    if (graphic) {
      set_type("total_severity_count");
      set_options(severityValues);
      set_values([]);
    }
  }, [graphic]);

  useEffect(() => {
    if (!graphic) {
      if (type === "last_module_score") {
        set_values([moduleScoreValues[0].value]);
      }
      if (type === "total_severity_count") {
        set_values([severityValues[0].value]);
      }
    }
  }, [graphic, type]);

  useEffect(() => {
    set_score_trends(default_widgets?.score_trends);
    set_score(default_widgets?.score);
    set_distribution_severity(default_widgets?.distribution_severity);
    set_severity_changes(default_widgets?.severity_changes);
  }, [default_widgets]);

  const handleChange = (selectItem) => {
    if (selectItem.length > 0) {
      let checkItemInValues = values.some(
        (item) => item.value === selectItem[selectItem.length - 1].value
      );
      if (!checkItemInValues) {
        set_values((prew) => [...prew, selectItem[selectItem.length - 1]]);
      } else {
        set_values(selectItem);
      }
    } else {
      set_values([]);
    }
  };

  const addWidget = async () => {
    let storageWidgets = window.localStorage.getItem("widgets");
    let data;
    if (graphic) {
      let fixValues = values.map((item) => item.value);
      data = {
        id: uuidv4(),
        graphic,
        graphic_type,
        title,
        type: "total_severity_count",
        values: fixValues,
        label_colors: [
          colorFirst,
          colorSecond,
          colorThird,
          colorFourth,
          colorFifth,
        ],
      };
    } else {
      data = {
        id: uuidv4(),
        graphic,
        graphic_type: null,
        title,
        type,
        values,
        color,
        label_colors: null,
      };
    }
    if (storageWidgets) {
      let parsedStorageWidgets = JSON.parse(storageWidgets);
      let addedWidgets = [...parsedStorageWidgets, data];
      window.localStorage.setItem("widgets", JSON.stringify(addedWidgets));
      toastSuccess("Widget Added");
      tog_standard5();
      tog_standard();
      getWidgets();
    } else {
      window.localStorage.setItem("widgets", JSON.stringify([data]));
      toastSuccess("Widget Added");
      tog_standard5();
      tog_standard();
      getWidgets();
    }
  };

  const addDefaultWidgets = async () => {
    let data = {
      score_trends,
      score,
      distribution_severity,
      severity_changes,
    };
    window.localStorage.setItem("defaultWidgets", JSON.stringify(data));
    toastSuccess("Widget Situation Changed");
    tog_standard5();
    tog_standard();
    getDefaultWidgets();
    goToTop();
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={step === 1 ? addWidget : addDefaultWidgets}
        text={`Are you sure want to ${step === 1 ? "add" : "update"} widget ?`}
        loadingAction={false}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            {step === 1 ? "Add" : "Add Default"} Widget
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div className="d-flex justify-content-center align-items-center gap-2 mb-3">
            <span
              onClick={() => setStep(1)}
              className={classNames("badge  font-size-14  cursor-pointer", {
                "bg-orange": step === 1,
                "bg-primary": step === 2,
              })}
            >
              Custom
            </span>
            <span
              onClick={() => setStep(2)}
              className={classNames("badge  font-size-14  cursor-pointer", {
                "bg-primary": step === 1,
                "bg-orange": step === 2,
              })}
            >
              Default
            </span>
          </div>
          {/* Add Widget */}
          {step === 1 && (
            <div className="row ">
              <div className="d-flex flex-column justify-content-center align-items-normal">
                {/* Graphic Sections */}

                <div className="col-12 d-flex ">
                  <div className="col-4 d-flex align-items-center pt-1">
                    <label
                      style={{ paddingBottom: "9px" }}
                      className="form-label me-2"
                    >
                      Graphic
                    </label>
                    <div className="square-switch">
                      <input
                        type="checkbox"
                        id="graphic1"
                        name="graphic"
                        is_demo="graphic"
                        switch="primary"
                        defaultChecked={graphic}
                        autoComplete="off"
                        onChange={(e) => set_graphic(e.target.checked)}
                      />
                      <label
                        htmlFor="graphic1"
                        data-on-label="Yes"
                        data-off-label="No"
                      />
                    </div>
                  </div>
                  {graphic && (
                    <div className="col-8">
                      <div className="d-flex ">
                        <label className="form-label me-2 mt-1 w-100">
                          Graphic Type
                        </label>
                        <div className="input-group">
                          <div
                            className="btn-group btn-group-sm   "
                            role="group"
                          >
                            <input
                              type="radio"
                              className="btn-check"
                              name="authentication"
                              id="graphic_type_donut"
                              defaultValue="donut"
                              autoComplete="off"
                              defaultChecked={
                                graphic_type === "donut" ? true : false
                              }
                              onChange={(e) => set_graphic_type(e.target.value)}
                            />
                            <label
                              className="btn btn-outline-primary"
                              htmlFor="graphic_type_donut"
                            >
                              Donut
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="authentication"
                              id="graphic_type_pie"
                              defaultValue="pie"
                              autoComplete="off"
                              defaultChecked={
                                graphic_type === "pie" ? true : false
                              }
                              onChange={(e) => set_graphic_type(e.target.value)}
                            />
                            <label
                              className="btn btn-outline-primary"
                              htmlFor="graphic_type_pie"
                            >
                              Pie
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="authentication"
                              id="graphic_type_bar"
                              defaultValue="bar"
                              autoComplete="off"
                              defaultChecked={
                                graphic_type === "bar" ? true : false
                              }
                              onChange={(e) => set_graphic_type(e.target.value)}
                            />
                            <label
                              className="btn btn-outline-primary"
                              htmlFor="graphic_type_bar"
                            >
                              Bar
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="authentication"
                              id="graphic_type_polar"
                              defaultValue="polarArea"
                              autoComplete="off"
                              defaultChecked={
                                graphic_type === "polarArea" ? true : false
                              }
                              onChange={(e) => set_graphic_type(e.target.value)}
                            />
                            <label
                              className="btn btn-outline-primary"
                              htmlFor="graphic_type_polar"
                            >
                              Polar
                            </label>
                            <input
                              type="radio"
                              className="btn-check"
                              name="authentication"
                              id="graphic_type_radar"
                              defaultValue="radar"
                              autoComplete="off"
                              defaultChecked={
                                graphic_type === "radar" ? true : false
                              }
                              onChange={(e) => set_graphic_type(e.target.value)}
                            />
                            <label
                              className="btn btn-outline-primary"
                              htmlFor="graphic_type_radar"
                            >
                              Radar
                            </label>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </div>

                {/* Data Type */}

                {!graphic && (
                  <div className="col-12 ">
                    <label className="form-label me-2">Data Type</label>
                    <select
                      className="form-select border-primary "
                      onChange={(e) => set_type(e.target.value)}
                      defaultValue={type}
                    >
                      {dynamicWizardInfoType.map((item) => (
                        <option
                          key={item.label + item.value}
                          value={item.value}
                        >
                          {item.label}
                        </option>
                      ))}
                    </select>
                  </div>
                )}

                {/* Without Graphic Values */}

                {!graphic && (
                  <div className="col-12 mt-1">
                    <label className="form-label me-2">Value</label>
                    <select
                      className="form-select border-primary "
                      onChange={(e) => set_values([e.target.value])}
                    >
                      {type === "last_module_score" &&
                        moduleScoreValues.map((item) => (
                          <option
                            key={item.label + item.value}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                      {type === "total_severity_count" &&
                        severityValues.map((item) => (
                          <option
                            key={item.label + item.value}
                            value={item.value}
                          >
                            {item.label}
                          </option>
                        ))}
                    </select>
                  </div>
                )}

                {/* Graphic Values */}

                {graphic && (
                  <div className="col-12 mt-1">
                    <label className="form-label me-2">Value</label>
                    <Select
                      className="input-dark"
                      isMulti={true}
                      options={options}
                      value={values}
                      onChange={handleChange}
                      closeMenuOnSelect={false}
                      components={{ animatedComponents, ValueContainer }}
                      styles={customStyles}
                      hideSelectedOptions={false}
                    />
                  </div>
                )}

                {/* Background Color */}

                {!graphic && type === "total_severity_count" && (
                  <div className="col-12">
                    <label className="form-label mt-2">Background Color</label>
                    <div className="form-group my-1 ">
                      <div
                        className="input-group colorpicker-default "
                        title="Using format option"
                      >
                        <input
                          readOnly
                          value={color}
                          type="text"
                          className="form-control cursor-pointer"
                          onClick={() => {
                            setsimple_color1(!simple_color1);
                          }}
                        />

                        {/* <span
                      className="input-group-text colorpicker-input-addon"
                      onClick={() => {
                        setsimple_color1(!simple_color1);
                      }}
                    >
                      <i
                        style={{
                          height: "16px",
                          width: "16px",
                          background: color,
                        }}
                      />
                    </span> */}
                        <div
                          className="badge p-2 d-flex justify-content-center align-items-center border-colorPicker-badge "
                          style={{
                            color: `${
                              getBrightness(color) < 128 ? "white" : "black"
                            }`,
                            backgroundColor: color,
                          }}
                        >
                          100
                        </div>
                      </div>

                      <Collapse isOpen={simple_color1}>
                        <ColorPicker
                          saturationHeight={100}
                          saturationWidth={100}
                          value={color}
                          onDrag={onDragRgb}
                        />
                      </Collapse>
                    </div>
                  </div>
                )}

                {/* Graphic Colors */}

                {graphic && (
                  <React.Fragment>
                    <div className="col-12 mt-2">
                      <label className="form-label ">
                        First Graphic Color{" "}
                      </label>
                      <div className="form-group  ">
                        <div
                          className="input-group colorpicker-default "
                          title="Using format option"
                        >
                          <input
                            readOnly
                            value={colorFirst}
                            type="text"
                            className="form-control cursor-pointer"
                            onClick={() => {
                              setsimple_colorFirst(!simple_colorFirst);
                            }}
                          />

                          <span
                            className="input-group-text colorpicker-input-addon"
                            onClick={() => {
                              setsimple_colorFirst(!simple_colorFirst);
                            }}
                          >
                            <i
                              style={{
                                height: "16px",
                                width: "16px",
                                background: colorFirst,
                              }}
                            />
                          </span>
                        </div>

                        <Collapse isOpen={simple_colorFirst}>
                          <ColorPicker
                            saturationHeight={100}
                            saturationWidth={100}
                            value={colorFirst}
                            onDrag={(e) => set_colorFirst(e)}
                          />
                        </Collapse>
                      </div>
                    </div>
                    {values.length > 1 && (
                      <div className="col-12 mt-2">
                        <label className="form-label ">
                          Second Graphic Color
                        </label>
                        <div className="form-group  ">
                          <div
                            className="input-group colorpicker-default "
                            title="Using format option"
                          >
                            <input
                              readOnly
                              value={colorSecond}
                              type="text"
                              className="form-control cursor-pointer"
                              onClick={() => {
                                setsimple_colorSecond(!simple_colorSecond);
                              }}
                            />

                            <span
                              className="input-group-text colorpicker-input-addon"
                              onClick={() => {
                                setsimple_colorSecond(!simple_colorSecond);
                              }}
                            >
                              <i
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  background: colorSecond,
                                }}
                              />
                            </span>
                          </div>

                          <Collapse isOpen={simple_colorSecond}>
                            <ColorPicker
                              saturationHeight={100}
                              saturationWidth={100}
                              value={colorSecond}
                              onDrag={(e) => set_colorSecond(e)}
                            />
                          </Collapse>
                        </div>
                      </div>
                    )}
                    {values.length > 2 && (
                      <div className="col-12 mt-2">
                        <label className="form-label ">
                          Third Graphic Color{" "}
                        </label>
                        <div className="form-group  ">
                          <div
                            className="input-group colorpicker-default "
                            title="Using format option"
                          >
                            <input
                              readOnly
                              value={colorThird}
                              type="text"
                              className="form-control cursor-pointer"
                              onClick={() => {
                                setsimple_colorThird(!simple_colorThird);
                              }}
                            />

                            <span
                              className="input-group-text colorpicker-input-addon"
                              onClick={() => {
                                setsimple_colorThird(!simple_colorThird);
                              }}
                            >
                              <i
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  background: colorThird,
                                }}
                              />
                            </span>
                          </div>

                          <Collapse isOpen={simple_colorThird}>
                            <ColorPicker
                              saturationHeight={100}
                              saturationWidth={100}
                              value={colorThird}
                              onDrag={(e) => set_colorThird(e)}
                            />
                          </Collapse>
                        </div>
                      </div>
                    )}
                    {values.length > 3 && (
                      <div className="col-12 mt-2">
                        <label className="form-label ">
                          Fourth Graphic Color
                        </label>
                        <div className="form-group  ">
                          <div
                            className="input-group colorpicker-default "
                            title="Using format option"
                          >
                            <input
                              readOnly
                              value={colorFourth}
                              type="text"
                              className="form-control cursor-pointer"
                              onClick={() => {
                                setsimple_colorFourth(!simple_colorFourth);
                              }}
                            />

                            <span
                              className="input-group-text colorpicker-input-addon"
                              onClick={() => {
                                setsimple_colorFourth(!simple_colorFourth);
                              }}
                            >
                              <i
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  background: colorFourth,
                                }}
                              />
                            </span>
                          </div>

                          <Collapse isOpen={simple_colorFourth}>
                            <ColorPicker
                              saturationHeight={100}
                              saturationWidth={100}
                              value={colorFourth}
                              onDrag={(e) => set_colorFourth(e)}
                            />
                          </Collapse>
                        </div>
                      </div>
                    )}
                    {values.length > 4 && (
                      <div className="col-12 mt-2">
                        <label className="form-label ">
                          Fifth Graphic Color{" "}
                        </label>
                        <div className="form-group  ">
                          <div
                            className="input-group colorpicker-default "
                            title="Using format option"
                          >
                            <input
                              readOnly
                              value={colorFifth}
                              type="text"
                              className="form-control cursor-pointer"
                              onClick={() => {
                                setsimple_colorFifth(!simple_colorFifth);
                              }}
                            />

                            <span
                              className="input-group-text colorpicker-input-addon"
                              onClick={() => {
                                setsimple_colorFifth(!simple_colorFifth);
                              }}
                            >
                              <i
                                style={{
                                  height: "16px",
                                  width: "16px",
                                  background: colorFifth,
                                }}
                              />
                            </span>
                          </div>

                          <Collapse isOpen={simple_colorFifth}>
                            <ColorPicker
                              saturationHeight={100}
                              saturationWidth={100}
                              value={colorFifth}
                              onDrag={(e) => set_colorFifth(e)}
                            />
                          </Collapse>
                        </div>
                      </div>
                    )}
                  </React.Fragment>
                )}

                {/* Title */}

                <div className="col-12 mt-2">
                  <label className="form-label me-2">Title</label>
                  <input
                    className="form-control border-primary "
                    onChange={(e) => set_title(e.target.value)}
                    defaultValue={title}
                  ></input>
                </div>
              </div>
            </div>
          )}

          {/* Default Widgets */}
          {step === 2 && (
            <div className="row">
              <div className="col-6">
                <div className="d-flex justify-content-center me-2  align-items-center">
                  <h5 className="font-size-15">Score Trends</h5>
                </div>
                <div className="square-switch d-flex justify-content-center align-items-center">
                  <input
                    type="checkbox"
                    id="score_trends"
                    name="score_trends"
                    switch="primary"
                    defaultChecked={score_trends}
                    onChange={(e) => set_score_trends(e.target.checked)}
                  />
                  <label
                    htmlFor="score_trends"
                    data-on-label="On"
                    data-off-label="Off"
                  />
                </div>{" "}
              </div>
              <div className="col-6">
                <div className="d-flex justify-content-center me-2  align-items-center">
                  <h5 className="font-size-15">Score</h5>
                </div>
                <div className="square-switch d-flex justify-content-center align-items-center">
                  <input
                    type="checkbox"
                    id="score"
                    name="score"
                    switch="primary"
                    defaultChecked={score}
                    onChange={(e) => set_score(e.target.checked)}
                  />
                  <label
                    htmlFor="score"
                    data-on-label="On"
                    data-off-label="Off"
                  />
                </div>{" "}
              </div>
              <div className="col-6">
                <div className="d-flex justify-content-center me-2  align-items-center">
                  <h5 className="font-size-15">Distribution Of Severity</h5>
                </div>
                <div className="square-switch d-flex justify-content-center align-items-center">
                  <input
                    type="checkbox"
                    id="distribution_severity"
                    name="distribution_severity"
                    switch="primary"
                    defaultChecked={distribution_severity}
                    onChange={(e) =>
                      set_distribution_severity(e.target.checked)
                    }
                  />
                  <label
                    htmlFor="distribution_severity"
                    data-on-label="On"
                    data-off-label="Off"
                  />
                </div>{" "}
              </div>
              <div className="col-6">
                <div className="d-flex justify-content-center me-2  align-items-center">
                  <h5 className="font-size-15">Severity Changes</h5>
                </div>
                <div className="square-switch d-flex justify-content-center align-items-center">
                  <input
                    type="checkbox"
                    id="severity_changes"
                    name="severity_changes"
                    switch="primary"
                    defaultChecked={severity_changes}
                    onChange={(e) => set_severity_changes(e.target.checked)}
                  />
                  <label
                    htmlFor="severity_changes"
                    data-on-label="On"
                    data-off-label="Off"
                  />
                </div>{" "}
              </div>
            </div>
          )}
        </div>
        <div className="modal-footer">
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary text-truncate"
          >
            {step === 1 ? "Add Widget" : "Add Default Widget"}
          </button>
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AddWidgetModal;
