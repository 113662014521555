import React, { useEffect, useState } from "react";
import { Modal } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

const AssignForwardModal = ({
  modal_standard,
  setmodal_standard,
  tog_standard,
  rowId,
  owners,
  getCustomers,
  toggleDropdown,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard5, setmodal_standard5] = useState(false);
  const [loading, set_loading] = useState(false);
  const [selectedID, set_selectedID] = useState();

  useEffect(() => {
    set_selectedID(owners[0].id);
  }, [owners]);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const AssignForwardFunction = async () => {
    paceFunction(true);
    set_loading(true);

    let data = {
      id: rowId,
      forward_id: selectedID,
    };

    try {
      let result = await put(`/distributor/customer`, data);
      if (result?.success) {
        toastSuccess(result?.message || "Customer Assigned");
        toggleDropdown();
        tog_standard5();
        tog_standard();
        getCustomers();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_loading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Delete Manager */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={AssignForwardFunction}
        text="Are you sure you want to assign customer?"
        loadingAction={true}
        isLoading={loading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="md"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            PentestBX asks
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <h5 className="mt-2">Select manager</h5>
          <select
            className="form-select"
            style={{ width: "80%" }}
            onChange={(e) => set_selectedID(e.target.value)}
          >
            {owners.map((ownersInfo, ownersKey) => {
              return (
                <option
                  value={ownersInfo.id}
                  key={ownersInfo?.organization_name + ownersKey}
                >
                  {ownersInfo?.organization_name}
                </option>
              );
            })}
          </select>
        </div>

        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Cancel
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Yes
          </button>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default AssignForwardModal;
