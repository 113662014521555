import React from "react";
import { AiFillFolder, AiFillFile } from "react-icons/ai";
import { MdArrowDropDown, MdArrowRight } from "react-icons/md";
import FolderTree from "./FolderTree";
import { Tree } from "react-arborist";
const NetworkSharesTree = ({ data }) => {
  const filterDirectories = (data) => {
    const directoryArray = Object.values(data).filter(
      (obj) => obj.type === "directory"
    );
    return directoryArray;
  };
  let directories = filterDirectories(data);

  const convertToArboristFormat = (dataArray) => {
    const result = [];

    const iterateData = (items, parent = null) => {
      items.forEach((item, index) => {
        const newItem = {
          id: item.path
            ? item.path + Math.floor(Math.random() * 10000)
            : item.name + Math.floor(Math.random() * 10000),
          name: item.path ? item.path : item.name,
          children: item?.type === "directory" ? [] : null,
          //   isOpen: "true",
        };

        if (item.shares) {
          iterateData(item.shares, newItem);
        }

        if (parent) {
          parent.children.push(newItem);
        } else {
          result.push(newItem);
        }
      });
    };

    iterateData(dataArray);

    return result;
  };
  const arboristData = convertToArboristFormat(directories);

  return (
    <div>
      {/* <FolderTree data={arboristData} /> */}
      <Tree initialData={arboristData} width="1000" openByDefault={false} />
    </div>
  );
};

export default NetworkSharesTree;
