import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Dropdown,
  DropdownToggle,
  DropdownItem,
  DropdownMenu,
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
  Table,
} from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import { useSelector } from "react-redux";
import { toastSuccess } from "../../../common/utils/commonFunctions";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function VulnerabilitiesTable({ data, entryValue }) {
  // Seem
  const [seeFirst, setSeeFirst] = useState(true);
  const [seeSecond, setSeeSecond] = useState(true);
  const [seeThird, setSeeThird] = useState(true);
  const [seeFourth, setSeeFourth] = useState(true);
  // Others
  const [btnprimary1, setBtnprimary1] = useState(false);
  const [rowId, setRowId] = useState([]);
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const { isDemoAccount } = useSelector((state) => ({
    isDemoAccount: state.custom.isDemoAccount,
  }));

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "first_name",
      },
      {
        Header: "Attachment Name",
        accessor: "name",
      },
      {
        Header: "Tags",
        accessor: "tags",
      },
      {
        Header: "Result",
        accessor: "secure_type",
      },
    ],
    []
  );

  const downloadCSV = (data) => {
    const csvHeader = "Attachment Name;Tags;Result";

    const csvRows = data.map((item) => {
      const secureStatus = item.secure_type;
      const tags = item.tags.join(", "); // Etiketleri virgülle ayırın
      return `${item.name};"${tags}";${secureStatus}`;
    });

    const csvContent = [csvHeader, ...csvRows].join("\n");

    const blob = new Blob([csvContent], { type: "text/csv;charset=utf-8" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = "Mailbox Threats.csv";
    document.body.appendChild(a);
    a.click();

    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
  };

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      initialState: {
        sortBy,
      },
      // manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };
  let rowData = [];

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col md={3}>
          <div className="dt-buttons  flex-wrap justify-content-center d-flex ">
            <button
              title="Copy Clipboard"
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                const csvHeader = "Attachment Name,Tags,Result";

                const csvRows = data.map((item) => {
                  const secureStatus = item?.secure_type;
                  const tags = item?.tags.join(", "); // Etiketleri virgülle ayırın
                  return `${item?.name},[${tags}],${secureStatus}`;
                });

                const csvContent = [csvHeader, ...csvRows].join("\n");

                var table = document.createElement("table");
                var tableBody = document.createElement("tbody");

                var rows = csvContent.split("\n");
                for (var i = 0; i < rows.length; i++) {
                  var row = document.createElement("tr");
                  var cells = rows[i].split("\t");
                  for (var j = 0; j < cells.length; j++) {
                    var cell = document.createElement(i === 0 ? "th" : "td");
                    cell.appendChild(document.createTextNode(cells[j]));
                    row.appendChild(cell);
                  }
                  tableBody.appendChild(row);
                }

                table.appendChild(tableBody);
                document.body.appendChild(table);

                var range = document.createRange();
                range.selectNode(table);
                window.getSelection().removeAllRanges();
                window.getSelection().addRange(range);

                try {
                  document.execCommand("copy");
                  toastSuccess("All Rows Copied");
                } catch (err) {
                  console.error("Panoya kopyalama hatası:", err);
                }

                table.remove();
                window.getSelection().removeAllRanges();
              }}
            >
              <span className="avatar-title bg-transparent">
                <i className="mdi mdi-content-copy  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <button
              type="button"
              className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              onClick={() => {
                downloadCSV(data);
              }}
              title="Download CSV"
            >
              <span className="avatar-title bg-transparent d-flex flex-column ">
                <i className="mdi mdi-microsoft-excel  "></i>
              </span>
              <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                <span className="visually-hidden">unread messages</span>
              </span>
            </button>
            <Dropdown
              isOpen={btnprimary1}
              toggle={() => setBtnprimary1(!btnprimary1)}
            >
              <DropdownToggle
                title="Column Visibility"
                tag="button"
                className="btn btn-primary position-relative p-2 avatar-sm me-2  rounded"
              >
                <i className="mdi mdi-eye" />
                <span className="position-absolute top-0 start-100 translate-middle badge border border-light rounded-circle bg-danger p-1">
                  <span className="visually-hidden">unread messages</span>
                </span>
              </DropdownToggle>
              <DropdownMenu>
                <DropdownItem
                  onClick={() => {
                    setSeeFirst(!seeFirst);
                  }}
                  className={`${seeFirst ? "bg-soft-primary" : ""}`}
                >
                  #
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeSecond(!seeSecond);
                  }}
                  className={`${seeSecond ? "bg-soft-primary" : ""}`}
                >
                  Name
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeThird(!seeThird);
                  }}
                  className={`${seeThird ? "bg-soft-primary" : ""}`}
                >
                  Tags
                </DropdownItem>
                <DropdownItem
                  onClick={() => {
                    setSeeFourth(!seeFourth);
                  }}
                  className={`${seeFourth ? "bg-soft-primary" : ""}`}
                >
                  Result
                </DropdownItem>
              </DropdownMenu>
            </Dropdown>
          </div>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <div style={{ maxHeight: "1000px", overflowY: "scroll" }}>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th
                  style={{
                    width: `${seeFirst ? "10%" : "0% "}`,
                    display: `${seeFirst ? "" : "none "}`,
                  }}
                >
                  {headerGroup.headers[0].Header}
                </th>
                <th
                  {...headerGroup.headers[1].getHeaderProps(
                    headerGroup.headers[1].getSortByToggleProps()
                  )}
                  style={{
                    width: `${seeSecond ? "50%" : "0% "}`,
                    display: `${seeSecond ? "" : "none "}`,
                  }}
                >
                  {headerGroup.headers[1].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[1].isSorted
                      ? headerGroup.headers[1].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                <th
                  // {...headerGroup.headers[2].getHeaderProps(
                  //   headerGroup.headers[2].getSortByToggleProps()
                  // )}
                  style={{
                    width: `${seeThird ? "35%" : "0% "}`,
                    display: `${seeThird ? "" : "none "}`,
                  }}
                >
                  {headerGroup.headers[2].Header}{" "}
                </th>
                <th
                  {...headerGroup.headers[3].getHeaderProps(
                    headerGroup.headers[3].getSortByToggleProps()
                  )}
                  style={{
                    width: `${seeFourth ? "15%" : "0% "}`,
                    display: `${seeFourth ? "" : "none "}`,
                  }}
                >
                  {headerGroup.headers[3].Header}{" "}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {headerGroup.headers[3].isSorted
                      ? headerGroup.headers[3].isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
                {/* {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {" "}
                  {column.render("Header")}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))} */}
              </tr>
            ))}
          </thead>

          {data.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                rowData.push(row.original);
                return (
                  <React.Fragment key={index}>
                    <tr
                      className={`${
                        rowId.some((item) => item === row.id)
                          ? "bg-soft-primary"
                          : ""
                      }`}
                      onClick={() => {
                        rowIdFunc(row.id);
                      }}
                      style={{ width: "100%", cursor: "pointer" }}
                      {...row.getRowProps()}
                    >
                      {seeFirst && (
                        <td style={{ width: "10%" }}>
                          {rowId.some((item) => item === row.id) ? (
                            <i className="fa fa-chevron-down cursor-pointer" />
                          ) : (
                            <i className="fa fa-chevron-right cursor-pointer" />
                          )}
                        </td>
                      )}
                      {seeSecond && (
                        <td style={{ width: "50%" }}>{row.cells[1].value}</td>
                      )}
                      {seeThird && (
                        <td style={{ width: "35%" }}>
                          {row.cells[2].value.map((item) => (
                            <div
                              key={item}
                              className="badge badge-soft-primary font-size-12 me-1 "
                            >
                              {item}
                            </div>
                          ))}
                        </td>
                      )}
                      {seeFourth && (
                        <td style={{ width: "15%" }}>
                          {row.cells[3].value === "Secure" ? (
                            <span className="text-success font-size-18 fw-semibold">
                              Secure
                            </span>
                          ) : (
                            <span className="text-danger font-size-18 fw-semibold">
                              Insecure
                            </span>
                          )}
                        </td>
                      )}

                      {/* {row.cells.map((cell, index) => {
                  return (
                    <td key={index} {...cell.getCellProps()}>
                      {cell.render("Cell")}
                    </td>
                  );
                })} */}
                    </tr>
                    <AnimatePresence>
                      {rowId.some((item) => item === row.id) && (
                        <motion.tr
                          // style={{ backgroundColor: "#f8f8f8" }}
                          initial={{ y: -100, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 0, opacity: 0 }}
                          transition={{ duration: 0.4 }}
                        >
                          <td>
                            <div>
                              <Table className="table mb-0">
                                <tbody border={1}>
                                  <tr>
                                    <td className="fw-semibold">Name</td>
                                    <td>{row?.original?.name || "-"}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold">Signature</td>
                                    <td>{row?.original?.signature || "-"}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold">Type</td>
                                    <td>{row?.original?.type || "-"}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold">Md5Hash</td>
                                    <td>{row?.original?.md5 || "-"}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold">Sha1Hash</td>
                                    <td>{row?.original?.sha1 || "-"}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold">Sha256hash</td>
                                    <td>{row?.original?.sha256 || "-"}</td>
                                  </tr>
                                  <tr>
                                    <td className="fw-semibold">Yara Rule</td>
                                    <td>
                                      {isDemoAccount ? (
                                        row?.original?.yara_rule.slice(0, 30)
                                      ) : (
                                        <pre>
                                          {atob(row?.original?.yara_rule)}
                                        </pre>
                                      )}
                                    </td>
                                  </tr>
                                </tbody>
                              </Table>
                              <div />
                            </div>
                          </td>
                        </motion.tr>
                      )}
                    </AnimatePresence>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default VulnerabilitiesTable;
