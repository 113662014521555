import React, { useState } from "react";
import classnames from "classnames";
import {
  CardBody,
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import DomainsTable from "./Domains/DomainsTable";
import IpsTable from "./Ips/IpsTable";

const BottomSection = ({ assets }) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");
  const [entryValue, setEntryValue] = useState(10);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div className="">
      <div className="row mb-5 ">
        <div className="col-6">
          <div className=" ">
            <Nav tabs className="nav-tabs-custom2 nav-justified  ">
              <NavItem className="   ">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "1",
                  })}
                  onClick={() => {
                    toggleCustom("1");
                  }}
                >
                  <span className="d-block ">
                    <i className="mdi mdi-domain fs-4 "></i>
                  </span>
                  <span className="d-none d-sm-block fs-4  ">Domains</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        <div className="col-6">
          <div className="border-none">
            {/* <CardBody> */}
            <Nav tabs className="nav-tabs-custom2 nav-justified">
              <NavItem>
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-block ">
                    <i className="mdi mdi-ip fs-4 "></i>
                  </span>
                  <span className="d-none d-sm-block fs-4 ">Ip's</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
      </div>

      <div className="special-card">
        <CardBody>
          <TabContent activeTab={customActiveTab} className=" text-muted ">
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <div>
                      <DomainsTable
                        data={assets?.discovered_domains}
                        entryValue={entryValue}
                        changeValue={(e) => changeValue(e)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
            <TabPane tabId="2">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <div>
                      <IpsTable
                        data={assets?.discovered_ips}
                        entryValue={entryValue}
                        changeValue={(e) => changeValue(e)}
                      />
                    </div>
                  </div>
                </Col>
              </Row>
            </TabPane>
          </TabContent>
        </CardBody>
      </div>
    </div>
  );
};
export default BottomSection;
