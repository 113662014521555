import React from "react";

const splitDataIntoTwo = (data) => {
  const keys = Object.keys(data);
  const middleIndex = Math.ceil(keys.length / 2);
  const firstHalfKeys = keys.slice(0, middleIndex);
  const secondHalfKeys = keys.slice(middleIndex);
  const firstHalf = {};
  const secondHalf = {};

  firstHalfKeys.forEach((key) => {
    firstHalf[key] = data[key];
  });

  secondHalfKeys.forEach((key) => {
    secondHalf[key] = data[key];
  });

  return [firstHalf, secondHalf];
};

const Certificate = ({ data }) => {
  const [firstHalf, secondHalf] = splitDataIntoTwo(data);

  return (
    <div className="row  ">
      <div className="col-lg-6 mb-lg-0 mb-3">
        <div className="special-card2">
          <table>
            <tbody>
              {Object.entries(firstHalf)?.map(([key, value], index) => (
                <tr key={index}>
                  <td className="fw-bold">{key} :</td>
                  <td>
                    {value === false
                      ? "False"
                      : value === true
                      ? "True"
                      : value === null
                      ? "-"
                      : value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      <div className="col-lg-6">
        <div className="special-card2 h-100 ">
          <table>
            <tbody>
              {Object.entries(secondHalf)?.map(([key, value], index) => (
                <tr key={index}>
                  <td className="fw-bold">{key} :</td>
                  <td>
                    {value === false
                      ? "False"
                      : value === true
                      ? "True"
                      : value === null
                      ? "-"
                      : value}
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default Certificate;
