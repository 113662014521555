import React, { useState } from "react";
import ColorPicker from "@vtaits/react-color-picker";
import { Collapse } from "reactstrap";
import { del, post, patch } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import { useHistory } from "react-router-dom";
import {
  getBrightness,
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const TagsComponent = ({ tags, getTags }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const router = useHistory();

  const [simple_color1, setsimple_color1] = useState(false);
  const [colorRgb, setcolorRgb] = useState("red");

  const [addLoading, set_addLoading] = useState(false);
  const [deleteLoading, set_deleteLoading] = useState(false);
  const [updateLoading, set_updateLoading] = useState(false);

  const [name, set_name] = useState("");
  const [description, set_description] = useState("");

  const [tagID, set_tagID] = useState();

  const [selectedRow, set_selectedRow] = useState();

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
    if (modal_standard3) {
      set_name("");
    }
  };

  const onDragRgb = (c1) => {
    setcolorRgb(c1);
  };

  const createTag = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      name,
      description,
      color: colorRgb,
    };
    try {
      let result = await post("/customer/module/cyberwatch/ti/cves/tags", data);
      if (result?.success) {
        toastSuccess(result?.message || "Tag Created");
        tog_standard();
        getTags();
        set_name("");
        set_description("");
        setcolorRgb("red");
        if (simple_color1) {
          setsimple_color1(false);
        }
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  const updateTag = async () => {
    paceFunction(true);
    set_updateLoading(true);
    let data = {
      id: tagID,
      description,
      color: colorRgb,
    };
    try {
      let result = await patch(
        "/customer/module/cyberwatch/ti/cves/tags",
        data
      );
      if (result?.success) {
        toastSuccess(result?.message || "Tag Updated");
        tog_standard2();
        getTags();
        if (simple_color1) {
          setsimple_color1(false);
        }
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_updateLoading(false);
    paceFunction(false);
  };

  const deleteTag = async () => {
    paceFunction(true);
    set_deleteLoading(true);
    try {
      let result = await del(
        `/customer/module/cyberwatch/ti/cves/tags?id=${tagID}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Tag Deleted");
        tog_standard3();
        set_tagID();
        set_name("");
        getTags();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_deleteLoading(false);
    paceFunction(false);
  };

  const cancelUpdate = () => {
    set_name("");
    set_description("");
    setcolorRgb("red");
    set_selectedRow();
    set_tagID();
  };

  const handleSubmitCreate = (e) => {
    e.preventDefault();
    tog_standard();
  };

  const handleSubmitUpdate = (e) => {
    e.preventDefault();
    tog_standard2();
  };

  return (
    <React.Fragment>
      {/* Create Tag */}
      <ConfirmModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        targetFunction={createTag}
        text={`Are you sure you want to create ${name} tag?`}
        loadingAction={true}
        isLoading={addLoading}
      />
      {/* Update Tag */}
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={updateTag}
        text={`Are you sure you want to update ${name} tag?`}
        loadingAction={true}
        isLoading={updateLoading}
      />
      {/* Create Tag */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={deleteTag}
        text={`Are you sure you want to delete ${name} tag?`}
        loadingAction={true}
        isLoading={deleteLoading}
      />
      <div className="row">
        <div className="col-lg-7">
          <div className="special-card ">
            <div className="card-header with-border">
              <h3 className="card-title">My Tags</h3>
            </div>
            <div className="card-body table-responsive no-padding">
              <table className="table table-hover ">
                <tbody>
                  {tags?.map((tag, tagIndex) => {
                    return (
                      <tr
                        className={`${
                          selectedRow == tag?.id ? "bg-soft-primary" : ""
                        }`}
                        key={tagIndex}
                      >
                        <td>
                          {" "}
                          <div
                            className="badge p-2 "
                            style={{
                              color: `${
                                getBrightness(tag?.color) < 128
                                  ? "white"
                                  : "black"
                              }`,
                              backgroundColor: tag?.color,
                            }}
                          >
                            {tag?.name}
                          </div>
                        </td>
                        <td className="text-center">{tag?.description}</td>
                        <td className="text-end">
                          <div className="btn-group">
                            <button
                              onClick={() =>
                                router.push(
                                  `/cyber-watch/threat-intelligence/cves?page=1&tag=${tag?.name}`
                                )
                              }
                              type="button"
                              className="btn btn-default-cves btn-sm"
                            >
                              View CVE
                            </button>
                            <button
                              onClick={() => {
                                set_tagID(tag?.id);
                                set_name(tag?.name);
                                set_description(tag?.description);
                                setcolorRgb(tag?.color);
                                set_selectedRow(tag?.id);
                              }}
                              className="btn btn-default-cves btn-sm "
                            >
                              Edit
                            </button>
                            <button
                              onClick={() => {
                                set_tagID(tag?.id);
                                tog_standard3();
                                set_name(tag?.name);
                              }}
                              className="btn btn-danger btn-sm "
                            >
                              Delete
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {/* {cwes?.length < 1 && (
              <div className="w-100 bg-info py-2 text-center text-white ">
                No cwes
              </div>
            )} */}
            </div>
          </div>
        </div>
        <div className="col-lg-5">
          <div className="special-card ">
            <div className="card-header with-border">
              <h3 className="card-title">
                {selectedRow ? `Update ${name}  ` : "Create "} Tag
              </h3>
            </div>
            <div className="card-body">
              <form
                onSubmit={selectedRow ? handleSubmitUpdate : handleSubmitCreate}
                className="form"
                role="form"
              >
                <div className="form-group  ">
                  <label htmlFor="name" className="control-label">
                    Name
                  </label>
                  <input
                    className="form-control"
                    disabled={selectedRow}
                    id="name"
                    name="name"
                    required
                    type="text"
                    value={name}
                    onChange={(e) => set_name(e.target.value)}
                  />
                </div>
                <div className="form-group  ">
                  <label htmlFor="description" className="control-label">
                    Description
                  </label>
                  <input
                    className="form-control"
                    id="description"
                    name="description"
                    type="text"
                    value={description}
                    onChange={(e) => set_description(e.target.value)}
                  />
                </div>
                <label>Color</label>
                <div className="form-inline form-group ">
                  <div
                    className="badge p-2 "
                    style={{
                      color: `${
                        getBrightness(colorRgb) < 128 ? "white" : "black"
                      }`,
                      backgroundColor: colorRgb,
                    }}
                  >
                    {name ? name : "preview"}
                  </div>
                  <div className="form-group my-1 ">
                    <div
                      className="input-group colorpicker-default "
                      title="Using format option"
                    >
                      <input
                        readOnly
                        value={colorRgb}
                        type="text"
                        className="form-control "
                        onClick={() => {
                          setsimple_color1(!simple_color1);
                        }}
                      />

                      <span
                        className="input-group-text colorpicker-input-addon"
                        onClick={() => {
                          setsimple_color1(!simple_color1);
                        }}
                      >
                        <i
                          style={{
                            height: "16px",
                            width: "16px",
                            background: colorRgb,
                          }}
                        />
                      </span>
                    </div>

                    <Collapse isOpen={simple_color1}>
                      <ColorPicker
                        saturationHeight={100}
                        saturationWidth={100}
                        value={colorRgb}
                        onDrag={onDragRgb}
                      />
                    </Collapse>
                  </div>
                </div>
                <div className="text-end">
                  {selectedRow ? (
                    <div>
                      <button
                        onClick={cancelUpdate}
                        type="button"
                        className="btn btn-default btn-secondary"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="btn btn-default btn-primary ms-1"
                      >
                        Update
                      </button>
                    </div>
                  ) : (
                    <button
                      type="submit"
                      className="btn btn-default btn-primary"
                    >
                      Create
                    </button>
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default TagsComponent;
