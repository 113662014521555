import React, { useState } from "react";
import classnames from "classnames";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Score from "./Score";
import Ports from "./Ports";
import Blacklist from "./Blacklist";

const ChildTabs = ({ datas }) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div className="">
      <div className="row mb-5 ">
        {Object.keys(datas?.score)?.length > 0 && (
          <div className="col  ">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-scoreboard fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">Score</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
        {Object.keys(datas?.ports)?.length > 0 && (
          <div className="col ">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-serial-port fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">Ports</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
        {Object.keys(datas?.blacklist)?.length > 0 && (
          <div className="col  ">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleCustom("4");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-playlist-remove fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">Blacklist</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
      </div>

      <div className="special-card">
        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
          {Object.keys(datas?.score)?.length > 0 && (
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <Score data={datas?.score?.data} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          {Object.keys(datas?.ports)?.length > 0 && (
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <Ports data={datas?.ports} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          {Object.keys(datas?.blacklist)?.length > 0 && (
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <Blacklist data={datas?.blacklist?.data} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
};

export default ChildTabs;
