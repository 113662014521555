import React, { useEffect, useRef, useState } from "react";
import IgnoreModal from "./IgnoreModal";
import FixedModal from "./FixedModal";
import FalsePositiveModal from "./FalsePositiveModal";
import { useSelector } from "react-redux";
import { removeBodyCss } from "../../../../common/utils/commonFunctions";

const DropdownHelp = ({
  hosts,
  row,
  moduleID,
  updated_at,
  getVulnerabilities,
}) => {
  const filteredHosts = hosts?.filter((item, index, self) => {
    const address = item?.address;
    return self.findIndex((item) => item?.address === address) === index;
  });

  const { isDemoAccount } = useSelector((state) => ({
    isDemoAccount: state.custom.isDemoAccount,
  }));

  const [isOpen, setIsOpen] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);

  const dropdownRef = useRef(null);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const toggleDropdown = () => {
    if (!isDemoAccount) {
      setIsOpen(!isOpen);
    }
  };

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setIsOpen(false);
    }
  };

  useEffect(() => {
    window.addEventListener("click", handleClickOutside);
    return () => {
      window.removeEventListener("click", handleClickOutside);
    };
  }, []);

  return (
    <div className={`dropdown-host ${isOpen ? "open" : ""}`} ref={dropdownRef}>
      <IgnoreModal
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={(e) => setmodal_standard(e)}
        assets={filteredHosts}
        row={row}
        moduleID={moduleID}
        updated_at={updated_at}
        getVulnerabilities={getVulnerabilities}
      />
      <FixedModal
        tog_standard={tog_standard2}
        modal_standard={modal_standard2}
        setmodal_standard={(e) => setmodal_standard2(e)}
        assets={filteredHosts}
        row={row}
        moduleID={moduleID}
        updated_at={updated_at}
        getVulnerabilities={getVulnerabilities}
      />
      <FalsePositiveModal
        tog_standard={tog_standard3}
        modal_standard={modal_standard3}
        setmodal_standard={(e) => setmodal_standard3(e)}
        assets={filteredHosts}
        row={row}
        moduleID={moduleID}
        updated_at={updated_at}
        getVulnerabilities={getVulnerabilities}
      />
      <div
        className={`selected-option-host ${
          isDemoAccount && "bg-light no-drop"
        }`}
        onClick={toggleDropdown}
      >
        <i className="mdi mdi-check text-primary fs-4 " />
        <span className="fs-6 fw-medium mx-1 ">Mark as</span>
        <i className="mdi mdi-chevron-down text-primary fs-4 " />
      </div>
      <ul className="options-host">
        <li onClick={tog_standard2} className="fs-6">
          Fixed
        </li>
        <li onClick={tog_standard} className="fs-6">
          Ignored
        </li>
        <li onClick={tog_standard3} className="fs-6">
          False Positive
        </li>
      </ul>
    </div>
  );
};

export default React.memo(DropdownHelp);
