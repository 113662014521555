import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { del } from "../../helpers/api_helper";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import IssuesDetail from "./IssuesDetail";
import IssueDropdownHelp from "./IssueDropdownHelp";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <form autoComplete="off">
        <input
          autoComplete="new-password"
          id="search"
          name="search"
          type="text"
          className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
          defaultValue={value || ""}
          onChange={(e) => {
            setValue(e.target.value);
            onChange(e.target.value);
            setCurrentPage(1);
          }}
          placeholder="Search"
        />
      </form>
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function IssuesTable({ data, entryValue, getIssues }) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [rowId, setRowId] = useState();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const [addLoading, setAddLoading] = useState(false);
  const [issueDetail, set_issueDetail] = useState();
  const [issueID, set_issueID] = useState();

  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);

  const columns = React.useMemo(
    () => [
      {
        Header: "Customer",
        accessor: "customer_company",
      },
      {
        Header: "Subject",
        accessor: "subject",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Created Date",
        accessor: "created",
      },
      {
        Header: "Updated Date",
        accessor: "updated",
      },
      {
        Header: "",
        accessor: "created1",
      },
      {
        Header: "",
        accessor: "created2",
      },
    ],
    []
  );
  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "updated", desc: true }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      initialState: {
        sortBy,
      },
      // manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };
  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const deleteIssue = async () => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await del(`/account/issue?id=${issueID}`);
      if (result?.success) {
        toastSuccess(result?.message || "Issue Deleted");
        tog_standard5();
        getIssues();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {issueDetail && (
        <IssuesDetail
          issueDetail={issueDetail}
          issueID={issueID}
          getIssues={getIssues}
          modal_standard={modal_standard2}
          tog_standard={tog_standard2}
          setmodal_standard={setmodal_standard2}
        />
      )}
      {/* Delete Issue */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteIssue}
        text="Are you sure you want to delete issue?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
        <Col xxl={2} xl={2} lg={3} md={3} sm={4} xs={5}>
          <GlobalFilter
            preGlobalFilteredRows={preGlobalFilteredRows}
            globalFilter={state.globalFilter}
            setGlobalFilter={setGlobalFilter}
            setCurrentPage={(e) => setCurrentPage(e)}
          />
        </Col>
      </Row>

      <table className="table" {...getTableProps()}>
        <thead>
          {headerGroups.map((headerGroup) => (
            <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
              {headerGroup.headers.map((column, columnIndex) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {column.render("Header")}{" "}
                  {columnIndex < 5 && (
                    <span style={{ cursor: "pointer" }} title="Sorting">
                      {column.isSorted
                        ? column.isSortedDesc
                          ? " 🔽"
                          : // : " 🔼"
                            " 🔽"
                        : "🔘"}
                    </span>
                  )}
                </th>
              ))}
            </tr>
          ))}
        </thead>

        {data.length > 0 && (
          <tbody {...getTableBodyProps()}>
            {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
              prepareRow(row);
              let createdDate = new Date(row.cells[3].value);
              let updatedDate = new Date(row.cells[4].value);
              const formattedCreated = `${createdDate.toLocaleDateString()} ${createdDate.toLocaleTimeString()}`;
              const formattedUpdated = `${updatedDate.toLocaleDateString()} ${updatedDate.toLocaleTimeString()}`;
              return (
                <tr key={index} {...row.getRowProps()}>
                  <td>{row.cells[0].value}</td>
                  <td>{row.cells[1].value}</td>
                  <td>
                    <IssueDropdownHelp
                      issue={row.original}
                      getIssues={getIssues}
                    />
                  </td>
                  <td>{formattedCreated}</td>
                  <td>{formattedUpdated}</td>
                  <td>
                    <button
                      onClick={() => {
                        set_issueDetail(row?.original);
                        tog_standard2();
                        set_issueID(row?.original?.id);
                      }}
                      className="btn btn-success btn-sm "
                    >
                      Detail
                    </button>
                  </td>
                  <td>
                    {" "}
                    <button
                      onClick={() => {
                        set_issueID(row?.original?.id);
                        tog_standard5();
                      }}
                      className="btn btn-danger btn-sm"
                    >
                      Delete
                    </button>
                  </td>
                </tr>
              );
            })}
          </tbody>
        )}
      </table>
      {data.length < 1 && (
        <span
          style={{ width: "100%" }}
          className="d-flex justify-content-center "
        >
          No data available in table
        </span>
      )}
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default IssuesTable;
