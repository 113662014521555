import React, { useEffect, useState } from "react";
import { CardHeader, CardBody, Spinner } from "reactstrap";
import DemoRequestTable from "./DemoRequestTable";
import { get } from "../../../helpers/api_helper";
import { useSelector } from "react-redux";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const DemoRequest = () => {
  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const { render } = useSelector((state) => ({
    render: state.render.render,
  }));

  const [entryValue, setEntryValue] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getDemoRequests = async () => {
    paceFunction(true);
    setIsLoading(true);
    try {
      let result = await get("/admin/demo-account");
      setData(result?.data);
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    getDemoRequests();
  }, [render]);

  return (
    <div className="special-card mb-4 ">
      <CardHeader>
        <h4 className="card-title text-primary ">Demo Request</h4>
      </CardHeader>
      <CardBody>
        {isLoading && (
          <div className="d-flex justify-content-center  align-items-center h-100  ">
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              className="ms-2 "
              color="primary"
            />
          </div>
        )}
        {!isLoading && (
          <div
            style={{
              overflowX: windowWidth < 1000 ? "scroll" : "none",
            }}
          >
            <DemoRequestTable
              data={data}
              entryValue={entryValue}
              changeValue={(e) => changeValue(e)}
            />
          </div>
        )}
      </CardBody>
    </div>
  );
};

export default DemoRequest;
