import React, { useEffect, useState } from "react";
import { del, get, post, put } from "../../../helpers/api_helper";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import { CardBody, Container, Spinner } from "reactstrap";
import ApplianceTable from "../../../components/Admin/Appliance/ApplianceTable";

const Appliances = () => {
  document.title = "Dashboard | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [entryValue, setEntryValue] = useState(10);
  const [isLoading, setIsLoading] = useState();
  const [data, setData] = useState([]);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getAppliances = async () => {
    paceFunction(true);
    setIsLoading(true);
    try {
      let result = await get("/admin/customer/endpoint");
      setData(result.data);
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    getAppliances();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Appliances" breadcrumbItem="Appliances" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && data && (
            <div className="special-card">
              <CardBody>
                <div
                  style={{
                    overflowX: windowWidth < 800 ? "overlay" : "none",
                  }}
                >
                  <ApplianceTable
                    data={data}
                    entryValue={entryValue}
                    changeValue={(e) => changeValue(e)}
                  />
                </div>
              </CardBody>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Appliances;
