import React, { useState } from "react";
import { CardHeader, CardBody, Container } from "reactstrap";
import { patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import {
  formatDateFlatPickr,
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";
import Flatpickr from "react-flatpickr";
import ConfirmModal from "../../../common/utils/ConfirmModal";

const ManagerDetails = ({ data }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [modal_standard, setmodal_standard] = useState(false);

  const [organization_name, set_organization_name] = useState(
    data.organization_name || ""
  );
  const [phone_number, set_phone_number] = useState(data.phone_number || "");
  const [email, set_email] = useState(data.email || "");
  const [enable, set_enable] = useState(data.enable);
  const [authentication, set_authentication] = useState(
    data.authentication || "None"
  );
  const [account_limit, set_account_limit] = useState(data.account_limit || 0);
  const [asset_limit_host, set_asset_limit_host] = useState(
    data.asset_limit_host || 0
  );
  const [asset_limit_webapp, set_asset_limit_webapp] = useState(
    data.asset_limit_webapp || 0
  );
  const [asset_limit_mxthreats, set_asset_limit_mxthreats] = useState(
    data.asset_limit_mxthreats || 0
  );
  const [asset_limit_cybersight, set_asset_limit_cybersight] = useState(
    data.asset_limit_cybersight || 0
  );
  const [subscription_renewal, set_subscription_renewal] = useState(
    data.subscription_renewal
  );
  const [date_joined, set_date_joined] = useState(data.date_joined);

  function tog_standard() {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }

  const options = { year: "numeric", month: "long", day: "numeric" };
  const subscriptionRenewal = new Date(data?.subscription_renewal);
  const formattedSubscriptionRenewal = subscriptionRenewal.toLocaleDateString(
    "en-US",
    options
  );

  const joinedDate = new Date(data?.date_joined);
  const formattedJoinedDate = joinedDate.toLocaleDateString("en-US", options);
  const changeTime = (e) => {
    set_subscription_renewal(formatDateFlatPickr(e[0]));
  };

  const handleSubmit = async (e) => {
    paceFunction(true);
    e.preventDefault();
    let updateData = {
      id: data.id,
      organization_name,
      phone_number,
      email,
      enable,
      authentication,
      account_limit,
      asset_limit_host,
      asset_limit_cybersight,
      asset_limit_mxthreats,
      asset_limit_webapp,
      subscription_renewal,
      date_joined,
    };
    try {
      let result = await patch("/distributor/manager", updateData);
      if (result?.success) {
        toastSuccess(result?.message || "Manager Updated");
        tog_standard();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Update Manager */}
      <ConfirmModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        targetFunction={handleSubmit}
        text="Are you sure you want to update manager?"
        loadingAction={false}
      />
      <div className="page-content">
        <Container fluid>
          <div className=" special-card">
            <CardHeader
              // style={{ backgroundColor: "#f8f8f8" }}
              className="d-flex justify-content-between   "
            >
              <h4 className="card-title text-primary ">Update Manager</h4>
            </CardHeader>
            <CardBody>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="organization_name"
                        className="form-control"
                        onChange={(e) => set_organization_name(e.target.value)}
                        defaultValue={data.organization_name}
                        required
                      />
                      <label>Name</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="email"
                        name="email"
                        className="form-control"
                        onChange={(e) => set_email(e.target.value)}
                        defaultValue={data.email}
                        required
                        disabled
                      />
                      <label>Email</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        type="text"
                        name="phone_number"
                        className="form-control"
                        onChange={(e) => set_phone_number(e.target.value)}
                        defaultValue={data.phone_number}
                        required
                      />
                      <label>Phone Number</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_host"
                        min={0}
                        max={3000}
                        step={1}
                        defaultValue={data.asset_limit_host}
                        onChange={(e) => set_asset_limit_host(e.target.value)}
                      />
                      <label>Max. Asset Limit (Hosts) (max 3000)</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_mxthreats"
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={data.asset_limit_mxthreats}
                        onChange={(e) =>
                          set_asset_limit_mxthreats(e.target.value)
                        }
                      />
                      <label>Max. Asset Limit (Mailbox) (max 100)</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_webapp"
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={data.asset_limit_webapp}
                        onChange={(e) => set_asset_limit_webapp(e.target.value)}
                      />
                      <label>Max. Asset Limit (Web App) (max 100)</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="asset_limit_cybersight"
                        min={0}
                        max={100}
                        step={1}
                        defaultValue={data.asset_limit_cybersight}
                        onChange={(e) =>
                          set_asset_limit_cybersight(e.target.value)
                        }
                      />
                      <label>Max. Asset Limit (Cyber) (max 100)</label>
                    </div>
                  </div>
                  <div className="col-xl-3 col-lg-4 col-md-6">
                    <div className="form-floating mb-3">
                      <input
                        className="form-control"
                        type="number"
                        name="account_limit"
                        min={0}
                        max={25}
                        step={1}
                        defaultValue={data.account_limit}
                        onChange={(e) => set_account_limit(e.target.value)}
                      />
                      <label>Max. Account Limit (max 25)</label>
                    </div>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6 form-floating mb-3">
                    <Flatpickr
                      name="expiration_date"
                      className="form-control d-block"
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "F j, Y",
                      }}
                      defaultValue={formattedJoinedDate}
                      // onChange={changeTime}
                      disabled
                    />
                    <label style={{ marginLeft: "6px" }}>Joined Date</label>
                  </div>
                  <div className="col-xl-6 col-lg-4 col-md-6 form-floating mb-3 ">
                    <Flatpickr
                      name="expiration_date"
                      className="form-control bg-flatpicker  d-block cursor-pointer "
                      options={{
                        altInput: true,
                        altFormat: "F j, Y",
                        dateFormat: "F j, Y",
                      }}
                      defaultValue={formattedSubscriptionRenewal}
                      onChange={changeTime}
                    />
                    <label style={{ marginLeft: "6px" }}>
                      Subscription Renewal
                    </label>
                  </div>
                  <div className=" col-xl-6 col-lg-4 col-md-6 d-flex flex-column align-items-center ">
                    <label className="form-label">Authentication</label>
                    <div className="btn-group btn-group-sm  " role="group">
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faNone1"
                        value="None"
                        autoComplete="off"
                        defaultChecked={
                          data.authentication === "None"
                            ? true
                            : data.authentication === null
                            ? true
                            : false
                        }
                        onChange={(e) => set_authentication(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faNone1"
                      >
                        None
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faSms1"
                        value="SMS"
                        autoComplete="off"
                        defaultChecked={
                          data.authentication === "SMS" ? true : false
                        }
                        onChange={(e) => set_authentication(e.target.value)}
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faSms1"
                      >
                        SMS
                      </label>
                      <input
                        type="radio"
                        className="btn-check"
                        name="authentication"
                        id="auth2faQr1"
                        value="QR"
                        autoComplete="off"
                        defaultChecked={
                          data.authentication === "QR" ? true : false
                        }
                        onChange={(e) => set_authentication(e.target.value)}
                        disabled
                      />
                      <label
                        className="btn btn-outline-primary"
                        htmlFor="auth2faQr1"
                      >
                        QR
                      </label>
                    </div>
                  </div>
                  <div className=" col-xl-6 col-lg-4 col-md-6 d-flex flex-column align-items-center mt-md-0 mt-3 ">
                    <label className="form-label  ">Active</label>
                    <div className="square-switch">
                      <input
                        type="checkbox"
                        id="is_active_mng"
                        name="is_active_mng"
                        switch="primary"
                        autoComplete="off"
                        defaultChecked={data.enable}
                        onChange={(e) => set_enable(e.target.checked)}
                      />
                      <label
                        htmlFor="is_active_mng"
                        data-on-label="Yes"
                        data-off-label="No"
                      />
                    </div>
                  </div>
                </div>
                <div className="form-group d-flex justify-content-center">
                  <button
                    type="button"
                    onClick={tog_standard}
                    className="btn btn-lg btn-primary"
                  >
                    <i className="mdi mdi-book-edit-outline me-3" />
                    Update Manager
                  </button>
                </div>
              </form>
            </CardBody>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ManagerDetails;
