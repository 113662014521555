import React, { useEffect, useState } from "react";
import { del, get, post, put } from "../../../helpers/api_helper";
import {
  Card,
  CardHeader,
  CardBody,
  Col,
  Container,
  Row,
  Spinner,
} from "reactstrap";
import HostsAssetTable from "./HostsAssetTable";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";

const Hosts = ({ hosts }) => {
  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [entryValue, setEntryValue] = useState(10);
  const [isLoading, setIsLoading] = useState(false);
  const [firstLoading, set_firstLoading] = useState(false);
  const [assets, set_assets] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [getHostRender, set_getHostRender] = useState(false);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  const getHostsAsset = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/asset?id=${hosts.id}`);
      if (result?.success) {
        set_assets(result.data);
        setIsLoading(false);
        set_firstLoading(true);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunctionNotSwal(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getHostsAsset();
  }, [getHostRender]);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <div className="special-card h-100">
      <CardHeader>
        <h4 className="card-title text-primary ">Hosts</h4>
      </CardHeader>
      {isLoading && (
        <div className="d-flex justify-content-center mt-4   align-items-center h-100  ">
          <Spinner
            style={{ width: "2rem", height: "2rem" }}
            className="ms-2 "
            color="primary"
          />
        </div>
      )}
      {!isLoading && assets && (
        <CardBody>
          <div
            style={{
              overflowX: windowWidth < 800 ? "overlay" : "none",
            }}
          >
            <HostsAssetTable
              getHostsAsset={getHostsAsset}
              maxAssetCount={hosts?.asset_count}
              moduleID={assets?.id}
              data={assets?.assets}
              entryValue={entryValue}
              changeValue={(e) => changeValue(e)}
            />
          </div>
        </CardBody>
      )}
    </div>
  );
};

export default Hosts;
