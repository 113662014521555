import React from "react";

const Score = ({ data }) => {
  const names = Object.keys(data?.ip_score_detailed);
  const values1 = Object.values(data?.ip_score_detailed);
  const values2 = Object.values(data?.normalized_ip_score_detailed);
  const resultDetails = data?.results_detailed;

  const objArray = Object.keys(resultDetails)?.map((key) => {
    return {
      name: key,
      data: resultDetails[key],
    };
  });

  const filteredObjArray = objArray.filter(
    (item) => item.name !== "is_load_balancer" && item.name !== "ports"
  );

  return (
    <div className="">
      <div className="row">
        <div className="col-md-6 mb-3 ">
          <div className="special-card2">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th></th>
                  <th className="text-center">Score Detail</th>
                </tr>
              </thead>
              <tbody>
                {names?.map((item, index) => (
                  <tr key={index}>
                    <td className="fw-bold">{item}</td>
                    <td className="text-center">{values1[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        <div className="col-md-6 mb-3 ">
          <div className="special-card2">
            <table className="table table-bordered">
              <thead>
                <tr>
                  <th>Score : ({data?.normalized_ip_score})</th>
                  <th className="text-center">Normalized Score Detail</th>
                </tr>
              </thead>
              <tbody>
                {names?.map((item, index) => (
                  <tr key={index}>
                    <td className="fw-bold">{item}</td>
                    <td className="text-center">{values2[index]}</td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
        {filteredObjArray?.map((item, index) => {
          let cveList = [];
          if (item?.name === "cve") {
            item?.data?.result.map((cve) => {
              let mainArray = cve?.cve[0]?.cve_list;
              if (mainArray?.length > 0) {
                cveList.push({ port: cve?.port, cve: mainArray });
              }
            });
          }
          return (
            <div key={item?.name} className="col- mb-3 ">
              <div className="special-card2">
                <div className="d-flex align-items-center  justify-content-between ">
                  <h4 className="text-center">{item?.name}</h4>
                  <h4>
                    Score :<strong>{item?.data?.score}</strong>
                  </h4>
                </div>
                <div>
                  <div className="row">
                    {item?.name === "torrents" ? (
                      <div className="col-3 fw-bold">Torrents</div>
                    ) : (
                      <div className="col-3 fw-bold">Port</div>
                    )}

                    <div className="col-3 fw-bold">Score</div>
                    {item?.data?.result?.length > 1 ? (
                      <>
                        {item?.name === "torrents" ? (
                          <div className="col-3 fw-bold">Torrents</div>
                        ) : (
                          <div className="col-3 fw-bold">Port</div>
                        )}
                        <div className="col-3 fw-bold">Score</div>
                      </>
                    ) : (
                      <>
                        <div className="col-3 fw-bold"></div>
                        <div className="col-3 fw-bold"></div>
                      </>
                    )}

                    {item?.data?.result?.map((det, i) => (
                      <div key={i} className="col-6">
                        <div className="row border-top border">
                          {item?.name === "torrents" ? (
                            <div className=" col-6 ">
                              {det?.torrents ? "True" : "False"}
                            </div>
                          ) : (
                            <div className=" col-6 ">{det?.port}</div>
                          )}

                          <div className=" col-6 ">{det?.score}</div>
                        </div>
                      </div>
                    ))}
                    <div className="row mt-4">
                      {item?.name === "cve" && cveList?.length > 0 && (
                        <h4 className="text-center">Cve List</h4>
                      )}
                      {item?.name === "cve" && cveList?.length < 1 && (
                        <h4 className="text-center">
                          <span className="mdi mdi-information-off text-primary me-1" />
                          There is no cve list
                        </h4>
                      )}
                      {item?.name === "cve" &&
                        cveList?.map((cve, index) => (
                          <div
                            key={index}
                            className="col-12 special-card mt-3 "
                          >
                            <div className="">
                              <h4 className="text-center">Port :{cve.port}</h4>
                              <table className="table table-bordered ">
                                <thead>
                                  <tr>
                                    <th>CVE</th>
                                    <th>CVSS</th>
                                    {cve?.cve?.length > 1 && (
                                      <>
                                        <th>CVE</th>
                                        <th>CVSS</th>
                                        <th>CVE</th>
                                        <th>CVSS</th>
                                        <th>CVE</th>
                                        <th>CVSS</th>
                                      </>
                                    )}
                                  </tr>
                                </thead>
                                <tbody>
                                  {cve?.cve?.map((lcve, index) => {
                                    const itemsPerRow = 4;
                                    const rows = Math.ceil(
                                      cve?.cve?.length / itemsPerRow
                                    );
                                    if (index === 0) {
                                      return [...Array(rows)].map(
                                        (_, rowIndex) => (
                                          <tr key={rowIndex}>
                                            {cve?.cve
                                              ?.slice(
                                                rowIndex * itemsPerRow,
                                                (rowIndex + 1) * itemsPerRow
                                              )
                                              ?.map((item, index) => (
                                                <React.Fragment key={index}>
                                                  <td>{item?.cve}</td>
                                                  <td>{item?.cvss}</td>
                                                </React.Fragment>
                                              ))}
                                          </tr>
                                        )
                                      );
                                    }
                                  })}
                                </tbody>
                              </table>
                            </div>
                          </div>
                        ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          );
        })}
        {data?.results_detailed?.ports?.open?.length > 0 && (
          <div className="col-xl-2 col-lg-3 col-md-4 ">
            <div className="special-card2">
              <h4 className="text-center">Open Ports</h4>
              <ul>
                {data?.results_detailed?.ports?.open?.map((item) => (
                  <li className="col-6" key={item}>
                    {item}
                  </li>
                ))}
              </ul>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Score;
