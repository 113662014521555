import React, { useEffect, useState } from "react";
import {
  CardHeader,
  CardBody,
  Col,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Spinner,
} from "reactstrap";
import classnames from "classnames";
import VpnCustomersTable from "./VpnCustomersTable";
import VpnInstancesTable from "./VpnInstancesTable";
import { del, get, post, put, patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const VpnConnections = () => {
  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [entryValue, setEntryValue] = useState(10);
  const [data, setData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);
  const [activeTab1, setactiveTab1] = useState("5");

  const toggle1 = (tab) => {
    if (activeTab1 !== tab) {
      setactiveTab1(tab);
    }
  };

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getVpnConnections = async () => {
    paceFunction(true);
    try {
      let result = await get("/admin/firewall/vpn");
      if (result?.success) {
        setData(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getVpnConnections();
  }, []);

  return (
    <div className="special-card">
      <CardHeader>
        <h4 className="card-title text-primary ">Vpn Connections</h4>
      </CardHeader>
      <CardBody>
        {isLoading && (
          <div className="d-flex justify-content-center  align-items-center h-100  ">
            <Spinner
              style={{ width: "3rem", height: "3rem" }}
              className="ms-2 "
              color="primary"
            />
          </div>
        )}
        {!isLoading && data && (
          <>
            <Col lg={6}>
              <Nav pills className="navtab-bg nav-justified">
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab1 === "5",
                    })}
                    onClick={() => {
                      toggle1("5");
                    }}
                  >
                    Customer
                  </NavLink>
                </NavItem>
                <NavItem>
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: activeTab1 === "6",
                    })}
                    onClick={() => {
                      toggle1("6");
                    }}
                  >
                    Instances
                  </NavLink>
                </NavItem>
              </Nav>{" "}
            </Col>

            <TabContent activeTab={activeTab1} className="p-3 text-muted">
              <TabPane tabId="5">
                <Row>
                  <Col sm="12">
                    <div
                      style={{
                        overflowX: windowWidth < 800 ? "overlay" : "none",
                      }}
                    >
                      <VpnCustomersTable
                        data={data?.customers}
                        entryValue={entryValue}
                        changeValue={(e) => changeValue(e)}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
              <TabPane tabId="6">
                <Row>
                  <Col sm="12">
                    <div
                      style={{
                        overflowX: windowWidth < 800 ? "overlay" : "none",
                      }}
                    >
                      <VpnInstancesTable
                        data={data?.instances}
                        entryValue={entryValue}
                        changeValue={(e) => changeValue(e)}
                      />
                    </div>
                  </Col>
                </Row>
              </TabPane>
            </TabContent>
          </>
        )}
      </CardBody>
    </div>
  );
};

export default VpnConnections;
