import React, { useEffect, useState } from "react";

//import Breadcrumbs
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { get } from "../../../helpers/api_helper";
import { Container, Spinner } from "reactstrap";
import AdminDashboard from "../../../pages/Admin/Dashboard/AdminDashboard";
import CustomerDashboard from "../../../pages/Customer/Dashboard/CustomerDashboard";
import ManagerDashboard from "../../../pages/Manager/Dashboard/ManagerDashboard";
import DistributorDashboard from "../../../pages/Distributor/Dashboard/DistributorDashboard";

const Dashboard = () => {
  //meta title
  document.title = "Dashboard | PentestBX";
  const isAdmin = true;
  const [user, setUser] = useState();
  const [render, setRender] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  // const role = JSON.parse(localStorage.getItem("authUser")).value.role;
  const role = JSON.parse(localStorage.getItem("authUser")).role;
  const storage = JSON.parse(localStorage.getItem("authUser"));

  const getUser = async () => {
    setIsLoading(true);
    try {
      let result = await get("/account/settings");
      if (result?.success) {
        if (storage.value.role !== result?.data?.role) window.location.reload();
        setUser(result?.data);
      }
    } catch (error) {
      // if (error.response.status === 401) window.location.reload();
      console.log(`error ==>`, error);
    }
    setIsLoading(false);
  };
  useEffect(() => {
    getUser();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          {/* Render Breadcrumbs */}
          <Breadcrumbs title="Dashboard" breadcrumbItem="Dashboard" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100 mt-5  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {/* {!isLoading && user?.role === "ADMIN" && <AdminDashboard />}
          {!isLoading && user?.role === "CUSTOMER" && <CustomerDashboard />} */}

          {!isLoading && user && storage.value.role === "ADMIN" && (
            <AdminDashboard />
          )}
          {!isLoading && user && storage.value.role === "MANAGER" && (
            <ManagerDashboard />
          )}
          {!isLoading && user && storage.value.role === "CUSTOMER" && (
            <CustomerDashboard />
          )}
          {!isLoading && user && storage.value.role === "DISTRIBUTOR" && (
            <DistributorDashboard />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Dashboard;
