import React from "react";
import { remainingDaysCalculation } from "../../../../common/utils/commonFunctions";
import classNames from "classnames";

const RemainingDay = ({ day, dateFormat, profileInfos }) => {
  let remainingDay = remainingDaysCalculation(day, dateFormat);
  if (remainingDay >= 0 && remainingDay <= 30) {
    return (
      <React.Fragment>
        <div
          style={{ minHeight: "300px" }}
          className={classNames("special-card mt-4", {
            "bg-critical-warning": remainingDay <= 7,
            "bg-high-warning": remainingDay > 7 && remainingDay <= 14,
            "bg-warning": remainingDay > 14 && remainingDay <= 30,
          })}
        >
          <div className="d-flex flex-column justify-content-center">
            <i
              style={{ fontSize: "120px" }}
              className="mdi mdi-alert-box text-white text-center"
            />
            <span
              style={{ fontSize: "40px" }}
              className="text-white text-center fw-bold"
            >
              Last {remainingDay} {remainingDay > 1 ? "Days" : "Day"}
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  } else if (remainingDay < 0) {
    return (
      <React.Fragment>
        <div
          style={{ minHeight: "300px" }}
          className={classNames("special-card mt-4", {
            "bg-critical-warning": profileInfos.account_deletion_remaining <= 7,
            "bg-high-warning":
              profileInfos.account_deletion_remaining > 7 &&
              profileInfos.account_deletion_remaining <= 14,
            "bg-warning":
              profileInfos.account_deletion_remaining > 14 &&
              profileInfos.account_deletion_remaining <= 30,
          })}
        >
          <div className="d-flex flex-column justify-content-center">
            <i
              style={{ fontSize: "120px" }}
              className="mdi mdi-alert-box text-white text-center"
            />
            <span
              style={{ fontSize: "30px" }}
              className="text-white text-center fw-bold"
            >
              {profileInfos.account_deletion_remaining}{" "}
              {profileInfos.account_deletion_remaining > 1 ? "Days" : "Day"}{" "}
              until membership is deleted
            </span>
          </div>
        </div>
      </React.Fragment>
    );
  } else return null;
};

export default RemainingDay;
