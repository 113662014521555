import React, { useState } from "react";
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useAsyncDebounce,
  useSortBy,
  useFlexLayout,
  useResizeColumns,
} from "react-table";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  Col,
  Row,
  Pagination,
  PaginationItem,
  PaginationLink,
} from "reactstrap";
import { motion, AnimatePresence } from "framer-motion";
import { post } from "../../../helpers/api_helper";
import { useDispatch } from "react-redux";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import DOMPurify from "dompurify";

function GlobalFilter({
  //this line  [eslint] 'preGlobalFilteredRows' is missing in props validation [react/prop-types]
  preGlobalFilteredRows,
  globalFilter,
  setGlobalFilter,
  setCurrentPage,
}) {
  const count = preGlobalFilteredRows.length;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value) => {
    setGlobalFilter(value || undefined);
  }, 200);
  return (
    <span>
      <span className="d-flex justify-content-end fw-semibold">Search:</span>
      <input
        id="search"
        name="search"
        type="text"
        className="form-control w-100 d-flex  justify-content-end p-1 fw-semibold border-primary  "
        defaultValue={value || ""}
        onChange={(e) => {
          setValue(e.target.value);
          onChange(e.target.value);
          setCurrentPage(1);
        }}
        placeholder="Search"
      />
    </span>
  );
}

function DefaultColumnFilter({
  column: { filterValue, preFilteredRows, setFilter },
}) {
  const count = preFilteredRows.length;

  return (
    <input
      className="mt-2 form-control"
      value={filterValue || ""}
      onChange={(e) => {
        setFilter(e.target.value || undefined);
      }}
      placeholder={`Search ${count} records...`}
    />
  );
}

function NotificationsTable({ firstData, entryValue }) {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [data, set_data] = useState(firstData);
  // Seem
  const [seeFirst, setSeeFirst] = useState(true);
  const [seeSecond, setSeeSecond] = useState(true);
  const [seeThird, setSeeThird] = useState(true);
  const [seeFourth, setSeeFourth] = useState(true);
  // Others
  const [rowId, setRowId] = useState([]);
  const dispatch = useDispatch();
  // Pagination
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setpostsPerPage] = useState(10);

  const columns = React.useMemo(
    () => [
      {
        Header: "#",
        accessor: "first_name",
      },
      {
        Header: "Type",
        accessor: "severity",
      },
      {
        Header: "Subject",
        accessor: "title",
      },
      {
        Header: "Date",
        accessor: "created_at",
      },
    ],
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      // Default Filter UI
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const [sortBy, setSortBy2] = useState([{ id: "company_name", desc: false }]);
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    preGlobalFilteredRows,
    setGlobalFilter,
    setSortBy,
  } = useTable(
    {
      columns,
      data: data,
      defaultColumn,
      initialState: {
        sortBy,
      },
      // manualSortBy: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    useFlexLayout
    // useResizeColumns
  );
  const lastPostIndex = currentPage * postsPerPage;
  const firstPostIndex = lastPostIndex - postsPerPage;
  const pages = Math.ceil(rows?.length / postsPerPage);

  let pageArrays = [];
  for (let i = 1; i <= pages; i++) {
    pageArrays.push(i);
  }
  const values = [25, 50, 100];

  const rightPagination = () => {
    if (currentPage < pages) {
      setCurrentPage(Number(currentPage) + 1);
    } else setCurrentPage(currentPage);
  };

  const leftPagination = () => {
    if (currentPage === 1) {
      setCurrentPage(currentPage);
    } else if (currentPage <= pages) {
      setCurrentPage(Number(currentPage) - 1);
    }
  };

  if (currentPage < 1) {
    setCurrentPage(1);
  }

  const rowIdFunc = (id) => {
    let tempArray = [...rowId];
    let check = rowId.some((item) => item === id);
    if (check) {
      let filterFunc = rowId.filter((item) => item !== id);
      setRowId(filterFunc);
    } else {
      tempArray.push(id);
      setRowId(tempArray);
    }
  };

  const notificationRead = async (id, row) => {
    paceFunction(true);
    if (!row.original?.is_read) {
      try {
        let result = await post("/customer/settings/notifications", { id });
        if (result?.success) {
          dispatch({ type: "RENDER_COMPONENT_REQ" });
          const updatedArray = data.map((item) => {
            if (item.id === id) {
              return { ...item, is_read: true };
            }
            return item;
          });
          set_data(updatedArray);
          rowIdFunc(row.id);
        }
      } catch (error) {
        console.log(`error ==>`, error);
        errorToastFunction(error);
      }
    } else {
      rowIdFunc(row.id);
    }
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <Row className="d-flex justify-content-between mt-1 align-items-center ">
        <Col
          xxl={1}
          xl={1}
          lg={2}
          md={2}
          sm={3}
          xs={3}
          className="d-flex flex-row "
        >
          <span className="fw-semibold">Show</span>
          <select
            className="rounded-1 border-primary fw-semibold"
            // style={{ width: "40%" }}
            onChange={(e) => {
              setpostsPerPage(e.target.value);
              setCurrentPage(1);
            }}
            name=""
            placeholder="Enter Phone"
          >
            <option value={entryValue}>{entryValue}</option>
            {values.map((item) => (
              <option key={item} value={item}>
                {item}
              </option>
            ))}
          </select>{" "}
          <span className="fw-semibold">entries</span>
        </Col>
      </Row>

      <div style={{ maxHeight: "1000px", overflowY: "scroll" }}>
        <table className="table" {...getTableProps()}>
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr key={headerGroup.id} {...headerGroup.getHeaderGroupProps()}>
                <th className={`${seeFirst ? "col-1" : "d-none "}`}>
                  {headerGroup.headers[0].Header}
                </th>
                <th className={`${seeSecond ? "col-1" : "d-none "}`}>
                  {headerGroup.headers[1].Header}{" "}
                </th>
                <th className={`${seeThird ? "col" : "d-none "}`}>
                  {headerGroup.headers[2].Header}{" "}
                </th>
                <th className={`${seeFourth ? "col" : "d-none "}`}>
                  {headerGroup.headers[3].Header}{" "}
                </th>
                {/* {headerGroup.headers.map((column, index) => (
                <th
                  {...column.getHeaderProps(column.getSortByToggleProps())}
                  key={column.id}
                >
                  {" "}
                  {column.render("Header")}
                  <span style={{ cursor: "pointer" }} title="Sorting">
                    {column.isSorted
                      ? column.isSortedDesc
                        ? " 🔽"
                        : // : " 🔼"
                          " 🔽"
                      : "🔘"}
                  </span>
                </th>
              ))} */}
              </tr>
            ))}
          </thead>

          {data.length > 0 && (
            <tbody {...getTableBodyProps()}>
              {rows.slice(firstPostIndex, lastPostIndex).map((row, index) => {
                prepareRow(row);
                return (
                  <React.Fragment key={index}>
                    <tr
                      className={`${
                        rowId.some((item) => item === row.id)
                          ? "bg-soft-primary"
                          : row.original.is_read
                          ? ""
                          : "bg-soft-warning"
                      }`}
                      onClick={() => {
                        notificationRead(row?.original?.id, row);
                      }}
                      style={{ width: "100%", cursor: "pointer" }}
                      {...row.getRowProps()}
                    >
                      {seeFirst && (
                        <td className="col-1">
                          {rowId.some((item) => item === row?.id) ? (
                            <i className="fa fa-chevron-down" />
                          ) : (
                            <i className="fa fa-chevron-right" />
                          )}
                        </td>
                      )}
                      {seeSecond && (
                        <td className="col-1">
                          {row.cells[1].value === "INFO" ? (
                            <i
                              title="Info"
                              className="mdi mdi-bell-ring-outline text-info fs-5"
                            />
                          ) : (
                            <i
                              title="Critical"
                              className="mdi mdi-bell-ring-outline text-danger fs-5 "
                            />
                          )}
                        </td>
                      )}
                      {seeThird && (
                        <td className="col">{row.cells[2].value}</td>
                      )}
                      {seeFourth && (
                        <td className="col">{row.cells[3].value}</td>
                      )}
                    </tr>
                    <AnimatePresence>
                      {rowId.some((item) => item === row?.id) && (
                        <motion.tr
                          // style={{ backgroundColor: "#f8f8f8" }}
                          initial={{ y: -100, opacity: 0 }}
                          animate={{ y: 0, opacity: 1 }}
                          exit={{ y: 0, opacity: 0 }}
                          transition={{ duration: 0.4 }}
                        >
                          <td>
                            <div>
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: DOMPurify.sanitize(
                                    row?.original?.description
                                  ),
                                }}
                              />
                            </div>
                          </td>
                        </motion.tr>
                      )}
                    </AnimatePresence>
                  </React.Fragment>
                );
              })}
            </tbody>
          )}
        </table>
        {data.length < 1 && (
          <span
            style={{ width: "100%" }}
            className="d-flex justify-content-center "
          >
            No data available in table
          </span>
        )}
      </div>
      <Row>
        <Col xs={5} className="d-flex align-items-center ">
          <span>
            {firstPostIndex + 1}-
            {lastPostIndex <= rows?.length ? lastPostIndex : rows?.length} of{" "}
            {rows?.length}
          </span>
        </Col>
        <Col className="d-flex align-items-center justify-content-end  mt-2 ">
          <Pagination
            className="d-flex align-items-center "
            aria-label="Page navigation "
          >
            <PaginationItem>
              <PaginationLink onClick={() => leftPagination()} tabIndex={-1}>
                Previous
              </PaginationLink>
            </PaginationItem>
            {pageArrays.length > 10 && (
              <>
                <PaginationItem>
                  <PaginationLink onClick={() => setCurrentPage(1)}>
                    First
                  </PaginationLink>
                </PaginationItem>
                {currentPage > 2 && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) - 2)}
                    >
                      {Number(currentPage) - 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem active>
                  <PaginationLink
                    onClick={() => setCurrentPage(Number(currentPage))}
                  >
                    {Number(currentPage)}
                  </PaginationLink>
                </PaginationItem>
                {currentPage + 2 <= pageArrays.length && (
                  <PaginationItem>
                    <PaginationLink
                      onClick={() => setCurrentPage(Number(currentPage) + 2)}
                    >
                      {Number(currentPage) + 2}
                    </PaginationLink>
                  </PaginationItem>
                )}
                <PaginationItem>
                  <PaginationLink
                    onClick={() => setCurrentPage(pageArrays.length)}
                  >
                    Last
                  </PaginationLink>
                </PaginationItem>
              </>
            )}
            {pageArrays.length <= 10 &&
              pageArrays.map((page) => (
                <PaginationItem
                  key={page}
                  active={page === currentPage ? true : false}
                >
                  <PaginationLink onClick={() => setCurrentPage(page)}>
                    {page}
                  </PaginationLink>
                </PaginationItem>
              ))}

            <PaginationItem>
              <PaginationLink onClick={() => rightPagination()}>
                Next
              </PaginationLink>
            </PaginationItem>
          </Pagination>
        </Col>
      </Row>
    </React.Fragment>
  );
}

export default NotificationsTable;
