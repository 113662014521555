import {
  LOGIN_USER,
  LOGIN_SUCCESS,
  LOGIN_SMS_SUCCESS,
  LOGIN_QR_CODE,
  LOGOUT_USER,
  LOGOUT_USER_SUCCESS,
  API_ERROR,
} from "./actionTypes";

const initialState = {
  error: "",
  loading: false,
  smsResponse: "",
  qrCode: false,
};

const login = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN_USER:
      state = {
        ...state,
        loading: true,
      };
      break;
    case LOGIN_SUCCESS:
      state = {
        ...state,
        loading: false,
      };
      break;
    case LOGIN_SMS_SUCCESS:
      state = {
        ...state,
        loading: false,
        smsResponse: action.payload ? action.payload.data.data.challenge : null,
      };
      break;
    case LOGIN_QR_CODE:
      state = {
        ...state,
        loading: false,
        qrCode: true,
      };
      break;
    case LOGOUT_USER:
      state = { ...state };
      break;
    case LOGOUT_USER_SUCCESS:
      state = { ...state };
      break;
    case API_ERROR:
      state = { ...state, error: action.payload, loading: false };
      break;
    default:
      state = { ...state };
      break;
  }
  return state;
};

export default login;
