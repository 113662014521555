import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Col, Container, Row, Progress, Spinner } from "reactstrap";
import { useSelector } from "react-redux";
//Verification code package
import AuthCode from "react-auth-code-input";
import axios from "axios";
//import images
import logo from "../../assets/images/logo_colors_lg.svg";
import CarouselPage from "./CarouselPage";
import { useHistory } from "react-router-dom";
// Toast
import DateIconComponent from "../../common/DateIconComponent";
import errorFunctionComponent from "../../common/errorFunctionComponent";
const TwoStepVerfication = () => {
  const { errorToastFunction } = errorFunctionComponent();
  const [verifyCode, setVerifyCode] = useState();
  const [progressValue, setProgressValue] = useState(0);
  const [isLoading, setIsLoading] = useState(false);

  const { error, smsResponse, qrCode } = useSelector((state) => ({
    smsResponse: state.Login.smsResponse,
    qrCode: state.Login.qrCode,
  }));

  //meta title
  document.title = "Two Step Verification | PentestBX";

  const router = useHistory();

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data = {
      token: smsResponse,
      otp_code: verifyCode,
    };
    setIsLoading(true);
    try {
      let response = await axios.post(
        `${process.env.REACT_APP_APIURL}/account/verify`,
        data
      );
      if (response?.status === 200) {
        const expireTimeInMilliseconds = 23 * 60 * 60 * 1000;
        const value = response.data;
        const expireTime = new Date().getTime() + expireTimeInMilliseconds;
        const dataToStore = {
          value,
          expireTime,
        };
        localStorage.setItem("authUser", JSON.stringify(dataToStore));
        window.location.reload();
        setIsLoading(false);
        // localStorage.setItem("authUser", JSON.stringify(response.data));
        // router.push("/dashboard");
      }
    } catch (error) {
      console.log("Error ==>", error);
      setIsLoading(false);
      errorToastFunction(error);
    }
  };

  useEffect(() => {
    if (!smsResponse) router.push("/login");
  }, [smsResponse]);

  const arttirFunc = () => {
    setProgressValue(Number(progressValue) + 1);
  };
  useEffect(() => {
    if (!qrCode) {
      setTimeout(() => {
        if (progressValue < 180) {
          arttirFunc();
        } else {
          window.location.reload();
        }
      }, 1000);
    }
  }, [progressValue]);
  return (
    <React.Fragment>
      <div style={{ overflow: "hidden" }} className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              {isLoading ? (
                <div className="auth-full-page-content d-flex justify-content-center  align-items-center h-100  ">
                  <Spinner
                    style={{ width: "3rem", height: "3rem" }}
                    className="ms-2 "
                    color="primary"
                  />
                </div>
              ) : (
                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5 text-center">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img src={logo} alt="" height="40" />
                          {/* <span className="logo-txt">Minia</span> */}
                        </Link>
                      </div>
                      <div className="auth-content my-auto">
                        <div className="text-center">
                          {qrCode ? (
                            <div className="avatar-lg mx-auto">
                              <div className="avatar-title rounded-circle bg-light">
                                <i className="bx bxs-barcode h2 mb-0 text-primary"></i>
                              </div>
                            </div>
                          ) : (
                            <div className="avatar-lg mx-auto">
                              <div className="avatar-title rounded-circle bg-light">
                                <i className="bx bxs-phone h2 mb-0 text-primary"></i>
                              </div>
                            </div>
                          )}

                          <div className="p-2 mt-4">
                            <h4>Verify your code</h4>
                            {qrCode ? (
                              <p className="mb-5">
                                Please enter the 6 digit code in{" "}
                                <span className="fw-bold">
                                  your authenticator
                                </span>
                              </p>
                            ) : (
                              <p className="mb-5">
                                Please enter the 6 digit code sent to{" "}
                                <span className="fw-bold">your phone</span>
                              </p>
                            )}

                            <form onSubmit={handleSubmit}>
                              <div className="row">
                                <div className="col-12">
                                  <div className="mb-3 verification">
                                    <label
                                      htmlFor="digit1-input"
                                      className="visually-hidden"
                                    >
                                      Dight 1
                                    </label>
                                    <AuthCode
                                      characters={6}
                                      className="form-control form-control-lg text-center"
                                      allowedCharacters="^[0-9]"
                                      inputStyle={{
                                        width: "40px",
                                        height: "35px",
                                        padding: "8px",
                                        borderRadius: "8px",
                                        fontSize: "16px",
                                        textAlign: "center",
                                        marginRight: "15px",
                                        border: "1px solid #ced4da",
                                        textTransform: "uppercase",
                                      }}
                                      onChange={(e) => setVerifyCode(e)}
                                    />
                                  </div>
                                </div>
                              </div>
                              <div>
                                <Progress
                                  max="180"
                                  value={progressValue}
                                  color="primary"
                                  animated
                                ></Progress>
                              </div>
                              <div className="mt-4">
                                <button
                                  type="submit"
                                  className="btn btn-primary w-100 fw-semibold"
                                >
                                  Confirm
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>

                        <div className="mt-5 text-center">
                          <p className="text-muted mb-0">
                            Didn't receive an code ?{" "}
                            <Link to="#" className="text-primary fw-semibold">
                              {" "}
                              Resend{" "}
                            </Link>{" "}
                          </p>
                        </div>
                        {/* <div className="mt-1 text-center">
                        <p className="text-muted mb-0  ">
                          Back to
                          <Link
                            to="/login"
                            className="text-primary fw-semibold ms-1"
                          >
                            Login
                          </Link>
                        </p>
                      </div> */}
                      </div>
                      <DateIconComponent />
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default TwoStepVerfication;
