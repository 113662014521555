import PropTypes from "prop-types";
import React, { useState } from "react";
import {
  Row,
  Col,
  Alert,
  Container,
  FormFeedback,
  Input,
  Label,
  Form,
  Spinner,
} from "reactstrap";
import { post } from "../../helpers/api_helper";
import { useSelector, useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import * as Yup from "yup";
import { useFormik } from "formik";
import DateIconComponent from "../../common/DateIconComponent";
import logo from "../../assets/images/logo_colors_lg.svg";
import CarouselPage from "../AuthenticationInner/CarouselPage";
import errorFunctionComponent from "../../common/errorFunctionComponent";

const ForgetPasswordPage = (history) => {
  document.title = "Forget Password | PentestBX";
  const { errorToastFunction } = errorFunctionComponent();
  const [isLoading, set_isLoading] = useState(false);
  const dispatch = useDispatch();

  const { forgetError, forgetSuccessMsg } = useSelector((state) => ({
    forgetError: state.ForgetPassword.forgetError,
    forgetSuccessMsg: state.ForgetPassword.forgetSuccessMsg,
  }));

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
    }),
    onSubmit: (values) => {
      resetPassword(values);
    },
  });
  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      icon: "success",
      timer: 24 * 60 * 60 * 1000,
      timerProgressBar: false,
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  const resetPassword = async (values) => {
    set_isLoading(true);
    try {
      let result = await post("/account/reset-password", values);
      if (result?.success) {
        showSweetAlert(result?.message || "Please Check your email");
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_isLoading(false);
  };
  return (
    <React.Fragment>
      <div className="auth-page">
        <Container fluid className="p-0">
          <Row className="g-0">
            <Col lg={4} md={5} className="col-xxl-3">
              {isLoading ? (
                <div className="auth-full-page-content d-flex justify-content-center  align-items-center h-100  ">
                  <Spinner
                    style={{ width: "3rem", height: "3rem" }}
                    className="ms-2 "
                    color="primary"
                  />
                </div>
              ) : (
                <div className="auth-full-page-content d-flex p-sm-5 p-4">
                  <div className="w-100">
                    <div className="d-flex flex-column h-100">
                      <div className="mb-4 mb-md-5 text-center">
                        <Link to="/dashboard" className="d-block auth-logo">
                          <img src={logo} alt="" height="28" />{" "}
                        </Link>
                      </div>
                      <div className="auth-content my-auto">
                        <div className="text-center">
                          <h5 className="mb-0">Reset Password</h5>
                        </div>

                        {forgetError && forgetError ? (
                          <Alert color="danger" style={{ marginTop: "13px" }}>
                            {forgetError}
                          </Alert>
                        ) : null}
                        {forgetSuccessMsg ? (
                          <Alert color="success" style={{ marginTop: "13px" }}>
                            {forgetSuccessMsg}
                          </Alert>
                        ) : null}

                        <Form
                          className="custom-form mt-4"
                          onSubmit={(e) => {
                            e.preventDefault();
                            validation.handleSubmit();
                            return false;
                          }}
                        >
                          <div className="mb-3">
                            <Label className="form-label">Email</Label>
                            <Input
                              name="email"
                              className="form-control"
                              placeholder="Enter email"
                              type="email"
                              onChange={validation.handleChange}
                              onBlur={validation.handleBlur}
                              value={validation.values.email || ""}
                              invalid={
                                validation.touched.email &&
                                validation.errors.email
                                  ? true
                                  : false
                              }
                            />
                            {validation.touched.email &&
                            validation.errors.email ? (
                              <FormFeedback type="invalid">
                                {validation.errors.email}
                              </FormFeedback>
                            ) : null}
                          </div>

                          <Row className="mb-3">
                            <Col className="text-end">
                              <button
                                className="btn btn-primary w-100 waves-effect waves-light"
                                type="submit"
                              >
                                Reset
                              </button>
                            </Col>
                          </Row>
                        </Form>

                        <div className="mt-5 text-center">
                          <p className="text-muted mb-0">
                            Remember It ?{" "}
                            <a
                              href="login"
                              className="text-primary fw-semibold"
                            >
                              {" "}
                              Log In{" "}
                            </a>{" "}
                          </p>
                        </div>
                      </div>
                      <DateIconComponent />
                    </div>
                  </div>
                </div>
              )}
            </Col>
            <CarouselPage />
          </Row>
        </Container>
      </div>
    </React.Fragment>
  );
};

ForgetPasswordPage.propTypes = {
  history: PropTypes.object,
};

export default ForgetPasswordPage;
