import React, { useState } from "react";
import classnames from "classnames";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
} from "reactstrap";
import Zonetransfer from "./Zonetransfer";
import Whois from "./Whois";
import Subdomains from "./Subdomains";
import Dnsrecords from "./Dnsrecords";
import Certificate from "./Certificate";
import SocialAccounts from "./SocialAccounts";
import PossiblePhisings from "./PossiblePhising";

const ChildTabs = ({ datas }) => {
  const [customActiveTab, setcustomActiveTab] = useState("1");

  const toggleCustom = (tab) => {
    if (customActiveTab !== tab) {
      setcustomActiveTab(tab);
    }
  };

  return (
    <div className="">
      <div className="row mb-5 ">
        {Object.keys(datas?.screenshot)?.length > 0 && (
          <div className=" col mb-3 ">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "1",
                    })}
                    onClick={() => {
                      toggleCustom("1");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-panorama-variant-outline fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">Screenshot</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}

        <div className=" col mb-3 ">
          <div className=" ">
            <Nav tabs className="nav-tabs-custom2 nav-justified  ">
              <NavItem className="   ">
                <NavLink
                  style={{ cursor: "pointer" }}
                  className={classnames({
                    active: customActiveTab === "2",
                  })}
                  onClick={() => {
                    toggleCustom("2");
                  }}
                >
                  <span className="d-block ">
                    <i className="mdi mdi-transfer fs-4 "></i>
                  </span>
                  <span className="d-none d-sm-block fs-4  ">Zonetransfer</span>
                </NavLink>
              </NavItem>
            </Nav>
          </div>
        </div>
        {Object.keys(datas?.whois)?.length > 0 && (
          <div className=" col mb-3">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "3",
                    })}
                    onClick={() => {
                      toggleCustom("3");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-account-question fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">Whois</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
        {Object.keys(datas?.possiblephisings)?.length > 0 && (
          <div className=" col mb-3 ">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "4",
                    })}
                    onClick={() => {
                      toggleCustom("4");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-monitor-cellphone fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">
                      PossiblePhisings
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
        {Object.keys(datas?.subdomains)?.length > 0 && (
          <div className=" col mb-3">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "5",
                    })}
                    onClick={() => {
                      toggleCustom("5");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-domain-plus fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">Subdomains</span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
        {Object.keys(datas?.certificates)?.length > 0 && (
          <div className=" col mb-3">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "7",
                    })}
                    onClick={() => {
                      toggleCustom("7");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-certificate fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">
                      Certificate
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
        {Object.keys(datas?.socialaccounts)?.length > 0 && (
          <div className=" col mb-3">
            <div className=" ">
              <Nav tabs className="nav-tabs-custom2 nav-justified  ">
                <NavItem className="   ">
                  <NavLink
                    style={{ cursor: "pointer" }}
                    className={classnames({
                      active: customActiveTab === "8",
                    })}
                    onClick={() => {
                      toggleCustom("8");
                    }}
                  >
                    <span className="d-block ">
                      <i className="mdi mdi-account-multiple fs-4 "></i>
                    </span>
                    <span className="d-none d-sm-block fs-4  ">
                      SocialAccounts
                    </span>
                  </NavLink>
                </NavItem>
              </Nav>
            </div>
          </div>
        )}
      </div>

      <div className="special-card">
        <TabContent activeTab={customActiveTab} className="p-3 text-muted">
          {Object.keys(datas?.screenshot)?.length > 0 && (
            <TabPane tabId="1">
              <Row>
                <Col sm="12">
                  <div className="mb-0" style={{ height: "700px" }}>
                    <img
                      style={{
                        width: "100%",
                        height: "100%",
                        objectFit: "fill",
                      }}
                      src={`data:image/png;base64,${datas?.screenshot?.data?.base64_img}`}
                      alt="screenshot"
                    />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          <TabPane tabId="2">
            <Row>
              <Col sm="12">
                <div className="mb-0">
                  <Zonetransfer data={datas?.zonetransfer} />
                </div>
              </Col>
            </Row>
          </TabPane>
          {Object.keys(datas?.whois)?.length > 0 && (
            <TabPane tabId="3">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <Whois data={datas?.whois?.data} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          {Object.keys(datas?.possiblephisings)?.length > 0 && (
            <TabPane tabId="4">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <PossiblePhisings data={datas?.possiblephisings?.data} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          {Object.keys(datas?.subdomains)?.length > 0 && (
            <TabPane tabId="5">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <Subdomains data={datas?.subdomains?.data} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          {Object.keys(datas?.certificates)?.length > 0 && (
            <TabPane tabId="7">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <Certificate data={datas?.certificates?.data} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
          {Object.keys(datas?.socialaccounts)?.length > 0 && (
            <TabPane tabId="8">
              <Row>
                <Col sm="12">
                  <div className="mb-0">
                    <SocialAccounts data={datas?.socialaccounts?.data} />
                  </div>
                </Col>
              </Row>
            </TabPane>
          )}
        </TabContent>
      </div>
    </div>
  );
};

export default ChildTabs;
