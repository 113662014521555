import React, { useCallback, useEffect, useState } from "react";
import ApplianceInfo from "./ApplianceInfo";
import ModalAddModule from "./ModalAddModule";
import LinkedAccounts from "./LinkedAccounts";
import {
  Input,
  FormFeedback,
  Form,
  CardBody,
  Dropdown,
  DropdownItem,
  DropdownMenu,
} from "reactstrap";
import * as Yup from "yup";
import { useFormik } from "formik";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { del, get, put, patch } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";
import ConfirmModal from "../../../common/utils/ConfirmModal";
import { listModules, planLists } from "../../../common/utils/commonValues";
import {
  formatDateFlatPickr,
  removeBodyCss,
  toastSuccess,
} from "../../../common/utils/commonFunctions";
import Flatpickr from "react-flatpickr";
const CustomerInfo = ({
  userInfo,
  applianceData,
  userId,
  modules,
  renderFunc,
}) => {
  const { errorFunction, errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [btnprimary1, setBtnprimary1] = useState(false);
  const [btnprimary2, setBtnprimary2] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard2, setmodal_standard2] = useState(false);
  const [modal_standard3, setmodal_standard3] = useState(false);
  const [modal_standard4, setmodal_standard4] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);
  // UserInfo
  const [company_name, set_company_name] = useState(userInfo.company_name);
  const [phone_number, set_phone_number] = useState(userInfo.phone_number);
  const [is_active, set_is_active] = useState(userInfo.is_active);
  const [auth_method, set_auth_method] = useState(userInfo.auth_method);
  const [is_endpoint, set_is_endpoint] = useState(userInfo.is_endpoint);
  const [remote_db, set_remote_db] = useState(userInfo.remote_db);
  const [email, set_email] = useState(userInfo.email);
  const [access_limit, set_access_limit] = useState(userInfo?.access_limit);
  const [auto_renewal, set_auto_renewal] = useState(userInfo?.auto_renewal);
  const [subscription_renewal, set_subscription_renewal] = useState(
    userInfo?.subscription_renewal
  );
  const [date_joined, set_date_joined] = useState(userInfo?.date_joined);
  const [plan, set_plan] = useState(userInfo?.plan);
  const [asset_collect, set_asset_collect] = useState(
    userInfo?.asset_collect || false
  );
  // Other
  const [updateLoading, setUpdateLoading] = useState(false);
  const [applianceLoading, setApplianceLoading] = useState(false);
  const [applianceRemoveLoading, setApplianceRemoveLoading] = useState(false);
  const [missingModules, setMissingModules] = useState();
  const [linkedAccounts, set_linkedAccounts] = useState();

  useEffect(() => {
    if (modules) {
      const missingModules = listModules.filter(
        (moduleName) => !modules.some((item) => item.name === moduleName)
      );
      setMissingModules(missingModules);
    }
  }, [modules]);

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      // text: "This is a SweetAlert2 dialog in a React app.",
      icon: "success",
      timer: 3000, // 3000 milisaniye (3 saniye)
      timerProgressBar: true, // İlerleme çubuğunu göster
      showConfirmButton: false,
    });
    renderFunc();
  };

  const tog_standard = useCallback(() => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  }, []);

  const tog_standard2 = () => {
    setmodal_standard2(!modal_standard2);
    removeBodyCss();
  };

  const tog_standard3 = () => {
    setmodal_standard3(!modal_standard3);
    removeBodyCss();
  };

  const tog_standard4 = () => {
    setmodal_standard4(!modal_standard4);
    removeBodyCss();
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  const options = { year: "numeric", month: "long", day: "numeric" };
  const subscriptionRenewal = new Date(userInfo?.subscription_renewal);
  const formattedSubscriptionRenewal = subscriptionRenewal.toLocaleDateString(
    "en-US",
    options
  );

  const joinedDate = new Date(userInfo?.date_joined);
  const formattedJoinedDate = joinedDate.toLocaleDateString("en-US", options);

  const changeTime = (e) => {
    set_subscription_renewal(formatDateFlatPickr(e[0]));
  };

  const addNewAppliance = async () => {
    paceFunction(true);
    setApplianceLoading(true);
    try {
      let result = await put("/manager/customer/endpoint", { id: userId });
      if (result?.success) {
        // dispatch({type:"RENDER_COMPONENT2_REQ"});
        showSweetAlert("Appliance setup completed.");
        tog_standard2();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setApplianceLoading(false);
    paceFunction(false);
  };

  const removeAppliance = async () => {
    paceFunction(true);
    setApplianceRemoveLoading(true);
    try {
      let result = await del(
        `/manager/customer/endpoint?id=${userId}&endpoint_id=${applianceData.id}`
      );
      if (result?.success) {
        // dispatch({type:"RENDER_COMPONENT2_REQ"});
        showSweetAlert("Appliance remove completed.");
        tog_standard3();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setApplianceRemoveLoading(false);
    paceFunction(false);
  };

  const updateUserInfo = async (data) => {
    paceFunction(true);
    setUpdateLoading(true);
    try {
      let result = await patch("/manager/customer", data);
      if (result?.success) {
        toastSuccess(result?.message || "Customer Updated");
        tog_standard5();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setUpdateLoading(false);
    paceFunction(false);
  };

  const getLinkedAccounts = async () => {
    paceFunction(true);
    try {
      let result = await get(`/manager/customer/linked-account?id=${userId}`);
      if (result?.success) {
        set_linkedAccounts(result?.data);
        tog_standard6();
      }
    } catch (error) {
      errorFunction(error);
      console.log(`error ==>`, error);
    }
    paceFunction(false);
  };

  const validation = useFormik({
    // enableReinitialize : use this flag when initial values needs to be changed
    enableReinitialize: true,

    initialValues: {
      email: email || "",
      phone_number: phone_number || "",
      company_name: company_name || "",
    },
    validationSchema: Yup.object({
      email: Yup.string().required("Please Enter Your Email"),
      phone_number: Yup.string().required("Please Enter Your Phone Number"),
      company_name: Yup.string().required(
        "Please Enter Your Organization Name"
      ),
    }),
    onSubmit: (values) => {
      let data = {
        ...values,
        is_active: is_active,
        is_demo: false,
        authentication: auth_method,
        id: userId,
        asset_collect: is_endpoint ? asset_collect : false,
        access_limit,
        subscription_renewal,
        plan,
        date_joined,
        auto_renewal,
      };
      updateUserInfo(data);
    },
  });

  return (
    <div className="special-card mb-4 ">
      <ModalAddModule
        modal_standard={modal_standard4}
        tog_standard={tog_standard4}
        setmodal_standard={setmodal_standard4}
        missingModules={missingModules}
        userId={userId}
      />
      {linkedAccounts && (
        <LinkedAccounts
          linkedAccounts={linkedAccounts}
          modal_standard={modal_standard6}
          setmodal_standard={(e) => setmodal_standard6(e)}
          tog_standard={tog_standard6}
        />
      )}
      <ApplianceInfo
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        data={applianceData}
      />
      {/* Setup a New Appliance */}
      <ConfirmModal
        modal_standard={modal_standard2}
        tog_standard={tog_standard2}
        setmodal_standard={setmodal_standard2}
        targetFunction={addNewAppliance}
        text="Are you sure you want to setup a new appliance?"
        loadingAction={false}
      />
      {/* Remove Appliance */}
      <ConfirmModal
        modal_standard={modal_standard3}
        tog_standard={tog_standard3}
        setmodal_standard={setmodal_standard3}
        targetFunction={removeAppliance}
        text="Are you sure you want to remove appliance?"
        loadingAction={false}
      />
      {/* Update Customer */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={validation.handleSubmit}
        text="Are you sure you want to update customer?"
        loadingAction={true}
        isLoading={updateLoading}
      />
      <Form
        onSubmit={(e) => {
          e.preventDefault();
          validation.handleSubmit();
          return false;
        }}
      >
        <CardBody>
          <div className="row">
            {/* Input Area */}
            <div className="col-lg-4">
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  name="company_name"
                  className="form-control "
                  // required
                  autoComplete="off"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.company_name || ""}
                  invalid={
                    validation.touched.company_name &&
                    validation.errors.company_name
                      ? true
                      : false
                  }
                />
                <label>Organization Name</label>
                {validation.touched.company_name &&
                validation.errors.company_name ? (
                  <FormFeedback type="invalid">
                    {validation.errors.company_name}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className=" col-lg-4  ">
              <div className="form-floating mb-3">
                <Input
                  disabled
                  type="email"
                  name="email"
                  className="form-control "
                  // required
                  autoComplete="off"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.email || ""}
                  invalid={
                    validation.touched.email && validation.errors.email
                      ? true
                      : false
                  }
                />
                <label>E-mail Address</label>
                {validation.touched.email && validation.errors.email ? (
                  <FormFeedback type="invalid">
                    {validation.errors.email}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className=" col-lg-4">
              <div className="form-floating mb-3">
                <Input
                  type="text"
                  name="phone_number"
                  className="form-control"
                  // required
                  autoComplete="off"
                  onChange={validation.handleChange}
                  onBlur={validation.handleBlur}
                  value={validation.values.phone_number || ""}
                  invalid={
                    validation.touched.phone_number &&
                    validation.errors.phone_number
                      ? true
                      : false
                  }
                />
                <label>Phone Number</label>
                {validation.touched.phone_number &&
                validation.errors.phone_number ? (
                  <FormFeedback type="invalid">
                    {validation.errors.phone_number}
                  </FormFeedback>
                ) : null}
              </div>
            </div>
            <div className=" col-lg-3 form-floating mb-3">
              <select
                className="form-select"
                name="type"
                id="type"
                defaultValue={plan}
                onChange={(e) => set_plan(e.target.value)}
              >
                {planLists.map((planItem, index) => {
                  return (
                    <option key={planItem + index} value={planItem}>
                      {planItem}
                    </option>
                  );
                })}
              </select>
              <label style={{ marginLeft: "6px" }}>Plan</label>
            </div>
            <div className=" col-lg-3 form-floating mb-3">
              <input
                className="form-control"
                type="number"
                name="scanperiod"
                min={1}
                step={1}
                defaultValue={access_limit}
                autoComplete="off"
                onChange={(e) => set_access_limit(e.target.value)}
              />
              <label style={{ marginLeft: "6px" }}>Access Limit</label>
            </div>
            <div className="col-lg-3 form-floating mb-3">
              <Flatpickr
                name="expiration_date"
                className="form-control d-block"
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "F j, Y",
                }}
                defaultValue={formattedJoinedDate}
                onChange={changeTime}
                disabled
              />
              <label style={{ marginLeft: "6px" }}>Joined Date</label>
            </div>
            <div className=" col-lg-3 form-floating mb-3 ">
              <Flatpickr
                name="expiration_date"
                className="form-control bg-flatpicker  d-block cursor-pointer "
                options={{
                  altInput: true,
                  altFormat: "F j, Y",
                  dateFormat: "F j, Y",
                }}
                defaultValue={formattedSubscriptionRenewal}
                onChange={changeTime}
              />
              <label style={{ marginLeft: "6px" }}>Subscription Renewal</label>
            </div>
            {/* Control Area */}
            <div className="col-xl-12 col-12">
              <div className="row d-flex  ">
                <div className="col-xl-6">
                  <div className="row">
                    <div className="col-3 ">
                      <label className="form-label">Active</label>
                      <div className="square-switch">
                        <input
                          type="checkbox"
                          id="is_active1"
                          name="is_active"
                          is_demo="is_active"
                          switch="primary"
                          defaultChecked={is_active}
                          autoComplete="off"
                          onChange={(e) => set_is_active(e.target.checked)}
                        />
                        <label
                          htmlFor="is_active1"
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </div>
                    </div>{" "}
                    <div className="col-sm-3 ">
                      <label className="form-label">Auto Renewal</label>
                      <div className="square-switch">
                        <input
                          type="checkbox"
                          id="auto_renewal"
                          name="auto_renewal"
                          is_demo="auto_renewal"
                          switch="primary"
                          defaultChecked={auto_renewal}
                          autoComplete="off"
                          onChange={(e) => set_auto_renewal(e.target.checked)}
                        />
                        <label
                          htmlFor="auto_renewal"
                          data-on-label="Yes"
                          data-off-label="No"
                        />
                      </div>
                    </div>
                    <div className="col-6 d-flex flex-column align-items-center ">
                      <label className="form-label">Authentication</label>
                      <div className="btn-group btn-group-sm  " role="group">
                        <input
                          type="radio"
                          className="btn-check"
                          name="authentication"
                          id="auth2faNone1"
                          defaultValue="None"
                          autoComplete="off"
                          defaultChecked={auth_method === "None" ? true : false}
                          onChange={(e) => set_auth_method(e.target.value)}
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="auth2faNone1"
                        >
                          None
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="authentication"
                          id="auth2faSms1"
                          defaultValue="SMS"
                          autoComplete="off"
                          defaultChecked={auth_method === "SMS" ? true : false}
                          onChange={(e) => set_auth_method(e.target.value)}
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="auth2faSms1"
                        >
                          SMS
                        </label>
                        <input
                          type="radio"
                          className="btn-check"
                          name="authentication"
                          id="auth2faQr1"
                          defaultValue="QR"
                          autoComplete="off"
                          defaultChecked={auth_method === "QR" ? true : false}
                          onChange={(e) => set_auth_method(e.target.value)}
                          disabled
                        />
                        <label
                          className="btn btn-outline-primary"
                          htmlFor="auth2faQr1"
                        >
                          QR
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6">
                  <div className="row">
                    {/* Appliance */}
                    <div className="col-xl-7 col-6  ">
                      <label className="form-label">Appliance</label>
                      <br />
                      {is_endpoint ? (
                        <Dropdown
                          isOpen={btnprimary2}
                          toggle={() => setBtnprimary2(!btnprimary2)}
                        >
                          <button
                            onClick={() => {
                              setBtnprimary2(!btnprimary2);
                            }}
                            type="button"
                            className="btn btn-primary fs-6 py-1 "
                          >
                            Actions{" "}
                            {applianceRemoveLoading ? (
                              <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>
                            ) : (
                              <i className="mdi mdi-chevron-down" />
                            )}
                          </button>
                          <DropdownMenu>
                            <DropdownItem
                              onClick={() => {
                                tog_standard();
                              }}
                            >
                              <i className="mdi mdi-cog label-icon text-primary me-1 " />
                              Config
                            </DropdownItem>
                            {/* <DropdownItem
                              onClick={() => {
                                router.push(
                                  `/admin/appliance-task/${applianceData.id}/${userInfo.company_name}`
                                );
                              }}
                            >
                              <i className="mdi mdi-view-list label-icon text-primary me-1 " />
                              Tasks List
                            </DropdownItem> */}
                            <DropdownItem
                              onClick={() => {
                                tog_standard3();
                              }}
                            >
                              <i className="mdi mdi-table-column-remove label-icon text-primary me-1 " />
                              Remove Appliance
                            </DropdownItem>
                          </DropdownMenu>
                        </Dropdown>
                      ) : applianceLoading ? (
                        <button
                          type="button"
                          className="btn btm-sm btn-success waves-effect waves-light"
                          disabled
                        >
                          <i className="bx bx-loader bx-spin font-size-16 align-middle me-2"></i>{" "}
                          Setup
                        </button>
                      ) : (
                        <button
                          type="button"
                          className="btn btn-sm btn-success"
                          id="setupApplianceBtn"
                          onClick={() => {
                            tog_standard2();
                          }}
                        >
                          Setup
                        </button>
                      )}
                    </div>
                    {/* Dropdown */}
                    <div className="col-xl-5 col-6">
                      <label className="form-label" />
                      <Dropdown
                        isOpen={btnprimary1}
                        toggle={() => setBtnprimary1(!btnprimary1)}
                      >
                        <button
                          onClick={() => {
                            setBtnprimary1(!btnprimary1);
                          }}
                          type="button"
                          className="btn btn-primary fs-6 "
                        >
                          Actions <i className="mdi mdi-chevron-down" />
                        </button>
                        <DropdownMenu>
                          <DropdownItem
                            onClick={() => {
                              tog_standard4();
                            }}
                          >
                            <i className="mdi mdi-view-grid label-icon text-primary me-1 " />
                            Add Module
                          </DropdownItem>
                          <DropdownItem onClick={getLinkedAccounts}>
                            <i className="mdi mdi-account-group label-icon text-primary me-1 " />
                            Linked Accounts
                          </DropdownItem>
                        </DropdownMenu>
                      </Dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </CardBody>
        <div className="col border-end d-flex justify-content-center align-items-end mb-2">
          {remote_db ? (
            <i
              className="mdi mdi-database-export-outline text-primary me-3 md-22 label-icon fs-3 "
              title="DB On Appliance"
            />
          ) : (
            <i
              className="mdi mdi-database-marker-outline text-primary me-3 md-22 label-icon fs-3 "
              title="DB On cloud"
            ></i>
          )}

          <button
            className="btn btn-outline-primary"
            id="updateWebAppBtn"
            type="button"
            onClick={tog_standard5}
          >
            <i className="bx bx-check-double label-icon" /> Update Customer
          </button>
        </div>
      </Form>
    </div>
  );
};

export default React.memo(CustomerInfo);
