// @flow
import { all, call, fork, takeEvery, put } from "redux-saga/effects";

import { RENDER_COMPONENT, RENDER_COMPONENT2 } from "./actionTypes";

import { renderComponent, renderComponent2 } from "./actions";

function* renderComponentFunc() {
  yield put({ type: "RENDER_COMPONENT" });
  // yield put(renderComponent(response));
}
function* renderComponentFunc2() {
  // try {
  //   let response = false;
  yield put({ type: "RENDER_COMPONENT2" });
  // yield put(renderComponent2(response));
  // } catch (error) {}
}

export function* watchRender() {
  yield takeEvery("RENDER_COMPONENT_REQ", renderComponentFunc);
}

export function* watchRender2() {
  yield takeEvery("RENDER_COMPONENT2_REQ", renderComponentFunc2);
}

function* RenderSaga() {
  yield all([fork(watchRender), fork(watchRender2)]);
}

export default RenderSaga;
