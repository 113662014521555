import React, { useState } from "react";
import { Modal } from "reactstrap";
import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";

const IssuesDetail = ({
  issueDetail,
  modal_standard,
  tog_standard,
  setmodal_standard,
}) => {
  const [isFits, setisFits] = useState(false);

  return (
    <div>
      {isFits ? (
        <Lightbox
          mainSrc={`data:image/png;base64,${issueDetail?.screenshot}`}
          enableZoom={true}
          onCloseRequest={() => {
            setisFits(!isFits);
          }}
        />
      ) : null}
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="xl"
      >
        {" "}
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Detail <span className="text-primary">{issueDetail?.subject}</span>{" "}
            Issue
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <form
            id="issues_detail"
            noValidate
            method="post"
            action="/update_profile/"
          >
            <div className="row justify-content-center">
              <div className="col-12">
                <div
                  // style={{ width: width, height: height }}
                  className="screenshot-image"
                >
                  <img
                    src={`data:image/png;base64,${issueDetail?.screenshot}`}
                    alt={"Screenshot"}
                    onClick={() => setisFits(true)}
                    className="img-fluid"
                    // width={width}
                    // height={height}
                  />
                </div>
              </div>
              <div className="col-12 mt-4">
                <div className="row">
                  <div className="col-lg-4">
                    <label htmlFor="ss-subject">Subject</label>
                    <div className="input-subject mb-2">
                      {issueDetail?.subject}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="ss-select-status">Status</label>
                    <div name="ss-select-status" className="input-subject">
                      {issueDetail?.status === "WIP"
                        ? "WORK IN PROGRESS"
                        : issueDetail?.status}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <label htmlFor="ss-select-type">Type</label>
                    <div
                      name="ss-select-type"
                      id="ss-select-type"
                      className="input-subject"
                    >
                      {issueDetail?.type}
                    </div>
                  </div>
                </div>
                <label htmlFor="ss-description">Description</label>
                <div name="ss-description" className="rounded input-subject">
                  {issueDetail?.description}
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-end mt-2 ">
              <button
                onClick={tog_standard}
                type="button"
                id="confirmationTooltip1"
                className="btn btn-secondary waves-effect btn-label waves-light  "
              >
                <i className="mdi mdi-close me-3 label-icon"></i>Close
              </button>
            </div>
          </form>
        </div>
      </Modal>
    </div>
  );
};

export default IssuesDetail;
