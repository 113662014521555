import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import {
  Switch,
  BrowserRouter as Router,
  useLocation,
  useHistory,
} from "react-router-dom";
import { connect } from "react-redux";
import ScrollToTop from "./ScrollToTop";
import { del, get, post, put, patch } from "./helpers/api_helper";
import errorFunctionComponent from "./common/errorFunctionComponent";
// Import Routes all
import { userRoutes, authRoutes } from "./routes/allRoutes";
import Maintenance from "./pages/Maintenance/Maintenance";
// Import all middleware
import Authmiddleware from "./routes/middleware/Authmiddleware";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
// layouts Format
import VerticalLayout from "./components/VerticalLayout/";
import HorizontalLayout from "./components/HorizontalLayout/";
import NonAuthLayout from "./components/NonAuthLayout";
// Import scss
import "./assets/scss/theme.scss";
import "./assets/scss/preloader.scss";

// Import Firebase Configuration file
// import { initFirebaseBackend } from "./helpers/firebase_helper"

import fakeBackend from "./helpers/AuthType/fakeBackend";
// Activating fake backend
fakeBackend();

// const firebaseConfig = {
//   apiKey: process.env.REACT_APP_APIKEY,
//   authDomain: process.env.REACT_APP_AUTHDOMAIN,
//   databaseURL: process.env.REACT_APP_DATABASEURL,
//   projectId: process.env.REACT_APP_PROJECTID,
//   storageBucket: process.env.REACT_APP_STORAGEBUCKET,
//   messagingSenderId: process.env.REACT_APP_MESSAGINGSENDERID,
//   appId: process.env.REACT_APP_APPID,
//   measurementId: process.env.REACT_APP_MEASUREMENTID,
// }

// init firebase backend
// initFirebaseBackend(firebaseConfig)

const App = (props) => {
  const { errorFunction, errorFunctionNotSwal } = errorFunctionComponent();
  const storage = JSON.parse(localStorage.getItem("authUser"));
  function getLayout() {
    let layoutCls = VerticalLayout;
    switch (props.layout.layoutType) {
      case "horizontal":
        layoutCls = HorizontalLayout;
        break;
      default:
        layoutCls = VerticalLayout;
        break;
    }
    return layoutCls;
  }

  const Layout = getLayout();
  const resetInactivityTimer = () => {
    let inactivityTimer;
    clearTimeout(inactivityTimer);
    if (localStorage.getItem("authUser")) {
      inactivityTimer = setTimeout(() => {
        showSweetAlert(
          "Your session will be terminated if no activity is detected within 1 minute."
        );
      }, 1);
    }
  };
  const resetInactivityTimer2 = () => {
    let inactivityTimer;
    clearTimeout(inactivityTimer);
    if (localStorage.getItem("authUser")) {
      inactivityTimer = setTimeout(() => {
        logoutFunc();
      }, 1);
    }
  };
  const logoutFunc = async () => {
    try {
      let result = await post(`/account/logout`, {
        refresh: storage?.value.data?.refresh,
      });
      if (result?.success) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("profileInfo");
        window.location.reload();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunctionNotSwal(error);
      if (error?.response?.status === 401) {
        localStorage.removeItem("authUser");
        localStorage.removeItem("profileInfo");
        window.location.reload();
      }
    }
  };
  useEffect(() => {
    let interactionTimeout;
    let interactionTimeout2;

    const handleInteraction = () => {
      clearTimeout(interactionTimeout);
      interactionTimeout = setTimeout(
        () => {
          resetInactivityTimer();
        },
        storage?.value?.role === "ADMIN" ? 14 * 60 * 1000 : 4 * 60 * 1000
      );
    };
    const handleInteraction2 = () => {
      clearTimeout(interactionTimeout2);
      interactionTimeout2 = setTimeout(
        () => {
          resetInactivityTimer2();
        },
        storage?.value?.role === "ADMIN" ? 15 * 60 * 1000 : 5 * 60 * 1000
      );
    };
    window.addEventListener("click", handleInteraction);
    window.addEventListener("keydown", handleInteraction);
    window.addEventListener("click", handleInteraction2);
    window.addEventListener("keydown", handleInteraction2);

    return () => {
      window.removeEventListener("click", handleInteraction);
      window.removeEventListener("keydown", handleInteraction);
      window.removeEventListener("click", handleInteraction2);
      window.removeEventListener("keydown", handleInteraction2);
    };
  }, []);

  useEffect(() => {
    const watchLocalStorageExpiry = () => {
      const now = new Date().getTime();
      for (let i = 0; i < localStorage.length; i++) {
        const key = localStorage.key(i);
        const value = localStorage.getItem(key);

        try {
          const data = JSON.parse(value);
          if (data && data.expireTime && data.expireTime <= now) {
            // Süresi geçmiş veriyi temizle
            // localStorage.removeItem(key);
            // window.location.reload();
            logoutFunc();
          }
        } catch (error) {
          // JSON ayrıştırma hatası olursa devam et
          // console.log(`json ayrıştırma hatası ==>`, error);
        }
      }
    };

    // İlk çalıştırma
    watchLocalStorageExpiry();

    // Belirli bir aralıkla tekrar çalıştırılabilir
    const intervalId = setInterval(watchLocalStorageExpiry, 60000); // Örneğin her 1 dakikada bir

    // Component unmount olduğunda interval'ı temizle
    return () => clearInterval(intervalId);
  }, []);

  const MySwal = withReactContent(Swal);

  const showSweetAlert = (msg) => {
    MySwal.fire({
      title: <p>{msg}</p>,
      // text: "This is a SweetAlert2 dialog in a React app.",
      icon: "warning",
      timer: 60 * 1000,
      timerProgressBar: true, // İlerleme çubuğunu göster
      customClass: {
        progressBar: "my-custom-progress-bar-class", // Özel CSS sınıfı
      },
      showConfirmButton: false,
    });
  };

  return (
    <React.Fragment>
      <Router>
        <ToastContainer />
        <Switch>
          {authRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={NonAuthLayout}
              component={route.component}
              key={idx}
              isAuthProtected={false}
              exact
            />
          ))}

          {userRoutes.map((route, idx) => (
            <Authmiddleware
              path={route.path}
              layout={Layout}
              component={route.component}
              key={idx}
              isAuthProtected={true}
              exact
            />
          ))}
        </Switch>
      </Router>
      <ScrollToTop />
    </React.Fragment>
  );
};

App.propTypes = {
  layout: PropTypes.any,
};

const mapStateToProps = (state) => {
  return {
    layout: state.Layout,
  };
};

export default connect(mapStateToProps, null)(App);
