import React from "react";
import { Link, withRouter } from "react-router-dom";

const DistributorSidebar = (propsValues) => {
  const props = propsValues.propsValues;
  return (
    <>
      <li>
        <Link to="/dashboard" className="">
          <i className="mdi mdi-view-dashboard "></i>
          <span>{props.t("Dashboard")}</span>
        </Link>
      </li>
      <li>
        <Link to="/distributor/customers" className="">
          <i className="mdi mdi-account-group-outline "></i>
          <span>{props.t("Customers")}</span>
        </Link>
      </li>
      <li>
        <Link to="/distributor/managers" className="">
          <i className="mdi mdi-account-tie-voice-outline"></i>
          <span>{props.t("Managers")}</span>
        </Link>
      </li>
      <li>
        <Link to="/distributor/profile" className="">
          <i className="mdi mdi-account-lock-outline"></i>
          <span>{props.t("Profile")}</span>
        </Link>
      </li>
    </>
  );
};

export default DistributorSidebar;
