import React, { useState } from "react";
import { Modal } from "reactstrap";
import { put } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../common/utils/commonFunctions";

const AddMailboxThreats = ({
  modal_standard,
  tog_standard,
  setmodal_standard,
  getMailboxThreatsAsset,
  moduleID,
}) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [address, set_address] = useState("");
  const [port, set_port] = useState("143");
  const [username, set_username] = useState("");
  const [password, set_password] = useState("");
  const [protocol, set_protocol] = useState("IMAP");
  const [ssl, set_ssl] = useState(true);

  const [modal_standard5, setmodal_standard5] = useState(false);

  const [addLoading, set_addLoading] = useState(false);

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const addMailboxThreatsAsset = async () => {
    paceFunction(true);
    set_addLoading(true);
    let data = {
      id: moduleID,
      address,
      port,
      protocol,
      username,
      password,
      is_ssl: ssl,
    };
    try {
      let result = await put("/customer/module/asset", data);
      if (result?.success) {
        toastSuccess(result?.message || "Asset Added");
        tog_standard5();
        tog_standard();
        getMailboxThreatsAsset();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_addLoading(false);
    paceFunction(false);
  };

  return (
    <div>
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={addMailboxThreatsAsset}
        text="Are you sure you want to add asset?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <Modal
        isOpen={modal_standard}
        toggle={() => {
          tog_standard();
        }}
        size="lg"
      >
        <div className="modal-header">
          <h5 className="modal-title mt-0" id="myModalLabel">
            Add Record
          </h5>
          <button
            type="button"
            onClick={() => {
              setmodal_standard(false);
            }}
            className="close"
            data-dismiss="modal"
            aria-label="Close"
          >
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <div className="modal-body">
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-is_credentials"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="protocol">Protocol:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <select
                className="form-control"
                name="protocol"
                style={{ border: "1px solid #3fa0da" }}
                id="protocol"
                defaultValue="IMAP"
                onChange={(e) => set_protocol(e.target.value)}
              >
                <option value="IMAP">IMAP</option>
                <option value="POP3">POP3</option>
                {/* <option value="SMTP">SMTP</option> */}
              </select>
              <label id="is_credentials-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="address">Mailbox Url:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                name="address"
                id="address"
                placeholder="Mailbox Url imap.google.com (without schema)"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_address(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="port">Port:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="number"
                pattern=".*"
                name="port"
                id="port"
                min={1}
                max={65535}
                defaultValue={143}
                style={{ overflow: "hidden" }}
                onChange={(e) => set_port(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="username">Username/E-mail:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                name="username"
                id="username"
                placeholder="Username/E-mail"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_username(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="password">Password:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <input
                className="form-control"
                type="text"
                pattern=".*"
                name="password"
                id="password"
                placeholder="Password"
                style={{ overflow: "hidden" }}
                onChange={(e) => set_password(e.target.value)}
              />
              <label id="url-label" className="errorLabel" />
            </div>
            <div style={{ clear: "both" }} />
          </div>
          <div
            style={{ marginLeft: "initial", marginRight: "initial" }}
            className="form-group row"
            id="alteditor-row-url"
          >
            <div
              className="col-sm-3 col-md-3 col-lg-3 text-right"
              style={{ paddingTop: "4px" }}
            >
              <label htmlFor="ssl">SSL:</label>
            </div>
            <div className="col-sm-8 col-md-8 col-lg-8">
              <div className="square-switch">
                <input
                  type="checkbox"
                  id="ssl"
                  name="ssl"
                  switch="primary"
                  defaultChecked={ssl}
                  autoComplete="off"
                  onChange={(e) => set_ssl(e.target.checked)}
                />
                <label htmlFor="ssl" data-on-label="Yes" data-off-label="No" />
              </div>
            </div>
            <div style={{ clear: "both" }} />
          </div>
        </div>
        <div className="modal-footer">
          <button
            type="button"
            onClick={() => {
              tog_standard();
            }}
            className="btn btn-secondary "
            data-dismiss="modal"
          >
            Close
          </button>
          <button
            onClick={tog_standard5}
            type="button"
            className="btn btn-primary "
          >
            Add Record
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default AddMailboxThreats;
