import React, { useState } from "react";
import { del } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import { useHistory } from "react-router-dom";
import {
  getBrightness,
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";

const SubscriptionsComponent = ({ subscriptions, getSubscriptions }) => {
  const router = useHistory();

  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [type, set_type] = useState();
  const [ID, set_ID] = useState();

  const [deleteLoading, set_deleteLoading] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const unsubscribeVendorProduct = async () => {
    paceFunction(true);
    set_deleteLoading(true);
    try {
      let result = await del(
        `/customer/module/cyberwatch/ti/cves/subscriptions?id=${ID}&type=${type}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Unsubscribed");
        tog_standard();
        getSubscriptions();
        set_ID();
        set_type();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    set_deleteLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <ConfirmModal
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        targetFunction={unsubscribeVendorProduct}
        text={`Are you sure you want to unsubscribe?`}
        loadingAction={true}
        isLoading={deleteLoading}
      />
      <div className="row">
        <div className="col-lg-6 mb-lg-0 mb-2">
          <div className="special-card ">
            <div className="card-header with-border">
              <h3 className="card-title">
                My Vendors ({subscriptions?.vendors?.length})
              </h3>
            </div>
            <div className="card-body table-responsive no-padding">
              <table className="table table-hover table-striped  ">
                <tbody>
                  <tr>
                    <th>Vendor</th>
                    <th>Tags</th>
                    <th className="text-end">Actions</th>
                  </tr>
                  {subscriptions?.vendors?.map((vendor, vendorIndex) => {
                    return (
                      <tr key={vendorIndex}>
                        <td className="">{vendor?.name}</td>
                        <td className="">
                          {" "}
                          {vendor?.tag__name !== null && (
                            <div
                              className="badge p-2 me-1 "
                              style={{
                                color: `${
                                  getBrightness(vendor?.tag__color) < 128
                                    ? "white"
                                    : "black"
                                }`,
                                backgroundColor: vendor?.tag__color,
                              }}
                            >
                              {vendor?.tag__name}
                            </div>
                          )}
                        </td>
                        <td className="text-end">
                          <div className="btn-group">
                            <button
                              onClick={() => {
                                set_ID(vendor?.id);
                                set_type("vendor");
                                tog_standard();
                              }}
                              className="btn btn-danger btn-sm "
                            >
                              Unsubscribe
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {subscriptions?.vendors?.length < 1 && (
                <div className="w-100  py-2 text-center ">
                  You have no subscription yet, click{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      router.push(
                        "/cyber-watch/threat-intelligence/vendor-product"
                      )
                    }
                    className=" text-info fw-bold"
                  >
                    here
                  </span>{" "}
                  to view the entire list of vendors and products.
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="col-lg-6 mb-lg-0 mb-2">
          <div className="special-card ">
            <div className="card-header with-border">
              <h3 className="card-title">
                My Products ({subscriptions?.products?.length})
              </h3>
            </div>
            <div className="card-body table-responsive no-padding">
              <table className="table table-hover table-striped  ">
                <tbody>
                  <tr>
                    <th>Vendor</th>
                    <th>Product</th>
                    <th>Tags</th>
                    <th className="text-end">Actions</th>
                  </tr>
                  {subscriptions?.products?.map((product, productIndex) => {
                    return (
                      <tr key={productIndex}>
                        <td>{product?.vendor_name || "-"}</td>
                        <td>{product?.name}</td>
                        <td>
                          {" "}
                          {product?.tag__name !== null && (
                            <div
                              className="badge p-2 me-1 "
                              style={{
                                color: `${
                                  getBrightness(product?.tag__color) < 128
                                    ? "white"
                                    : "black"
                                }`,
                                backgroundColor: product?.tag__color,
                              }}
                            >
                              {product?.tag__name}
                            </div>
                          )}
                        </td>
                        <td className="text-end">
                          <div className="btn-group">
                            <button
                              onClick={() => {
                                set_ID(product?.id);
                                set_type("product");
                                tog_standard();
                              }}
                              className="btn btn-danger btn-sm "
                            >
                              Unsubscribe
                            </button>
                          </div>
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
              </table>
              {subscriptions?.products?.length < 1 && (
                <div className="w-100  py-2 text-center ">
                  You have no subscription yet, click{" "}
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      router.push(
                        "/cyber-watch/threat-intelligence/vendor-product"
                      )
                    }
                    className=" text-info fw-bold"
                  >
                    here
                  </span>{" "}
                  to view the entire list of vendors and products.
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </React.Fragment>
  );
};

export default SubscriptionsComponent;
