import React, { useState } from "react";
import { del, put } from "../../../../helpers/api_helper";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";
import AddNewApplianceModal from "./AddNewApplianceModal";

const Appliance = ({ data, getAppliance }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [newApplianceData, setNewApplianceData] = useState();

  const [removeLoading, setRemoveLoading] = useState(false);
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard6, setmodal_standard6] = useState(false);

  const [popoverOpen, setPopoverOpen] = useState(false);

  const togglePopover = () => {
    setPopoverOpen(!popoverOpen);
  };

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard6 = () => {
    setmodal_standard6(!modal_standard6);
    removeBodyCss();
  };

  const addAppliance = async () => {
    paceFunction(true);
    try {
      let result = await put("/customer/settings/endpoint");
      if (result?.success) {
        setNewApplianceData(result?.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    paceFunction(false);
  };

  const removeAppliance = async () => {
    paceFunction(true);
    setRemoveLoading(true);
    try {
      let result = await del("/customer/settings/endpoint");
      if (result?.success) {
        tog_standard6();
        toastSuccess(result?.message || "Appliance was removed.", 2000);
        getAppliance();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setRemoveLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      {/* Setup Appliance */}
      {newApplianceData && (
        <AddNewApplianceModal
          modal_standard={modal_standard}
          tog_standard={tog_standard}
          setmodal_standard={setmodal_standard}
          newApplianceData={newApplianceData}
          getAppliance={getAppliance}
        />
      )}
      {/* Remove Appliance */}
      <ConfirmModal
        modal_standard={modal_standard6}
        tog_standard={tog_standard6}
        setmodal_standard={setmodal_standard6}
        targetFunction={removeAppliance}
        text="Are you sure you want to remove appliance?"
        loadingAction={true}
        isLoading={removeLoading}
      />
      {data?.success && (
        <div className="special-card my-4">
          <div className="">
            <div className="d-flex flex-md-row flex-column justify-content-between align-items-center   ">
              <div className="align-items-center d-flex fw-bold fs-4 ">
                <span>Vpn Service</span>
                <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                <span>{data?.data?.vpn_service}</span>
              </div>{" "}
              <a
                className="btn-floating btn-lg btn-default"
                href="https://www.youtube.com/watch?v=VV511tWSnT4"
                target="_blank"
                rel="noreferrer"
              >
                <i
                  title="Watch Video"
                  className="mdi mdi-help-circle-outline label-icon text-primary  fs-2 "
                ></i>
              </a>
              {data?.data?.is_connect ? (
                <span className="fw-bold fs-4 ">
                  <i className="mdi mdi-lan-connect me-3 text-success" />
                  Connected
                </span>
              ) : (
                <span className="fw-bold fs-4 ">
                  <i className="mdi mdi-lan-disconnect me-3 text-danger" />
                  Disconnected
                </span>
              )}
            </div>
          </div>
        </div>
      )}
      {data?.success ? (
        <button
          onClick={tog_standard6}
          type="button"
          className="btn btn-primary waves-effect btn-label waves-light w-100 mt-2 "
        >
          <i className="mdi mdi-trash-can label-icon"></i> Remove
        </button>
      ) : (
        <button
          onClick={() => {
            tog_standard();
            addAppliance();
          }}
          type="button"
          className="btn btn-success waves-effect btn-label waves-light w-100 mt-2 "
        >
          <i className="mdi mdi-cloud-download label-icon"></i> Set Up Appliance
        </button>
      )}
    </React.Fragment>
  );
};

export default React.memo(Appliance);
