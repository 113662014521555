import React, { useState } from "react";
import { Row } from "reactstrap";
import LogsTable from "./LogsTable";

const Logs = ({ logs }) => {
  const [entryValue, setEntryValue] = useState(10);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  return (
    <div className="special-card">
      <Row>
        <LogsTable
          data={logs}
          entryValue={entryValue}
          changeValue={(e) => changeValue(e)}
        />
      </Row>
    </div>
  );
};

export default Logs;
