import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import NotificationsTable from "../../../components/Customer/Notifications/NotificationsTable";
import { Container, Spinner } from "reactstrap";
import { get } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const Notifications = () => {
  document.title = "Dashboard | PentestBX";

  const [isLoading, setIsLoading] = useState(false);
  const [firstLoading, set_firstLoading] = useState(false);
  const [renderGetNotification, set_renderGetNotification] = useState(false);
  const [data, setData] = useState();
  const [entryValue, setEntryValue] = useState(10);
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getNotifications = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/settings/notifications");
      if (result?.success) {
        setIsLoading(false);
        setData(result.data);
        set_firstLoading(true);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    if (!firstLoading) {
      setIsLoading(true);
    }
    getNotifications();
  }, [renderGetNotification]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Notifications" breadcrumbItem="Notifications" />

          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && data && (
            <div
              style={{
                overflowX: windowWidth < 800 ? "scroll" : "none",
              }}
              className="special-card"
            >
              <NotificationsTable
                firstData={data.slice().reverse()}
                entryValue={entryValue}
                changeValue={(e) => changeValue(e)}
                renderGetNotification={renderGetNotification}
                set_renderGetNotification={(e) => set_renderGetNotification(e)}
              />
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default Notifications;
