import React, { useEffect, useState } from "react";
import { get } from "../../../helpers/api_helper";
import { CardHeader, CardBody, Spinner } from "reactstrap";
import AddCyberWatch from "./AddCyberWatch";
import errorFunctionComponent from "../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../common/paceFunctionComponent";
import CyberWatchAssetTable from "./CyberWatchAssetTable";
import { removeBodyCss } from "../../../../common/utils/commonFunctions";

const CyberWatch = ({ cybers }) => {
  const { errorFunctionNotSwal } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [assets, set_assets] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [entryValue, setEntryValue] = useState(10);

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const changeValue = (value) => {
    setEntryValue(value);
  };

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const getCyberWatchAsset = async () => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/asset?id=${cybers?.id}`);
      if (result?.success) {
        const updatedData = result?.data?.assets?.map((asset) => {
          if (asset?.discovered) {
            return {
              ...asset,
              discovered_type: "true",
            };
          } else {
            return {
              ...asset,
              discovered_type: "false",
            };
          }
        });
        set_assets(updatedData);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunctionNotSwal(error);
    }
    setIsLoading(false);
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getCyberWatchAsset();
  }, []);

  return (
    <div className="special-card h-100">
      <AddCyberWatch
        getCyberWatchAsset={getCyberWatchAsset}
        moduleID={cybers?.id}
        tog_standard={tog_standard}
        modal_standard={modal_standard}
        setmodal_standard={(e) => setmodal_standard(e)}
      />
      <CardHeader>
        <div className="d-flex align-items-center justify-content-between ">
          <h4 className="card-title text-primary ">Cyber Watch</h4>
          <button onClick={tog_standard} className="btn btn-primary ">
            Add Cyber Watch
          </button>
        </div>
      </CardHeader>
      {isLoading && (
        <div className="d-flex justify-content-center mt-4   align-items-center h-100  ">
          <Spinner
            style={{ width: "2rem", height: "2rem" }}
            className="ms-2 "
            color="primary"
          />
        </div>
      )}
      {!isLoading && assets && (
        <CardBody>
          <div
            style={{
              overflowX: windowWidth < 800 ? "overlay" : "none",
            }}
          >
            <CyberWatchAssetTable
              getCyberWatchAsset={getCyberWatchAsset}
              moduleID={cybers?.id}
              data={assets}
              entryValue={entryValue}
              changeValue={(e) => changeValue(e)}
            />
          </div>
        </CardBody>
      )}
    </div>
  );
};

export default CyberWatch;
