import React from "react";

const DynamicLastScoreModule = ({ title, score }) => {
  return (
    <div className="special-score-card">
      <div className="d-flex  py-2  align-items-center justify-content-between  ">
        <div>
          <span className="fw-bold fs-4 me-1 ">{title} </span>
        </div>
      </div>
      <div>
        <div
          style={{
            height: "265px",
          }}
          className={`row-bs row row-bs bg-hexagon-${
            score == null
              ? "g"
              : score <= 0.8
              ? "a"
              : score <= 1.5
              ? "b"
              : score <= 2.4
              ? "c"
              : score <= 3.5
              ? "d"
              : "e"
          } pb-5  pt-2 align-items-center justify-content-center `}
        >
          <div className="hexagon   mt-5 ">
            <span className="">
              {score == null
                ? ""
                : score <= 0.8
                ? "A"
                : score <= 1.5
                ? "B"
                : score <= 2.4
                ? "C"
                : score <= 3.5
                ? "D"
                : "E"}
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DynamicLastScoreModule;
