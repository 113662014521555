import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import ReportCompare from "../../../components/Customer/ReportCompare/ReportCompare";
import { Container, Spinner } from "reactstrap";
import { get } from "../../../helpers/api_helper";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const ReportCompareMain = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [selectedModule, setSelectedModule] = useState("");
  const [modules, setModules] = useState([]);
  const [dates, setDates] = useState([]);
  const [assetDatas, setAssetDatas] = useState([]);

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const getModules = async () => {
    paceFunction(true);
    try {
      let result = await get("/customer/module");
      if (result?.success) {
        const filteredData = result.data.modules.map((item) => {
          return {
            id: item.id,
            name: item.name,
            text: item.text,
          };
        });
        setModules(filteredData);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getModules();
  }, []);

  const getAssets = async (id) => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/asset?id=${id}`);
      if (result?.success) {
        setAssetDatas(result.data);
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  const getAuditsOverview = async (id) => {
    paceFunction(true);
    try {
      let result = await get(`/customer/module/overview?id=${id}`);
      if (result?.success) {
        if (selectedModule === "HOST") {
          const updatedAtDizisi = result.data.scan_history.map(
            (item) => item.updated_at
          );
          const sortedDates = result.data.scan_history
            .slice() // Orijinal diziyi değiştirmemek için kopyasını oluşturuyoruz
            .sort((a, b) => new Date(b.updated_at) - new Date(a.updated_at));
          setDates(sortedDates);
        } else if (selectedModule === "WEB_APPLICATION") {
          setDates(result.data);
        } else if (selectedModule === "MXTHREAT") {
          setDates(result.data);
        }
      }
    } catch (error) {
      console.log(`Error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
  };

  useEffect(() => {
    setDates([]);
    if (selectedModule === "HOST") {
      let moduleID;
      const hostVerisi = modules.find((item) => item.name === "HOST");
      if (hostVerisi) {
        moduleID = hostVerisi.id;
      }
      getAssets(moduleID);
      getAuditsOverview(moduleID);
    }
    if (selectedModule === "WEB_APPLICATION") {
      let moduleID;
      const hostVerisi = modules.find(
        (item) => item.name === "WEB_APPLICATION"
      );
      if (hostVerisi) {
        moduleID = hostVerisi.id;
      }
      getAuditsOverview(moduleID);
    }
    if (selectedModule === "MXTHREAT") {
      let moduleID;
      const hostVerisi = modules.find((item) => item.name === "MXTHREAT");
      if (hostVerisi) {
        moduleID = hostVerisi.id;
      }
      getAuditsOverview(moduleID);
    }
  }, [selectedModule]);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Report Compare" breadcrumbItem="Report Compare" />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && (
            <ReportCompare
              assetDatas={assetDatas}
              modules={modules}
              setSelectedModule={(e) => setSelectedModule(e)}
              selectedModule={selectedModule}
              dates={dates}
            />
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default ReportCompareMain;
