import React, { useState } from "react";
import AddGrantAccess from "./AddGrantAccess";
import {
  removeBodyCss,
  toastSuccess,
} from "../../../../../common/utils/commonFunctions";
import errorFunctionComponent from "../../../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../../../common/paceFunctionComponent";
import { del } from "../../../../helpers/api_helper";
import ConfirmModal from "../../../../../common/utils/ConfirmModal";

const GrantAccess = ({ grantAccess, getGrantAccess }) => {
  const { errorToastFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [accountID, set_accountID] = useState();
  const [modal_standard, setmodal_standard] = useState(false);
  const [modal_standard5, setmodal_standard5] = useState(false);
  const [addLoading, setAddLoading] = useState(false);

  const tog_standard = () => {
    setmodal_standard(!modal_standard);
    removeBodyCss();
  };

  const tog_standard5 = () => {
    setmodal_standard5(!modal_standard5);
    removeBodyCss();
  };

  const deleteGrantAccess = async () => {
    paceFunction(true);
    setAddLoading(true);
    try {
      let result = await del(
        `/distributor/settings/linked-account?id=${accountID}`
      );
      if (result?.success) {
        toastSuccess(result?.message || "Linked Account Deleted");
        tog_standard5();
        getGrantAccess();
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorToastFunction(error);
    }
    setAddLoading(false);
    paceFunction(false);
  };

  return (
    <React.Fragment>
      <AddGrantAccess
        modal_standard={modal_standard}
        tog_standard={tog_standard}
        setmodal_standard={setmodal_standard}
        getGrantAccess={getGrantAccess}
      />
      {/* Delete Linked Account */}
      <ConfirmModal
        modal_standard={modal_standard5}
        tog_standard={tog_standard5}
        setmodal_standard={setmodal_standard5}
        targetFunction={deleteGrantAccess}
        text="Are you sure you want to delete linked account?"
        loadingAction={true}
        isLoading={addLoading}
      />
      <div className=" mt-3  ">
        <div className="  d-flex align-items-start justify-content-center">
          <button
            type="button"
            className="btn btn-soft-primary special-card-button"
            onClick={tog_standard}
          >
            <i className="mdi mdi-view-grid label-icon  me-1 " />
            Add Grant Access
          </button>
        </div>
      </div>
      <hr className="my-2" />
      <div className="row mt-3 ">
        {grantAccess?.map((item, index) => (
          <div key={index} className="col-lg-6 mb-4 ">
            <div className="special-card">
              <div className="d-flex justify-content-center align-items-center flex-sm-row flex-column    fs-6 fw-bold text-primary  ">
                <button
                  onClick={() => {
                    set_accountID(item.id);
                    tog_standard5();
                  }}
                  id="confirmationTooltipGrant"
                  type="button"
                  className="btn btn-soft-primary   special-card-button"
                >
                  <i className="mdi mdi-delete label-icon  me-1 " />
                  Delete
                </button>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-sm-row flex-column    fs-6 fw-bold text-primary  ">
                <div className="d-flex align-items-center font-size-16">
                  <strong className="me-1 badge bg-primary">E-mail</strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span className=" badge bg-primary">{item.email}</span>
                </div>
                <div className="d-flex align-items-center font-size-16 ">
                  <strong className="me-1 badge bg-primary">Phone</strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span className=" badge bg-primary">
                    {item?.phone_number}
                  </span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-sm-row flex-column    fs-6 fw-bold text-primary  ">
                <div className="d-flex align-items-center font-size-16">
                  <strong className="me-1 badge bg-primary">
                    Company Name
                  </strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span className=" badge bg-primary">
                    {item.organization_name}
                  </span>
                </div>
                <div className="d-flex align-items-center font-size-16">
                  <strong className="me-1 badge bg-primary">Address</strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span className=" badge bg-primary">{item?.address}</span>
                </div>
              </div>
              <div className="d-flex justify-content-between align-items-center flex-sm-row flex-column    fs-6 fw-bold text-primary  ">
                <div className="d-flex align-items-center font-size-16">
                  <strong className="me-1 badge bg-primary">City</strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span className=" badge bg-primary">{item.city}</span>
                </div>
                <div className="d-flex align-items-center font-size-16">
                  <strong className="me-1 badge bg-primary">State</strong>
                  <i className="mdi mdi-arrow-right-thin me-1 text-primary fs-3 " />
                  <span className=" badge bg-primary">{item?.state}</span>
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
    </React.Fragment>
  );
};

export default GrantAccess;
