import React, { useEffect, useState } from "react";
import Breadcrumbs from "../../../components/Common/Breadcrumb";
import { Container, Spinner } from "reactstrap";
import { get } from "../../../helpers/api_helper";
import RejectedRenewalsTable from "../../../components/Admin/RejectedRenewal/RejectedRenewalTable";
import errorFunctionComponent from "../../../common/errorFunctionComponent";
import paceFunctionComponent from "../../../common/paceFunctionComponent";

const RejectedRenewal = () => {
  document.title = "Rejected Renewal | PentestBX";

  const { errorFunction } = errorFunctionComponent();
  const { paceFunction } = paceFunctionComponent();

  const [isLoading, setIsLoading] = useState();
  const [data, set_data] = useState();

  const [entryValue, setEntryValue] = useState(10);

  const changeValue = (value) => {
    setEntryValue(value);
  };

  const getRejectedRenewals = async () => {
    paceFunction(true);
    try {
      let result = await get(`/admin/customer/rejected_renewal`);
      if (result?.success) {
        set_data(result.data);
      }
    } catch (error) {
      console.log(`error ==>`, error);
      errorFunction(error);
    }
    paceFunction(false);
    setIsLoading(false);
  };

  useEffect(() => {
    setIsLoading(true);
    getRejectedRenewals();
  }, []);

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs
            title="Rejected Renewal"
            breadcrumbItem="Rejected Renewal"
          />
          {isLoading && (
            <div className="d-flex justify-content-center  align-items-center h-100  ">
              <Spinner
                style={{ width: "3rem", height: "3rem" }}
                className="ms-2 "
                color="primary"
              />
            </div>
          )}
          {!isLoading && data && (
            <div className="special-card">
              <div className="row">
                <RejectedRenewalsTable
                  data={data}
                  entryValue={entryValue}
                  changeValue={(e) => changeValue(e)}
                />
              </div>
            </div>
          )}
        </Container>
      </div>
    </React.Fragment>
  );
};

export default RejectedRenewal;
